import {
  Form,
  Row,
  Col,
  Select,
  Input,
  DatePicker,
  Typography,
  Item,
  message,
} from "antd";
import React, { useState, useEffect } from "react";
import moment from 'moment'
import "../../Styles/subtenant.css";
import Services from "../../network/Services";
import { useRef } from "react";
import { isValidFuntion } from "../../control/Constant";
import konsole from "../../network/konsole";
import DatePickerComp from "../DatePickerComp";
const { Option } = Select;

export default function PersonalInfo(props) {
  const [form] = Form.useForm();
  const [gender, setGender] = useState([]);
  const { Option } = Select;
  const { Title } = Typography;
  const [citizenship, setCitizenship] = useState([]);
  const [dateSatate, setDateSatate] = useState(null)
  const [startDate, setStartDate] = useState('');
  const getDateData = (date, dateString) => {
    konsole.log("dateString", dateString);
  };

  useEffect(()=>{
    if(props.updatePartnerUsersData !== null && props.updatePartnerUsersData !== undefined && props.updatePartnerUsersData !== ""){

      props.form.setFieldsValue({
        'fName' : props?.updatePartnerUsersData?.fName,
        'mName' : props?.updatePartnerUsersData?.mName,
        'lName' : props?.updatePartnerUsersData?.lName,
        'dob' : (isValidFuntion(props?.updatePartnerUsersData?.dob) && moment(props?.updatePartnerUsersData?.dob).format("YYYY-MM-DD")),
        'genderId' : props?.updatePartnerUsersData?.genderId !== 0 && props?.updatePartnerUsersData?.genderId !== null && props?.updatePartnerUsersData?.genderId !== undefined && props?.updatePartnerUsersData?.genderId !== ""? props?.updatePartnerUsersData?.genderId : null ,
      })
      setDateSatate(isValidFuntion(props?.updatePartnerUsersData?.dob) && moment(props?.updatePartnerUsersData?.dob).format("YYYY-MM-DD"))
      getMemberAPiFunction(props.updatePartnerUsersData?.userId)
      setStartDate(isValidFuntion(props?.updatePartnerUsersData?.dob) && moment(props?.updatePartnerUsersData?.dob).format("YYYY-MM-DD"))
    }
  },[])

const [presonalCitizenShip, setPresonalCitizenShip] = useState()
  const getMemberAPiFunction = (userId) =>{

    let promise = Services.getMemberDetails(userId)
    promise.then((response)=>{
      konsole.log("getMemberAPiFunction",response.data.data)
      setPresonalCitizenShip(response.data?.data?.member?.citizenshipId)
      props.form.setFieldsValue({
        'Citizenship': response.data?.data?.member?.citizenshipId,

      })
      // setGetMemberDetailsData(response.data?.data?.member)
     
    }).catch((error)=>{
      konsole.log("getMemberAPiFunctionError",error)
      
    }).finally(()=>{
      konsole.log("getContactDetailsEnd")

    })

  }
  const handleDateData =(date)=>{
    setDateSatate(date)
    props.form.setFieldsValue({
      'dob' : date
    })
  }

 

  useEffect(()=>{
    getCitizenship();
    let genderspramas = Services.getgenders();
    genderspramas
      .then((res) => {
        konsole.log("response gender", res)
        setGender(res.data.data);
      })
      .catch((err) => {
        konsole.log(err);
        // message.error("Enter valid details")

      });
  },[])

  const getCitizenship = () => {
    let getCitizenshipPromise = Services.getCitizenshipType();
    getCitizenshipPromise
      .then((res) => {
        setCitizenship(res.data.data);
      })
      .catch((err) => {
        konsole.log(err);
      });
  };
  
  function Capitalize(str){
    return str.charAt(0).toUpperCase() + str.slice(1)
    }

  return (
    <card
      style={{
        border: "1px dashed black",
        borderRadius: "5px",
        padding: "5px",
      }}
    >
      <Title level={5} style={{ color: "#720C20" }}>
        Personal Information
      </Title>
      <Row className="w-100" gutter={[8, 8]}>
        <Col xs={24} lg={8}>
          <Form.Item
            name="fName"
            className="mt-1"
            onSearch={Capitalize("" )}
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
              },
              {
                pattern: /^[a-zA-Z\d]+$/,
                message: "Please input Valid First Name!",
              },
              
            ]}
          >
            <Input placeholder="First Name"   onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}  />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            name="mName"
            className="mt-1"
            rules={[
              {
                pattern: /^[a-zA-Z\d]+$/,
                message: "Please input Valid Middle Name!",
              },
            ]}
          >
            <Input placeholder="Middle Name" onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}  />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            name="lName"
            className="mt-1"
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
              },

              {
                pattern: /^[a-zA-Z\d]+$/,
                message: "Please input Valid Last Name!",
              },
            ]}
          >
            <Input placeholder="Last Name" onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}  />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            name="dob"
            className="mt-1"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input your Date of Birth",
            //   },
            // ]}
          >
            {/* <DatePicker
              className="w-100"
              placeholder="Date of Birth"
              onChange={getDateData}
              format='MM-DD-YYYY'
              valueFormat='MM-DD-YYYY'
            /> */}
            <DatePickerComp  
                className="w-100" 
                value={startDate}
                placeholderText="Date of Birth"
                handleDateData={handleDateData}
                dateSatate = {dateSatate}
                setDateSatate ={setDateSatate}
                future='show'/>

          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item className="mt-1"name="genderId"
          >
            <Select placeholder="Gender"className="Input"defaultValue={props?.updatePartnerUsersData?.genderId}
              value={props?.updatePartnerUsersData?.genderId}>
              {gender.map((genderprop) => (
                <Option value={ (props?.updatePartnerUsersData?.genderId == genderprop.value)? props?.updatePartnerUsersData?.genderId : genderprop.value  }>{genderprop.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item  name="Citizenship"className="mt-1">
            <Select placeholder="Citizenship"defaultValue={props.form.getFieldsValue()?.Citizenship}
              value={props.form.getFieldsValue()?.Citizenship}className="Input"style={{marginRight: 15}}showSearch optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {citizenship.map((data) =>
              
              (
                
                <Select.Option value={presonalCitizenShip == data.value? presonalCitizenShip : data.value }>{data.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </card>
  );
}
