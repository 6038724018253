import React, { useEffect, useState, useContext } from "react";
import "./Firmpartner.css";
import {
  Select,
  Tag,
  Form,
  Button,
  Table,
  DatePicker,
  TimePicker,
  Space,
  Row,
  Col,
  Checkbox,
  message,
  Alert,
  List
} from "antd";

import Services from "../../network/Services";
import moment from "moment";
import "./scheduletimeslot.css";
import { formatCountdown } from "antd/lib/statistic/utils";
import { Toast } from "bootstrap";
import konsole from "../../network/konsole";
import { UserContext } from "../../App";
import commonLib from "../../control/commonLib";
import SendEmailModal from '../SendEmailModal';
import { getDay } from "date-fns";

const { Option } = Select;
let index = 0;

const SchedulePlanner2 = (props) => {


  const [form] = Form.useForm();
  const dateFormat = "MM/DD/YYYY";
  const [data, setdata] = useState([]);
  const [userlistdata, setuserlistdata] = useState([]);
  const [subtenantValue, setSubtenantValue] = useState();
  const [sendButtonStatus, setSendButtonStatus] = useState(false);
  const [planner, setPlanner] = useState("Select a Subtenant Planner");
  const [items, setItems] = useState(["15", "30", "45", "60", "120"]);
  const [day, setDay] = useState(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri","Sat"]);
  const [toDate, setTodate] = useState("");
  const [fromDate, setFromdate] = useState([]);
  const [toTime, setTotime] = useState("");
  const [fromTime, setFromtime] = useState("")
  const [timeSlot, setTimeSlot] = useState();
  const [dateArr, setDateArr] = useState([]);
  const [timeArr, setTimeArr] = useState([]);
  const [slotcreated, setSlotcreated] = useState(false);
  const [sendEmailStatus, setSendEmailStatus] = useState(true)
  const [plannerAllData, setPlannerAllData] = useState([])
  const [selectedSubtenantAllData, setSelectedSubtenantAllData] = useState([])
  const extra30 = 30;
  const [slotbreak, setSlotBreak] = useState();
  const [roleid, setroleid] = useState('')
  const { setLoader } = useContext(UserContext)
  const [defaultvalue, setDefaultvalue] = useState('Select a Firm')
  const [disabledselect, setdisableselect] = useState(false)
  const [date, setdate] = useState(null)
  const [selectedDay, setSelectedDay] = useState(null);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false)
  const [previewTemplateData, setpreviewTemplateData] = useState()
  const [bool, setbool] = useState(false)
  let roleUserId = commonLib.getSessionStoarge("roleUserId")
  const [subtenantIdd, setsubtenantId] = useState('')
  const [upcommingSlotData, setupcommingSlotData] = useState([])
  const [deleteSlots, setdeleteSlots] = useState(true)
  const [deleteSlotsData2, setdeleteSlotsData2] = useState([])
  const [emailTemplateData, setEmailTemplateData] = useState([])
  const [startDate, setstartDate] = useState(null)
  const [endDate, setendDate] = useState(null)
  const [currentData, setCurrentData] = useState("")
  const [currentTime, setCurrentTime] = useState("")
  const [currentTimeAMPM, setCurrentTimeAMPM] = useState("")
  const [allSloats, setallSloats] = useState(null)
  const [openDays, setopenDays] = useState(false)
  const [dateTime, setDateTime] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [stat, setstat] = useState(false)
  const [boolDate, setboolDate] = useState([])
  const [recreate, setrecreate] = useState(false)
  const [selectInput, setselectInput] = useState(false)
  const [renderPage,setRenderPage]=useState(false)
  const stateObj = JSON.parse(commonLib.getSessionStoarge('stateObj'))
  let newDate = new Date(boolDate)
  let newIndex = newDate.getDay()
  
  const dataArr = [];
   const onSelect = (value, key) => {
    let resultData = data.filter((x) => {
      return x.subtenantId == value
    })
    setSelectedSubtenantAllData(resultData)
    getSubtenantPlannerListApi(key.key);
    setPlanner(null);
  };

  useEffect(() => {
   if((startDate == null || startDate == "") || (endDate == null || endDate == "") && roleUserId == 6){
      setupcommingSlotData(allSloats)
    }
  }, [startDate,endDate])
  


  const onSelectPlanner = (value, key) => {
    setbool(true)
    konsole.log("key", key?.id);
    let plannerId = key?.id
   


    let resultData = userlistdata.filter((data) => {
      return data.userRgstrtnId == plannerId
    })
    setPlannerAllData(resultData)
  
    setPlanner(value);

    getPlennerSlotDetails(value)




  };

  const getPlennerSlotDetails = (plannerUserId) => {
    setupcommingSlotData([])
    setLoader(true)
    let json = {
      "plannerUserId": plannerUserId,
      "subtenantId": selectedSubtenantAllData.length > 0 && selectedSubtenantAllData[0]?.subtenantId,
      "isActive": true,
      "slotStatusId": 1,
      "slotStatus": "UPCOMING"
    }
    let result = Services.getpostPlannerSlotDetails(json)
    result.then((res) => {
      
      let newSlotjson = res?.data?.data.map((ele, index) => {
        ele.checked = false;
        return ele;
      });
      setallSloats(newSlotjson)
       setupcommingSlotData(newSlotjson)
        // setdeleteSlotsData2(newSlotjson)
     if(roleUserId == 6){
     
      if (date == "") {
        setupcommingSlotData(newSlotjson)
        
      } else {

        let daTe = moment(date).format('YYYY-MM-DD')
        var specificDate = new Date(daTe); // Replace with your specific date

        specificDate.setDate(specificDate.getDate() + 7);

        var formattedDate = specificDate.toISOString().split('T')[0]; // YYYY-MM-DD
        let daTes = moment(date).format('MM/DD/YYYY')
        let endDate = moment(formattedDate).format('MM/DD/YYYY')
        let newSloats = res.data.data.filter((ele) => {
          return ele?.plannerDate >= daTes && ele?.plannerDate <= endDate
        })
         
      let newSlotjson = newSloats.map((ele, index) => {
        ele.checked = false;
        return ele;
      });
        
      
        setupcommingSlotData(newSlotjson)
        form.setDate({From_Dates:date})
      }
     }else{
      setupcommingSlotData(newSlotjson)
     }
     
      
    
      setLoader(false)
    }).catch((err) => {
      setLoader(false)
    })

  }




  const onSearch = (val) => {
    konsole.log("search:", val);


  }

  useEffect(() => {
    if (sendButtonStatus == true) {

      postApi(finalarray());
    }
  }, [sendButtonStatus])

  useEffect(() => {
    subtenantDetails();
    getEmailTemplatesFunc()
  }, [subtenantValue]);

  useEffect(() => {
    konsole.log("jhkfdhglkdhgfdh", props)
    if(props?.seminarDate != ""){
      let daTe = moment(props?.seminarDate).format('YYYY-MM-DD')
      var specificDate = new Date(daTe); // Replace with your specific date
  
      specificDate.setDate(specificDate.getDate() + 7);
  
      var formattedDate = specificDate.toISOString().split('T')[0]; // YYYY-MM-DD
      
      // let daTes = moment(props?.seminarDate).format('MM/DD/YYYY')
      let endDate = moment(formattedDate).format('MM/DD/YYYY')
      form.setFieldsValue({"From_Dates":moment(props?.seminarDate)})
      setstartDate(props?.seminarDate)
      setendDate(endDate)
      form.setFieldsValue({"To_Dates":moment(endDate)})
    
      
      // setendDate(props?.seminarDate)
    }
   
    
    setdate(props?.seminarDate)
  }, [props])



  const getEmailTemplatesFunc = () => {
    let promise = Services.getEmailTemplatesUrlPath(17, true)
    promise.then((response) => {
      konsole.log("getEmailTemplatesFuncRes", response)
      setEmailTemplateData(response.data.data)
    }).catch((error) => {
      konsole.log("getEmailTemplatesFuncError", error)
    }).finally(() => {
      konsole.log("getEmailTemplatesFuncEnd")
    })
  }

  const tableFuncReturn = () => {


    function formatDate1(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [month, day, year].join("/");
    }




    let table = document.createElement("table");

    var th1 = document.createElement("th");
    var th2 = document.createElement("th");
    var TableDate = document.createTextNode("DATE");
    var Slot_Timming = document.createTextNode("SLOT TIMING");
    th1.appendChild(TableDate);
    th2.appendChild(Slot_Timming);
    th1.style.border = "1px solid #720c20"
    table.appendChild(th1);
    table.appendChild(th2);
    // .............................................................
    for (var i = 0; i < dateArr.length; i++) {
      const dateFormat1 = formatDate1(dateArr[i]);
      var mainSpan = document.createElement("div")
      var span = document.createElement("span")
      var span1 = document.createElement("span")
      var span2 = document.createElement("span")
      mainSpan.style.border = "1px solid #720c20"
      mainSpan.style.borderRadius = "5px"
      mainSpan.style.width = "100%"
      mainSpan.style.height = "60%"
      mainSpan.style.padding = "5px"
      // span.style.border = "2px solid #720c20"
      // span1.style.border = "2px solid #720c20"
      // span.style.marginLeft = "2px"
      span1.style.marginLeft = "5px"
      span1.style.padding = "5px"
      span.style.width = "90px"
      // span.style.padding = "5px"
      // span.style.borderRadius = "5px"
      // span1.style.borderRadius = "5px"
      span1.style.width = "30px"
      const slotDates = dateFormat1.toString();
      konsole.log("dsdadasdasd", slotDates)
      const WeekDayName = findWeekDayName(slotDates)

      var tr = document.createElement("tr");

      var td1 = document.createElement("td");
      var td2 = document.createElement("td");

      td2.style.border = "1px solid #720c20";
      td2.style.display = "flex";
      td2.style.flexWrap = "wrap";
      // td2.style.width= "100% ";
      td2.style.gap = '10px';
      td2.style.padding = "20px"
      td1.style.border = "1px solid #720c20"
      td1.style.padding = "10px"


      var tableSlotDates = document.createTextNode(slotDates);
      var tableSlotDates1 = document.createTextNode(WeekDayName);
      var createDiv1 = document.createElement('div')
      for (let j = 0; j < timeArr.length; j++) {
        var createDiv = document.createElement('div')
        var tableSlotTimes = document.createTextNode(timeArr[j]);
        // createDiv1.style = ;
        createDiv.appendChild(tableSlotTimes)
        createDiv.style.border = "1px solid #720c20"
        createDiv.style.borderRadius = "5px"
        createDiv.style.padding = "5px"
        createDiv.style.marginLeft = "5px"
        // createDiv.style.margin="10px"
        createDiv.style.width = "80px"
        // createDiv.style.fontSize="12px"
        // createDiv1.appendChild(createDiv)
        span.appendChild(tableSlotDates)
        span1.appendChild(tableSlotDates1)
        span2.innerHTML = ","
        mainSpan.appendChild(span1)
        mainSpan.appendChild(span2)
        mainSpan.appendChild(span)


        td1.appendChild(mainSpan);
        
        // td1.appendChild(span1);
        td1.style.border = "1px solid #720c20"
        td1.style.padding = "20px 20px 20px 20px"

        td2.appendChild(createDiv);
        th2.style.padding = "10px 10px 10px 10px";
        th2.style.border = "1px solid #720c20";
        tr.appendChild(td1);
        tr.appendChild(td2);
        tr.style.padding = "5px 5px 5px 5px"
        tr.style.width = "100%"


        table.appendChild(tr);
        table.setAttribute("border", "2");
      }

    }
    // .............................................................


    return table;


  }

  const postSendEmailCommFunc = () => {

    let AoAdminName = commonLib.getObjFromStorage('userLoggedInDetail')?.memberName
    let tableEmail = emailTemplateData[0]?.templateContent

    let replace = tableEmail.replace('@@SUBTENANTNAME', selectedSubtenantAllData[0]?.subtenantName)
    let replace1 = replace.replace('@@PLANNERNAME', plannerAllData[0]?.fullName)
    let replace2 = replace1.replace('@@ADMINNAME', AoAdminName)

    let gettableFuncReturn = tableFuncReturn()
    konsole.log("gettableFuncReturn", gettableFuncReturn.innerHTML)

    konsole.log("gettableFuncReturnAPi", gettableFuncReturn.innerHTML)

    let tableDAtaString = `<table border='2' cellspacing='0' cellpadding='0' >
    ${gettableFuncReturn.innerHTML}
    <table>`

    let replace3 = replace2.replace('@@SLOTDETAILS', tableDAtaString)


    let loginuserid = sessionStorage.getItem('loggedUserId')
    let promise = Services.postSendEmailComm(emailTemplateData[0]?.templateType, plannerAllData[0]?.primaryEmail, emailTemplateData[0]?.emailSubject, emailTemplateData[0]?.templateId, previewTemplateData, 1, "tblUsers", plannerAllData[0]?.userRgstrtnId, loginuserid)
    promise.then((response) => {
      konsole.log("postSendEmailCommRes", response)
      setbool(true)
      message.success("Email Send Successfully")
      setOpenSendEmailModal(false)
      setSendButtonStatus(false)
      setLoader(false)
    }).catch((error) => {
      konsole.log("postSendEmailCommerror", error)
      setLoader(false)
    }).finally(() => {
      setLoader(false)
      konsole.log("postSendEmailCommEnd")
    })

  }

  const subtenantDetails = () => {
    const createdBy = null;
    const isActive = true;
    setLoader(true)
    const Subtenantdetailspromise = Services.getsubtenantdetails(
      createdBy,
      isActive
    );
    konsole.log("promise", Subtenantdetailspromise);
    Subtenantdetailspromise.then((res) => {
     
      konsole.log("promiseSubtenant", res);
      setdata(res.data.data);
      setLoader(false)
      // message.success("ss")
    }).catch((err) => {
      konsole.log("err", err);
      // message.error("Enter valid details")
    }).finally(() => {
      // setLoader(false)
    })
  };


  useEffect(() => {
    let userroleId = JSON.parse(sessionStorage.getItem("stateObj"))
    let subtenantId = sessionStorage.getItem("subtenantId")
    setsubtenantId(subtenantId)
    setroleid(userroleId?.roleId)
    if (data !== '' && data !== undefined && roleid == "11" || roleid == "6") {
      setdisableselect(true)
      getSubtenantPlannerListApi(subtenantId);

      let datafilter = data.filter((val) => {
        return val.subtenantId == subtenantId
      })
      setDefaultvalue(datafilter[0]?.subtenantName)
      setSelectedSubtenantAllData(datafilter)
      form.setFieldsValue({
        "Subtenant": datafilter[0]?.subtenantName
      })

      konsole.log("datafilterdatafilter", datafilter)

    } else {
      setdisableselect(false)
    }

  }, [data])

  const getSubtenantPlannerListApi = (subtenantValue) => {
    const roleId = 8;
    const getUserlistpromise = Services.getUserListByRoleId(
      subtenantValue,
      true,
      roleId
    );
    getUserlistpromise
      .then((res) => {
        setuserlistdata(res.data.data);
        konsole.log("selectPlanner", res.data.data);
      })
      .catch((err) => {
        konsole.log("err", err);
        // message.error("Enter valid details");
      });
  };


  const onChangeCheck = (e) => {
    konsole.log(`checked = ${e.target.checked}`);
    setSendEmailStatus(!sendEmailStatus)
  };
  const onChangeCheckbox = (e) => {
    setstat(!stat)
    // konsole.log(`checked = ${e.target.checked}`);s
    setSendEmailStatus(!sendEmailStatus)
    setopenDays(!openDays)
  };


  const disabledforfromDate = (current) => {
    let customDate = moment().format("YYYY-MM-DD");
      return current && current < moment(customDate, "YYYY-MM-DD") ;

  }

  function disabledfortoDate(current) {
    let customDate = new Date(fromDate);
    return current && current < moment(customDate, "YYYY-MM-DD");
  }

  const onFinishForm = () => {
   
    if (currentData == fromDate && currentTime > fromTime) {
      setbool(false)
      CreateSlot();
    //   message.error("Please Insert Valid Time")
    } else {
      CreateSlot();
    }
  };
  
  const CreateSlot = () => {
    var arr1 = [];
    var arr2 = [];
    setSlotcreated(true);
   
    let updatedJson = fromDate.map(dateString => {
        let date = new Date(dateString);
        date.setDate(date.getDate());
        return date
      });
   
    
      setDateArr(updatedJson.sort((a,b)=>a-b));
  
    // const date2 = new Date(toDate);
    // const diffTime = Math.abs(date2 - date1);
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    
    const startTime = moment(fromTime, "HH:mm:ss a");
    // const endTime = moment(toTime, "HH:mm:ss a");
    // const duration = moment.duration(endTime.diff(startTime));
    // const hours = parseInt(duration.asHours());
    // const minutes = parseInt(duration.asMinutes()) % 60;
    // const hoursInmins = hours * 60;

    // const totalmins = hoursInmins + minutes;
    // konsole.log("rExtraa", timeSlot, slotbreak)
    // let r = parseInt(timeSlot);

    // let extra30 = parseInt(slotbreak) ? slotbreak !== undefined : 0;

    // const totalTimeslot = r + extra30;
    // konsole.log(totalTimeslot, extra30, r, "totalmins")
    // const Slotsmins2 = totalmins / timeSlot;
    // const Slotsmins = Math.round(totalmins / totalTimeslot);
    // konsole.log("Slotsmins", Slotsmins)
    // Slotsmins=Math.trunc(Slotsmins)

    // for (var i = 1; i <= Slotsmins; i++) {
    //   const newTime = moment(fromTime, "HH:mm:ss a")
    //     .add(timeslots, "minutes")
    //     .format("hh:mm a");
    //   //hh:mm:ss.SSS
    //   var timeslots = totalTimeslot * i;
    //   arr1.push(newTime);
    // }
    konsole.log("lkdfgh97987",fromTime)
    setTimeArr(fromTime);
    // for (var a = 1; a <= diffDays; a++) {
    //   const result = new Date(fromDate);
    //   result.setDate(result.getDate() - 1 + a);

    //   arr2.push(result);
    // }
    
    

  };

  const ReCreateSlot = () => {
  
    setrecreate(true)
    setSlotcreated(false);
  };
  const formatDateS=(dateArr)=>{
    var d = new Date(dateArr),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
   
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
  }
 
  function finalarray() {
    let json = []
    for (var i = 0; i < dateArr.length; i++) {
        const plannerDate = formatDateS(dateArr[i]);
    
        const plannerTime = moment(timeArr, "HH:mm:ss a").format("HH:mm:ss.SSS");
        const slot = {
          plannerCalId: 0,
          plannerUserId: planner,
          plannerDate: plannerDate,
          plannerTime: plannerTime,
          plannerServiceFeeId: 1,
          slotStatusId: 1,
          timeDuration: timeSlot,
          isActive: true,
          upsertedBy: stateObj?.userId,
        };
        json.push(slot);
       

    }

       

    //   });
    // });
    konsole.log("farrfarrFinalArr", json)
    return json;
  }


  const newTime = (time, duration) => {
    konsole.log("samofugour87or09",time, duration)
    var timeArray = time.split(' ');
    var timeString = timeArray[0];
    var period = timeArray[1];
  
    var [hours, minutes] = timeString.split(':').map(Number);
    minutes += Number(duration); // Convert duration to a number
  
    hours += Math.floor(minutes / 60); // Add excess minutes to hours
    minutes = minutes % 60; // Get remaining minutes after converting to hours
  
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 12) {
      period = period === 'AM' ? 'PM' : 'AM';
    }
  
    var updatedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
    return updatedTime;
  }
 
const compareTimes=(time1, time2)=> {
  
    // Convert time1 to minutes since midnight
    const [hours1, minutes1] = time1.split(":");
    const time1Period = time1.slice(-2); // AM or PM
    let totalMinutes1 = parseInt(hours1, 10) * 60 + parseInt(minutes1, 10);
    if (time1Period === "PM" && parseInt(hours1, 10) !== 12) {
      totalMinutes1 += 12 * 60;
    }
  
    // Convert time2 to minutes since midnight
    const [hours2, minutes2] = time2.split(":");
    const time2Period = time2.slice(-2); // AM or PM
    let totalMinutes2 = parseInt(hours2, 10) * 60 + parseInt(minutes2, 10);
    if (time2Period === "PM" && parseInt(hours2, 10) !== 12) {
      totalMinutes2 += 12 * 60;
    }
  
    // Compare the two times

    if (totalMinutes1 >= totalMinutes2) {
      return true;
    } else {
      return false;
    }
}

  
 
  
  
const validationFunc =(item1,item2)=>{
   const now = new Date();
   const currentTime = now.toLocaleTimeString();
   const currentDate = now.toLocaleDateString();
   const ExestingDate = new Date(item2.plannerDate);
   const currentDates = new Date(currentDate);   
   
 

    if(ExestingDate.getTime() > currentDates.getTime()){
      if(item1?.plannerDate == item2?.plannerDate){
        if(compareTimes(item2.plannerTime , item1.plannerTime) == true){
          return compareTimes(newTime(item1.plannerTime,item1?.timeDuration) , item2.plannerTime )
        }else{
            return compareTimes(newTime(item2.plannerTime,item2?.timeDuration),item1.plannerTime)
        }
      }
      

    }else{
        if(compareTimes(item2.plannerTime , currentTime)){
            if(item1?.plannerDate == item2?.plannerDate){

                if(item2.plannerTime > item1.plannerTime){
                  return compareTimes(newTime(item1.plannerTime,item1?.timeDuration) , item2.plannerTime)
                }else{
                    return compareTimes(newTime(item2.plannerTime,item2?.timeDuration),item1.plannerTime)
                }
            }
           
        }else{
            return false
        }
      
      
    }




}
 
  const onSubmit = () => {
   
    let slotArr = finalarray()
    if (sendEmailStatus == true) {
      let newArray = []
      for (let i = 0; i < slotArr.length; i++) {
        slotArr[i].plannerTime = moment(slotArr[i].plannerTime, "HH:mm").format("h:mm A")
        slotArr[i].plannerDate = moment(slotArr[i].plannerDate).format("MM/DD/YYYY")
      }


      newArray.push(slotArr)
      
      let postArray = newArray.flat(1)
      const array2Only = postArray.filter((item2) => upcommingSlotData.some((item1) => validationFunc(item1,item2)));
     
      const now = new Date();
      const currentTime = now.toLocaleTimeString();
      const currentDate = now.toLocaleDateString();
      var date1 = new Date(postArray[0]?.plannerDate);
      var date2 = new Date(currentDate);
 
   
      if (array2Only.length > 0) {
        message.warn("Duplicate slot/slots cannot be created, please check and create again.")
        setOpenSendEmailModal(false)
        setSendButtonStatus(false)
        setPlannerAllData(plannerAllData)
      } else {
        if (date1.getTime() === date2.getTime() && !compareTimes(postArray[0]?.plannerTime,currentTime)) {
            message.warn("Please select correct date and time.")
            setOpenSendEmailModal(false)
            setSendButtonStatus(false)
            setPlannerAllData(plannerAllData)
          }else{
            previewTableTemplateData();
          }
        
      }
   
    }
    else {
       
        let newArray = []
      for (let i = 0; i < slotArr.length; i++) {

        slotArr[i].plannerTime = moment(slotArr[i].plannerTime, "HH:mm").format("h:mm A")
        slotArr[i].plannerDate = moment(slotArr[i].plannerDate).format("MM/DD/YYYY")
      }
      newArray.push(slotArr)
      
      let postArray = newArray.flat(1)
      const array2Only = postArray.filter((item2) =>upcommingSlotData.some((item1) => validationFunc(item1,item2)));
      
      const now = new Date();
      const currentTime = now.toLocaleTimeString();
      const currentDate = now.toLocaleDateString();
      var date1 = new Date(postArray[0]?.plannerDate);
      var date2 = new Date(currentDate);
    if (array2Only.length > 0) {
         message.warn("Duplicate slot/slots cannot be created, please check and create again.")
        setOpenSendEmailModal(false)
        setSendButtonStatus(false)
        setPlannerAllData(plannerAllData)
       
      } else {
       
        if (date1.getTime() === date2.getTime() && !compareTimes(postArray[0]?.plannerTime,currentTime)) {
            message.warn("Please select correct date and time.")
            setOpenSendEmailModal(false)
            setSendButtonStatus(false)
            setPlannerAllData(plannerAllData)
          }else{
             setSendButtonStatus(true);
          }
        
      }
    }

  };
 const previewTableTemplateData = () => {

    function formatDate1(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [month, day, year].join("/");
    }



    let table = document.createElement("table");

    var th1 = document.createElement("th");
    var th2 = document.createElement("th");
    var TableDate = document.createTextNode("DATE");
    var Slot_Timming = document.createTextNode("SLOT TIMING");
    th1.appendChild(TableDate);
    th2.appendChild(Slot_Timming);
    th1.style.border = "1px solid #720c20"
    table.appendChild(th1);
    table.appendChild(th2);
    for (var i = 0; i < dateArr.length; i++) {
      const dateFormat1 = formatDate1(dateArr[i]);
      var mainSpan = document.createElement("div")
      var span = document.createElement("span")
      var span1 = document.createElement("span")
      var span2 = document.createElement("span")
      mainSpan.style.border = "1px solid #720c20"
      mainSpan.style.display = "inline-flex"
      mainSpan.style.borderRadius = "5px"
      mainSpan.style.width = "100%"
      span1.style.fontWeight = "500"
      // mainSpan.style.height = "30%"
      // span.style.border = "2px solid #720c20"
      // span1.style.border = "2px solid #720c20"
      // span.style.marginLeft = "2px"
      span1.style.marginLeft = "5px"
      span1.style.padding = "5px"
      // span1.style.borderRadius = "5px"
      // span.style.borderRadius = "5px"
      span.style.width = "85px"
      span.style.padding = "5px"
      // span1.style.width = "30px"
      span.style.fontWeight = "500"
      const slotDates = dateFormat1.toString();
      const WeekDayName = findWeekDayName(slotDates)

      var tr = document.createElement("tr");

      var td1 = document.createElement("td");
      var td2 = document.createElement("td");

      td2.style.border = "1px solid #720c20";
      // td2.style.display = "flex";
      td2.style.flexWrap = "wrap";
      // td2.style.width= "100% ";
      td2.style.gap = '10px';
      td2.style.padding = "14px"
      td1.style.border = "1px solid #720c20"
      td1.style.padding = "10px"
      th1.style.width = "50%"


      var tableSlotDates = document.createTextNode(slotDates);
      var tableSlotDates1 = document.createTextNode(WeekDayName);
      var createDiv1 = document.createElement('div')
    //   for (let j = 0; j < timeArr.length; j++) {
        var createDiv = document.createElement('div')
        var tableSlotTimes = document.createTextNode(timeArr);
        // createDiv1.style = ;
        createDiv.appendChild(tableSlotTimes)
        createDiv.style.border = "1px solid #720c20"
        createDiv.style.borderRadius = "5px"
        createDiv.style.padding = "5px"
        // createDiv.style.margin="10px"
        createDiv.style.width = "100px"
        createDiv.style.margin = "0 auto"
        // createDiv.style.fontSize="12px"
        // createDiv1.appendChild(createDiv)
        span.appendChild(tableSlotDates)
        span1.appendChild(tableSlotDates1)
        span2.innerHTML = ","

        mainSpan.appendChild(span1)
        mainSpan.appendChild(span2)
        mainSpan.appendChild(span)


        td1.appendChild(mainSpan);
        // td1.appendChild(span2);
        // td1.appendChild(span);
        td1.style.border = "1px solid #720c20"
        // td1.style.padding = "20px 20px 20px 20px"

        td2.appendChild(createDiv);
        th2.style.padding = "10px 10px 10px 10px";
        th2.style.border = "1px solid #720c20";
        tr.appendChild(td1);
        tr.appendChild(td2);
        tr.style.padding = "5px 5px 5px 5px"
        tr.style.width = "100%"


        table.appendChild(tr);
        table.setAttribute("border", "2");
    //   }

    }


    let AoAdminName = commonLib.getObjFromStorage('userLoggedInDetail')?.memberName
    let tableEmail = emailTemplateData[0]?.templateContent

    let replace = tableEmail.replace('@@SUBTENANTNAME', selectedSubtenantAllData[0]?.subtenantName)
    let replace1 = replace.replace('@@PLANNERNAME', plannerAllData[0]?.fullName)
    let replace2 = replace1.replace('@@ADMINNAME', AoAdminName)

    let tableDAtaString = `<table border='2' cellspacing='0' cellpadding='0' >
    ${table.innerHTML}
    <table>`

    let replace3 = replace2.replace('@@SLOTDETAILS', tableDAtaString)

    setpreviewTemplateData(replace3)
    setOpenSendEmailModal(sendEmailStatus == true ? true : false)

  }

  const postApi = (slotArr) => {
     let newArray = []
    for (let i = 0; i < slotArr.length; i++) {

      slotArr[i].plannerTime = moment(slotArr[i].plannerTime, "HH:mm").format("h:mm A")
      slotArr[i].plannerDate = moment(slotArr[i].plannerDate).format("MM/DD/YYYY")
    }
    newArray.push(slotArr)

    let postArray = newArray.flat(1)
    const array2Only = postArray.filter(
      (item2) =>
        !upcommingSlotData.some(
          (item1) => item1.plannerDate === item2.plannerDate && item1.plannerTime === item2.plannerTime
        )
    );
 
        setLoader(true)
        let postPlannerCalendardataprom = Services.postPlannerCalendardata(array2Only);
        postPlannerCalendardataprom
          .then((res) => {
            setSelectedDays([]);
            setopenDays(false)
            setstat(false)
            setboolDate([])
            onSelectPlanner(res.data.data[0]?.plannerUserId)
            setPlanner( res.data.data[0]?.plannerUserId)
            getPlennerSlotDetails(planner)
            setselectInput(false)
    
            // setbool(false)
            message.success("Slot Created Successfully")
            setPlannerAllData(plannerAllData)
            if (sendEmailStatus == true) {
    
              postSendEmailCommFunc()
            } else {
              setOpenSendEmailModal(false)
              setSendButtonStatus(false)
            }
            setSlotcreated(false);
            setOpenSendEmailModal(false)
            form.setFieldsValue({
              From_Date: "",
              From_Time: "",
              To_Date: "",
              To_Time: '',
              duration: null,
              slotBreak: null,
            //   Subtenant_Planner: null
            });
          })
          .catch((err) => {
            konsole.log("err", err);
            setbool(false)
            message.error("Enter valid data")
            setLoader(false)
    
          }).finally(() => {
            if (sendEmailStatus == false) {
    
              setLoader(false)
            }
          })
    
   
  };

  useEffect(() => {

    // ....................... For System Date ......................................
    let FromDateToLocal = new Date().toLocaleDateString()
    let FromDateToLocal1 = moment().format('MM/DD/YYYY')
    if (FromDateToLocal1 == fromDate) {
       setCurrentData(FromDateToLocal1)
    } else {
      setCurrentData("")
     }






  }, [fromDate])




  useEffect(() => {

    // ....................... For System Time ......................................

    let FromTimeToLocal = new Date().toLocaleTimeString()
    // let FromTimeToLocal1 = moment(FromTimeToLocal, "HH:mm:ss a")?._i
    let FromTimeToLocal1 = moment().format('hh/mm')

    setCurrentTimeAMPM(FromTimeToLocal1)

    setCurrentTime(FromTimeToLocal)
    




  }, [fromTime])

  const findWeekDayName = (date) => {
    const d = new Date(date);
    let day = d.getDay()

    switch (day) {
      case 0:
        return day = "Sun";
        break;
      case 1:
        return day = "Mon";
        break;
      case 2:
        return day = "Tues";
        break;
      case 3:
        return day = "Wed";
        break;
      case 4:
        return day = "Thur";
        break;
      case 5:
        return day = "Fri";
        break;
      case 6:
        return day = "Sat";
    }

  }
  const slotCheckboxfun = (e, index, item) => {
   let loginuserid = sessionStorage.getItem('loggedUserId')
   let arrData = upcommingSlotData
   let checkedstatus = item?.checked
    
    if (checkedstatus == false) {
      arrData[index].isActive = false
      arrData[index].slotStatusId = 3
      arrData[index]['upsertedBy'] = stateObj?.userId
      arrData[index].checked = true

    } else if (checkedstatus == true) {
      arrData[index].isActive = true
      arrData[index].slotStatusId = item.slotStatusId
      arrData[index].checked = false
    }
    let isActive = arrData?.every((ele) => ele?.checked === true);

    if(isActive == true){
      setselectInput(true)
    }else{
      setselectInput(false)
    }
    setupcommingSlotData(arrData)
    setRenderPage(!renderPage)
  }

  const deleteSlotsfun = () => {
    let filterData = upcommingSlotData.filter(item => item.isActive == false && item.slotStatusId == 3)
    if (filterData.length == 0) {
      message.warning("Please select a slot")
      return;
    }
    setLoader(true)
    let jsonobj = filterData
    let result = Services.upsertPlannerDetails(jsonobj)
    result.then((res) => {
      konsole.log("result", res)
      setselectInput(false)
      setLoader(false)
      message.success(filterData.length == 1 ? "Slot Deleted" : "Slots Deleted")
      setdeleteSlots(true)
      konsole.log("getPlennerSlotDetails", planner)
      getPlennerSlotDetails(planner)
    }).catch((err) => {
      konsole.log("err", err)
      setLoader(false)
    }).finally(() => {
      konsole.log("completefinally")
      setLoader(false)
    })
  }
  const handleSubmit = (e) => {

    form.validateFields().then((values) => {
      onFinishForm()

    })
      .catch((errorInfo) => {

      });





  }
  const changeDataFrom = (date, dateString) => {
    setstartDate(dateString)
   
    if(endDate != null && endDate != undefined && endDate != ""){
        let daTes = moment(dateString).format('MM/DD/YYYY')
        let endDates = moment(endDate).format('MM/DD/YYYY')
        let newSloats = upcommingSlotData.filter((ele) => {
          return ele?.plannerDate >= daTes && ele?.plannerDate <= endDates
        })
       
        setupcommingSlotData(newSloats)
      }
  }
  const changeDataTo = (date, dateString) => {
   
    setendDate(dateString)
    if(startDate != null){
      let daTes = moment(dateString).format('MM/DD/YYYY')
      let startDates = moment(startDate).format('MM/DD/YYYY')
      let newSloats = upcommingSlotData.filter((ele) => {
        return ele?.plannerDate >= startDates && ele?.plannerDate <= daTes
      })
     
      setupcommingSlotData(newSloats)

    }

  }
  const range=(start, end)=> {
    return Array.from({ length: end - start }, (_, i) => start + i);
  }
  const formatDate=(date)=> {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }
  const dateData=()=>{
       
    const dateFormat = formatDate(dateArr);
        const javaScriptRelease = dateFormat.toString();

        const weekDayName = findWeekDayName(javaScriptRelease)
        
        return (
          <>
              {/* <td className="date-row">
                <p className="slot-date">
                  <div style={{ border: "2px solid #720c20", borderRadius: "5px", maxWidth: "100%", padding: "5px", display: "flex", flexWrap: "wrap" }}>
                    <div>
                      {weekDayName},

                    </div>
                    <div className="ms-1">

                      {javaScriptRelease}

                    </div>
                  </div>
                </p>
              </td> */}
           
                {dateArr.map((props) => {
                        //format date

                        function formatDate(date) {
                          var d = new Date(date),
                            month = "" + (d.getMonth() + 1),
                            day = "" + d.getDate(),
                            year = d.getFullYear();

                          if (month.length < 2) month = "0" + month;
                          if (day.length < 2) day = "0" + day;

                          return [month, day, year].join("/");
                        }
                       
                        const dateFormat = formatDate(props);
                        const javaScriptRelease = dateFormat.toString();
                        
                        const weekDayName = findWeekDayName(javaScriptRelease)
                       
                        // let WeekDayName = date1(javaScriptRelease, javaScriptRelease)

                        // dataArr.push(javaScriptRelease);
                       
                        return (
                          <tr>
                          {/* <table className="w-100">
                            <tr className="tr-main w-100"> */}
                              <td className="date-row">
                                <p className="slot-date">
                                  <div style={{ border: "2px solid #720c20", borderRadius: "5px", maxWidth: "100%", padding: "5px", display: "flex", flexWrap: "wrap", }}>
                                    <div>
                                      {weekDayName},

                                    </div>
                                    <div className="ms-1">

                                      {javaScriptRelease}

                                    </div>
                                  </div>
                                </p>
                              </td>
                              <td className="slot-row">
                                
                                {timeData()}
                                
                                </td>
                             
                              {/* </tr>

                            </table> */}
                          </tr>
                        );
                      })}
             
          
          </>
        );
    
  }
  const timeData=()=>{
    
      
        return (
          <td className="td-timing">
            <p className="slot-date">
              <div style={{ border: "2px solid #720c20", borderRadius: "5px", maxWidth: "100%", padding: "5px", display: "flex", flexWrap: "wrap" }}>
                {timeArr}

              </div>
            </p>
          </td>
        );
      
  }
  const handleDateTimeChange = (date, dateString) => {
    if(recreate == true){
      setSelectedDays([]);
    }
    setSelectedDates(date)
    const selectedDate = moment(dateString, 'MM-DD-YYYY HH:mm').format('MM-DD-YYYY');
    const selectedTime = moment(dateString, 'MM-DD-YYYY HH:mm').format('HH:mm');
    
    setFromtime(selectedTime)
   let same = selectedDate.replaceAll("-","/")
    setFromdate([same])
    setboolDate([same])
     setDateTime(date);
  };
  let newjson = []

  const handleDayClick = (value,index) => {
       
       let newDate = new Date(boolDate)
       let newIndex = newDate.getDay()
    
       if(index != newIndex && boolDate != null && boolDate != undefined && boolDate != ""){
        setSelectedDay(index)
        newjson.push(value) 
      if (selectedDays.includes(index)) {
         setSelectedDays(selectedDays.filter((day) => day !== index));
       } else {
         setSelectedDays([...selectedDays, index]);
       }
     
     const currentDate = new Date(fromDate[0]);
     const selectedDayNumbers = newjson.map((day) => {
       return ["Sun","Mon", "Tue", "Wed", "Thu", "Fri","Sat"].indexOf(day);
     });
    
     const nextDates = selectedDayNumbers.map((dayNumber) => {
       const nextDate = getNextDay(currentDate, dayNumber);
       return nextDate.toLocaleDateString(); // Format the date as needed
     });
    
    
    
      if (fromDate.includes(nextDates[0])) {
       setFromdate(fromDate.filter((date) => date !== nextDates[0]));
       } else {
         setFromdate([...fromDate, nextDates[0]]);
       }
      
    
        
       }else{
       
       }
       
    
   
    
  };
  
  const getNextDay = (currentDate, dayOfWeek) => {
    const today = currentDate.getDay();
  
    const daysToAdd = (7 + dayOfWeek - today) % 7;
    currentDate.setDate(currentDate.getDate() + daysToAdd);
  
    return currentDate;
  };
  
  const deleteAll =(e)=>{
    let arrData = upcommingSlotData
    let checkedstatus = e.target.checked
    setselectInput(checkedstatus)
    
    for(let i = 0;i < upcommingSlotData.length;i++){
       
        arrData[i].isActive = false
        arrData[i].slotStatusId = 3
        arrData[i]['upsertedBy'] = stateObj?.userId
        arrData[i].checked = checkedstatus
      }
      setupcommingSlotData(arrData)   

     

  }

  const messageAPlanner=(roleid==4)? "Please select Firm Planner":"Please select a Planner";
  const messageAPlanner2=(roleid==4)? "Select Firm Planner":"Select a Planner";
   return (
    <div>
      <div className={roleUserId == 6 ? "ms-1" : ""} style={{
        maxHeight: 'calc(100vh - 140px)',
        overflowY: 'auto'
      }} >
      <p className="fs-4 fw-600 p-0 m-0 mt-1">{(roleid==4)?'Select a Firm and User':<br />}</p> 
    
        <Form form={form} name="Addtimeslot" onFinish={onFinishForm}>
          <div className="mt-1" style={{ width: "99%", }}>
            <Form.Item
              name="Subtenant"
              rules={[
                {
                  required: true,
                  message: "Please select a Firm",
                },
              ]}
            >
              <Select

                // placeholder={defaultvalue}
                disabled={disabledselect}
                placeholder="Select a Firm"
                style={{
                  width: "100%",
                }}
                className="fs-5 border-2 rounded-2"
                onSelect={onSelect}
              >
                {data.map((props) => (
                  <Option value={props.subtenantId} key={props.subtenantId}>
                    {props.subtenantName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div style={{ width: "99%" }}>
            <Form.Item
              name="Subtenant_Planner"
              rules={[
                {
                  required: true,
                  message:messageAPlanner 
                },
              ]}
            >
              <Select

                placeholder={messageAPlanner2}

                style={{
                  width: "100%",
                  "border-radius": 10,

                }}
                className="fs-5 select-Subtenant-Planner"
                showSearch

                onSelect={onSelectPlanner}
                value={planner}
                onSearch={onSearch}



                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

                }
              >
                {userlistdata.map((props, key) => (
                  <Option value={props.userId} key={key} id={props?.userRgstrtnId}>
                    {props.fullName}

                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <p className="fs-5 fw-500 m-0 p-0">Schedule time slots</p>

          <div className="" style={{ width: "99%" }}>

            <Space>
              <Row className="mt-1" style={{ display: "flex", flexWrap: "wrap" }}>
                <Col>
                  <Form.Item
                    name="From_Date"
                    rules={[
                      {
                        required: true,
                        message: "Please select Date and Time",
                      },
                    ]}
                  >
                              <DatePicker
                                 showTime
                                 disabledDate={disabledforfromDate}
                                 format="MM-DD-YYYY H:mm"
                                 className="fs-4 date-picker me-2"
                                 placeholder="Select Date and Time"
                                 value={dateTime ? moment(dateTime) : null}
                                 onChange={handleDateTimeChange}
                                //  disabledHours={() => range(0, 8).concat(range(18, 24))}
                                 hideDisabledOptions
                                 showNow={true}
                                 minuteStep={15} 
                               />
                  </Form.Item>
                </Col>
               
               
                <Col>
                  <Form.Item
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message: "Please select slot duration",
                      },
                    ]}
                  >
                    <Select
                      style={{
                        width: "210px",
                      }}
                      placeholder="Slot Duration"
                      className=" me-2"
                      onChange={(value) => setTimeSlot(value)}
                    >
                      {items.map((item) => (
                        <Option key={item}>{item} mins</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                              {openDays == true ?
                                  <Col>
                                      <div className="week-row">
                                          {day.map((day, index) => (
                                            
                                              <div
                                                  key={index}
                                                     className={`circle ${selectedDays.includes(index) || newIndex == index ? 'selected' : selectedDays.includes("newclass") ? "newclass" :''}`}
                                                     onClick={() => handleDayClick(day,index)}
                                              >
                                                  {day}
                                              </div>
                                          ))}
                                      </div>

                                  </Col>
                                  : ""}
                                  <Col>
              <Form.Item>
              <Checkbox
                    className="checkbox-timeslot"
                    onChange={(e) => onChangeCheckbox(e)}
                    // defaultChecked={sendEmailStatus}
                    checked={stat}
                  >
                    Do you want to repeat your slot{" "}
                  </Checkbox>
                
                </Form.Item>
                </Col>
              
              </Row>

              {/* <Col>
              <Form.Item>
              <Checkbox
                    className="checkbox-timeslot"
                    onChange={(e) => onChangeCheckbox(e)}
                    // defaultChecked={sendEmailStatus}
                    checked={stat}
                  >
                    Do You want to reapeat your sloat{" "}
                  </Checkbox>
                
                </Form.Item>
                </Col> */}

              
            </Space>

            {slotcreated == false ? (
              <Button
                style={{
                  "background-color": "#720C20",
                  color: "white",
                  borderRadius: "5px",
                  fontWeight: "600"
                }}


                onClick={(e) => handleSubmit(e)}
                className=""
              >
                Create Slots
              </Button>
            ) : (
              <>
                <Row Span={24} id="tableData">
                  {
                    <table className="slot-table m-0 p-0" id="tableDetails">
                      <tr>
                        <th>Date</th>
                        <th>Slot timing</th>
                      </tr>
                      
                       <> {dateData()}</>

                    </table>
                  }

                  {/* <br /> */}

                  <Checkbox
                    className="checkbox-timeslot"
                    onChange={(e) => onChangeCheck(e)}
                    defaultChecked={sendEmailStatus}
                  // checked={sendEmailStatus}
                  >
                    Send Notification to {(roleid !=4) ?'Staff member':'Firm User'} {" "}
                  </Checkbox>
                </Row>
                <div className="mt-2 mb-2">
                  <Button
                    className="save-btn"
                    style={{
                      "background-color": "#720C20",
                      color: "white",
                      borderRadius: "5px",
                      fontWeight: "600",
                      height: "40px"

                    }}
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                  <Button
                    className="save-btn"
                    onClick={() => ReCreateSlot()}
                    style={{
                      "background-color": "#720C20",
                      color: "white",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      fontWeight: "600",
                      height: "40px"
                    }}
                  >
                    Re-Create Slots
                  </Button>
                </div>
              </>
            )}
          </div>
        
          {bool == true ? (<>
            {upcommingSlotData?.length > 0 ? (
              <>
                <div className={roleUserId == 6 ? "me-2 ps-4 mt-2 mb-1":"me-3 mt-2 ps-5 mb-1"} style={{ border: "1px dashed grey" }}>
                 <div className="m-2 d-flex ">
                
                  <div> <input type="checkbox" checked={selectInput}style={{ width: "20px", height: "20px" }}defaultChecked={false} onChange={(e)=>deleteAll(e)}/></div>
                  <div className="ms-2">Delete all</div>
                 </div>
                  <div className={roleUserId == 6 ? "row me-2 align-items-center justify-content-center w-100 mb-1 mt-1" 
                                : "row ms-2 me-2 align-items-center justify-content-start w-100 mb-1 mt-1"}>
                  {roleUserId == 6 ? (
                    <div className="">
                    <Space>
                       <Row className="mt-1" style={{ display: "flex", flexWrap: "wrap" }}>
                         <Col>
                           <Form.Item
                             name="From_Dates"
                            //  rules={[
                            //    {
                            //      required: true,
                            //      message: "Please select start date",
                            //    },
                            //  ]}
                           >
                             <DatePicker
                               placeholder="From Date"
                               className="fs-4 date-picker me-2"
                               format={dateFormat}
                               disabledDate={disabledforfromDate}
                               onChange={changeDataFrom}
                             />
                           </Form.Item>
                         </Col>
                         <Col>
                           <Form.Item
                             name="To_Dates"
                            //  rules={[
                            //   //  {
                            //   //    required: true,
                            //   //    message: "Please select end date",
                            //   //  },
                            //  ]}
                           >
                             <DatePicker
                               placeholder="To Date"
                               className="fs-4  me-2 date-picker"
                               format={dateFormat}
                               disabledDate={disabledforfromDate}
                               onChange={changeDataTo}
                             />
                           </Form.Item>
                         </Col>
 
 
                       </Row>
                     </Space> 
                    </div>
                    ):("")}
                   
                    
                    <div className="fw-bold row me-2 align-items-center justify-content-center w-100  mt-1">{plannerAllData[0]?.fullName}</div>
                    <div className="fw-bold row me-2 align-items-center justify-content-center w-100  mt-1">Available time slots </div>
                    {upcommingSlotData.length > 0 && upcommingSlotData.map((item, index) => {
                      
                     return <>
                      
                        <div key={index} className="rounded border  border-dark p-2 mt-2 me-1 d-flex" style={{ maxWidth: '220px' }}>
                        <div>
                          <div>
                          <input type="checkbox"name="select-all"  style={{ width: "20px", height: "20px" }} onChange={(e) => slotCheckboxfun(e, index, item)}
                          checked={item.checked}
                            />
                          </div>
                            
                          </div>
                          &nbsp;

                          <div className="ms-1"> {commonLib.dayFormate(item.plannerDate)}, {item.plannerDate} {item.plannerTime}</div>
                        </div>
                      </>
                    })}
                    <div style={{paddingLeft:"0px"}}>
                      {upcommingSlotData.length > 0
                        && (
                          <>
                          {roleUserId == 6 ? (
                           <Button style={{backgroundColor: "#720C20", color: "white", borderRadius: "5px", borderStyle: "none", height: "35px", }} className="mt-2 mb-1 align-items-start justify-content-start" onClick={() => deleteSlotsfun()}>Delete selected Slots</Button>

                          ):(
                            <div className="" style={{marginLeft:"-3rem"}}>
                               <Button style={{backgroundColor: "#720C20", color: "white", borderRadius: "5px", borderStyle: "none", height: "35px"}} className="mt-3 mb-1 align-items-start justify-content-start" onClick={() => deleteSlotsfun()}>Delete selected Slots</Button>

                            </div>

                          )}
                          </>
                        )
                        

                      }

                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
              
                <div className="me-3 mt-2" style={{ border: "1px dashed grey" }}>
                {roleUserId == 6 ? (
                    <div className="ms-1">
                    <Space>
                       <Row className="mt-1" style={{ display: "flex", flexWrap: "wrap" }}>
                         <Col>
                           <Form.Item
                             name="From_Dates"
                            //  rules={[
                            //    {
                            //      required: true,
                            //      message: "Please select start date",
                            //    },
                            //  ]}
                           >
                             <DatePicker
                               placeholder="From Date"
                               className="fs-4 date-picker me-2"
                               format={dateFormat}
                               disabledDate={disabledforfromDate}
                               onChange={changeDataFrom}
                             />
                           </Form.Item>
                         </Col>
                         <Col>
                           <Form.Item
                             name="To_Dates"
                            //  rules={[
                            //   //  {
                            //   //    required: true,
                            //   //    message: "Please select end date",
                            //   //  },
                            //  ]}
                           >
                             <DatePicker
                               placeholder="To Date"
                               className="fs-4  me-2 date-picker"
                               format={dateFormat}
                               disabledDate={disabledforfromDate}
                               onChange={changeDataTo}
                             />
                           </Form.Item>
                         </Col>
 
 
                       </Row>
                     </Space> 
                    </div>
                    ):("")}
                  <div className="fw-bold  row ms-2 me-2 me-2 align-items-center justify-content-center w-100 mt-5">{plannerAllData[0]?.fullName}</div>
                  <div className="fw-bold  row ms-2 me-2 me-2 align-items-center justify-content-center w-100 mb-5"> No Slots Available</div>
                </div>

              </>
            )
            }
          </>) : ("")}

        </Form>

      </div>
      {
        (openSendEmailModal == true) ? <SendEmailModal cancelMOdal={setOpenSendEmailModal} openSendEmailModal={openSendEmailModal} titleText="Send Email" previewTemplateData={previewTemplateData} sendEmailStauts={setSendButtonStatus} /> : ""
      }
    </div>
  );
};

export default SchedulePlanner2;
