import React,{useEffect, useState,useContext,useImperativeHandle,forwardRef} from 'react';
import { Modal,Button,Form,Row,Col } from 'react-bootstrap';
import konsole from '../../network/konsole';
import { Input } from "antd";
import '../Subscription/Manage.css';
import AddProductsGroups from './AddProductsGroups';
import Services from '../../network/Services';
import { UserContext } from '../../App';
import { capitalizeFirstLetter, completeEventMessage, isNullUndefine } from '../../control/Constant';
import Tooltip from '../Tooltip';
// import CurrencyInput from 'react-currency-input-field';

const createServiceObj = () =>{ return {serviceId: 0,serviceName: "",isActive: true,description: "",} }
const createServiceGrpObj = () =>{ return {serviceGrpId: 0,serviceGrpName: "",isActive: true,description: "",} }
const createPackageObj = () => {return { skuListId: 0,skuListName: "",isActive: true,isVisible: true,subscriptionId: 0,description: "",}}
const rateCardObj = () => { return {rateCardId: 0,rateCardName: "",subscriptionId: 0,currency: "USD",isActive: true,currencyTypeId: 1,packageType : ""} }
const applicableSubsModelObj = () => {return {skuSubsModelId: 0,appRateCardId: 0,subsModelId: 3,endSubscriptionAmt: 0,appCurrency: "USD",isDisAllowed: true,appIsActive: true,appCurrencyTypeId: 1,}}
const subtenantRateCardObj = () =>{return{subtenantRateCardId: 0,subtenantName: "Life_PointLaw",subSkuListId: 0,subRateCardId: 0,subSkuSubsModelId: 0,validityPeriod: "3 months",validityPeriodDuration: "3 months",validityFromDate: "2024-02-27T05:10:41.268Z",validityToDate: "2024-05-27T05:10:41.268Z",marginAmt: 0,marginPercent: 0,subIsActive: true,subIsDisAllowed: true,}}

function AddPackageModal({showModal,heading,productsList,productGrpsList,editCard,onclose,getUserDetails,packageList},ref){
    const loginUserID = sessionStorage.getItem("loggedUserId")
    const subtenantID = sessionStorage.getItem("subtenantId")
    const [productAndProductGrpList, setProductAndProductGrpList] = useState([...productsList ?? [], ...productGrpsList ?? []])
    const [hideList, setHideList] = useState(true)
    const [serviceJson, setServiceJson] = useState({...createServiceObj(),subtenantId: subtenantID, upsertedBy: loginUserID})
    const [serviceGrpJson, setServiceGrpJson] = useState({...createServiceGrpObj(),subtenantId: subtenantID,upsertedBy: loginUserID})
    const [packageJson, setPackageJson] = useState({...createPackageObj(),subtenantId: subtenantID,upsertedBy: loginUserID})
    const [endSubsAmnt, setEndSubsAmnt] = useState(editCard?.endSubscriptionAmt)
    const [searchQuery, setSearchQuery] = useState('');
    const { Search, TextArea } = Input;
    const { setLoader } = useContext(UserContext);
    const checkAddedProduct = productAndProductGrpList?.filter(item => item.checked == true) 

    konsole.log("setShowModal",productsList)

    useEffect(()=>{
     setStatesToUpdate()
    },[])

    useImperativeHandle(ref, () => ({
        deleteProduct,
        deleteProductGrp,
        deletePackage,
        deleteProductInProdGrp,
        deleteProdAndGrpInPackage,
      }));

    const setStatesToUpdate = () =>{
        konsole.log("editCard134",editCard)
        if(editCard){
            if (heading === "Product") {
                setJsonValuesToUpdate(setServiceJson);
            } else if (heading === "Product Group") {
                setJsonValuesToUpdate(setServiceGrpJson);
                for(const item of productAndProductGrpList){
                    for(const det of editCard?.services){
                        konsole.log("detssss222",det)
                        if((item.serviceId == det.serviceId)){
                            item["checked"] = true
                            item["serviceGrpMapId"] = det.serviceGrpMapId
                            break;
                        } else {
                            item.checked = false;
                        }
                    }
                }
            } else if (heading === "Package") {
                setJsonValuesToUpdate(setPackageJson);
                for(const item of productAndProductGrpList){
                    for(const det of editCard?.services){
                        if((item.serviceId == det.skuServiceId) || (item.serviceGrpId == det.skuServiceId)){
                            item["checked"] = true
                            item["skuServiceMapId"] = det.skuServiceMapId                            
                            break;
                        } else {
                            item.checked = false;
                        }
                    }
                }
            }
            setHideList(false)
        }
        else{
            if (heading === "Product Group") {
                setJsonValuesToUpdate(setServiceGrpJson);
                for(const item of productAndProductGrpList){
                            item["checked"] = false
                            delete item?.serviceGrpMapId;
                    }
            } else if (heading === "Package") {
                setJsonValuesToUpdate(setPackageJson);
                for(const item of productAndProductGrpList){
                            item["checked"] = false
                            delete item?.serviceGrpMapId;                            
                }
            }
        }
    }

    const setJsonValuesToUpdate = (setter) => {
        setter((prev) => {
            const { createdBy, createdOn, updatedBy, updatedOn, remarks, services,skuSubsModelId,subtenantRateCardId,...rest } = editCard;
            return {
                ...prev,
                ...rest,
                upsertedBy: loginUserID
            };
        });
    };

    const handleChange = (e, setInputFor) =>{
        const {name, id, value} = e.target
        const updatedValue = (name == "serviceName" || name == "serviceGrpName" || name == "skuListName") ? capitalizeFirstLetter(value) : value
        konsole.log("e.target.key",name, id, updatedValue)
        
        if(setInputFor == "Product"){
          handleStateUpdate(setServiceJson,name,updatedValue)
        }
        else if(setInputFor == "Product Group"){
            handleStateUpdate(setServiceGrpJson,name,updatedValue)
        }
        else if(setInputFor == "Package"){
            handleStateUpdate(setPackageJson,name,updatedValue)
        }
    }

    const handleStateUpdate = (setState,key,value) =>{
        setState(prev=>({
            ...prev,[key] : value
        }))
    }

    const validate = () => {

        if (serviceJson?.serviceName === "" && heading == "Product") {
            completeEventMessage("error", "Please enter product name");
            return true;
        }

        if (((serviceGrpJson?.serviceGrpName == "" || checkAddedProduct?.length == 0) && heading == "Product Group")) {
            completeEventMessage("error", `Please ${checkAddedProduct?.length == 0 ? "add any product" : "enter product group name"}`);
            return true;
        }

        if (((packageJson?.skuListName === "" || checkAddedProduct?.length == 0) && heading == "Package")) {
            completeEventMessage("error", `Please ${checkAddedProduct?.length == 0 ? "add any product/product group" : "enter package name"}`);
            return true;
        }

        if(endSubsAmnt == undefined || endSubsAmnt == null || endSubsAmnt == ""){
            completeEventMessage("error", `Please enter ${heading?.toLowerCase()} price`);
            return true;
        }
    };
    

    const handleSubmit = (callApiFor) =>{
        if(!validate()){
            if(callApiFor == "Product"){
                createProducts()
            }
            else if(callApiFor == "Product Group"){
                createProductGrpJson()
            }
            else if(callApiFor == "Package"){
                createPackageJson()
            }
        }
    }

    
    const createProducts = async(deleteJson) => {
        const arrayForUniqueName = editCard ? productsList?.filter((item) =>item.serviceId != editCard?.serviceId) : productsList;
        const uniqueProdName = arrayForUniqueName?.some((item) =>item.serviceName?.toLowerCase() == serviceJson?.serviceName?.toLocaleLowerCase());

        const json = deleteJson ?? [serviceJson]
        if(deleteJson || uniqueProdName == false || isNullUndefine(arrayForUniqueName)){
              setLoader(true);
              const promises = Services.postIndService(json);
              promises.then(async(res) => {
                setLoader(false);
                konsole.log("resresServices", res,serviceJson);
                getRateCardIdToUpdateAndDeleteProd(json,res.data.data,deleteJson)
                completeEventMessage("success",`Product ${(deleteJson != undefined && deleteJson != null && deleteJson != "") ? "deleted" : (editCard != undefined && editCard != null && editCard != "") ? "updated" : "created"} successfully`)
              }).catch((err) => {
                  konsole.log("errorrr", err);
                });
        }
        else{
            completeEventMessage("error", "Product name already exists");
        }
      };

      const getRateCardIdToUpdateAndDeleteProd = async (json,responseData,deleteJson) =>{
        const serviceID = responseData[0].serviceId
        const serviceName = responseData[0].serviceName
        const isActive = deleteJson ? false : true;
        let rateCardID = 0;
        if(json[0]?.serviceId != 0){
            const res = await Services.getSkuList("",true, subtenantID, `INDPKG_${serviceName}`);
            const skuLsitID = (res?.data?.data?.length > 0) ? res.data.data[0].skuListId : 0;

            const rateCardRes = await Services.getRateCard('',skuLsitID,subtenantID,true)
            rateCardID = (rateCardRes?.data?.data?.length > 0) ? rateCardRes.data.data[0].rateCardId : 0;
        }
        const serviceRateCardObj = { ...rateCardObj(),rateCardId : rateCardID, rateCardName : `RC-${serviceName}` ,packageType : "IND",serviceId : serviceID, isActive : isActive, upsertedBy: loginUserID}
        addRateCard(serviceRateCardObj,deleteJson)
      }

      const createProductGrpJson = (deleteJson) =>{
        const arrayForUniqueName = editCard ? productGrpsList?.filter((item) =>item.serviceGrpId != editCard?.serviceGrpId) : productGrpsList;
        const uniqueProdGrpName = arrayForUniqueName?.some((item) =>item.serviceGrpName?.toLowerCase() == serviceGrpJson?.serviceGrpName?.toLocaleLowerCase());

        if(deleteJson || uniqueProdGrpName == false || isNullUndefine(arrayForUniqueName)){
        setLoader(true);
        let serviceArray = [];
        let obj = {}
        let json = deleteJson ?? []
        if(json.length == 0){
            for (const item of productAndProductGrpList) {
                if (item.checked === true) {
                     obj = {
                        serviceGrpMapId: item?.serviceGrpMapId ?? 0,
                        serviceId: item.serviceId,
                        isActive: true
                    }
                    serviceArray.push(obj);
                }
                else if(item?.serviceGrpMapId && item.checked == false){
                    obj = {
                       serviceGrpMapId: item?.serviceGrpMapId,
                       serviceId: item.serviceId,
                       isActive: false
                   }
                   serviceArray.push(obj);
               }
            } 
            serviceGrpJson.services = serviceArray;
            const {checked, endSubscriptionAmt,skuSubsModelId,subtenantRateCardId,roleId,...rest} = serviceGrpJson
             json = [rest]
        }
        createProductGrp(json,deleteJson)
    }
    else{
        completeEventMessage("error", "Product group name already exists");
    }
    }

      const createProductGrp = (json,deleteJson,deletedTypeFrom) =>{
        konsole.log("prodGrpJson",json,deleteJson,deletedTypeFrom)

            const promises = Services.serviceGrp(json);
            promises.then((res) => {
                konsole.log("resresServiceGroup", res.data.data);
                setLoader(false)
                if(deleteJson != "deleteServiceInGrp"){
                    getRateCardIdToUpdateAndDeleteProdGrp(json,res.data.data,deleteJson)
                    completeEventMessage("success",`Product group ${(deleteJson != undefined && deleteJson != undefined && deleteJson != "") ? "deleted" : (editCard != undefined && editCard != undefined && editCard != "") ? "updated" : "created"} successfully`)
                }else{
                    completeEventMessage("success","Product deleted successfully")
                    getUserDetails()
                }
              })
              .catch((err) => {
                konsole.log("posetResponse", err);
              });
    }

    const getRateCardIdToUpdateAndDeleteProdGrp = async (json,responseData,deleteJson) =>{
        const serviceGrpID = responseData[0].serviceGrpId
        const serviceGrpName = responseData[0].serviceGrpName
        const isActive = deleteJson ? false : true;
        let rateCardID = 0;
        if(json[0]?.serviceGrpId != 0){
            const res = await Services.getSkuList("",true, subtenantID, `GRPPKG_${serviceGrpName}`);
            const skuLsitID = (res?.data?.data?.length > 0) ? res.data.data[0].skuListId : 0;

            const rateCardRes = await Services.getRateCard('',skuLsitID,subtenantID,true)
            rateCardID = (rateCardRes?.data?.data?.length > 0) ? rateCardRes.data.data[0].rateCardId : 0;
        }
        const serviceGrpRateCardObj = { ...rateCardObj(),rateCardId : rateCardID, rateCardName : `RC-${serviceGrpName}`,packageType : "GRP",groupId : serviceGrpID, isActive : isActive, upsertedBy: loginUserID}
        konsole.log("rateCardGrpObj",serviceGrpRateCardObj,rateCardID)
        addRateCard(serviceGrpRateCardObj,deleteJson)
      }


      const createPackageJson = (deleteJson) =>{
        const arrayForUniqueName = editCard ? packageList?.filter((item) =>item.skuListId != editCard?.skuListId) : productsList;
        const uniquePackName = arrayForUniqueName?.some((item) =>item.skuListName?.toLowerCase() == packageJson?.skuListName?.toLocaleLowerCase());

        if (deleteJson || uniquePackName == false || isNullUndefine(arrayForUniqueName)) {
            setLoader(true);
            const serviceArray = [];
            let obj = {}
            let json = deleteJson ?? [];
            if(json.length == 0){
                for (const item of productAndProductGrpList) {
                    if (item.checked === true) {
                        obj = {
                            skuServiceMapId: item?.skuServiceMapId ?? 0,
                            skuServiceId: item.serviceId || item.serviceGrpId,
                            isIndService: item.serviceId ? true : false,
                            isActive: true,
                            subtenantId: item?.subtenantId
                          };
                        serviceArray.push(obj);
                    }
                    else if(item?.skuServiceMapId && item.checked == false){
                         obj = {
                            skuServiceMapId: item?.skuServiceMapId ?? 0,
                            skuServiceId: item.serviceId || item.serviceGrpId,
                            isIndService: item.serviceId ? true : false,
                            isActive: false,
                            subtenantId: item?.subtenantId
                          };
                          serviceArray.push(obj);
                    }
                }
                packageJson.services = serviceArray;
                json = [packageJson]
            }
             konsole.log("packageJson",json)
             createPackage(json,deleteJson)
        }
        else{
            completeEventMessage("error", "Package name already exists");
        }
      }

    const createPackage = (json,deleteJson,deletedTypeFrom) => {
        konsole.log("deletedTypeFrom",json,deleteJson,deletedTypeFrom)

        const promises = Services.saleAbleSkuPath(json);
        promises
          .then(async(res) => {
            setLoader(false);
            konsole.log("resresPackage",res.data.data,deleteJson)
            if(deleteJson !== "deleteServiceInGrp"){
                getRateCardIdToUpdateAndDeletePackage(json,res.data.data,deleteJson)
                completeEventMessage("success",`Package ${(deleteJson != undefined && deleteJson != undefined && deleteJson != "") ? "deleted" : (editCard != undefined && editCard != undefined && editCard != "") ? "updated" : "created"} successfully`)
            }
            else{
                completeEventMessage("success",`${deletedTypeFrom?.isIndService == false ? "Product group" : "Product"} deleted successfully`)
                getUserDetails()
            }
          })
          .catch((err) => {
            setLoader(false);
            konsole.log("subErr", err);
          });
      };

      const getRateCardIdToUpdateAndDeletePackage = async (json,responseData,deleteJson) =>{
        const SkuListId = responseData[0].skuListId;
        const skuListName = responseData[0].skuListName
        const isActive = deleteJson ? false : true;
        const toGetFalseSkuRateCardAndToUpdate = isActive == false ? "" : true
        let rateCardID = 0;
        if(json[0]?.skuListId != 0){
            const res = await Services.getSkuList("",isActive, subtenantID, `${skuListName}`);
            const skuLsitID = (res?.data?.data?.length > 0) ? res.data.data[0].skuListId : 0;

            const rateCardRes = await Services.getRateCard('',skuLsitID,subtenantID,toGetFalseSkuRateCardAndToUpdate)
            rateCardID = (rateCardRes?.data?.data?.length > 0) ? rateCardRes.data.data[0].rateCardId : 0;
        }
        const packageRateCardObj = { ...rateCardObj(),rateCardId : rateCardID,rateCardName : `RC-${skuListName}`,skuListId : SkuListId, isActive : isActive, upsertedBy: loginUserID}
        konsole.log("rateCardPackageObj",packageRateCardObj,rateCardID)
        addRateCard(packageRateCardObj,deleteJson)
      }

      const addRateCard = (rateCardData, deleteJson) => {
        setLoader(true);
         const rateCardJson = [rateCardData];
         konsole.log("rateCardJson1313",rateCardJson)
         let SkuListId;
         const promise = Services.postToAddRateCard(rateCardJson);
         promise.then((res) => {
            setLoader(false);
            konsole.log("resresRateCard",res.data.data,deleteJson)
            const rateCardID = res.data.data.length > 0 && res.data.data[0]?.rateCardId
            if(deleteJson == undefined || deleteJson == null || deleteJson == ""){
                SkuListId = res.data.data.length > 0 && res.data.data[0]?.skuListId
                const applSubsModelJson = {...applicableSubsModelObj(),skuSubsModelId : editCard?.skuSubsModelId ?? 0, appRateCardId : rateCardID,endSubscriptionAmt : endSubsAmnt,appUpsertedBy : loginUserID}
                const subtRateCardJson = {...subtenantRateCardObj(),subtenantRateCardId : editCard?.subtenantRateCardId ?? 0, subSkuListId : SkuListId, subRateCardId : rateCardID,subtenantId: subtenantID}
                const applicableSubsModelJson = [{...applSubsModelJson, subtenantRateCard : [subtRateCardJson]}]
                konsole.log("applicableSubsModelJson",applicableSubsModelJson)
                appSubtForRateCard(applicableSubsModelJson)
            }
            else{
                getUserDetails()
            }
             }).catch((err) => {
                 konsole.log("error", err);
             });
        };

        const appSubtForRateCard = (addApplSubtForRateCrdJson) => {
            setLoader(true);
            const promise = Services.postAppSubtForRateCard(addApplSubtForRateCrdJson);
            promise
                .then((res) => {
                    setLoader(false);
                    const rateCardID = res.data.data?.length && res.data.data[0]?.rateCardId
                    konsole.log("res12", res,rateCardID);
                    getUserDetails()
                    onclose()
                })
                .catch((err) => {
                    konsole.log("error12", err);
                });
        };

    const selectProduct = (item) => {
        setProductAndProductGrpList((prevList) => {
            const updatedList = prevList.map((det) =>
                   item.serviceGrpId
                    ? det.serviceGrpId === item.serviceGrpId
                        ? { ...det, checked: !det.checked }
                        : det
                    : det.serviceId === item.serviceId
                        ? { ...det, checked: !det.checked }
                        : det
            );
    
            const hasCheckedTrue = updatedList.some((det) => det.checked === true);
            if (!hasCheckedTrue) {
                setHideList(true);
            }
            return updatedList;
        });
    };

    const deleteProduct = (item) => {
        deleteItem(item, createServiceObj, createProducts);
        getUserDetails()
    };
    
    const deleteProductGrp = (item) => {
        deleteItem(item, createServiceGrpObj, createProductGrpJson);
        getUserDetails()
    };
    
    const deletePackage = (item) => {
        deleteItem(item, createPackageObj, createPackageJson);
        getUserDetails()
    };

    const deleteItem = (item, createObjFunction, createFunction) => {
        const mergeObjToDelete = { ...createObjFunction(), ...item };
        const { checked, remarks, createdBy, createdOn, endSubscriptionAmt,skuSubsModelId,subtenantRateCardId, updatedBy, updatedOn, services, ...rest } = mergeObjToDelete;
        const deleteJson = [{ ...rest, isActive: false, upsertedBy: loginUserID }];
        konsole.log("deleteJson",deleteJson,item)
        createFunction(deleteJson);
    };

    const deleteProductInProdGrp = (item,det) =>{
        deleteProdAndGrp(item,det,createServiceGrpObj,createServiceObj,createProductGrp)
    }

    const deleteProdAndGrpInPackage = (item,det) =>{
        const prodFunc = det?.isIndService == false ? createServiceGrpObj : createServiceObj
        deleteProdAndGrp(item,det,createPackageObj,prodFunc,createPackage)
    }

    const deleteProdAndGrp = (item,det,createGrpObjFunction,createServObjFunction,createFunction) =>{
        const mergeObjToDelete = { ...createGrpObjFunction(), ...item };
        const {remarks, createdBy, createdOn, endSubscriptionAmt, updatedBy, updatedOn, services, ...restGrp } = mergeObjToDelete;
        let serviceObj = {}
        let serviceArr = []
        for(const id of services){
            serviceObj = {...createServObjFunction(), ...id} 
            if(det?.skuServiceId){
                let {remarks,description,createdBy, createdOn, updatedBy, updatedOn,serviceGrpId,serviceGrpName,serviceId,serviceName, ...restService } = serviceObj;
                serviceArr.push({ ...restService, isActive: (id?.skuServiceId == det?.skuServiceId) ? false : true, upsertedBy: loginUserID });
            }
            else{
                let {remarks,description,createdBy, createdOn, updatedBy, updatedOn, ...restService } = serviceObj;
                serviceArr.push({ ...restService, isActive: (id?.serviceId == det?.serviceId) ? false : true, upsertedBy: loginUserID });
            }
        }
        const createJson = [{ ...restGrp, services : serviceArr, upsertedBy: loginUserID }];
        konsole.log("createJson",createJson)
        createFunction(createJson,"deleteServiceInGrp",det)
    }

    const searchProds = (e) => {
        const query = e.target.value?.toLowerCase();
        const prodAndGrpList = [...productsList ?? [], ...productGrpsList ?? []]
        setSearchQuery(query);

        const filtered = prodAndGrpList?.filter((item) => {
            return (
                (item.serviceName && item.serviceName?.toLowerCase()?.includes(query)) ||
                (item.serviceGrpName && item.serviceGrpName?.toLowerCase()?.includes(query))
            );
        });
        setProductAndProductGrpList(filtered);
  };

    const cardsInnerBorderLine = (data) => {
        konsole.log("dattatatat",data)
        const borderStyle = {
          height: "1px",
          backgroundColor: "rgba(235, 235, 235, 1)",
          marginBottom: "20px",
          width:  "100%",
        };
        return (
        <Row className='mt-3'>
        <Col lg="12" md="12" sm="12" className='d-flex justify-content-center'>
        <div style={borderStyle}></div>
        </Col>
       </Row>
        )
      };

    return(
        <>
        <Modal show={showModal} onHide={onclose} animation={false} centered={true} size='lg' > 
      <Modal.Header closeButton closeVariant='white' style={{backgroundColor:"#720c20",color:"white"}}>
          <Modal.Title>
            <div className="d-flex justify-content-center align-items-center">
           <div>
                {heading == "Product" ? 
                <img src="./images/Service.svg" style={{ height: "22px", width: "22px" }}/>
               : heading == "Product Group" ?
               <img src="./images/productGroup.svg" style={{ height: "22px", width: "22px" }}/>
               : heading == "Package" ?
               <img src="./images/Package.svg" style={{ height: "22px", width: "22px" }}/>: ""
            }
              </div>
           <div className='mt-1 mx-2'>{`${editCard != "" ? "Update" : "Add"} ${heading}`}</div>
            </div></Modal.Title>
        </Modal.Header>
        <Modal.Body style={heading != "Product" ? {maxHeight: "calc(65vh - 150px)",overflowY: "auto",scrollbarWidth: "none"} : {}}>
            <Form>
                <Row>
                    <Col lg="7" md="7" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{heading} Name</Form.Label>
                    <Form.Control type="text" placeholder={`Enter ${heading} Name`} 
                    name={`${heading == "Product" ? "serviceName" : heading == "Product Group" ? "serviceGrpName" : heading == "Package" ? "skuListName" : ""}`}
                    value={serviceJson?.serviceName || serviceGrpJson?.serviceGrpName || packageJson?.skuListName}
                    onChange={(e)=>handleChange(e, heading)} />
                </Form.Group>
                    </Col>
                    <Col lg="5" md="5" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{heading} Price</Form.Label>
                    {/* <CurrencyInput placeholder="$0.00" value={endSubsAmnt} onValueChange={(value)=>setEndSubsAmnt(value)} prefix="$"/> */}
                    <Form.Control type="text" placeholder="$0.00" value={endSubsAmnt} onChange={(e)=>setEndSubsAmnt(e.target.value)}/>
                </Form.Group>
                    </Col>
                </Row>
                <Row>
                <Col lg="12" md="12" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Type something" name = 'description'
                    value={serviceJson?.description || serviceGrpJson?.description || packageJson?.description}
                    onChange={(e)=>handleChange(e, heading)} />
                </Form.Group>
                    </Col>
                </Row>
            </Form>
            <div>

             {(heading == "Product Group" || heading == "Package") && 
             <div>
            {cardsInnerBorderLine("upperBoxLine")}
            <div className='border border-grey h-100 w-100 pt-3 ps-1 pe-1 pb-3'>
            <Row>
                <Col lg="12" md="12" sm="12">
                <div className="d-flex searchInputMainInAddProdModal w-100">
                    <div className="searchInputInnerDiv d-flex w-100" style={{ position: "relative" }}>
                        <Search placeholder={`Search added ${heading == "Product Group" ? "product" : heading == "Package" ? "product/group" : "product"}`} className="searchInput w-100" id="Services" onChange={(e)=>searchProds(e,heading)} value = {searchQuery}/>
                        <div style={{ position: "absolute", right: "35px", top: "50%", transform: "translateY(-60%)" }}>
                            <img src='./images/searchImg.svg' style={{ height: "20px", width: "20px" }} alt="Search Icon" />
                        </div>
                    </div>
                </div>
                </Col>
            </Row>

            {checkAddedProduct?.length > 0 && <AddProductsGroups heading = {heading} key={productAndProductGrpList} productAndProductGrpList={productAndProductGrpList} checkAddedProduct = {checkAddedProduct} cardsInnerBorderLine = {cardsInnerBorderLine} hideList = {hideList} setHideList={setHideList} selectProduct={selectProduct}/>}

            {hideList == true && 
            <div>
            <Row className='mt-3 ms-2'>
                <Col lg="12" md="12" sm="12">
                    <div style={{fontSize:"12px",fontWeight:"bold"}}>All Products({productsList?.length}):</div>
                    <div className="d-flex flex-wrap mt-2" >
                    {productAndProductGrpList?.length && productAndProductGrpList.map((item,ind)=>
                        item.serviceId && (
                        <Tooltip text = {item.serviceName}>
                        <div className={`${item.checked == true ? "addProductPillRed" : "addProductPillGrey"} d-flex justify-content-between align-items-center px-2 mx-1 mb-2 cursor-pointer `} onClick={()=>selectProduct(item,ind)}>
                            <div>{(item.subtenantId == 3 && item?.roleId == null) ? <img src='./icons/AOLogo.svg' style={{ height: "18px", width: "18px" }} alt="AO Logo" /> : 
                            item.checked == true ?  <img src='./icons/redProductIcon.svg' style={{height:"14px",width:"14px"}}/>
                             : <img src='./icons/greyProductIcon.svg' style={{height:"14px",width:"14px"}}/>}</div>
                            <div  className= "addedProductDotShow ms-1" style={{fontSize:"11px"}}>{item.serviceName}</div>
                            <div> {item.checked == true ? <img src="./icons/checked.svg"/> : <img src="./icons/add.svg"/>}</div>
                        </div>
                        </Tooltip>
                        )             
                    )}
                    </div>   
                </Col>
            </Row>
            {heading == "Package" && 
            <Row className='mt-3 ms-2'>
            <Col lg="12" md="12" sm="12">
                <div style={{fontSize:"12px",fontWeight:"bold"}}>All product groups({productGrpsList ? productGrpsList?.length : null}):</div>
                <div className="d-flex flex-wrap mt-2" >
                {productAndProductGrpList?.length && productAndProductGrpList.map((item,ind)=>
                        item.serviceGrpId && (
                        <Tooltip text = {item.serviceGrpName}>
                            <div className={`${item.checked == true ? "addProductPillRed" : "addProductPillGrey"} d-flex justify-content-center align-items-center px-2 mx-1 mb-2 cursor-pointer `} onClick={()=>selectProduct(item,ind)}>
                        <div>{(item.subtenantId == 3 && item?.roleId == null) ? <img src='./icons/AOLogo.svg' style={{ height: "18px", width: "18px" }} alt="AO Logo" /> :
                          item.checked == true ? <img src='./images/redProductGroup.svg' style={{height:"14px",width:"14px"}}/> :
                         <img src='./images/greyProductGroup.svg' style={{height:"14px",width:"14px"}}/>}</div>
                        <div  className= "addedProductDotShow ms-1" style={{fontSize:"11px"}}>{item.serviceGrpName}</div>
                        <div> {item.checked == true ? <img src="./icons/checked.svg"/> : <img src="./icons/add.svg"/>}</div>
                    </div>
                        </Tooltip>
                    )             
                )}
                </div>   
            </Col>
        </Row>}
            </div>}

             {(checkAddedProduct?.length > 0 && hideList) && <div className='d-flex justify-content-center'>
                <div className='ms-auto mt-3 me-2'>
                    <Button style={{backgroundColor:"#720c20",width:"85px",height:"35px",fontSize:"14px"}} className='rounded-pill' onClick={()=>setHideList(false)}>
                        <img src='./images/WhiteTick.svg' style={{width:"11px",height:"7"}}/><span className='ms-1'>Done</span>
                    </Button>
                </div>
             </div>}
            </div>
            </div>}
            </div>
        </Modal.Body>

        <Modal.Footer style={{boxShadow: "0px -3.8511295318603516px 3.8511295318603516px 0px rgba(0, 0, 0, 0.05)"}}>
            <div style={{border:"1px solid #720c20",backgroundColor:"#720c20",width:"115px",height:"40px",fontSize:"18px",color:"white",borderRadius:"8px"}} className='d-flex justify-content-center align-items-center cursor-pointer' onClick={()=>handleSubmit(heading)}>
              Save
            </div>
        </Modal.Footer>
        </Modal>
        </>
    )
}
export default forwardRef(AddPackageModal);