import React from 'react';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';

const Tooltip = ({ children, text, ...props }) => {
  return (
    <OverlayTrigger
      overlay={<BootstrapTooltip>{text}</BootstrapTooltip>}
      {...props}
    >
      {children}
    </OverlayTrigger>
  );
};

export default Tooltip;
