import { useState } from "react";
import { InputGroup, Form, Button } from "react-bootstrap";

const SendMessageForm = ({ sendMessage, sendFile }) => {
    const [msg, setMessage] = useState('');
    const [file, setFile] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (file) {
            await sendFile(file);
            setFile(null);
        } else {
            sendMessage(msg);
            setMessage('');
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <InputGroup className="mb-3">
                <InputGroup.Text>Chat</InputGroup.Text>
                <Form.Control 
                    onChange={e => setMessage(e.target.value)} 
                    value={msg} 
                    placeholder="Type Message"
                    disabled={file !== null}
                />
                <Form.Control 
                    type="file" 
                    onChange={e => setFile(e.target.files[0])} 
                />
                <Button variant="primary" type="submit" disabled={!msg && !file}>Send</Button>
            </InputGroup>
        </Form>
    );
}

export default SendMessageForm;
