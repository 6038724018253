import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Container } from 'react-bootstrap';
import WaitingRoom from './WaitingRoom';
import { useState, useEffect } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import ChatRoom from './ChatRoom';

function Chat() {
    const [conn, setConnection] = useState();
    const [messages, setMessages] = useState([]);
    const [user, setUser] = useState({ username: '', chatroom: '' });

    const LoggegInUser = sessionStorage.getItem('loggedUserId');
    let UserDetail = sessionStorage.getItem('userLoggedInDetail');
    UserDetail = JSON.parse(UserDetail);
    const { memberName } = UserDetail;
    console.log("UserDetail", memberName);
    console.log("ID is : ", LoggegInUser);

    const joinChatRoom = async (memberName, chatroom) => {
        try {
            const conn = new HubConnectionBuilder()
                .withUrl("https://localhost:5001/Chat")
                .configureLogging(LogLevel.Information)
                .build();

            conn.on("JoinSpecificChatRoom", (memberName, msg) => {
                console.log("msg : ", msg);
                <h1>msg</h1>
            });
            conn.on("ReceiveSpecificMessage", (memberName, msg) => {
                setMessages(messages => [...messages, { memberName, msg }]);
            });
            conn.on("ReceiveChatHistory", (chatHistory) => {
                setMessages(chatHistory);
            });
            conn.on("ReceiveFile", (username, fileName, filePath) => {
                setMessages(messages => [...messages, { memberName: username, msg: `File: ${fileName}`, filePath }]);
            });

            await conn.start();
            console.log("SignalR connection established");
            await conn.invoke("JoinSpecificChatRoom", { userName: memberName, chatRoom: chatroom });

            setConnection(conn);
            setUser({ memberName, chatroom });

        } catch (e) {
            console.error("Error connecting to SignalR:", e);
        }
    }

    const sendMessage = async (message) => {
        try {
            await conn.invoke("SendMessage", user.chatroom, memberName, message);
        } catch (e) {
            console.error("Error sending message:", e);
        }
    }

   const sendFile = async (file) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('chatRoom', user.chatroom);
        formData.append('username', memberName);

        const response = await fetch('https://localhost:5001/api/chat/upload', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to upload file');
        }

        const result = await response.json();
        console.log('File uploaded successfully', result);
    } catch (e) {
        console.error('Error sending file:', e);
    }
};

  

    const handleReceiveFile = (username, fileName, filePath) => {
        const newMessage = { memberName: username, msg: `File: ${fileName}`, filePath };
        // Update messages state to include the new file message
        console.log(newMessage);
        setMessages(messages => [...messages, newMessage]);
    };

    // Register the ReceiveFile event handler when component mounts
    useEffect(() => {
        // Ensure conn is defined before attaching event handlers
        if (conn) {
            conn.on("ReceiveFile", handleReceiveFile);
        }

        // Clean up the event handler when component unmounts
        return () => {
            if (conn) {
                conn.off("ReceiveFile", handleReceiveFile);
            }
        };
    }, [conn]); // Only re-run effect if conn changes

    return (
        <div>
            <main>
                <Container>
                    <Row className='px-5 my-5'>
                        <Col sm='12'>
                            <h1>Chat App</h1>
                        </Col>
                    </Row>
                    {!conn ?
                        <WaitingRoom joinChatRoom={joinChatRoom} />
                        : <ChatRoom messages={messages} sendMessage={sendMessage} sendFile={sendFile} />
                    }
                </Container>
            </main>
        </div>
    );
}

export default Chat;
