
import config from '../config.json'

// export const BASE_URL = (config.demo)? "https://aointakeuatapi.azurewebsites.net": "https://aointakeapi.azurewebsites.net";
// export const EVENTCOUNTERURL =  (config.demo)?"https://aoeventcounteruat.azurewebsites.net/": "https://aoeventcounter.azurewebsites.net/";
// export const AOLOGINBASEURL =  (config.demo)?"https://aologinuat.azurewebsites.net": "https://aologin.azurewebsites.net/";
// export const EVENTFUNNELURL=(config.demo)?'https://eventuat.azurewebsites.net':'https://eventdev.azurewebsites.net';
export const BASE_URL = (config.demo)? "https://aointakeuatapi.azurewebsites.net": "https://aointakeapi.azurewebsites.net";
export const EVENTCOUNTERURL =  (config.demo)?"https://aoeventcounteruat.agingoptions.com/": "https://Event.counter.agingoptions.com/";
export const AOLOGINBASEURL =  (config.demo)?"https://aologinuat.agingoptions.com": "https://Unilogin.agingoptions.com";
export const EVENTFUNNELURL=(config.demo)?'https://eventuat.agingoptions.com':'https://events.agingoptions.com';
export const stripePaymentConfirmationUrl=(config.demo) ?'https://staging2.agingoptionsacademy.com//wp-json/custom-api/v1/update-user-confirmation':'https://agingoptionsacademy.com/wp-json/custom-api/v1/update-user-confirmation';

export const intakeBaseUrl = (config?.demo) ? 'https://aointakeformuat.agingoptions.com/' : 'https://member.intake.agingoptions.com/';
export const AoAgenturl = (config.demo) ? 'https://aoagentuat.agingoptions.com/' : 'https://agent.agingoptions.com/';
export const AoAcaddemyUrl = (config.demo) ? "https://academy.nsplindia.co.in" : "https://agingoptionsacademy.com/"
export const EventBaseUrl =(config.demo)?"https://eventuat.agingoptions.com/":"https://events.agingoptions.com/";
export const CounterBaseUrl =  (config.demo)?"https://aoeventcounteruat.agingoptions.com/": "https://Event.counter.agingoptions.com/";
export const CounterMobileBaseUrl = (config.demo)?"https://aoeventcountermobileuat.agingoptions.com/": "https://event.mcounter.agingoptions.com/";
export const AoAdminBaseUrl = (config.demo)?'https://Aoadminuat.agingoptions.com/': 'https://AOAdmin.agingoptions.com/';;
export const AoPartnerBaseUrl =(config.demo)?"https://aopartneruat.agingoptions.com/": "https://partner.admin.agingoptions.com/";;
export const IntakeEntryBaseUrl =(config.demo)?"https://intakeentryformuat.azurewebsites.net/": "https://intakeentryform.azurewebsites.net/";;


let AoUrl = {
   getUserListByEmailId: "/api/Account/GetLoginUserDetails",
   getUserDetailsByUserEmailId:'/api/Account/GetUserDetailByEmailId',
   putClientHandOff: "/api/Member/ClientHandOff",
   getFeedbackPriority:"/api/FeedbackPriority/GetFeedbackPriority",
   putUserName: "/api/Account/UpdateUserName",
   getModuleType: "/api/ModuleType/GetModuleType",
   getFeedbackStatus:"/api/FeedbackStatus/GetFeedbackStatus",
   getFeedbackType:"/api/FeedbackType/GetFeedbackType",
   updateFeedback:"/api/Feedback/update-feedback",
   // ........................................
   postSubtenantDetailPath: 'api/Subtenant/add-subtenant/',
   putSubtenantDetailPath: 'api/Subtenant/update-subtenant/',
   getSubtenantCategoryPath: 'api/SubtenantCategory/GetSubtenantCategoryList/true',
   // ..................1.....................
   postSpeakerResgisterUser: '/api/User/RegisterUser',
   // .......
   postfileuploadspeaker: '/api/FileUpload/uploadUserDocuments',
   // ..................
   postspeakerupsertuser: '/api/SpeakerUser/upsert-SpeakerUser',
   seminarTypeUrl: 'api/SeminarType/get-SeminarTypes/',
   // GetSubtenantCategoryListPath: 'api/SubtenantCategory/GetSubtenantCategoryList/true',
   getSeminarDetailsVenueData: '/api/Venue/getVenue',
   postSubtenantDetails: '/api/Subtenant/get-subtenant-details',
   getCoOrdinatorUserList: '/api/User/getUserList',
   postAddressData: 'api/Address/add-address',
   postVenueData: 'api/Venue/upsertVenueBySubtenantId',
   getCountryListPath: + "/api/Country",
   postSeminarPublisherApi: 'api/SeminarPublisher/upsertSeminar',

   getSpeakerUserDetailsPath: 'api/User/getUserList',
   postCoordinatorAdd: 'api/Account/add-User',
   getPhoneCodePath: '/api/Country/GetRegistrationEnableCountry',
   getAddressIdPath: '/api/Address/get-address',
   postSpeakerList: 'api/SpeakerUser/get-SpeakerUser',
   getUserListpath: 'api/User/getUserList',
   getGenders: '/api/Genders',
   getCitizenshipTypePath: 'api/CitizenshipType',
   getcountrycodepath: '/api/Country/GetRegistrationEnableCountry',
   getContactTypePath: 'api/ContactType',
   // ..................................................................................
   getAthenticatePath: '/api/User/AuthenticateLoggedInUser/',
   getSubtenantDetailPath: 'api/Subtenant/get-subtenant-details',
   postAddUserPath: 'api/Account/add-User',
   getSubtenantPlanner: 'api/User/getUserList',
   postPlannerCalendar: 'api/PlannerCalendar/upsertPlannerCalendar',
   getseminar: '/api/SeminarPublisher/getSeminar/',
   upsertSeminar: 'api/SeminarPublisher/updateSeminarStatus',
   postsubtenantcreateUser: '/api/User/CreateUser',
   postaddressbyuserid: '/api/Address/add-address-by-userid',
   postsubtenantusermemberupdate: '/api/Member/update-member-by-id/',
   postAnsycRole: 'api/UserRole/AddRoleAsync/',
   getUserListByRoleId: '/api/User/getUserListByRoleId/',
   postRoleIdAPI: '/api/UserRole/AddRoleAsync/',
   getAddressAPI: '/api/Address/get-address-by-userid/',
   getContactAPi: '/api/Contact/get-contact-by-userid/',
   getMemberAPI: '/api/Member/',
   getAllUserRoleIdAPi: '/api/UserRoles/getRoles',
   getUserRoleAPI: '/api/UserRole/GetUserRole',
   putAPIForContact: "/api/Contact/update-contact-by-userid/",
   postAPIForContact: "/api/Contact/add-contact-by-userid/",
   putAPIForAddress: "/api/Address/update-address",
   putAPIForMember: "/api/Member/update-member-by-id",
   putRoleIdAPI: '/api/UserRole/UpdateRoleAsync/',
   postAcademyUser :"/api/User/CreateAcademyUser",
   sendUserActivationLink: '/api/UserActivation/SendUserActivationLink',
   getExpirydatetime:'/api/UserValidity/GetUserValidity',
   upGradeValidity : '/api/UserValidity/UpgradeUserValidity',
   getContactTypeCat : '/api/SubContactType',


   deletePartnerUserAPI: '/api/User/delete-User/',


   // //classroom
   // postuploadUserDocumant:'/api/FileUpload/uploadUserDocuments'

   //sumit
   getCommonChannelPath: '/api/CommChannel/GetCommChannel/',
   getUSerRolesAdminPath: '/api/UserRoles/getRoles/',
   getOccurrencePath: '/api/Occurrence/GetOccurrence/',

   getEmailTemplatesPath: '/api/EmailTemplates/GetEmailTemplates/',
   getTextTemplatesPath: '/api/TextTemplates/GetTextTemplates/',

   postUpsertOccurrencePath: '/api/Occurrence/upsertOccurrence/',
   postEmailTemplatesPath: '/api/EmailTemplates/AddEmailTemplates/',
   postTextTemplatesPath: '/api/TextTemplates/UpsertTextTemplates/',
   postUpsertCommMediumPath: '/api/CommMedium/UpsertCommMedium/',
   GetCommMediumPath: '/api/CommMedium/GetCommMedium/',
   putUpdateEmailTemplatesPath: '/api/EmailTemplates/UpdateEmailTemplates/',
    atTheRateGetOccurrenceDataPath:'/api/OccurrenceData/GetOccurrenceData/',
   //sumit

   getEmailTemplatesUrl: '/api/EmailTemplates/GetEmailTemplates',
   postSendEmailCommUrl: '/api/EmailComm/SendEmailComm',

   getEmailTemplatesUrl: '/api/EmailTemplates/GetEmailTemplates',
   postSendEmailCommUrl: '/api/EmailComm/SendEmailComm',

   // //Subscription
   // postIndServicePath: '/api/IndService/Upsert-IndService',
   // getIndService: '/api/IndService/Get-IndService',
   // postGrpService: '/api/GrpService/upsertGrpService',
   // getGrpService: '/api/GrpService/getGrpService',
   // postSaleAbleSKUPath: '/api/SaleableSKU/UpsertSaleableSKU',
   // getSaleAbleSKUPath: '/api/SaleableSKU/GetSaleableSKU',

   //Subscription
   postIndServicePath: '/api/IndService/Upsert-IndService',
   getIndService: '/api/IndService/Get-IndService',
   postGrpService: '/api/GrpService/upsertGrpService',
   getGrpService: '/api/GrpService/getGrpService',
   postSaleAbleSKUPath: '/api/SaleableSKU/UpsertSaleableSKU',
   getSaleAbleSKUPath: '/api/SaleableSKU/GetSaleableSKU',

   //Rate Card
   postToAddRateCardPath: '/api/RateCard/UpsertRateCard',
   postAppSubtForRateCardPath: '/api/ApplicableSubtentantSubscriptions/UpsertApplicableSubtentantSubscriptions',
   getRateCardPath: '/api/RateCard/GetRateCard',
   getpostPlannerDetails: '/api/PlannerCalendar/GetPlannerCalendarDetails',
   upsertPlannerDetails: '/api/PlannerCalendar/upsertPlannerCalendar',

   // file cabinet
   getFileCabinetType: 'api/FileCabinet/GetFileCabinetList',
   postFileDocumentCabinetById: '/api/FileCabinet/GetUserDocsByFileCabinet',
   filecabinetPreparebyUrl: 'api/FileCabinet/PreparedBy/',
   filecabinetfilestatus: '/api/FileCabinet/FileStatus',
   filecabinetdocumenturl: '/api/UserFileCabinet/Documents',


   //Rate Card
   postToAddRateCardPath : '/api/RateCard/UpsertRateCard',
   // postAppSubtForRateCardPath : '/api/ApplicableSubtentantSubscriptions/UpsertApplicableSubtentantSubscriptions',
   postAppSubtForRateCardPath : '/api/ApplicableSubsModel/UpsertApplicableSubsModel',
   getRateCardPath : '/api/RateCard/GetRateCard',
   getpostPlannerDetails:'/api/PlannerCalendar/GetPlannerCalendarDetails',
   upsertPlannerDetails:'/api/PlannerCalendar/upsertPlannerCalendar',
   getAvailableRateCardName : '/api/RateCard/IsRateCardNameAvailable',
   getSubsModelPath : '/api/SubsModel/GetSubsModel',

   //Feedback
   getFeedback:'/api/Feedback/get-feedback',

   //  new file cabinet api --------------------------------------------------
   getMainCabinet: '/api/2/FileCabinet/GetMainCabinet',
   upsertMainCabinet: '/api/2/FileCabinet/UpsertMainCabinet',
   upsertCabinetCategoryType: '/api/2/FileCabinet/UpsertFileCategoryType',
   upsertCabinetFolder: '/api/2/FileCabinet/UpsertFileCabinetFolder',
   getFileCategoryTypeList: '/api/2/FileCabinet/GetFileCategoryTypeList',
   addFileCabinetFolder: "/api/2/FileCabinet/AddFileCabinetFolder",
   deleteFileCabinetFolder:'/api/2/FileCabinet/DeleteFolder/',
   getSubtenantDetails: '/api/Subtenant/get-subtenant-details',
   getUserList: '/api/User/getUserList',
   fileBelongsTo: '/api/FileCabinet/FileBelongsTo',
   getFileTypeByCategory: '/api/2/FileCabinet/GetFileTypeList',
   upsertFileTypeByCategory: '/api/2/FileCabinet/UpsertFileType',
   postUploadUserDocument: '/api/FileUpload/uploadUserDocuments',
   addUserFileCabinet: '/api/2/UserFileCabinet/Add',
   getUserFileCabinetFile: '/api/2/UserFileCabinet/Documents/',
   getFileForView: '/api/FileUpload/downloadUserDocuments/',
   deleteFileCabinetFileByFileId: '/api/FileCabinet/DeleteFilebyFileId',
   sendMailToClientOnclosingFeedbackStatus:'/api/EmailComm/SendEmailWithCCnBccAsync',
   postLogUserout: BASE_URL + '/api/User/LogoutUser/'


}

export default AoUrl;

