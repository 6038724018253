import React, { useEffect, useState, useContext } from "react";
import { Space, Table, Tag, Input, Button, Row, Col, Modal, message } from "antd";
import AddSubtents from "./AddSubtents";
import Services from "../../network/Services";
import konsole from "../../network/konsole";
import "./Firmpartner.css";
import { UserContext } from "../../App";
const { Column, ColumnGroup } = Table;
const { Search } = Input;

export default function Subtents() {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setdata] = useState([]);
  const [assignOptionsList, setAssignOptionsList] = useState([]);
  const [dataSearch, setdataSearch] = useState([]);
  const { setLoader } = useContext(UserContext)
  const [value, setValue] = useState("");
  const [subtenantAddress, setSubtenantAddress] = useState("");
  const [show, setshow] = useState(false)
  const [tab, setTab] = useState([])
  const [userData,setuserdata] = useState({})
  konsole.log("hmt",data)

  const showModal = () => {
    setIsModalVisible(true);
    setshow(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setshow(false)
    setuserdata(null)
  };

  const onSearch = (value) => konsole.log(value);

  const createdBy = null;
  const isActive = true;

  useEffect(() => {
    saveTableData();
  }, []);
  konsole.log("valuecheck",show)

  const saveTableData = () => {
    // setshow(true)
    setLoader(true)
    const Subtenantdetailspromise = Services.getsubtenantdetails(
      createdBy,
      isActive
    );
    konsole.log("promise", Subtenantdetailspromise);
    setLoader(true)
    Subtenantdetailspromise.then((res) => {

      konsole.log("tableData1", res.data.data);
      let len = res.data.data.length
      let totalArray = [];


      // for(let i = 0; i <res.data.data.length ; i++){
      //   if((res.data.data[i].addressId  && res.data.data[i].addressValue  !== null) 
      //     && (res.data.data[i].addressId && res.data.data[i].addressValue  !== undefined)
      //     && (res.data.data[i].addressId && res.data.data[i].addressValue !== ""))

      //   {
      //     // totalArray = res.data.data;
      //     konsole.log([i],"yes")
      //     konsole.log("dssb1",res.data.data[i])

      //   }
      //   else{
      //     konsole.log([i],"no")
      //   }

      // }

      totalArray = res.data.data;

      for (let i = 0; i <totalArray.length ; i++) {

        // setdataSearch([...dataSearch, ])
        if(totalArray[i]?.addressId !== null && totalArray[i]?.addressId !== undefined && totalArray[i]?.addressId !== "" ){
          const address = Services.getAddressId(totalArray[i]?.addressId);
          address
            .then((res) => {


              totalArray[i].addressValue = res.data.data.addressLine1

              
              
              konsole.log("addressREs",[i],res.data.data );

            })
            .catch((err) => {
              konsole.log("Error",[i], err);
              // message.error("Enter valid details")

            });


        }

      }
      konsole.log("totalArray", totalArray);
      konsole.log("assignOptionsList",assignOptionsList)

      setTimeout(()=>{
        setLoader(true)
        setdata(totalArray);
        setAssignOptionsList(totalArray);
        setLoader(false)
      },4000)
    }).catch((err) => {
      konsole.log("err", err);
    }).finally(()=>{
      konsole.log("Finish")
      setLoader(false)
      // setLoader(false)
    })
  };

  // const getAddress = (getArrrayData) => {

  // };
 const onClickRow = (data) =>{
  showModal(true)
  setuserdata(data)
 }
  konsole.log("adress", data);
  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <div style={{ display: "flex", justifyContent: "space-between" }} className="mt-3 ">
            <Col span={12} style={{ display: "flex" }}>
              <Input.Search
                placeholder="Search for firm"
                onSearch={onSearch}
                onChange={(e) =>
                // setuserAgent(e.target.value)
                {
                  const currValue = e.target.value;
                  setValue(currValue);
                  let filteredData = [];
                  let data1 = [];

                  data1 = assignOptionsList;

                  if (currValue !== "") {
                    filteredData = data1.filter((entry) => {
                      if (
                        entry.subtenantName
                          .toLowerCase()
                          .includes(currValue.toLowerCase())
                        // ||
                        // entry.subtenantCategoryId
                        // .toLowerCase()
                        // .includes(currValue.toLowerCase())
                      ) {
                        return data1;
                      }
                    });
                  } else {
                    filteredData = data1;
                  }
                  // konsole.log("mpaeed filtered", data);
                  
                  setdata(filteredData);
                }
                }
                style={{
                  width: "100%",
                  border: "1px solid #d9d9d9", borderRadius: "4px"
                }}
              />
            </Col>
            <Col>
              <Button
                style={{
                  "background-color": "#720C20",
                  color: "white",
                  borderRadius: "5px",
                  marginRight:"10px",
                }}

                onClick={showModal}
              >
                + Add New
              </Button>
            </Col>
            {show ? <AddSubtents
              title="Basic Modal"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              tableData={saveTableData}
              subtenantAddress={setSubtenantAddress}

              userData={userData}
            /> : <></>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>

          <Table
            style={{
              maxHeight: 'calc(100vh - 150px)',
              overflowY: 'auto'
             }} 
            dataSource={data}
            className="mt-3 subtenant-Table"
          >
            <Column
              title="Firm Id "
              align="center"
              dataIndex="subtenantId"
              key="subtenantId"
            />
            <Column
              title="Firm Name"
              align="center"
              dataIndex="subtenantName"
              key="subtenantCategory"
              render={(_,data)=>(
                <a onClick={()=>onClickRow(data)}>{data.subtenantName}</a>
              )}
            />
            <Column
              title="Location"
              align="center"
              dataIndex="addressValue"
              key="addressValue"
            />
            <Column
              title="Subscriptions"
              align="center"
              dataIndex="addressa"
              key="addressa"
            />
          </Table>
        </Col>
      </Row>
    </>
  );
}
