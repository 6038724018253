import React from 'react'
import { Row, Col, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';

import '../../components/FileCabinet/Filecabinet.css'

const FileStructureForm = () => {
    return (
        <div>
            <Row>
                <Col lg="12" className='main-col'>
                    <Row className='mt-2'>
                        <Col lg="10">
                            <Row>
                                <Col>
                                    <h3 className='filetool-text-tag-h6 '>File Details</h3>
                                </Col>
                            </Row>
                            <form>
                                <Row className='mt-2'>
                                    <Col lg="5" >
                                        <div className='d-flex  align-items-center w-100 h-100'>
                                            <h6 className='file-form-h6-tag'>File to Upload</h6>
                                        </div>
                                    </Col>
                                    <Col lg="7">
                                        <div>
                                            <input type='file' className='w-100' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col lg="5" >
                                        <div className='d-flex align-items-center w-100 h-100'>
                                            <h6 className='file-form-h6-tag'>File Cabinet</h6>
                                        </div>
                                    </Col>
                                    <Col lg="7">
                                        <div>
                                            <Form.Select size='sm'>
                                                <option value="1">Life Plan</option>
                                                <option value="2">Family</option>
                                                <option value="3">Health</option>
                                                <option value="4">Housing</option>

                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col lg="5" >
                                        <div className='d-flex align-items-center w-100 h-100'>
                                            <h6 className='file-form-h6-tag'>Name</h6>
                                        </div>
                                    </Col>
                                    <Col lg="7">
                                        <div>
                                            <input type='text' className='w-100' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col lg="5" >
                                        <div className='d-flex align-items-center w-100 h-100'>
                                            <h6 className='file-form-h6-tag'>Date Finalized</h6>
                                        </div>
                                    </Col>
                                    <Col lg="7">
                                        <div>
                                            <input type='text' className='w-100' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col lg="5" >
                                        <div className='d-flex  w-100 h-100'>
                                            <h6 className='file-form-h6-tag'>Description</h6>
                                        </div>
                                    </Col>
                                    <Col lg="7">
                                        <div>
                                            <textarea className='w-100' rows="4" cols="50" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col lg="5" >
                                        <div className='d-flex w-100 h-100'>
                                            <h6 className='file-form-h6-tag'>Location of original document</h6>
                                        </div>
                                    </Col>
                                    <Col lg="7">
                                        <div>
                                            <input type='text' className='w-100' />
                                            <h6 className='file-form-h6-tag file-form-sub-para-h6-tag'>(if applicable)</h6>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mt-1'>
                                    <Col lg="5" >
                                        <div className='d-flex  w-100 h-100'>
                                            <h6 className='file-form-h6-tag'>Emergency Document</h6>
                                        </div>
                                    </Col>
                                    <Col lg="7">
                                        <div>
                                            <Form.Select size='sm'>
                                                <option value="1">No</option>
                                                <option value="2">Yes</option>
                                            </Form.Select>
                                        </div>
                                        <h6 className='file-form-h6-tag file-form-sub-para-h6-tag m-0 p-0'>Is this a document that you would need in an Emergency situation?
                                            If YES then this document will be visible on your Emergency Access page.</h6>
                                        <h6 className='file-form-sub-para-h6-tag'>View yours <span className='folder-text-class file-form-sub-para-h6-tag'>here.</span></h6>
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col lg="5" >
                                        <div className='d-flex w-100 h-100'>
                                            <h6 className='file-form-h6-tag'>Permissions</h6>
                                        </div>
                                    </Col>
                                    <Col lg="7">
                                        <div>
                                            <h6 className='file-form-h6-tag folder-text-class m-0 p-0'>Show/Hide Permissions</h6>
                                            <h6 className='file-form-h6-tag file-form-sub-para-h6-tag'>Choose if you would like to share this file. 
                                                You can grant access to this document to any of 
                                                your contacts, and control if they are able to 
                                                view or edit on this document.</h6>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col lg="5" >
                                        <div className='d-flex  w-100 h-100'>
                                            <h6 className='file-form-h6-tag'>Legal Document</h6>
                                        </div>
                                    </Col>
                                    <Col lg="7">
                                        <div>
                                            <Form.Select size='sm'>
                                                <option value="1">Will</option>
                                                <option value="2">Revocable Living Trust</option>
                                            </Form.Select>
                                        </div>
                                        <h6 className='file-form-h6-tag file-form-sub-para-h6-tag'>If this is a Legal Document, please select from the options above.</h6>
                                    </Col>
                                </Row>


                                <Row className='mt-2'>
                                    <Col lg="5"></Col>
                                    <Col lg="7">
                                        <Button className='folder-save-button'>Submit</Button>
                                    </Col>
                                </Row>

                            </form>
                        </Col>
                        {/* <Col lg="2">
                            <div>
                                <img src="./uploadfile1.png" className='w-75' />
                            </div>
                        </Col> */}
                    </Row>

                </Col>
            </Row>
        </div>
    )
}

export default FileStructureForm
