
import config from '../config.json'
import {message} from "antd";
export const imagePath = [
    "icons/personalInfoHeader.svg",
    "icons/FamilyInfoHeader.svg",
    "icons/healthInfoHeader.svg",
    "icons/housingInfoHeader.svg",
    "icons/financialInfoHeader.svg",
    "icons/legalHeader.svg",
]

// new file cainet 
// export const operationType = ["Permission", "New Category", "Mapping For Subtenant", "Mapping New Category For Subtenant"]
export const operationForCabinet=['Create New Cabinet','Mapping Cabinet']
export const operationForCategoryType = ['Create New Category',"Map New Category"]
export const folderOperationType = ['Create New Folder', 'Map All Folders',"Map New Folder","Map New Subtenant Folder"] 
export const emergencyDocumentArr = [{ label: 'Yes', value: true }, { label: 'No', value: false }]
export const cabinetFileCabinetId =[1]
export const sortingFolderForCabinet=["Life Plan","Archived", "Draft", "Current"];
// export const belongsToArr = [{ value: "098EB6EC-53AD-4C8F-90C8-0028E660CB61", label: "Lane  Lee" }, { value: "D21EDB93-5F84-45D3-B98E-8E7A5E12DC70", label: "Spouse  Leeaaaa", }]
// export const paralegalLoginId = '18793be4-2813-490d-b741-0035982ce0c9'



export const createCabinetObjJson = ({ cabinetId, cabinetName, cabinetDescription,cabinetOperation, isActive, createdBy, updatedBy, updatedOn, createdOn }) => {
    return {
        cabinetId: cabinetId ?? 0,
        cabinetName: cabinetName ?? null,
        cabinetDescription: cabinetDescription ?? null,
        cabinetOperation:cabinetOperation,
        isActive: isActive ?? true,
        createdBy: createdBy ?? null,
        updatedBy: updatedBy ?? null,
        updatedOn: updatedOn ?? null,
        createdOn: createdOn ?? null,
    }
}

export const createCategoryObjJson = ({ roleId, subtenantId, categoryCabinetId, fileCategoryId, fileCategoryType, categoryMappingId, fileCabinetOrder, isFileCabinet, categoryIsActive, description, categoryMapIsShared, categoryMapIsMandatory, categoryMapIsAdd, categoryMapIsDelete, categoryMapIsEdit, categoryMapIsActive, operation, createdBy, updatedBy, updatedOn, createdOn, }) => {
    return {
        "roleId": Number(roleId) ?? null,
        "subtenantId": Number(subtenantId) ?? null,
        "categoryCabinetId": Number(categoryCabinetId) ?? 0,
        "fileCategoryId": fileCategoryId ?? 0,
        "fileCategoryType": fileCategoryType ?? '',
        "categoryMappingId": categoryMappingId ?? 0,
        "fileCabinetOrder": fileCabinetOrder ?? 1,
        "isFileCabinet": isFileCabinet ?? true,
        "categoryIsActive": categoryIsActive ?? true,
        "categoryDescription": description ?? null,
        "categoryMapIsShared": categoryMapIsShared ?? true,
        "categoryMapIsMandatory": categoryMapIsMandatory ?? true,
        "categoryMapIsAdd": categoryMapIsAdd ?? true,
        "categoryMapIsDelete": categoryMapIsDelete ?? true,
        "categoryMapIsEdit": categoryMapIsEdit ?? true,
        "categoryMapIsActive": categoryMapIsActive ?? true,
        "operation": operation ?? null,
        "categoryCreatedBy": createdBy,
        "categoryUpdatedBy": updatedBy,
        "categoryCreatedOn": createdOn,
        "categoryUpdatedOn": updatedOn,
    }
}

export const createFolderObjJson = ({ folderRoleId, folderSubtenantId, folderCabinetId, folderFileCategoryId, folderId, parentFolderId, folderName, folderDescription, folderOperation, level, displayOrder, sharedRoleId, belongsTo, folderIsShared, folderIsMandatory, folderIsActive, createdBy, updatedBy, updatedOn, createdOn, isFile,  }) => {
    return {
      "folderRoleId": Number(folderRoleId) ?? null,
      "folderSubtenantId": Number(folderSubtenantId) ?? null,
      "folderCabinetId": Number(folderCabinetId) ?? null,
      "folderFileCategoryId": Number(folderFileCategoryId) ?? null,
      "folderId": Number(folderId) ?? null,
      "parentFolderId": Number(parentFolderId) ?? 0,
      "folderName": folderName ?? null,
      "folderDescription": folderDescription ?? null,
      "folderOperation": folderOperation,
      "level": level ?? 0,
      "displayOrder": displayOrder ?? 0,
      "sharedRoleId": sharedRoleId ?? '',
      "belongsTo": belongsTo ?? null,
      "folderIsShared": folderIsShared ?? false,
      "folderIsMandatory": folderIsMandatory ?? false,
      "folderIsActive": folderIsActive ?? true,
      "isFile": isFile ?? false,
      "folderCreatedBy": createdBy, 
      "folderUpdatedBy": updatedBy,
      "folderCreatedOn": createdOn,
      "folderUpdatedOn": updatedOn,
    };
  };
  

  export const createFileCabinetFileAdd = ({primaryUserId, fileId, fileCategoryId, fileStatusId, fileTypeId, fileUploadedBy, fileCabinetId, isActive, isShared, isMandatory, isFolder, isCategory, folderId, belongsTo }) => {
    return {
      primaryUserId: primaryUserId ?? null,
      fileId: Number(fileId) ?? 0,
      fileCategoryId: Number(fileCategoryId) ?? 0,
      fileStatusId: Number(fileStatusId) ?? 0,
      fileTypeId: Number(fileTypeId) ?? 0,
      fileUploadedBy: fileUploadedBy ?? null,
      fileCabinetId: Number(fileCabinetId) ?? 0,
      isActive: isActive ?? true,
      isShared: isShared ?? false,
      isMandatory: isMandatory ?? false,
      isActive: isActive ?? true,
      isFolder: isActive ?? true,
      isCategory: isCategory ?? true,
      folderId: Number(folderId) ?? 0,
      belongsTo: belongsTo ?? null
  
    }
  }
  export const isValidFuntion = (data) => {
    if (data !== undefined && data !== null && data !== "") {
      return true
    } else {
      return false
    }
  };

  export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const formatDateIntoDDMMYY = (inputDate) =>{
const date = new Date(inputDate);

const day = date.getDate().toString().padStart(2, '0');
const month = (date.getMonth() + 1).toString().padStart(2, '0');
const year = date.getFullYear().toString().slice(-2);

return`${day}/${month}/${year}`;

}
export const completeEventMessage = (type,messageFor) =>{
     message.destroy()
     message[type](messageFor);
  }
  export const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  
export const isAlphabetRegex2 = (string) => {
  let regex = /^[a-zA-Z-,0-9,& ]+$/;
  return regex.test(string);
}
export const isNumberRegex = (value) => {
  let regex = /^[0-9]*$/;
  return regex.test(value);
}
export const isNullUndefine = (value) => {
  if (value == undefined || value == null || value == '') {
    return true;
  }
  return false
}
