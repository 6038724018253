import React,{useEffect, useState, useRef} from 'react';
import {Button} from 'react-bootstrap';
import { Input, message } from "antd";
import PackageMappedCards from './PackageMappedCards';
import konsole from '../../network/konsole';
import AddPackageModal from './AddPackageModal';
import DeleteConfirmModal from '../DeleteConfirmModal';
import { completeEventMessage } from '../../control/Constant';

function PackageSubscriptionBoxes ({heading,addedList,productsList,productGrpsList,noPackageExists,getUserDetails}){
    const [showModal, setShowModal] = useState(false)
    const [render, setRender] = useState(false)
    const [editCard, setEditCard] = useState("")
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    const [deletedItem, setDeletedItem] = useState(null)
    const [removeProdOrGrpFromGrpOrPack, setRemoveProdOrGrpFromGrpOrPack] = useState(null)
    const [eventType, setEventType] = useState("")
    const [messageTypeFor, setMessageTypefor] = useState("")
    const { Search, TextArea } = Input;
    const subtenantID = sessionStorage.getItem("subtenantId")
    const deleteCardsRef = useRef();

    konsole.log("productGrpsList",productsList,productGrpsList,filteredItems,filteredItems?.length)

    useEffect(()=>{
      setFilteredItems(addedList)
    },[addedList])

      const getEditableItem = (item,type,det) =>{
        konsole.log("detectId", item,det,type)
        setRender(true)
        if(type == "update"){
          setShowModal(true)
          setEditCard(item)
        }
        else if(type == "delete"){
          setDeletedItem(item)
          setMessageTypefor(heading)
          setEventType(type)
          setShowDeleteConfirmModal(true)
        }
        else if(type == "removeServiceOrGrp"){
          if(item?.services?.length > 1){
            setDeletedItem(item)
            setRemoveProdOrGrpFromGrpOrPack(det)
            setEventType(type)
            setMessageTypefor(`${(item?.skuListId && det?.isIndService == false) ? "product group from this package" : (item?.skuListId && det?.isIndService == true) ? "product from this package" : (item?.serviceGrpId) ? "product from this product group" : ""}`)
            setShowDeleteConfirmModal(true)
          }
          else{
            completeEventMessage("error",`You have only one ${item?.serviceGrpId ? "product in this product group" : 
            det?.isIndService == false ? "product group in this package" : det?.isIndService == true ? 
            "product in this package" : ""}`)
          }
        }
      }

      const confirmDelete = (deletionIsConfirm) =>{
        konsole.log("deletionIsConfirm",deletionIsConfirm,eventType)
        if(deletionIsConfirm == true && eventType == "removeServiceOrGrp"){
          handleCallChildFuncToRemoveProdAndGrp(deletedItem,removeProdOrGrpFromGrpOrPack)
          setShowDeleteConfirmModal(false)
        }
        if(deletionIsConfirm == true && eventType == "delete"){
          handleCallChildFunction(deletedItem)
          setShowDeleteConfirmModal(false)
        }

      }

      const handleCallChildFunction = (item) => {
        if(item.serviceId){
          deleteCardsRef.current.deleteProduct(item);
        }
        else if(item.serviceGrpId){
          deleteCardsRef.current.deleteProductGrp(item);
        }
        else if(item.skuListId){
          deleteCardsRef.current.deletePackage(item);
        }
      };

      const handleCallChildFuncToRemoveProdAndGrp = (item,det) => {
        if(item.serviceGrpId){
          deleteCardsRef.current.deleteProductInProdGrp(item,det);
        }
        else if(item.skuListId){
          deleteCardsRef.current.deleteProdAndGrpInPackage(item,det);
        }
      };

      const searchProds = (e) => {
          const query = e.target.value?.toLowerCase();
          setSearchQuery(query);
  
          const filtered = addedList?.filter((item) => {
              return (
                  (item.serviceName && item.serviceName?.toLowerCase()?.includes(query)) ||
                  (item.serviceGrpName && item.serviceGrpName?.toLowerCase()?.includes(query)) ||
                  ((item.skuListName && item.isVisible) && item.skuListName?.toLowerCase()?.includes(query))
              );
          });
          setFilteredItems(filtered);
    };

      const onclose = () =>{
          setShowModal(false)
          setRender(false)
          setEditCard("")
      }
    
    const createNewPackage = () =>{
      return(
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <div><img src='./images/createPackageImage.svg' style={{height:"170px",width:"170px"}}/></div>
          <div style={{color:"rgba(147, 147, 147, 1)",fontSize:"14px"}}>Please create a package by clicking below</div>
          <div><Button style={{backgroundColor:"#720c20",height:"35px",width:"130px",fontSize:"14px"}} className='text-white' onClick={()=>openModal()}>+ Create New</Button></div>
        </div>
      )
    }

    const openModal = () =>{
      setShowModal(true)
      setRender(true)
      setEditCard("")
    }

        return(
          <>
          {render && <AddPackageModal showModal={showModal} key = {render}  onclose = {onclose} heading = {heading} productsList = {productsList} productGrpsList = {productGrpsList} editCard = {editCard} ref={deleteCardsRef} getUserDetails = {getUserDetails} packageList = {addedList}/>}
          {showDeleteConfirmModal == true && <DeleteConfirmModal visible = {showDeleteConfirmModal} titleText = "Confirmation" bodyContent = {`Are you sure, you want to delete this ${messageTypeFor}?`} deleteStatus = {confirmDelete} hideModal = {setShowDeleteConfirmModal}/>}

          <div className="service-manage ps-2 pe-2">
            <div className="d-flex justify-content-start align-items-center" style={{ height: "50px" }}>
              <div className="d-flex justify-content-center align-items-center ms-1">
                {heading == "Product" ? 
                <img src="./images/Service.svg" style={{ height: "22px", width: "22px" }}/>
               : heading == "Product Group" ?
               <img src="./images/productGroup.svg" style={{ height: "22px", width: "22px" }}/>
               : heading == "Package" ?
               <img src="./images/Package.svg" style={{ height: "22px", width: "22px" }}/>: ""
            }
              </div>
              <div className="d-flex justify-content-center align-items-center mx-2 mt-2">
                <h6 className="fs-5" style={{ color: "white" }}>{`${heading}s`}</h6>
              </div>
              <div className="totalServicesNumDivInPartner">
                <div className="d-flex justify-content-center align-items-center">
                  <p style={{ fontSize: "13px", color: "white" }}>{(filteredItems?.length != undefined || filteredItems?.length != undefined || filteredItems?.length > 0) ? filteredItems?.length : 0}</p>
                </div>
              </div>
            </div>
    
            <div className="d-flex justify-content-center mb-0">
            <div style={{height: "1.5px",width:  "97%",backgroundColor: "rgba(255, 255, 255, 0.14)",marginTop: "",marginBottom: "20px"}}>{/* Horizontal Line */}</div>
            </div>
    
            <div className="d-flex searchInputMainDivInAddProd" style={{ height: "50px" }}>
              <div className="searchInputInnerDiv d-flex w-100">
                <Search placeholder={`Search ${heading}`} className="searchInput" id="Services" onChange={(e)=>searchProds(e,heading)} value={searchQuery}/>
              </div>
            </div>
          </div>
          <div className="packageLowerbox" style={{ height: "19rem" }}>
              {noPackageExists == true ? createNewPackage() :
              <div className="justify-content-center d-flex position-relative" style={{width:"98.5%"}}>
              <div className="subscriptonScroll w-100" style={{paddingBottom:"80px"}}>
                {filteredItems?.length > 0 && filteredItems.map((item, index) => (
                    <React.Fragment key={index}>
                      {konsole.log("itemKey",item)}
                    {item.serviceName &&  <PackageMappedCards productTypeName={item.serviceName} key = {item} item = {item} index={index} description = {item.description} rate = {item.endSubscriptionAmt} getEditableItem = {getEditableItem} subtenantID = {item.subtenantId} heading = {heading} roleId = {item?.roleId}/>}
                    {item.serviceGrpName && <PackageMappedCards productTypeName={item.serviceGrpName} key = {item} item = {item} index={index} description = {item.description} rate = {item.endSubscriptionAmt} getEditableItem = {getEditableItem} subtenantID = {item.subtenantId} heading = {heading} roleId = {item?.roleId}/>}
                    {(item.skuListName && item.isVisible) && <PackageMappedCards productTypeName={item.skuListName} key = {item} item = {item} index={index} description = {item.description} rate = {item.endSubscriptionAmt} getEditableItem = {getEditableItem} subtenantID = {item.subtenantId} heading = {heading} roleId = {item?.roleId}/>}
                    </React.Fragment>
                ))}
                </div>
                  <div className="position-absolute bottom-0 end-0 m-3 cursor-pointer" style={{zIndex:"2"}} onClick={()=>openModal()}>
                    <div className="rounded-circle d-flex justify-content-center align-items-center text-white fs-3" style={{backgroundColor:"#720c20",height:"50px",width:"50px"}}>+</div>
                  </div>
              </div>}
          </div>
            </>
        )
}
export default PackageSubscriptionBoxes;