import moment from "moment";
import { AOLOGINBASEURL } from "../network/url";

const commonLib = {

    timeConvertion: function (timeStr) {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM' || modifier === 'pm') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`
    },
    capitalizeFirstLetter: function (string) {
      return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    },
    getSessionStoarge: function (value) {
        return sessionStorage.getItem(value);
    },
    // ..................................................................................

    getQueryParameters: function (urlQuery, name) {
        return new URLSearchParams(urlQuery).get(name);
    },
    setSessionStoarge: function (key, value) {
        sessionStorage.setItem(key, value);
    },
    GetQueryValues: (query,param)=> {
        var url = "";
        url = query.slice(query.indexOf('?') + 1).split('&');
        for (var i = 0; i < url.length; i++) {
          var urlparam = url[i].split('=');
          if (urlparam[0] == param) {
            return urlparam[1].split("#")[0];
          }
        }
        return "";
      },
      isNumberRegex: function(value){
        let regex = /^[0-9]*$/; 
        return regex.test(value);
      },
      isAlphabetRegex: function(value){
        let regex = /^[a-zA-Z ]+$/;
        return regex.test(value);
      },
      getFormattedTime: function(seminarTime){
        return moment(seminarTime, "HH:mm").format("h:mm a");
    },
    getFormattedDate: function (seminarDate) {
        return moment(seminarDate).format('MMMM Do YYYY')
    },
    getObjFromStorage: function (key) {
      return JSON.parse(sessionStorage.getItem(key));
  },
  getFormatDateForfilter:function(seminarDate){
  return moment(seminarDate).format("YYYY-MM-DD")
},
unAuthurizeAccess : () => {
  window.location.replace(`${AOLOGINBASEURL}/Account/Signin`)
},
 dayFormate :(date) => {
   
  const d = new Date(date);
  let day = d.getDay()

switch (day) {
case 0:
 return day = "Sun";
  break;
case 1:
  return day = "Mon";
  break;
case 2:
  return day = "Tue";
  break;
case 3:
  return day = "Wed";
  break;
case 4:
  return day = "Thur";
  break;
case 5:
  return day = "Fri";
  break;
case  6:
  return day = "Sat";
}

}

    // ..................................................................................
   


}
export default commonLib;