import React, { useContext, useEffect, useState } from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'
import { InputCom, InputSelectCom } from './InputCom'
import { createFileCabinetFileAdd, emergencyDocumentArr } from '../../control/Constant';
import { FileCabinetContext } from './ParentCabinet'
import konsole from '../../network/konsole';
import Services from '../../network/Services';
import { message } from 'antd';
import commonLib from '../../control/commonLib';

let cretObj = () => {
    return { file: '', name: '', fileCabinetId: '', fileCategoryId: '', fileFolderId: '', dateFinalized: '', locationOfDocument: '', emergencyDocument: '', legalDocFileType: '', description: '' }
}
const AddFile = ({ refrencePage, uniqueref, selectFolderInfo, fileAddFrom, dispatchloader }) => {
    let { allMainCabinetContainer, cabinetList, allFolderList, getFileFromCabinet } = useContext(FileCabinetContext)
    const subtenantId = commonLib.getSessionStoarge("subtenantId");
    const stateObj = commonLib.getObjFromStorage("stateObj");
    const loggedUserId = sessionStorage.getItem('loggedUserId')


    // define state-----------------------------------------------------------------------------------------------------------------
    const [manageFileData, setManageFileData] = useState({ ...cretObj() })
    const [legaDoc, setLegalDoc] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [folderList, setFolderList] = useState([])
    const [belongsToDetails, setBelongsToDetails] = useState([])

    konsole.log('manageFileDatamanageFileData', JSON.stringify(manageFileData))

    useEffect(() => {
        if (refrencePage == 'STRUCTURE') {
            let { folderCabinetId, folderFileCategoryId, folderId } = selectFolderInfo
            setManageFileData(prev => ({
                ...prev,
                fileCabinetId: folderCabinetId, fileCategoryId: folderFileCategoryId, fileFolderId: folderId
            }))
            commonForCabinetCategory('fileCabinetId', folderCabinetId, 'effect')

            handleOnBlur(folderFileCategoryId)
            konsole.log('manageFileDatamanageFileDataaaaaaa', selectFolderInfo)

        }
    }, [refrencePage, uniqueref, selectFolderInfo])

    //define useEffect--------------------------------------------------------------------------------------------------------------


    const handleChangeCabinet = (e) => {
        let { value, id, files } = e.target
        let valueAll = (id == 'file') ? files[0] : value
        handleInput(id, valueAll)
        konsole.log('filterfolderListfilterfolderListfilterfolderListaaa', value, id)
        commonForCabinetCategory(id, value)

    }

    const commonForCabinetCategory = (id, value, type) => {
        if (id == 'fileCabinetId') {
            const filterCategoryList = cabinetList?.filter(item => item.cabinetId == value)
            const categoryList = filterCategoryList.length > 0 ? filterCategoryList[0].fileCategoryTypeMappingList : [];
            if (type == 'effect') {
                let filterfolderList = categoryList.length > 0 ? categoryList[0].fileCabinetFolderList : []
                setFolderList(filterfolderList)
            }
            konsole.log('categoryListcategoryList', categoryList)
            setCategoryList(categoryList);
        } else if (id == 'fileCategoryId' && type !== 'effect') {
            konsole.log('aabx', categoryList, allMainCabinetContainer)
            let filterfolderList = categoryList.length > 0 ? categoryList[0].fileCabinetFolderList : []
            konsole.log('filterfolderListfilterfolderList', filterfolderList)
            setFolderList(filterfolderList)
        }
    }

    konsole.log('categoryListcategoryList', categoryList)
    const handleInput = (key, value) => {
        setManageFileData(prev => ({
            ...prev,
            [key]: value
        }))
    }

    //  this function use for get file type base on categry Id
    const handleOnBlur = (fileCategoryId) => {
        let fileCategory = (refrencePage === 'STRUCTURE') ? fileCategoryId : manageFileData?.fileCategoryId
        Services.getFileTypeByCategory(fileCategory)
            .then((res) => {
                console.log('getFileTypeByCategory', res)
                setLegalDoc(res.data.data)
            }).catch((err) => {
                console.log('getFileTypeByCategory', err)
            })
    }


    //  validation function----------------------------------------------------------------------------------------------------------------------------------------------------

    let warningMessage = {
        "file": "File can't be blank",
        "name": "Name can't be blank",
        "fileCabinetId": "Please select cabinet",
        "fileFolderId": "Please select folder",
        "belongsTo": "Please select file belongs ",
        "dateFinalized": "Date finalized can't be blank",
        "locationOfDocument": "Location of document can't be blank",
        "emergencyDocument": "Please select emrgency document type",
        "emergencyDocument": "Please select emrgency document type",
        'legalDocFileType': 'Please select file type',
        'description': "Description can't be blank"
    }


    const isEmpty = (value) => {
        if (value === undefined || value === null || value === "") {
            return true;
        }
        return false;
    };

    const validateObject = (obj) => {
        for (const key in obj) {
            if (isEmpty(obj[key])) {
                message.error(warningMessage[key])
                return true;
            }
        }
        return false; // Return null if there are no empty fields
    };
    const saveFileFun = () => {
        if (validateObject(manageFileData)) return;
        let { file, name, fileCategoryId, description, dateFinalized, locationOfDocument, emergencyDocument, legalDocFileType } = manageFileData
        let jsonObj = {
            UserId: loggedUserId,
            File: file,
            UploadedBy: loggedUserId,
            FileTypeId: legalDocFileType == '' ? null : Number(legalDocFileType),
            FileCategoryId: Number(fileCategoryId),
            FileStatusId: 2,
            EmergencyDocument: emergencyDocument,
            DocumentLocation: locationOfDocument,
            UserFileName: name,
            DateFinalized: dateFinalized,
            Description: description
        }
        konsole.log('jsonObjjsonObjjsonObj', jsonObj)
        Services.postUploadUserDocumant({ ...jsonObj }).then((res) => {
            konsole.log('uploadUserDoc', res)
            let responseData = res?.data?.data
            apiCallUserFileCabinetAdd(responseData)
        }).catch((err) => {
            konsole.log(' err in uploadUserDoc', err, err?.response)
        })
        konsole.log('jsonObjjsonObj', jsonObj)
    }

    const apiCallUserFileCabinetAdd = (responseData) => {
        let { fileCategoryId, fileId, fileStatusId, fileTypeId } = responseData
        let belongsTo = [{ "fileBelongsTo": loggedUserId }]

        let postJson = createFileCabinetFileAdd({ fileCabinetId: manageFileData.fileCabinetId, belongsTo, fileUploadedBy: loggedUserId, fileCategoryId, folderId: Number(manageFileData.fileFolderId), fileId, fileStatusId, fileTypeId, primaryUserId: loggedUserId })
        console.log('postJson', postJson)
        Services.addUserFileCabinet(postJson).then((res) => {
            konsole.log('addUserFileCabinet', res)
            message.success('File upload Successfully')
            window.location.reload()
        }).catch((err) => {
            console.log('err in file ading in cabiner', err, err?.response)
        })
    }

    //  warning ---------------------------------------------------------------------------------------------------------------------------


    //  konsole-----------------------------------------------------------------------------------------------------------------------------
    konsole.log('legaDocaaaaa', legaDoc)
    konsole.log('allMainCabinetContainerallMainCabinetContainer', allMainCabinetContainer)
    konsole.log('categoryListcategoryList', categoryList)
    konsole.log('belongsToDetailsbelongsToDetails', belongsToDetails)
    konsole.log('selectFolderInfoselectFolderInfo', selectFolderInfo)
    konsole.log('manageFileDatamanageFileData', manageFileData)
    konsole.log('cabinetListcabinetList', cabinetList)

    return (
        <div>
            <Row lg='8'>
                <Col lg="12" className='main-col'>
                    <Row className='mt-2'>
                        <Col lg="10" className='bg-light'>
                            <Row> <Col><h3 className='filetool-text-tag-h6'>File Details</h3></Col></Row>
                            <InputCom type='file' label='File to Upload' placeholder='File to Upload' id='file' handleInputChange={handleChangeCabinet} />
                            <InputCom label='Name' placeholder='Enter Name' id='name' value={manageFileData.name} handleInputChange={handleChangeCabinet} />
                            <InputSelectCom label='Cabinet' placeholder='Select any Cabinet' id='fileCabinetId' disable={uniqueref == 'STRUCTURE'} selectTypeValue='cabinetId' selectTypeLabel='cabinetName' selectInfo={cabinetList} value={manageFileData.fileCabinetId} handleSelectChange={handleChangeCabinet} />
                            <InputSelectCom label='Category' placeholder='Select any Category' id='fileCategoryId' disable={uniqueref == 'STRUCTURE'} selectTypeValue='fileCategoryId' selectTypeLabel='fileCategoryType' selectInfo={categoryList} value={manageFileData.fileCategoryId} handleSelectChange={handleChangeCabinet} onBlurfun={handleOnBlur} />
                            <InputSelectCom label='File Cabinet Folder' placeholder='Select any  Folder' id='fileFolderId' disable={uniqueref == 'STRUCTURE'} selectTypeValue='folderId' selectTypeLabel='folderName' selectInfo={folderList} value={manageFileData.fileFolderId} handleSelectChange={handleChangeCabinet} />
                            {/* <InputSelectCom label='Belongs To' placeholder='Belongs To' id='belongsTo' selectTypeValue='value' selectTypeLabel='label' selectInfo={belongsToDetails} value={manageFileData.belongsTo} handleSelectChange={handleChangeCabinet} /> */}
                            <InputCom type='date' label='Date Finalized' id='dateFinalized' placeholder='Date Finalized' value={manageFileData.dateFinalized} handleInputChange={handleChangeCabinet} />
                            <InputCom label='Location of original document' id='locationOfDocument' placeholder='Location of original document' value={manageFileData.locationOfDocument} handleInputChange={handleChangeCabinet} />
                            <InputSelectCom label='Emergency Document' placeholder='Emergency Document Type' id='emergencyDocument' selectTypeValue='value' selectTypeLabel='label' selectInfo={emergencyDocumentArr} value={manageFileData.emergencyDocument} handleSelectChange={handleChangeCabinet} />
                            <InputSelectCom label='Legal Document Type' placeholder='Select Legal Document' id='legalDocFileType' selectTypeValue='fileTypeId' selectTypeLabel='fileType' selectInfo={legaDoc} value={manageFileData.legalDocFileType} handleSelectChange={handleChangeCabinet} />
                            <InputCom type='textarea' label='Description' id='description' placeholder='Description' value={manageFileData.description} handleInputChange={handleChangeCabinet} />
                            <Row className='mt-2 mb-2'> <Col lg="5"></Col><Col lg="7"><Button className='folder-save-button' onClick={() => saveFileFun()}>Submit</Button></Col></Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
        </div>
    )
}

export default AddFile

