const MessageContainer = ({ messages }) => {
    return (
        <div>
            {
                messages.map((msg, index) =>
                    <div key={index}>
                        {msg.filePath ? (
                            <a href={msg.filePath} target="_blank" rel="noopener noreferrer">{msg.msg} - {msg.memberName}</a>
                        ) : (
                            <p>{msg.msg} - {msg.memberName}</p>
                        )}
                    </div>
                )
            }
        </div>
    );
}

export default MessageContainer;
