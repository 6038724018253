import React from "react";
import { useState } from "react";
import Menubtns from "../FirmPartner/Menubtns";
import SubscriptionRate from "./SubscriptionRate";
import Discount from "./Discount";
import PackageSunbscription from "./PackageSubscription"
import commonLib from "../../control/commonLib";
import PackageManagementStructure from "../PartnerSubscription/PackageManagementStructure";

function SubscriptionStructure(){
    const [subscriptionbtn, setsubscriptionbtn] = useState(1);
    let roleid = commonLib.getObjFromStorage("stateObj").roleId
    return(
        <>
         <Menubtns
        screenType="subscriptionbtns"
        subscriptionbtn={subscriptionbtn}
        setsubscriptionbtn={setsubscriptionbtn}
      />
        {(subscriptionbtn == 1 && roleid == 4) ? (<h1>
           <PackageSunbscription/>
        </h1>)
         : (subscriptionbtn == 1 && roleid == 11) ?
         (<h1>
          <PackageManagementStructure/>
       </h1>)
            : (subscriptionbtn == 2 && roleid == 4) ? (
            <SubscriptionRate />
          ) : (subscriptionbtn == 3 && roleid == 4) ? (
            <Discount />
          ) : (subscriptionbtn == 4 && roleid == 4) ? (
            <p>four</p>
          ) : (subscriptionbtn == 5 && roleid == 4) ? (
            <p>five</p>
          ) : (
            ""
          )}
        </>
    )
}
export default SubscriptionStructure;