import React, { useState } from 'react'
import { Row, Col, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import '../../components/FileCabinet/Filecabinet.css'

const FolderStructureForm = (props) => {


    const [showFolderFormState, setShowFolderFormState] = useState(props.showFolderFormState)
    const [hide,setHide] = useState(props.showForm)

    console.log("showFolderFormStatejjj", props.showFolderFormState, props.lifePointv)

    const addNewFolderFunction = (e) => {
        setShowFolderFormState(e)
        setHide(true)
    }

    return (
        <div>
            <Row>
                <Col lg="12" className='folderstructure'>
                    <Row>
                        <Col>
                            <h4 style={{ color: "#720c20" }}>
                                Manage Your File Cabinet</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-1'>
                            <div className='d-flex'>
                                <h6>Add New:</h6>
                                <div className='d-flex'>
                                    {console.log("showFolderFormState", showFolderFormState)}
                                    <h6 className={`${showFolderFormState == "Drawer" ? "filetool-text-tag-h6" : "folder-text-class"} mx-3 `} onClick={(e) => addNewFolderFunction("Drawer")}>Drawer</h6> |

                                    <h6 className={`${showFolderFormState == "Folder" ? "filetool-text-tag-h6" : "folder-text-class"} mx-3 `} onClick={(e) => addNewFolderFunction("Folder")}>Folder</h6> |
                                    <h6 className='mx-3 folder-text-class'
                                    // onClick={(e) => addNewFolderFunction("File")}
                                    >File</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        hide == true ? <>
                            <Row className='mt-4'>
                                <Col>
                                    <h6>Add/Edit {showFolderFormState == "Drawer" ? "Drawer" : "Folder"}</h6>
                                </Col>
                            </Row>

                            {/* folder / drawer form code */}
                            <form>
                                {
                                    showFolderFormState == "Folder" ?
                                        <>
                                            <Row className=''>
                                                <Col lg="2" >
                                                    <div className='d-flex align-items-center w-100 h-100'>
                                                        <h6>Drawer</h6>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div>
                                                        <Form.Select>
                                                            {props.lifePointv.map((item, index) => {
                                                                return (
                                                                    <option value="1">{item.label}</option>
                                                                )
                                                            })}


                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </> : ""
                                }

                                <Row className='mt-3'>
                                    <Col lg="2">
                                        <div className='d-flex align-items-center w-100 h-100'>
                                            <h6>Name</h6>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <input type='text' className='w-100' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col lg="2">
                                        <div className='d-flex align-items-center w-100 h-100'>
                                            <h6>Description</h6>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <textarea className='w-100' rows="4" cols="50" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col lg="2" >
                                        <div className='d-flex align-items-center w-100 h-100'>
                                            <h6>Order</h6>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <Form.Select>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>

                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col lg="2"></Col>
                                    <Col lg="6">
                                        <Button className='folder-save-button'>Save</Button>
                                    </Col>
                                </Row>
                            </form>
                            {/* folder / drawer form code end */}

                            
                        </>:""
                    }

                    {/* folder / drawer table code start */}

                    <Row className='mt-4'>
                        <Col lg="12">
                            <Row>
                                <Col>
                                    <h6>Your Drawers & Folders</h6>
                                </Col>
                            </Row>
                            <Row className="bg-light" style={{ height: "34px" }}>
                                <Col lg="4" className=''>
                                    <div className="d-flex justify-content-start align-items-center h-100">
                                        <h6 className="m-0 p-0  filetool-text-tag-h6">Drawer</h6>
                                    </div>
                                </Col>
                                <Col lg="6" className=''>
                                    <div className='d-flex justify-content-start align-items-center h-100'>
                                        <h6 className="m-0 p-0 filetool-text-tag-h6">Folder</h6>
                                    </div>
                                </Col>
                                <Col lg="2" className=''>
                                    <div className="d-flex justify-content-end align-items-center h-100">
                                        <h6 className='m-0 p-0 filetool-text-tag-h6'>Action</h6>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Row>
                                        <Col lg="4">
                                            <div className='d-flex h-100'>
                                                <div style={{ width: "50px" }} className='d-flex justify-content-center align-items-center h-100'>
                                                    <img src="./drawer1.png" className='w-100' />
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center h-100'>
                                                    <h6 className='m-0 p-0'>Life Plan</h6>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col></Col>
                                        <Col>
                                            <div className="d-flex justify-content-end align-items-center h-100">
                                                <h6 className='m-0 p-0 folder-text-class'>Edit</h6>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mt-1'>
                                        <Col></Col>
                                        <Col>
                                            <div className='d-flex h-100'>
                                                <div style={{ width: "30px" }} className='d-flex justify-content-center align-items-center h-100'>
                                                    <img src="./Group.png" className='w-100' />
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center h-100 px-1'>
                                                    <h6 className='m-0 p-0'>Folder 1</h6>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col>
                                            <div className="d-flex justify-content-end align-items-center h-100">
                                                <h6 className='m-0 p-0 folder-text-class'>Edit</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-1'>
                                        <Col></Col>
                                        <Col>
                                            <div className='d-flex h-100'>
                                                <div style={{ width: "30px" }} className='d-flex justify-content-center align-items-center h-100'>
                                                    <img src="./Group.png" className='w-100' />
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center h-100 px-1'>
                                                    <h6 className='m-0 p-0'>Folder 2</h6>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col>
                                            <div className="d-flex justify-content-end align-items-center h-100">
                                                <h6 className='m-0 p-0 folder-text-class'>Edit</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-1'>
                                        <Col></Col>
                                        <Col>
                                            <div className='d-flex h-100'>
                                                <div style={{ width: "30px" }} className='d-flex justify-content-center align-items-center h-100'>
                                                    <img src="./Group.png" className='w-100' />
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center h-100 px-1'>
                                                    <h6 className='m-0 p-0'>Folder 3</h6>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col>
                                            <div className="d-flex justify-content-end align-items-center h-100">
                                                <h6 className='m-0 p-0 folder-text-class'>Edit</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* folder / drawer table code end */}
                    
                   


                </Col>
            </Row>
        </div>
    )
}

export default FolderStructureForm
