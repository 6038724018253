import { useState } from "react";
import { useEffect, useRef } from "react";
import konsole from '../../network/konsole';
import Services from '../../network/Services';

export default function AtTheRateTextArea ( props ) {

    const [modalstate, setModalState] = useState({
        id: props.id,
        className: props.className,
        rows: props.rows,
        // handleTextUpdate: props.handleTextUpdate,
        // occuranceId: props.occuranceId,
        defaultText: props.defaultText || "",
        placeholder: props.placeholder || ""
    })
    const textarea = useRef(null);
    const suggestionBox = useRef(null);
    const [dataList, setDataList] = useState([]);
    // const [selectedSuggestion, setSelectedSuggestion] = useState();
    const [Top, setTop] = useState(0)
    const [Left, setLeft] = useState(0)

    useEffect(() => {
        updateModalState();

        textarea.current.value = props.defaultText || "";

        // get occurrence data list
        setDataList([]);
        konsole.log("vsh props.occuranceId", props.occuranceId)
        const promises = Services.atTheRateGetOccurrenceDatalApi(props.occuranceId || 0, true)
        promises.then((response) => {
            konsole.log("vsh @ resssresslklk", response.data?.data, " for ", props.occuranceId);
            setDataList(response.data?.data);
        })
        .catch((error) => {
            konsole.log("vsh error", error);
        })
        .finally(() => {
            konsole.log("vsh finish");
        });

        // Hide suggestion box when clicking outside of it
        document.addEventListener("click", (event) => {
            if (!event.target.closest("#suggestionBox")) {
                hideSuggestions();
            }
        });
    }, [props.occuranceId, props.defaultText]);

    function updateModalState() {
        const newState = {
            id: props.id,
            className: props.className,
            rows: props.rows,
            // handleTextUpdate: props.handleTextUpdate,
            // occuranceId: props.occuranceId,
            defaultText: props.defaultText || "",
            placeholder: props.placeholder || ""
        }
        setModalState(newState)
    }

    function handleInput(e) {
        props.handleTextUpdate(e);
        const text = textarea.current.value;
        const cursorPos = textarea.current.selectionStart;
        const triggerIndex = text.lastIndexOf("@@", cursorPos - 1);
        if(text[triggerIndex-1] == "@") return hideSuggestions();
        konsole.log("vsh trigger", triggerIndex, text[triggerIndex-1])
        const string = text.substring(triggerIndex, cursorPos);
        konsole.log("triggerIndex", triggerIndex, cursorPos, string)
        if (triggerIndex !== -1 && triggerIndex < cursorPos) {
        showSuggestions(string);
        } else {
        hideSuggestions();
        }
    }

    function getSuggestions(text) {
        return dataList.filter((d) => d.occurrenceData.toLowerCase().includes(text));
    }

    function showSuggestions(text) {
        const suggestions = getSuggestions(text);
        konsole.log("vsh suggestions" ,suggestions)
        const cursorPos = textarea.current.selectionStart;
        const triggerIndex = textarea.current.value.lastIndexOf(
        "@@",
        cursorPos - 1
        );
        const triggerText = textarea.current.value.substring(
        triggerIndex + 2,
        cursorPos
        );

        if (suggestions.length > 0) {
            // setSelectedSuggestion(suggestions);
        suggestionBox.current.innerHTML = "";
        suggestions.forEach((suggestion) => {
            const suggestionItem = document.createElement("div");
            suggestionItem.textContent = suggestion.occurrenceData;
            suggestionItem.classList.add("suggestion");
            suggestionItem.addEventListener("click", () => {
            replaceSuggestion(triggerIndex, triggerText, suggestion.occurrenceData);
            });
            suggestionBox.current.appendChild(suggestionItem);
        });

        suggestionBox.current.style.display = "block";
        // suggestionBox.current.style.top = textarea.current.offsetTop + 
        //     Math.floor(triggerIndex / textarea.current.cols) * 40 +
        //     "px";
        // suggestionBox.current.style.left =
        //     textarea.current.offsetLeft +
        //     (triggerIndex % textarea.current.cols) * 10 +
        //     "px";
        } else {
        hideSuggestions();
        }
    }

    function hideSuggestions() {
        if(suggestionBox.current){
        suggestionBox.current.style.display = "none";
        }
    }

    function replaceSuggestion(triggerIndex, triggerText, suggestion) {
        const text = textarea.current.value;
        const newText =
        text.substring(0, triggerIndex) +
        suggestion +
        text.substring(triggerIndex + 2 + triggerText.length);
        textarea.current.value = newText;
        props.handleTextUpdate({target : {value: newText}});
        konsole.log("vsh input called", {target : {value: newText}})
        hideSuggestions();
    }
    
    const setPosition = () => {
        var textArea = document.getElementById(modalstate.id);
        var attherate = document.getElementById(modalstate.id+"attherate");

        var getCaretCoordinates = require('textarea-caret');
        var position = getCaretCoordinates(textArea, textArea.selectionEnd);

        konsole.log("vsh position",position, Math.min(position.top, textArea.clientHeight));
        konsole.log("vsh position @@",textArea.clientWidth, attherate.clientWidth);
        
        var calTop = Math.min(position.top + 45, textArea.clientHeight + 25);
        var calLeft = textArea.clientWidth < (position.left + attherate.clientWidth) ? position.left - attherate.clientWidth : position.left;

        konsole.log("vsh position" ,calLeft)
        setTop(calTop + "px");
        setLeft( calLeft + "px");
    }

    return (
        <>
        <textarea
            id={modalstate.id}
            ref={textarea}
            rows={modalstate.rows}
            // cols="50"
            className={modalstate.className}
            onChange={e => {
                handleInput(e);
                setPosition();
            }}
            // onBlur={e => props.handleTextUpdate(e)}
            placeholder={modalstate.placeholder}
        ></textarea>
        <div id={modalstate.id+"attherate"} ref={suggestionBox} style={{ display: "none", backgroundColor: "white", border: "1px solid black", width: "30%", maxHeight: "100px", position: "absolute", top: Top, left: Left, zIndex: "199999999999",maxWidth: "100%", overflowY: "scroll" }}></div>
        </>
    );
}