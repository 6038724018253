import React, { useState, useContext, createContext, useEffect } from 'react'
import "antd/dist/antd.css";
import { Form, Button, Input, Select, Modal, Row, Col, notification } from 'antd';
import konsole from '../network/konsole'
import Services from '../network/Services'
import Address from './Address'
import { GlobalData } from './Seminar/SeminarFile';
import { UserContext } from '../App';
export const GlobalVenue = createContext();
const { Option } = Select;


const AddEditVenue = ({ visible, onCancel, status, statusId, RecallgetvenueDataAPI, getAddressByAddressId }) => {




  const [form] = Form.useForm();

  const { setLoader } = useContext(UserContext)

  const [getVenueName, setGetVenueName] = useState("")

  const [getAddressId, setGetAddressId] = useState("")
  let subtenantId = sessionStorage.getItem('subtenantId')
  let loginUserId = sessionStorage.getItem('loggedUserId')
  konsole.log("MyVenue", getVenueName)


  status(getVenueName)
  // konsole.log("Statusstatus",status)

  const [loggedUserId, setLoggedUserId] = useState("")
  useEffect(() => {
    let loggedUserId = sessionStorage.getItem('loggedUserId')
    konsole.log('respaaa', loggedUserId)
    setLoggedUserId(loggedUserId)
  }, [])

  const openNotification = (placement, Status) => {

    if (Status == 500) {

      notification.error({
        message: `Error ${Status} `,
        description:
          'Server Issue! Please try after sometime.',
        placement,
      });
    } else if(Status == 200){
      notification.success({
        message: `Venue added successfully`,
      
        placement,
      });
    } 
   
  };


  let AddressId;

  const EndedData = (postData) => {
    setLoader(true)
    setGetVenueName(postData.venueName)
    konsole.log("data", postData);
    let mainAddress = postData.address;
    let mainCity = postData.city;
    let mainState = postData.state;
    let mainCountry = postData.country;
    let mainZipCode = postData.zipcode
    let mainVenue = postData.venueName

    sendData(mainAddress, mainCity, mainState, mainCountry, mainZipCode, mainVenue)

  }

  const sendData = (mainAddress, mainCity, mainState, mainCountry, mainZipCode, mainVenue) => {
    const AddressData = Services.addressData(null, null, mainAddress, null, null, mainZipCode, 'no', mainCity, mainState, mainCountry, 1, loggedUserId)
    AddressData.then((res) => {
      konsole.log(res.data)
      konsole.log("AddressId:", res.status);
      SendVenue(res.data.data.addressId, mainVenue)
      openNotification('topRight', res.status)
    })
      .catch((err) => {
        konsole.log(err)
      }).finally(() => {
        setLoader(false)
      })




  }

  const SendVenue = (AddressId, mainVenue) => {

    const UpsertVenue = Services.getVenueData(subtenantId, 0, mainVenue, AddressId, true, "", loginUserId)
    UpsertVenue.then((res) => {
      konsole.log("VenuePostRes", res)
      konsole.log("VenuePostResVenueText", res.data.data.venueText)
      konsole.log("VenuePostResVenueId", res.data.data.addressId)
      setApiVenue(res.data.data.venueText)
      setApiAddressId(res.data.data.addressId)
      getAddressByAddressId(res.data.data.addressId);
      RecallgetvenueDataAPI();
      setLoader(false)
    }).catch((err) => {
      konsole.log("VenuePost", err)
    }).finally(() => {
      setLoader(false)
    })

  }



  const onOk = () => {
    form.submit();

  }

  const [ApiVenue, setApiVenue] = useState("")
  const [ApiAddressId, setApiAddressId] = useState("")

  // status(ApiVenue)
  statusId(ApiAddressId)

  const { VenueSave } = useContext(GlobalData);

  useEffect(() => {
    form.setFieldsValue({
      'venueName': VenueSave,
    });
  }, [VenueSave])

  konsole.log("SetFieldVenue", VenueSave)

  return (
    <>
      <Modal visible={visible} onOk={onOk} okText="Add Venue" onCancel={onCancel} maskClosable={false}  >
        <Form form={form} onFinish={EndedData} name="userForm" layout="vertical">
          <Row className='w-100' gutter={[8, 8]}>
            <Col xs={24} lg={24} >
              <Form.Item label="Venue" name="venueName"
                rules={[{
                  required: true,
                  message: 'Enter Venue',
                }]}

              >
                <Input placeholder="Name"
                // onChange={(e) => setGetVenueName(e.target.value)} 
                />

              </Form.Item>
            </Col>
            <GlobalVenue.Provider
            //  value={{ sendVenueName: getVenueName }}
            >

              <Address form={form} />
            </GlobalVenue.Provider>
          </Row>
          {/* <Button type='primary' htmlType='submit'>Add Venue</Button> */}
        </Form>
      </Modal>




    </>

  );
}

export default AddEditVenue