import { Col, Row, Modal, message } from 'antd';
import React, { useState, useRef, useEffect, useContext, useMemo, useCallback, } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button, Form } from 'antd';
import commonLib from '../../../control/commonLib';
import konsole from '../../../network/konsole';
import Services from '../../../network/Services';
// import JoditEditor, { EditorState, ContentState, htmlToDraft } from "jodit-react";
// import Jodit from 'jodit';
// import 'jodit/build/jodit.min.css';
import "./communicationmodal.css";
import { UserContext } from "../../../App.js";
import Element from 'antd/lib/skeleton/Element';
import { ModalBody } from 'react-bootstrap';
import config from "../../../config.json"
import AtTheRateTextArea from '../AtTheRateTextArea';



let index = 0;

const initialContent = `
<table border='0' width='100%' cellspacing='0' cellpadding='0'> <tbody> <tr> <td style='padding: 10px 0 30px 0;'> 
<table style='border: 1px solid #cccccc; border-collapse: collapse;' border='0' width='600' cellspacing='0' cellpadding='0' align='center'> <tbody> <tr> <td style='border-bottom: 1px; border-bottom-style: solid; padding: 20px 0 30px 0; color: #153643; font-size: 28px; font-weight: bold; font-family: Arial, sans-serif;' align='center'>
<p style='font-size: 16px; font-weight: bold; font-family: sans-serif; text-decoration: none; line-height: 40px; width: 100%; display: inline-block; color: #871a27 !important;'>
AgingOptions<br/>@@SUBTENANTNAME</p>
</td></tr><tr> <td style='padding: 40px 30px 0px 30px;' bgcolor='#ffffff'> <table border='0' width='100%' cellspacing='0' cellpadding='0'> <tbody><tr>
 <td style='padding: 20px 0 30px 0; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px'>

Please enter your email text here.

</td>
</tr><tr> <td style='padding: 30px 30px 30px 30px;' bgcolor='#6d6864'> <table border='0' width='100%' cellspacing='0' cellpadding='0'> <tbody> <tr> <td style='color: #ffffff; font-family: Arial, sans-serif; font-size: 14px;' width='50%'>AgingOptions<br/><span style='color: #ffffff;'>31919 6th Ave S, Suite A100</span><br/><span style='color: #ffffff;'>Federal Way, WA 98003.</span></td><td><span style='color: #ffffff;'>info@agingoptions.com</span><br/><span style='color: #ffffff;'>www.agingoptions.com</span><br/><span style='color: #ffffff;'> 1-877-76-AGING (24464) </span></td></tr></tbody> </table> </td></tr></tbody> </table> </td></tr></tbody></table>
`

// `
// <table border='0' width='100%' cellspacing='0' cellpadding='0'> <tbody> <tr> <td style='padding: 10px 0 30px 0;'> <table style='border: 1px solid #cccccc; border-collapse: collapse;' border='0' width='600' cellspacing='0' cellpadding='0' align='center'> <tbody> <tr> <td style='border-bottom: 1px; border-bottom-style: solid; padding: 20px 0 30px 0; color: #153643; font-size: 28px; font-weight: bold; font-family: Arial, sans-serif;' align='center'><a style='font-size: 16px; font-weight: bold; font-family: sans-serif; text-decoration: none; line-height: 40px; width: 100%; display: inline-block; color: #871a27 !important;' href='REPLCEINVITEURL'>AgingOptions<br/>@@SUBTENANTNAME</a></td></tr><tr> <td style='padding: 40px 30px 0px 30px;' bgcolor='#ffffff'> <table border='0' width='100%' cellspacing='0' cellpadding='0'> <tbody><tr> <td style='padding: 20px 0 30px 0; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px;'>
// Please enter your email text here.

// / <tr > <td style="padding: 40px 30px 0px 30px;" bgcolor="#ffffff"> 
// // <table border="0" width="100%" cellspacing="0" cellpadding="0"> 
// // <tbody><tr  > <td style="padding: 20px 0 30px 0; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px;"> 
// // <p>${placeHolder}</P>

// // </td></tr></tbody> </table> </td></tr>

// </td></tr><tr> <td style='padding: 30px 30px 30px 30px;' bgcolor='#6d6864'> <table border='0' width='100%' cellspacing='0' cellpadding='0'> <tbody> <tr> <td style='color: #ffffff; font-family: Arial, sans-serif; font-size: 14px;' width='50%'>AgingOptions<br/><span style='color: #ffffff;'>31919 6th Ave S, Suite A100</span><br/><span style='color: #ffffff;'>Federal Way, WA 98003.</span></td><td><span style='color: #ffffff;'>info@agingoptions.com</span><br/><span style='color: #ffffff;'>www.agingoptions.com</span><br/><span style='color: #ffffff;'> 1-877-76-AGING (24464) </span></td></tr></tbody> </table> </td></tr></tbody> </table> </td></tr></tbody></table>
// `

export const Addcommunicationmodal = (props) => {
    // const [placeHolder,setPlaceHolder] = useState("Please enter your email text here")
    konsole.log("plplplpl", config.SUBTENANT_ID)



    const [form] = Form.useForm();
    // const editor = useRef(null);
    const { setLoader } = useContext(UserContext)
    const [items, setItems] = useState([]);

    const [lastdata, setlastdata] = useState(false)
    const [getChannelValue, setChannelValue] = useState()
    const [getOccurrenceSelectedValue, setOccurrenceSelectedValue] = useState()
    const [getSelectRoleFromValue, setSelectRoleFromValue] = useState("")
    const [getSelectRoleToValue, setSelectRoleToValue] = useState("")
    const [getCreateValue, setCreateValue] = useState("");
    const [showDropDown, setShowDropDown] = useState(false);
    const [CreateNewButtonId, setCreateNewButtonId] = useState(false);
    const [uploadPreTempDropDown, setuploadPreTempDropDown] = useState(false);
    const [isDisabled, setISDisabled] = useState(false)
    const [isDisabledInblidTemplate, setISDisabledInblidTemplate] = useState(false)
    const [commonChannelSelect, setCommonChannelSelect] = useState([]);
    const [getUserRoleSelect, setUserRoleSelect] = useState([])
    const [getEmailTemplateSelect, setEmailTemplateSelect] = useState(false)
    const [getTextTemplateSelect, setTextTemplateSelect] = useState(false)
    const [getOccurrenceSelect, setOccurrenceSelect] = useState([]);
    const [getEmailSubjectValue, setEmailSubjectValue] = useState();
    const [postOccurrence, setPostOccurrence] = useState()
    const [getCkEditorText, setCkEditorText] = useState("")
    const [getCkEditorEmail, setCkEditorEmail] = useState("")
    const [postCkEditorText, setPostCkEditorText] = useState([])
    const [putCkEditorText, setPutCkEditorText] = useState([])
    const [postCkEditorEmail, setPostCkEditorEmail] = useState([])
    const [callpostAllData, setPostAllData] = useState([]);
    const [callPutAllData, setPutAllData] = useState([])
    const [GetCommMediumData, setCommMediumData] = useState([]);
    const [GetCommMediumData1, setCommMediumData1] = useState([]);

    const [inblidEmailTemplate, setInblidEmailTemplate] = useState([])
    const [inblidTextTemplate, setInblidTextTemplate] = useState([])
    const [subjectEmailData, setSubjectEmailData] = useState()

    const [getSelectedEmailTemplateData, setSelectedEmailTemplateData] = useState([])
    const [getSelectedTextTemplateData, setSelectedTextTemplateData] = useState([])
    const [updateText, setUpdateText] = useState(false)
    const [RoleFromLength, setRoleFromLength] = useState(0)
    const [RoleToLength, setRoleToLength] = useState(0)
    const [fromRoleIdd, setfromRoleId] = useState(0)
    const [toRoleId, settoRoleId] = useState(0)
    const [isTrueValue, setIsTrueValue] = useState(false)
    const [isStopApi, setISStopApi] = useState(false)
    const [EmailSubject, setEmailSubject] = useState("");
    const [preDefineEmailTemplateData, setPreDefineEmailTemplateData] = useState("")
    const [comChanID, setComChanID] = useState(GetCommMediumData[0]?.commChannelId)
    const [addOccuranceName, setOccuranceName] = useState()
    const [EmailTemtID, setEmailTemopID] = useState()
    const [subtenantID, setSubtenantID] = useState()

    const [selectedChannelID, setSelectedChannelID] = useState()
    const [ckeditorID, setCkEditorID] = useState(4)
    const [ckeditorIDEmail, setCkeditorIDEmail] = useState(4)
    const [selectedFromID, setSelectedFromID] = useState(2)
    const [selectedToID, setSelectedToID] = useState(2)
    const [templateDataEdit, setTemplateDataEdit] = useState(initialContent);
    const [open, setOpen] = useState(false);
    const [OccurrencNameIsTrue, setOccurrencNameIsTrue] = useState()
    const [cktext, setCkText] = useState()
    const [preDefineTemplateId, setPreDefineTemplateId] = useState(4)
    const [textMessage, setTextMessage] = useState()
    const [showSerchTrue, setShowSerchTrue] = useState(true)
    const [createLinkState, setCreateLinkState] = useState(false)
    const [selectedChannelLinkID, setSelectedChannelLinkID] = useState()
    const [firstselect1, setselect1] = useState(false)
    const [firstselect2, setselect2] = useState(false)
    const [firstselect3, setselect3] = useState(false)
    const [firstselect4, setselect4] = useState(false)

    const [occuranceId, setOccuranceId] = useState(0);

    let userId = commonLib.getObjFromStorage("stateObj").userId
    const inputFieldRef = useRef()
    const buttonFieldRef = useRef()

    // console.log("UserID", userId)

    konsole.log("textMessagetextMessage", document?.getElementById("testAreaId")?.innerHTML)


    const [name, setName] = useState("");
    const inputRef = useRef(null);


    useEffect(() => {
        konsole.log("initialContentinitialContent", initialContent)
        let subtenantID = commonLib.getSessionStoarge("subtenantId")
        setSubtenantID(subtenantID)
        getCommonChannelfunction();
        getUserRolesfunction();
        getOccurrencefunction(subtenantID);
        // setTemplateDataEdit(initialContent)
        getPreEmailTemplatesPathApiFunction(subtenantID)
    }, [])


    konsole.log("khkhkhkhkhkkhhfhfhgfytr", subtenantID)

    useEffect(() => {

        // alert("second useffect inside")
        setComChanID(GetCommMediumData[0]?.commChannelId)

        konsole.log("inblidinblidTextTemTextTemplate", inblidEmailTemplate[0]?.emailSubject)

        if (isTrueValue === true) {
            setfromRoleId(GetCommMediumData[0]?.commMediumRoles[0]?.fromRoleId)
            settoRoleId(GetCommMediumData[0]?.commMediumRoles[0]?.toRoleId)
        }

        // let valuedata = getChannelValue === "Email" ? "Email"
        //     : (getChannelValue === "Text") === "Text" ? "Text"
        //         : getChannelValue === "Both" === "Both" ? "Both" : ""

        form.setFieldsValue({
            "commChannel": getChannelValue?.value,
            // valuedata != (undefined||null||"")? valuedata : getChannelValue?.value,
            // "roleFrom": getSelectRoleFromValue ? getSelectRoleFromValue : "",
            // "asignRoleTo": getSelectRoleToValue ? getSelectRoleToValue : "",
            "emailsubject":
                getEmailTemplateSelect == false ? postOccurrence ||
                    GetCommMediumData[0]?.applicableEmailTempId == ("" || null || undefined) ? ""
                    : subjectEmailData :
                    subjectEmailData,
            // "emailckeditor": templateDataEdit


        })
        // setTemplateDataEdit(initialContent)


    }, [GetCommMediumData[0]?.commChannelId, GetCommMediumData[0]?.commMediumRoles, subjectEmailData, GetCommMediumData[0]?.commMediumRoles[0]?.fromRoleId, GetCommMediumData[0]?.commMediumRoles[0]?.toRoleId])



    konsole.log("khkuuuuuuuujhk", getChannelValue)

    const handleClose = () => {

        form.resetFields();
        setCreateNewButtonId(false)
        setChannelValue("")
        setName('');
        setSelectRoleFromValue("")
        setSelectRoleToValue("")
        setCreateValue(false)
        setShowDropDown(false)
        setCommMediumData(0)
        setuploadPreTempDropDown(false)
        props.setShowAddModal(false);
        getOccurrencefunction(subtenantID)
    }

    const handleTextUpdate = (e) => {
        // setTextMessage(e.target.value)
        // document.querySelector('.textarea_text').addEventListener('input', () => {
        //     var text = document.querySelector('.textarea_text').value;
        //     // document.querySelector('.textarea_text').value = text.charAt(0).toUpperCase() + text.slice(1);
        //     setTextMessage(document.querySelector('.textarea_text').value = text.charAt(0).toUpperCase() + text.slice(1))
        //     // setCkText(e)
        // })
        form.setFieldsValue(
            {
                addTextOnly: e.target.value
            }
        )
    }


    const onNameChange = (event) => {
        setName(event.target.value);
        // console.log("InputValue", name)
    };

    konsole.log("getOccurrenccurrenceSelect", getOccurrenceSelect)
    // ?.toLowerCase()?.includes(name)
    const addItem = (e) => {
        // konsole.log("item", items)
        e.preventDefault();
        let oldOccurenceName = getOccurrenceSelect?.filter(data => data?.occurrenceDetails === name)
        konsole.log("oldOccurenceNamegetOccurrenceSelect", oldOccurenceName)
        if (name === "") {
            message.error("Please enter occurrenc name")
            // alert("Please enter occurrenc name")
        }
        else if (oldOccurenceName[0]?.occurrenceDetails?.toLowerCase() == name?.toLowerCase()) {
            message.error("Occurrenc name allready exist")

            // alert("Occurrenc name allready exist")
        }
        else {
            setItems([...items, name || `New item ${index++}`]);
            setName('');
            setOpen(false)
            // items.push()
            // setTimeout(() => {
            //     inputRef.current?.focus();
            // }, 0);
            //    alert("tttttttttt")
            postOccurrenceData()
        }


    };


    const getCommonChannelValue = (value, key) => {
        setselect2(true)
        setChannelValue(key)
        // setComChanID(0)
        // setEmailSubject(null)
        if (key?.key == 1) {
            setSelectedChannelID(1)
            setSelectedChannelLinkID(1)
            setComChanID(1)
            setCkeditorIDEmail(0)
            setCkEditorID(0)
            // setuploadPreTempDropDown(false)
            form.setFieldsValue({

                "preDefineEmailTemplate": null,
                "emailsubject": ""

            })
        }
        else if (key?.key == 2) {
            setSelectedChannelID(2)
            setComChanID(2)
            setCkeditorIDEmail(0)
            setCkEditorID(0)
            // setuploadPreTempDropDown(false)

            form.setFieldsValue({
                "preDefineTextTemplate": null

            })
        }
        else if (key?.key == 3) {
            setSelectedChannelID(3)
            setSelectedChannelLinkID(3)
            setComChanID(3)
            // setuploadPreTempDropDown(false)

            form.setFieldsValue({
                "preDefineEmailTemplate": null,
                "preDefineTextTemplate": null

            })
        }
        else {
            // setSelectedChannelID(4)

        }
    }
    konsole.log("getChannelValuegetChannelValue", getChannelValue?.key)

    const getOccurrenceSelectedValueFunction = (value, key) => {
        setselect1(true)
        konsole.log(value)
        setOccurrenceSelectedValue(key)
        setOccuranceId(key?.key)
        if (key?.key !== undefined) {
            // alert("lkjlkjkljlkj")
            setShowSerchTrue(false)
        }
        else {
        }
    }

    const handleEmailTemplateChange = (value, key) => {
        konsole.log("dfysdf", key);
        setEmailTemplateSelect(true)
        setISDisabledInblidTemplate(true)
        konsole.log("printed");
        getEmailTemplatesPathApiFunction(subtenantID, key?.key);
    }

    // konsole.log("selectedChannelIDselectedChannelID", selectedChannelID, uploadPreTempDropDown, getChannelValue)

    const handleTextTemplateChange = (value, key) => {
        setTextTemplateSelect(true)
        setISDisabledInblidTemplate(true)
        getTextTemplatesPathApiFunction(subtenantID, key?.key);
        // GetCommMediumPathApiFuction(subtenantID, key?.key)
        if (selectedChannelID == 2) {
            setCkEditorID(2)

        }
        else if (selectedChannelID == 3) {
            setCkEditorID(3)
        }
        else {
            // setCkEditorID(4)
        }
    }

    const GetCommMediumPathApiFuction = (subtenantID, occuranceData) => {
        const promises = Services.GetCommMediumPathApi(subtenantID)
        promises.then((response) => {
            konsole.log("GetCommMediumPathApi", response?.data?.data);


            setCommMediumData1(response?.data?.data)
            newFunction(occuranceData, response.data.data);


        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });

    };

    const newFunction = (getOccurrenceSelect, GetCommMediumData1) => {
        let i, j
        let occuranceData = getOccurrenceSelect;
        for (i = 0; i <= getOccurrenceSelect?.length - 1; i++) {
            for (j = 0; j <= GetCommMediumData1?.length - 1; j++) {
                if (getOccurrenceSelect[i]?.occurrenceId === GetCommMediumData1[j]?.occurrenceId) {
                    // setOccurrencNameIsTrue(true)
                    konsole.log("asdfgh", occuranceData[i]);
                    Object?.assign(occuranceData[i], { disabled: true });
                }
                else {
                    // setOccurrencNameIsTrue(false)

                }
            }

        }
        setOccurrenceSelect(occuranceData)

    }

    const createNewFunction = () => {
        //  e.preventDefault()

        if ((CreateNewButtonId != false && getChannelValue?.key == 1) || preDefineTemplateId == 1) {
            // alert("Email")
            postEmailTemplatesPathApi();
            setChannelValue(1)

        }
        else {
            if (uploadPreTempDropDown != true) {
                // alert("Email esle Part")
                setCreateNewButtonId(true)
            }

        }

        if ((CreateNewButtonId != false && getChannelValue?.key == 2 || getChannelValue == 2 || preDefineTemplateId == 2)) {
            // alert("Text")
            postTextTemplatesPathApi();
            setChannelValue(2)
        }
        else {
            // alert("Text Else Part")
            setCreateNewButtonId(true)
        }


        konsole.log("textMessagetextMessagetextMessagesadsadasdadasd", cktext)

        // if (cktext != "<p><br></p>" && cktext !== "" && cktext !== null && cktext !== undefined) {
        if ((CreateNewButtonId != false && getChannelValue?.key == 3 || (preDefineTemplateId == 1 && preDefineTemplateId == 2))) {
            // alert("Both")
            postEmailTemplatesPathApi();
            setChannelValue(3)
        }
        else {
            setCreateNewButtonId(true)
        }

    }

    konsole.log("getChannelValue", getChannelValue)

    const handleFromRoleChange = (value, key) => {
        setselect3(true)
        setSelectRoleFromValue(key)
        setRoleFromLength(0)
        setfromRoleId(0)
        setIsTrueValue(false)
        setISStopApi(true)


        if (getSelectRoleToValue?.length > 0) {
            setSelectedFromID(2)
            setSelectedToID(2)

        }

        else {
            setSelectedToID(1)

        }

    };
    konsole.log("gjjkgkgkjgkkgkgkggkkkkkjgj", getSelectRoleFromValue?.key)


    const handleToRoleChange = (value, key) => {
        setselect4(true)
        setSelectRoleToValue(key)
        setRoleToLength(0)
        settoRoleId(0)
        setISStopApi(true)
        konsole.log("getSelectRoleFromValue", getSelectRoleFromValue?.length)

        if (getSelectRoleFromValue?.length > 0) {

            setSelectedToID(2)
            setSelectedFromID(2)
        }

        else {
            setSelectedFromID(1)
        }
    }


    const getValueSubjectInput = (e) => {
        setEmailSubjectValue(e?.target?.value)

    }

    const showContentfunction = () => {
        // alert("not requi func call")
        // form.submit()

        if ((selectedChannelID == 1) || (selectedChannelID == 2) || (selectedChannelID == 3)) {
            setuploadPreTempDropDown(true)
            getPreEmailTemplatesPathApiFunction(subtenantID)
            getPreTextTemplatesPathApiFunction(subtenantID)
            // setCreateNewButtonId(false)
        }


    }

    konsole.log("klhlkhhklhlhklhhlh", getEmailSubjectValue)

    //Api Code
    const getCommonChannelfunction = () => {
        const promises = Services.getCommonChannelApi()
        promises.then((response) => {
            // konsole.log("resssress", response);
            setCommonChannelSelect(response.data.data);

        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });
    };

    const getUserRolesfunction = () => {
        const promises = Services.getUSerRolesAdminApi()
        promises.then((response) => {
            // konsole.log("resssress", response);
            setUserRoleSelect(response?.data?.data);

        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });
    };


    const getEmailTemplatesPathApiFunction = (subtenantID, emailID) => {

        const resultEmailTemplate = getSelectedEmailTemplateData.filter(element => element.templateId == emailID)
        if(resultEmailTemplate.length == 0) return;
        setInblidEmailTemplate(resultEmailTemplate[0]?.templateContent);
        setSubjectEmailData(resultEmailTemplate[0]?.emailSubject)
        setCreateLinkState(true)
        setSelectedChannelLinkID(0)
        form.setFieldsValue({

            "emailsubject":

            resultEmailTemplate[0]?.emailSubject,


        })
        if (selectedChannelID == 1) {
            setCkEditorID(1)

        }
        else if (selectedChannelID == 2) {
            setCkEditorID(2)

        }
        else if (selectedChannelID == 3) {
            // setCkEditorID("BothEmail")
            setCkeditorIDEmail(3)
        }

        else {
            setCkEditorID(4)
        }
        if (uploadPreTempDropDown == true) {
            setPreDefineTemplateId(1)
        }
        konsole.log("printed 2");
        // const promises = Services.getEmailTemplatesPathApi(config.SUBTENANT_ID, emailID)
        // promises.then((response) => {
        //     konsole.log("printed 3");
        //     konsole.log("EmailTemplatesData", response?.data?.data);
        //     setInblidEmailTemplate(response?.data?.data[0]?.templateContent);
        //     setSubjectEmailData(response?.data?.data[0]?.emailSubject)
        //     setCreateLinkState(true)
        //     setSelectedChannelLinkID(0)

        //     // setTemplateDataEdit(response?.data?.data[0]?.templateContent)
        //     form.setFieldsValue({

        //         "emailsubject":

        //             response?.data?.data[0]?.emailSubject,


        //     })
        //     if (selectedChannelID == 1) {
        //         setCkEditorID(1)

        //     }
        //     else if (selectedChannelID == 2) {
        //         setCkEditorID(2)

        //     }
        //     else if (selectedChannelID == 3) {
        //         // setCkEditorID("BothEmail")
        //         setCkeditorIDEmail(3)
        //     }

        //     else {
        //         setCkEditorID(4)
        //     }
        //     if (uploadPreTempDropDown == true) {
        //         setPreDefineTemplateId(1)
        //     }
        // })
        //     .catch((error) => {
        //         konsole.log("error", error);
        //     })
        //     .finally(() => {
        //         konsole.log("finish");
        //     });
    };


    konsole.log('inblidinblidEmailTemplatedEmaatejlkjkl', inblidEmailTemplate)


    const getPreEmailTemplatesPathApiFunction = (subtenantID) => {
        // alert(subtenantID,emailID)
        // konsole.log("subtenanlkjlj", subtenantID, emailID)

        const promises = Services.getEmailTemplatesPathApi(config.SUBTENANT_ID)
        promises.then((response) => {
            konsole.log("preEmailTemplatesData", response?.data?.data);
            // setInblidEmailTemplate(response?.data?.data);
            setSelectedEmailTemplateData(response?.data?.data)

        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });
    };


    const getTextTemplatesPathApiFunction = (subtenantID, id) => {
        // konsole.log("subtenanlkjljkhkh", subtenantID, id)
        const resultTextTemplate = getSelectedTextTemplateData.filter(element => element.textTemplateId == id);
        if(resultTextTemplate.length == 0) return;
        setInblidTextTemplate(resultTextTemplate);
        setTextMessage(resultTextTemplate[0]?.textTemplateContent)
        setCreateLinkState(true)
        setSelectedChannelLinkID(0)
        form.setFieldsValue(
            {
                addTextOnly: resultTextTemplate[0]?.textTemplateContent
            }
        )

        if (uploadPreTempDropDown == true) {
            setPreDefineTemplateId(2)
            // setCkText(response?.data?.data[0]?.textTemplateContent)
        }

        // const promises = Services.getTextTemplatesPathApi(config.SUBTENANT_ID, id)
        // promises.then((response) => {
        //     konsole.log("TextTemplatesDatayyyyyyyyyy", response.data.data);
        //     setInblidTextTemplate(response?.data?.data);
        //     setTextMessage(response?.data?.data[0]?.textTemplateContent)
        //     setCreateLinkState(true)
        //     setSelectedChannelLinkID(0)
        //     form.setFieldsValue(
        //         {
        //             addTextOnly: response.data?.data[0]?.textTemplateContent
        //         }
        //     )

        //     if (uploadPreTempDropDown == true) {
        //         setPreDefineTemplateId(2)
        //         // setCkText(response?.data?.data[0]?.textTemplateContent)
        //     }

        // })
        //     .catch((error) => {
        //         konsole.log("error", error);
        //     })
        //     .finally(() => {
        //         konsole.log("finish");
        //     });
    };

    // konsole.log("templtemplateDataEditateDataEdit", templateDataEdit)

    const getPreTextTemplatesPathApiFunction = (subtenantID) => {
        // konsole.log("subtenanlkjljkhkh", )

        const promises = Services.getTextTemplatesPathApi(config.SUBTENANT_ID)
        promises.then((response) => {
            konsole.log("TextTemplatesDatalkllllllllllllllkllll", response.data.data);
            setSelectedTextTemplateData(response?.data?.data)
            // form.setFieldsValue(
            //     {
            //         addTextOnly: response.data?.data[0]?.textTemplateContent
            //     }
            // )
        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });
    };

    konsole.log("getSelecTextTemplateuuuDatiia", form.getFieldValue().addTextOnly)

    const getOccurrencefunction = (subtenantID) => {
        const promises = Services.getOccurrenceApi()
        promises.then((response) => {
            konsole.log("resssress", response.data.data);
            setItems(response.data.data);
            setOccurrenceSelect(response.data.data)
            GetCommMediumPathApiFuction(subtenantID, response.data.data)

        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });
    }

    konsole.log("getOccurrencjljljeSelect", getOccurrenceSelect.length)



    const postOccurrenceData = () => {

        const promises = Services.postUpsertOccurrenceApi(0, name, true, userId)
        promises.then((response) => {
            // konsole.log("ressresssresssress", response);
            setItems(response.data.data);
            setPostOccurrence(response.data.data)
            // setOccuranceName()
            form.setFieldsValue({
                "occurrence": response.data.data?.occurrenceDetails
            })
            message.success("Occurrenc name add successfully")
            setChannelValue("")
            setSelectRoleFromValue("")
            setSelectRoleToValue("")
            setCommMediumData("")
            setCreateValue(false)
            setCreateNewButtonId(false)
            setOccurrenceSelectedValue(0)
            getOccurrencefunction(subtenantID);

        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });



    };


    const postEmailTemplatesPathApi = () => {

        let jsonObj = {
            "templateType": postOccurrence?.occurrenceDetails ? postOccurrence?.occurrenceDetails : getOccurrenceSelectedValue?.value,
            "emailSubject": getEmailSubjectValue ? getEmailSubjectValue : subjectEmailData,
            "templateContent": document.getElementById("testAreaId").innerHTML,
            // templateDataEdit,
            "isActive": true,
            "createdBy": userId
        }

        let strValue = JSON.stringify(jsonObj)
        konsole.log("strValue", strValue)
        konsole.log("emailobj", jsonObj)

        const promises = Services.postEmailTemplatesPathApi(postOccurrence?.occurrenceDetails ? postOccurrence?.occurrenceDetails : getOccurrenceSelectedValue?.value, getEmailSubjectValue ? getEmailSubjectValue : subjectEmailData, document?.getElementById("testAreaId")?.innerHTML, true, userId)
        promises.then((response) => {
            konsole.log("resporesponsense", response?.data?.data);
            setPostCkEditorEmail(response.data.data);

            if (getChannelValue?.key == 1 || getChannelValue == 1) {

                postUpsertCommMediumApiFuction(response.data.data?.templateId);
            }
            else {
                postTextTemplatesPathApi(response.data.data?.templateId);

            }

        })
            .catch((error) => {
                konsole.log("errorpostEmailTemplatesPathApi", error);
            })
            .finally(() => {
                konsole.log("finish");
            });

    }

    konsole.log("postCkEditorEmail", postCkEditorEmail);

    const postTextTemplatesPathApi = (EmailId) => {

        if (form.getFieldValue().addTextOnly !== "" && form.getFieldValue().addTextOnly !== null && form.getFieldValue().addTextOnly !== undefined) {
            // alert("data having")
            let jsonObj = {
                "textTemplateId": 0,
                "textTemplateType": getOccurrenceSelectedValue?.value,
                // "textSubject": postOccurrence?.occurrenceDetails ? postOccurrence?.occurrenceDetails : getOccurrenceSelectedValue?.value,
                "textTemplateContent": form.getFieldValue().addTextOnly,
                "isActive": true,
                "upsertedBy": userId
            }

            let strValue = JSON.stringify(jsonObj)
            // konsole.log("textobj", strValue)

            const promises = Services.postTextTemplatesPathApi(
                0,
                postOccurrence?.occurrenceDetails ? postOccurrence?.occurrenceDetails : getOccurrenceSelectedValue?.value,
                form.getFieldValue().addTextOnly,
                true,
                userId
            )

            promises.then((response) => {
                // konsole.log("postTextTemplatesPathApi", response)
                setPostCkEditorText(response.data.data)

                if (getChannelValue?.key == 2 || getChannelValue == 2) {
                    // alert("inside text")
                    postUpsertCommMediumApiFuction(response.data.data?.textTemplateId);

                }
                else {
                    postUpsertCommMediumApiFuction(EmailId, response.data.data?.textTemplateId);

                }

            })
                .catch((error) => {
                    konsole.log("errorpostTextTemplatesPathApi", error);
                })
                .finally(() => {
                    konsole.log("finish");
                });


        } else {
            message.error("Please Fill the text")
        }




    };

    // konsole.log("postCkEditorTextpostCkEditorText", templateDataEdit)

    const postUpsertCommMediumApiFuction = (templateId, textTempId) => {



        let arr = [];
        let i, j;

        for (i = 0; i <= getSelectRoleFromValue?.length - 1; i++) {
            for (j = 0; j <= getSelectRoleToValue?.length - 1; j++) {

                arr.push(
                    {
                        "commMediumRolesId": 0,
                        "fromRoleId": getSelectRoleFromValue[i]?.key,
                        "toRoleId": getSelectRoleToValue[j]?.key,
                        "isActive": true
                    }
                )
            }
        }
        konsole.log("arayfir", arr)

        let obj = (getChannelValue?.key == 3) && (templateId && textTempId) || getChannelValue == 3 && (templateId && textTempId) ? {
            "commMediumId": 0,
            "commChannelId": getChannelValue?.key,
            "subtenantId": subtenantID,
            "applicableEmailTempId": templateId,
            "applicableTextTempId": textTempId,
            "occurrenceId": postOccurrence?.occurrenceId ? postOccurrence?.occurrenceId : getOccurrenceSelectedValue?.key,
            "isActive": true,
            "upsertedBy": userId,
            "commMediumRoles": arr
        }
            : getChannelValue?.key == 2 && templateId || getChannelValue == 2 && templateId ? {

                "commMediumId": 0,
                "commChannelId": getChannelValue?.key,
                "subtenantId": subtenantID,
                // "applicableEmailTempId": postCkEditorEmail?.templateId ? postCkEditorEmail?.templateId : "",
                "applicableTextTempId": templateId,
                "occurrenceId": postOccurrence?.occurrenceId ? postOccurrence?.occurrenceId : getOccurrenceSelectedValue?.key,
                "isActive": true,
                "upsertedBy": userId,
                "commMediumRoles": arr
            }
                : getChannelValue?.key == 1 && templateId || getChannelValue == 1 && templateId ? {
                    "commMediumId": 0,
                    "commChannelId": getChannelValue?.key,
                    "subtenantId": subtenantID,
                    "applicableEmailTempId": templateId,
                    // "app(licableTextTempId": postCkEditorText?.textTemplateId ? postCkEditorText?.textTemplateId : null,
                    "occurrenceId": postOccurrence?.occurrenceId ? postOccurrence?.occurrenceId : getOccurrenceSelectedValue?.key,
                    "isActive": true,
                    "upsertedBy": userId,
                    "commMediumRoles": arr
                } : {}

        konsole.log("objobjobjobj", obj)
        setLoader(true)

        const promises = Services.postUpsertCommMediumApi(obj)
        promises.then((response) => {
            konsole.log("responseresponse", response.data.data);
            setPostAllData(response.data.data)
            // setTemplateDataEdit("")
            setCkText("")
            getPreEmailTemplatesPathApiFunction(subtenantID)
            getPreTextTemplatesPathApiFunction(subtenantID)
            props.GetCommMediumPathApiFuction()

            handleClose()
            setLoader(false)
            // window.location.reload()

        })
            .catch((error) => {
                konsole.log("errorerrorerror", error);
            })
            .finally(() => {
                konsole.log("finish post api");
                setLoader(false)

                // konsole.log(props.GetCommMediumPathApiFuction(), "GetCommMediumPathApiFuction")

            });

    }


    konsole.log("callpostAllData", callpostAllData)




    // End api code

    const fromRoleNameFuction = (data) => {

        let arrData = [], newArr = [];

        arrData.push(data);

        konsole.log("formarrData", arrData)

        for (let i = 0; i <= arrData[0]?.length - 1; i++) {
            newArr.push(arrData[0]?.[i]?.fromRoleName)

        }

        for (let k = 1; k <= [...new Set(newArr)].length; k++) {
            setRoleFromLength(k);

        }
        return [...new Set(newArr)]

    }


    const ToRoleNameFuction = (data) => {
        // console.log("NewDaata", data)
        let arrData = [], newArr = [];

        arrData.push(data);
        // console.log("arrDataarrDataarrDataarrData", arrData[0]?.[3]?.fromRoleId)

        for (let i = 0; i <= arrData[0]?.length - 1; i++) {

            //   console.log("arrDataarrDataarrDataarrData", arrData[0]?.[i]?.toRoleName)
            newArr.push(arrData[0]?.[i]?.toRoleName)
        }
        // console.log("NewArrayArrayNewArray", newArr)

        for (let q = 1; q <= [...new Set(newArr)].length; q++) {

            setRoleToLength(q);

        }
        // konsole.log("toroletorole", [...new Set(newArr)])

        return [...new Set(newArr)]

    }
    // konsole.log("onfinsh", selectedChannelID, getOccurrenceSelectedValue?.key, postOccurrence)

    const onFinish = (e) => {
        // alert("finish")

        if ((selectedChannelID == 1 || selectedChannelID == 2 || selectedChannelID == 3) && (getOccurrenceSelectedValue?.key || postOccurrence)) {
            // alert("finish if")
            createNewFunction()

        }
        else {
            // alert("finish else")
        }

        konsole.log("eeeeeeeeeeeeesgdgh", e)
    }

    const getChangeDataOfEditor = (e) => {
        setTemplateDataEdit(e.target.value)
    }
    konsole.log("uuuuuuuaEdittemplateDataEdit", templateDataEdit)

    const selectFuction = (visible) => {
        setOpen(visible)
        if (visible && getOccurrenceSelectedValue?.key != undefined) {
            setShowSerchTrue(true)
        }
    }

    // const placeHolderFunction = (e) =>{
    //    let data =  document.getElementById("testAreaId").value
    //    let data1 =  document.getElementById("testAreaId").innerHTML
    //     konsole.log("sadasdasdasd",data1)
    //     setPlaceHolder(data1)
    // }
    // konsole.log("placeholder",placeHolder)


    let linkButton = document.getElementById("createLink");

    const modifyText = (command, defaultUi, value) => {
        //execCommand executes command on selected text
        konsole.log("point here", command, defaultUi, value);
        document.execCommand(command, defaultUi, value)
        // setCreateLinkState(true)


    };


    const linkCreateButtonFunction = () => {

        let userLink = prompt("Enter a URL");

        //if link has http then pass directly else add https
        if (/http/i?.test(userLink)) {

            modifyText(linkButton?.id, false, userLink);
            konsole.log("sdfsfasewe", linkButton?.id)
            // setCreateLinkState(true)

        } else {
            userLink = "http://" + userLink;
            // userLink = "http://" + userLink + " " + "target" + "=" + "_blank"
            modifyText(linkButton?.id, false, userLink);
            // setCreateLinkState(true)

            konsole.log("kkkkkkhghhghkkk", linkButton?.id)
        }

    }


    return (
        <div>
            <Modal
                centered={true}
                className="Modal-Main-Class"
                title={<div className='d-flex justify-content-center'>Add Content</div>}
                visible={props?.showAddModal}
                footer={false}
                onCancel={handleClose}
                closeIcon={<div className='text-light'> X</div>}
                maskClosable={false}
                size="xl"

            >
                <Form form={form} name="useForm" onFinish={onFinish} onFinishFailed={(data) => konsole.log("errorin on Finsin", data)}>
                    <Row >
                        <Col span={24}>

                            <Row>
                                <Col span={24} xs={24} sm={24} md={24} lg={24} >
                                    <h6 style={{ marginLeft: "1px" }}>Occurrence</h6>
                                    <Form.Item
                                        name="occurrence"

                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Occurrence"
                                            }
                                        ]}>

                                        <Select
                                            className='SeminarRegistrationSelect Select-input'
                                            placeholder="Select a Occurrence"
                                            open={open}
                                            onChange={getOccurrenceSelectedValueFunction}
                                            onDropdownVisibleChange={(visible) => selectFuction(visible)
                                                // (visible) => setOpen(visible)
                                            }
                                            listHeight={90}
                                            defaultOpen={true}
                                            // onValueChange={selectedChannelValue}
                                            // defaultValue={postOccurrence?.occurrenceDetails}
                                            showSearch={showSerchTrue}
                                        // onFocus={selectFuction}
                                        // dropdownRender={(menu) => (
                                        //     <>
                                        //         {menu}
                                        //         <Divider
                                        //             style={{
                                        //                 margin: '8px 0',
                                        //             }}
                                        //         />
                                        //         <Space
                                        //             style={{
                                        //                 padding: '0 8px 4px',
                                        //                 // marginBottom: "-10px"

                                        //             }}
                                        //         >
                                        //             <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        //                 <div>
                                        //                     <Input
                                        //                         placeholder="Please enter occurrence name"
                                        //                         ref={inputRef}
                                        //                         value={name}
                                        //                         onChange={onNameChange}
                                        //                         className="occurrence-input"
                                        //                         onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}

                                        //                     /></div>
                                        //                 <div className='ms-3'>
                                        //                     <Button type="text" style={{ backgroundColor: "#720c20", color: "white" }} onClick={addItem}>
                                        //                         + Add item
                                        //                     </Button></div>
                                        //             </div>
                                        //         </Space>
                                        //     </>
                                        // )}


                                        >
                                            {
                                                getOccurrenceSelect?.filter(data => data?.occurrenceDetails?.toLowerCase().includes(name.toLowerCase())).map((item) => (
                                                    <option value={item?.occurrenceDetails} key={item?.occurrenceId} disabled={item.disabled}
                                                    >{item?.occurrenceDetails}</option>

                                                ))
                                            }
                                        </Select>

                                    </Form.Item>

                                </Col>

                                {konsole.log("getOccurrenceSelect", OccurrencNameIsTrue)}
                            </Row>

                            <Row className=' d-flex justify-content-between '>
                                <Col className='CommunicationChannel-Col' span={8} xs={24} sm={24} md={8} lg={7}>
                                    <h6 style={{ marginLeft: "1px" }}>Communication Channel</h6>
                                    <Form.Item
                                        name="commChannel"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select channel"
                                            }
                                        ]}>
                                        <Select size='sm'
                                            className='commChannelSelect'
                                            listHeight={100}
                                            onChange={getCommonChannelValue}
                                            placeholder="Communication Channel"
                                            style={{ width: "100%" }}
                                        >
                                            {commonChannelSelect.map((data) => (

                                                <option value={data?.label} key={data?.value}>{data?.label}</option>

                                            ))}

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} xs={24} sm={24} md={8} lg={7} >
                                    <h6 style={{ marginLeft: "1px" }}>From</h6>
                                    <Form.Item
                                        name="roleFrom"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Role"
                                            }
                                        ]}
                                        className="asignRoleTo-col m-0 pb-2"

                                    >
                                        <Select
                                            showSearch
                                            listHeight={100}
                                            className='commChannelSelect'

                                            mode="multiple"
                                            maxTagCount={'responsive'}
                                            showArrow
                                            onChange={handleFromRoleChange}
                                            placeholder="Select a role"
                                            style={{ width: "100%" }}

                                        >
                                            {
                                                getUserRoleSelect.map((userdata) => (
                                                    <option value={userdata?.label} key={userdata?.value}>{userdata?.label}</option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>

                                </Col>
                                <Col span={8} xs={24} sm={24} md={8} lg={7} className="asignRoleTo-col m-0 p-0">
                                    <h6 style={{ marginLeft: "1px" }}>To</h6>
                                    <Form.Item
                                        name="asignRoleTo"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Asign Role"
                                            }
                                        ]}
                                        className="asignRoleTo-col m-0 pb-4"
                                    >


                                        <Select
                                            // defaultValue={() => ToRoleNameFuction(props?.selectedRowData?.commMediumRoles)}
                                            showSearch
                                            style={{ width: "100%" }}
                                            className='commChannelSelect'

                                            listHeight={100}
                                            maxTagCount={'responsive'}
                                            mode="multiple"
                                            showArrow
                                            onChange={handleToRoleChange}
                                            placeholder="Select a role">
                                            {
                                                getUserRoleSelect.map((userdata) => (
                                                    <option value={userdata?.label} key={userdata?.value}>{userdata?.label}</option>
                                                    // <option  value={userdata.label} key={userdata.value}>
                                                    //     <input type="checkbox"/><span className='mx-2'>{userdata.label}</span>
                                                    // </option>

                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>


                            {/* <hr className='bg-light mb-1' /> */}

                            <ModalBody className='Modal-Body-Add-Modal'>
                                {
                                    (uploadPreTempDropDown == true && selectedChannelID == 3)
                                        ?
                                        <div>
                                            <Row className='d-flex justify-content-between mb-1' style={{ marginRight: "5px" }}>
                                                <Col span={11} md={24}>
                                                    <h6>Text Templat</h6>
                                                    <Form.Item
                                                        name="preDefineTextTemplate"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select text template"
                                                            }
                                                        ]}>
                                                        <Select size='sm' className='w-100'
                                                            onChange={handleTextTemplateChange}
                                                            placeholder="Select pre define text template "
                                                            showSearch
                                                            listHeight={100}
                                                        >

                                                            {getSelectedTextTemplateData.map((item) => (
                                                                <option value={item?.textTemplateType} key={item?.textTemplateId}>{item?.textTemplateType}</option>

                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    {
                                                        (uploadPreTempDropDown == true && selectedChannelID == 3 && getChannelValue?.key == 3 && ckeditorID == 3)
                                                        &&
                                                        <div>
                                                            <Row >
                                                                <Col span={24}>
                                                                    <Row className='d-flex justify-content-between'>

                                                                        <Col className='' span={24} xs={24} sm={24} md={24} lg={24}>
                                                                            <h6>Text</h6>

                                                                            <div className='mt-2 w-100'>
                                                                                {/* <textarea
                                                                                    rows="4"
                                                                                    className='w-100'
                                                                                    // cols="110"
                                                                                    defaultValue={textMessage}
                                                                                    onChange={(e) => handleTextUpdate(e)}
                                                                                /> */}
                                                                                <AtTheRateTextArea id="textAreaAt1" className="w-100" rows="4" handleTextUpdate={handleTextUpdate} occuranceId={occuranceId} defaultText={textMessage} placeholder=""/>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col span={11} md={24} className="mt-3">
                                                    <h6>Email Template</h6>
                                                    <Form.Item
                                                        name="preDefineEmailTemplate"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select email template"
                                                            }
                                                        ]}>
                                                        <Select size='sm' className='w-100'
                                                            onChange={handleEmailTemplateChange}
                                                            listHeight={100}
                                                            placeholder="Select pre define email template "
                                                            showSearch

                                                        >
                                                            {getSelectedEmailTemplateData?.map((item) => (
                                                                <option value={item?.templateType} key={item?.templateId} >{item?.templateType}</option>

                                                            ))}
                                                        </Select>
                                                    </Form.Item>

                                                    {
                                                        (uploadPreTempDropDown == true && selectedChannelID == 3 && getChannelValue?.key == 3 && ckeditorIDEmail == 3)

                                                        &&
                                                        <>
                                                            {/* // <Col span={24} xs={24} sm={24} md={24} lg={24} className="mt-3"> */}
                                                            <h6>Email</h6>
                                                            <div className='mb-2'>
                                                                <Form.Item
                                                                    name="emailsubject"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please Enter email subject"
                                                                        }
                                                                    ]}>
                                                                    <Input placeholder="Enter Email Subject"
                                                                        onChange={getValueSubjectInput}
                                                                        onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}


                                                                    />
                                                                </Form.Item>
                                                            </div>


                                                            <div className='d-flex justify-content-center'>
                                                                <div placeholder='enter your data'

                                                                    style={{ border: "1px solid grey", width: "90%" }}
                                                                    id="testAreaId" rows={20} contenteditable="true" dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            (uploadPreTempDropDown == true) ? inblidEmailTemplate : templateDataEdit

                                                                    }}>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>

                                        :
                                        (uploadPreTempDropDown == true && selectedChannelID == 1)
                                            ?
                                            <>
                                                <Row className='d-flex justify-content-between '>
                                                    <Col span={11} >
                                                        <h6>Email Template</h6>
                                                        <Form.Item
                                                            name="preDefineEmailTemplate"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please select email template"
                                                                }
                                                            ]}>
                                                            <Select size='sm' className='w-100'
                                                                onChange={handleEmailTemplateChange}
                                                                listHeight={100}
                                                                placeholder="Select pre define email template "
                                                                showSearch
                                                            >
                                                                {getSelectedEmailTemplateData.length > 0 && getSelectedEmailTemplateData.map((data, index) => (
                                                                    <option value={data?.templateType} key={data?.templateId}>{data?.templateType}</option>

                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>

                                                </Row>
                                            </>

                                            :
                                            (uploadPreTempDropDown == true && selectedChannelID == 2)

                                                ?
                                                <>
                                                    <Row className='d-flex justify-content-between mt-4 '>
                                                        <Col span={11} >
                                                            <h6>Text Templat</h6>
                                                            <Form.Item
                                                                name="preDefineTextTemplate "
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Please select text template"
                                                                    }
                                                                ]}>
                                                                <Select size='sm' className='w-100'
                                                                    onChange={handleTextTemplateChange}
                                                                    listHeight={100}
                                                                    placeholder="Select pre define text template "
                                                                    showSearch
                                                                >
                                                                    {getSelectedTextTemplateData.map((item) => (
                                                                        <option value={item?.textTemplateType} key={item?.textTemplateId}>{item?.textTemplateType}</option>

                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </>

                                                :
                                                ""

                                }

                                {
                                    (getChannelValue?.key == 3 && CreateNewButtonId === true && uploadPreTempDropDown == false && comChanID == 3)
                                        // ||
                                        // (uploadPreTempDropDown == true && selectedChannelID == 3 && ckeditorID == 3) || getChannelValue == "Both"

                                        ?
                                        <div style={{ marginRight: "5px" }}>
                                            <Row >
                                                <Col span={24}>
                                                    <Row className='d-flex justify-content-between'>

                                                        <Col className='' span={24} xs={24} sm={24} md={24} lg={24}>
                                                            <h6>SMS-Text</h6>
                                                            <div className='mt-2 w-100'>
                                                                {/* <textarea
                                                                    rows="4"
                                                                    // cols="110"
                                                                    className="textarea_text w-100"
                                                                    onChange={(e) => handleTextUpdate(e)}
                                                                />  */}
                                                                <AtTheRateTextArea id="textAreaAt2" className="textarea_text w-100" rows="4" handleTextUpdate={handleTextUpdate} occuranceId={occuranceId} defaultText="" placeholder=""/>
                                                            </div>
                                                        </Col>

                                                        <Col span={24} className="d-flex justify-content-end mt-2 " style={{ position: "sticky", top: " 10px", zIndex: "1" }}>
                                                            <div className={createLinkState == true && getChannelValue?.key == 1 && inblidEmailTemplate.length == 0 ? 'show-link-with-pre-temp' : uploadPreTempDropDown == true && getChannelValue?.key == 1 && selectedChannelLinkID == 1 ? "show-link-with-pre-temp2" :
                                                                uploadPreTempDropDown == true && getChannelValue?.key == 3 && selectedChannelLinkID == 3 ? "show-link-with-pre-temp3" :
                                                                    uploadPreTempDropDown == true && getChannelValue?.key == 3 && inblidEmailTemplate.length > 0 ? "show-link-with-pre-temp4" :
                                                                        ''}>

                                                                {getChannelValue?.key == 1 && CreateNewButtonId == true || getChannelValue?.key == 3 && CreateNewButtonId == true
                                                                    || createLinkState == true && getChannelValue?.key == 1 || createLinkState == true && getChannelValue?.key == 3
                                                                    ?

                                                                    <div style={{ width: "41px", height: "35px", marginRight: "10px" }} className=" "
                                                                        onMouseLeave={() => { document.querySelector(".linkicons").style.transform = "scale(1)" }}
                                                                    >
                                                                        <button id="createLink" className="adv-option-button mt-1 "
                                                                            onClick={linkCreateButtonFunction}
                                                                            disabled={isDisabled}
                                                                            style={{ backgroundColor: "white", float: "right", cursor: "pointer", border: "none", }}>
                                                                            <img src="images/link5.jpg" className='img-fluid linkicons' style={{ opacity: ".7" }}

                                                                                onMouseEnter={() => { document.querySelector(".linkicons").style.transform = "scale(1.1)" }}
                                                                            />

                                                                        </button>
                                                                    </div>
                                                                    : ""}
                                                            </div>
                                                        </Col>

                                                        <Col span={24} xs={24} sm={24} md={24} lg={24} className="">
                                                            <h6>Email</h6>
                                                            <div className='mb-2'>
                                                                <Form.Item
                                                                    name="emailsubject"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please Enter email subject"
                                                                        }
                                                                    ]}>
                                                                    <Input placeholder="Enter Email Subject"
                                                                        onChange={getValueSubjectInput}
                                                                        onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='d-flex justify-content-center'>
                                                                <div placeholder='enter your data' style={{ border: "1px solid grey", width: "90%" }}
                                                                    // className='textarea' 
                                                                    id="testAreaId"
                                                                    // rows={20} 
                                                                    contenteditable="true" dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            (uploadPreTempDropDown == true) ? inblidEmailTemplate : templateDataEdit

                                                                    }}>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        :
                                        (getChannelValue?.key == 2 && CreateNewButtonId === true && uploadPreTempDropDown == false && comChanID == 2) ||
                                            (uploadPreTempDropDown == true && selectedChannelID == 2 && ckeditorID == 2) || getChannelValue == 2
                                            ? <>

                                                <Row >
                                                    <Col span={24}>
                                                        <Row className='d-flex justify-content-between mt-2'>
                                                            <Form.Item
                                                                name="addTextOnly"
                                                                className='w-100'
                                                            >
                                                                <Col span={24} xs={24} sm={24} md={24} lg={24} className='w-100' >
                                                                    <h6>SMS-Text</h6>
                                                                    {/* <textarea rows="4"
                                                                        // cols="110"
                                                                        defaultValue={textMessage}
                                                                        className="textarea_text w-100"
                                                                        onChange={(e) => handleTextUpdate(e)}
                                                                    /> */}
                                                                    <AtTheRateTextArea id="textAreaAt3" className="textarea_text w-100" rows="4" handleTextUpdate={handleTextUpdate} occuranceId={occuranceId} defaultText={textMessage} placeholder=""/>

                                                                </Col>
                                                            </Form.Item>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            (getChannelValue?.key == 1 && CreateNewButtonId === true && uploadPreTempDropDown == false) ||
                                                (uploadPreTempDropDown == true && selectedChannelID == 1 && ckeditorID == 1)
                                                ?

                                                <>
                                                    {konsole.log("uploadPreTempDropDown", uploadPreTempDropDown)}

                                                    <Row style={{ position: "sticky", top: " 10px", zIndex: "1" }}>
                                                        <Col span={24} className="d-flex justify-content-end  " >
                                                            <div className={createLinkState == true && getChannelValue?.key == 1 && inblidEmailTemplate.length == 0 ? 'show-link-with-pre-temp' : uploadPreTempDropDown == true && getChannelValue?.key == 1 && selectedChannelLinkID == 1 ? "show-link-with-pre-temp2" :
                                                                uploadPreTempDropDown == true && getChannelValue?.key == 3 && selectedChannelLinkID == 3 ? "show-link-with-pre-temp3" :
                                                                    uploadPreTempDropDown == true && getChannelValue?.key == 3 && inblidEmailTemplate.length > 0 ? "show-link-with-pre-temp4" :
                                                                        ''}>

                                                                {getChannelValue?.key == 1 && CreateNewButtonId == true || getChannelValue?.key == 3 && CreateNewButtonId == true
                                                                    || createLinkState == true && getChannelValue?.key == 1 || createLinkState == true && getChannelValue?.key == 3
                                                                    ?

                                                                    <div style={{ width: "40px", height: "35px", marginRight: "10px" }} className=" "
                                                                        onMouseLeave={() => { document.querySelector(".linkicons").style.transform = "scale(1)" }}
                                                                    >
                                                                        <button id="createLink" className="adv-option-button mt-1 "
                                                                            onClick={linkCreateButtonFunction}
                                                                            disabled={isDisabled}
                                                                            style={{ backgroundColor: "white", float: "right", cursor: "pointer", border: "none", }}>
                                                                            <img src="images/link5.jpg" className='img-fluid linkicons' style={{ opacity: ".7" }}
                                                                                onMouseEnter={() => { document.querySelector(".linkicons").style.transform = "scale(1.1)" }}


                                                                            />
                                                                        </button>
                                                                    </div>
                                                                    : ""}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="" style={{ marginRight: "5px" }}>
                                                        <Col span={24}>
                                                            <Row className='d-flex justify-content-between'>

                                                                <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                                                    <h6>Email Subject</h6>
                                                                    <div className='mb-2'>
                                                                        <Form.Item
                                                                            name="emailsubject"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please Enter email subject"
                                                                                }
                                                                            ]}>
                                                                            <Input placeholder="Enter Email Subject"
                                                                                // defaultValue={inblidEmailTemplate[0]?.emailSubject ? inblidEmailTemplate[0]?.emailSubject : inblidEmailTemplate[getSelectedEmailTemplateData]?.emailSubject}
                                                                                onChange={getValueSubjectInput}

                                                                                onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}
                                                                            // ref={inputFieldRef}

                                                                            />
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div className='d-flex justify-content-center'>

                                                                        <div placeholder='enter your data'
                                                                            style={{ border: "1px solid grey", width: "90%" }}
                                                                            id="testAreaId"
                                                                            contenteditable="true"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                    (uploadPreTempDropDown == true) ? inblidEmailTemplate : templateDataEdit
                                                                            }}>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </> : ""
                                }
                                {konsole.log("getOccurrenceSelectedValue", templateDataEdit)}

                                <Row className='m-0 pt-2 ' style={{ height: "54px" }}>
                                    <Col span={24} className="m-0 p-0 ">
                                        {

                                            <div className=" d-flex justify-content-center align-items-center m-0 p-0">
                                                <Form.Item>
                                                    <Button
                                                        className="Add-Edit-btton text-white m-0 p-0"
                                                        htmlType="submit"
                                                        type='primary'
                                                        onClick={() => {
                                                            {
                                                                firstselect1 && firstselect2 && firstselect3 && firstselect4 ? <>
                                                                    {
                                                                        setlastdata(true)
                                                                    }
                                                                </>
                                                                    :
                                                                    <>

                                                                        {
                                                                            setlastdata(false)
                                                                        }
                                                                    </>
                                                            }


                                                            // setuploadPreTempDropDown(true)

                                                        }}
                                                        onSubmitFail
                                                        style={{ backgroundColor: "#720c20" }} >

                                                        {
                                                            (CreateNewButtonId == true && getChannelValue?.key == 1) || (uploadPreTempDropDown == true) || getChannelValue == 1
                                                                || (CreateNewButtonId == true && getChannelValue?.key == 2) || (uploadPreTempDropDown == true) || getChannelValue == 2
                                                                || (CreateNewButtonId == true && getChannelValue?.key == 3) || (uploadPreTempDropDown == true) || getChannelValue == 3 ? "Save" : "Create New"
                                                        }

                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        }

                                    </Col>
                                </Row>
                                {
                                    uploadPreTempDropDown == false ?
                                        <div>

                                            {(lastdata) ? "" : <>
                                                <Row>
                                                    <Col span={24} className=' d-flex justify-content-center'>
                                                        <h6 style={{ color: "#720c20", fontWeight: "500" }}>or</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24} className=' d-flex justify-content-center'>

                                                        <h6 style={{ color: "#720c20", cursor: "pointer" }}

                                                            onClick={showContentfunction}>
                                                            Choose content from existing template
                                                        </h6>

                                                    </Col>
                                                </Row>
                                            </>
                                            }


                                        </div>
                                        : ""
                                }
                            </ModalBody>

                        </Col>
                    </Row>
                </Form>
                {konsole.log("inblidEmainblidEmaiemplate", inblidEmailTemplate.length)}
            </Modal>
        </div >
    )
}
