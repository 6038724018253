import axios from 'axios';
import config from '../config.json'
import konsole from "./konsole";
import AoUrl from './url';
import { BASE_URL ,stripePaymentConfirmationUrl} from './url';
import commonLib from '../control/commonLib';


// axios.defaults.timeout = 15000;
axios.defaults.baseURL = BASE_URL;
// axios.defaults.headers.post['Content-Type'] = 'application/json;text/html;charset=utf-8';

const mapApi = "https://maps.googleapis.com/maps/api/js";
const mapKey = 'AIzaSyBaUn22pwovCzOxH7Uthivbd8_ScMkaEAI';

const invoke = (url, method, body, cancel) => {

  if (cancel) {
    cancel();
  }

  try {
    konsole.log('URL: ' + url);
    konsole.log('method:' + method);
    konsole.log(((method == "POST") ? "body" : 'params') + JSON.stringify(body));
    let token = sessionStorage.getItem("AuthToken");


    konsole.log("token ", `Bearer ${token}`);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;




    return axios({
      cancelToken: new axios.CancelToken(function executor(c) {
        cancel = c;
      }),

      method: method,
      url: url,
      params: (method === "GET") ? body : null,
      data:
        method === "POST" || method === "PUT" || method === "DELETE"
          ? body
          : null,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      konsole.error('Request canceled', error.message);
    } else {
      konsole.error('Something went wrong: ', error.message)
    }
  }
};
const invokefileApi = (url, method, body, cancel) => {

  if (cancel) {
    cancel();
  }
  axios.defaults.headers.common['Content-Type'] = `multipart/form-data;boundary`
  try {
    konsole.log('URL: ' + url);
    konsole.log('method:' + method);
    konsole.log(((method == "POST") ? "body" : 'params') + JSON.stringify(body));

    return axios({
      cancelToken: new axios.CancelToken(function executor(c) {
        cancel = c;
      }),
      method: method,
      url: url,
      params: (method === "GET") ? body : null,
      data: (method === "POST") ? body : null
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      konsole.error('Request canceled', error.message);
    } else {
      konsole.error('Something went wrong: ', error.message)
    }
  }
};

// let isActive;
const Services = {
  // get users by email
  getUserByEmail: async (email) => {
    let cancel;
    let url = AoUrl.getUserListByEmailId;
    let body = {};
    body['emailId'] = email;
    return invoke(url, "GET", body, cancel);
  },

  putClientHandOff: async (userId) => {
    let cancel;
    let url = AoUrl.putClientHandOff + `?userId=${userId}&IsHandOff=false`;
    let body = {};
    return invoke(url, "PUT", body, cancel);
  },

  putUserName: async (currentUserName, newUserName, updatedBy, clientIPAddress) => {
    let cancel;
    let url = AoUrl.putUserName;
    let body = {};
    body["currentUserName"] = currentUserName;
    body["newUserName"] = newUserName;
    body["updatedBy"] = updatedBy;
    body["clientIPAddress"] = clientIPAddress;
    return invoke(url, "PUT", body, cancel);
  },

  updateContactDetails: async (userId, activityTypeId, contactTypeId, mobileNo, commTypeId, contactId, updatedBy) => {
    let cancel;
    let url = AoUrl.putAPIForContact + userId

    let body = {};
    let contact = {};
    let mobiles = {}

    body["userId"] = userId;
    body["activityTypeId"] = activityTypeId
    mobiles["contactTypeId"] = contactTypeId
    mobiles["mobileNo"] = mobileNo
    mobiles["commTypeId"] = commTypeId
    mobiles["contactId"] = contactId
    mobiles["updatedBy"] = updatedBy

    contact["mobiles"] = [mobiles]
    contact["emails"] = []
    body["contact"] = contact

    return invoke(url, 'PUT', body, cancel)
  },

  postAPIForContact: async (userId, activityTypeId, contactTypeId, mobileNo, commTypeId, createdBy) => {
    let cancel;
    let url = AoUrl.postAPIForContact + userId

    let body = {};
    let contact = {};
    let mobiles = {}

    body["userId"] = userId;
    body["activityTypeId"] = activityTypeId
    mobiles["contactTypeId"] = contactTypeId
    mobiles["mobileNo"] = mobileNo
    mobiles["commTypeId"] = commTypeId
    mobiles["createdBy"] = createdBy

    contact["mobiles"] = [mobiles]
    contact["emails"] = []
    body["contact"] = contact

    return invoke(url, 'POST', body, cancel);
  },

  getSubtenantDetail: async (subtenantId) => {
    let cancel;
    let url = AoUrl.getSubtenantDetailPath
    let body = {};
    body['subtenantId'] = subtenantId;

    return invoke(url, "POST", body, cancel);

  },

  postSpeakerUserResgister: async (subtenantId, roleId, firstName, lastName, emailAddress, userName, countryCode, mobileNumber, password, packageCode, activateUser, autoConfirmEmail, autoConfirmMobile) => {
    let cancel
    let url = AoUrl.postSpeakerResgisterUser;
    let body = {}
    let user = {}
    body['subtenantId'] = subtenantId
    user['roleId'] = roleId
    user['firstname'] = firstName
    user['lastName'] = lastName;
    user['userName'] = userName;
    user['countryCode'] = countryCode;
    user['emailAddress'] = emailAddress;
    user['mobileNumber'] = mobileNumber;
    user['password'] = password;
    user['packageCode'] = packageCode;
    user['activateUser'] = activateUser;
    user['autoConfirmEmail'] = autoConfirmEmail;
    user['autoConfirmMobile'] = autoConfirmMobile;

    body['user'] = user

    return invoke(url, "POST", body, cancel)

  },

  postfileuploadspeaker: async (File, UploadedBy, UserId, FileTypeId, FileCategoryId, fileStatusId) => {
    let cancel;
    let url = AoUrl.postfileuploadspeaker;
    let formdata = new FormData();
    formdata.append('file', File);
    formdata.append('uploadedBy', UploadedBy);
    formdata.append('userId', UserId);
    formdata.append('fileTypeId', FileTypeId)
    formdata.append('fileCategoryId', FileCategoryId);
    formdata.append('fileStatusId', fileStatusId)

    return invokefileApi(url, "POST", formdata, cancel)

  },

  postspeakerupsertuser: async (speakerUserId, userId, speakerProfile, videoLinkPath, speakerImageId, remarks, isActive, upsertedBy,speakersignId) => {
    let cancel
    let url = AoUrl.postspeakerupsertuser;
    let body = {}
    body['speakerUserId'] = speakerUserId
    body['userId'] = userId;
    body['speakerProfile'] = speakerProfile;
    body['videoLinkPath'] = videoLinkPath;
    body['speakerImageId'] = speakerImageId;
    body['remarks'] = remarks;
    body['isActive'] = isActive;
    body['upsertedBy'] = upsertedBy
    body['speakersignId'] = speakersignId



    return invoke(url, "POST", body, cancel)

  },

  subtenantType: async (isActive) => {
    let cancel;
    let url = AoUrl.seminarTypeUrl
    let body = {};
    body['isActive'] = isActive
    return invoke(url, "GET", body, cancel)
  },

  postLogUserout: async () => {
    const stateObj = commonLib.getObjFromStorage('stateObj');
    const userId = stateObj.userId;
    const appState = stateObj.appState;
    const loggenInId = stateObj.loggenInId;
    const roleId = stateObj.roleId;

    let cancel;
    let url = AoUrl.postLogUserout + `${userId}/${appState}/${loggenInId}/${roleId}`;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },





  // getSubtenantDetail: async (userId,userName,authId) => {
  //     let cancel;
  //     let url = AoUrl.getSubtenantDetailPath
  //     let body = {};
  //     body['subtenantId '] = subtenantId;
  //     body['userId'] = subteuserIdnantId;
  //     body['userName'] = userName;
  //     body['authId'] = authId;

  //     return invoke(url, "GET", body, cancel);
  // },
  postSubtenantDetail: async (json) => {
    let cancel;
    let url = AoUrl.postSubtenantDetailPath
    let body = json;

    return invoke(url, "POST", body, cancel);

  },
  putSubtenantDetail: async (json) => {
    let cancel;
    let url = AoUrl.putSubtenantDetailPath
    let body = json;

    return invoke(url, "PUT", body, cancel);

  },
  getSubtenantCategory: async () => {
    let cancel;
    let url = AoUrl.getSubtenantCategoryPath
    let body = {};
    // body['subtenantCategoryId'] = subtenantCategoryId;
    // body['createdBy'] = createdBy;
    // body['isActive'] = true;
    // sessionStorage.setItem("subtenant",subtenantName)
    return invoke(url, "GET", body, cancel);

  },

  getFeedbackPriority:async () => {
    let cancel;
    let url = AoUrl.getFeedbackPriority
    let body = {};
    return invoke(url, "GET", body, cancel);

  },

  getModuleType:async () => {
    let cancel;
    let url = AoUrl.getModuleType
    let body = {};
    return invoke(url, "GET", body, cancel);

  },

  getFeedbackStatus:async () => {
    let cancel;
    let url = AoUrl.getFeedbackStatus
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  
  getFeedbackType:async () => {
    let cancel;
    let url = AoUrl.getFeedbackType
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  




  SubtenantCategoryList: async () => {
    let cancel;
    let url = AoUrl.GetSubtenantCategoryListPath;
    let body = {};
    // body['subtenantCategoryId'] = subtenantCategoryId;
    // body['createdBy'] = createdBy;
    // body['isActive'] = isActive;
    // sessionStorage.setItem("subtenant",subtenantName)
    return invoke(url, "GET", body, cancel);

  },
  getSeminarVenueData: async () => {
    let cancel;
    let url = AoUrl.getSeminarDetailsVenueData;
    let body = {};
    return invoke(url, "GET", body, cancel)
  },
  postSubtenantDetails: async () => {
    let cancel;
    let url = AoUrl.postSubtenantDetails;
    // konsole.log("sub",subtenantId);
    // konsole.log("is",isActive);
    // konsole.log('createdBy',createdBy);
    let body = {};
    // body['subtenantId']=subtenantId;
    // body['isActive']=isActive;
    // body['createdBy']=createdBy;
    return invoke(url, "POST", body, cancel)

  },

  updateFeedback: async (body) => {
    let cancel;
    let url = AoUrl.updateFeedback;
    // let body = {body};
    return invoke(url, "PUT", body, cancel)

  },





  getCoOrdinatorUserList: async (subtenantId, userId, isActive, roleId) => {
    let cancel;
    let url = AoUrl.getCoOrdinatorUserList;
    let body = {};
    body['subtenantId'] = subtenantId;
    // body['userId']=userId;
    body['isActive'] = isActive;
    body['roleId'] = roleId;
    return invoke(url, "POST", body, cancel)
  },

  // ..................... Kartik Saxena ...................
  addressData: async (lattitude, longitude, addressLine1, addressLine2, addressLine3, zipcode, county, city, state, country, addressTypeId, createdBy) => {
    let cancel;
    let url = AoUrl.postAddressData;
    let body = {};
    body['lattitude'] = lattitude
    body['longitude'] = longitude
    body['addressLine1'] = addressLine1
    body['addressLine2'] = addressLine2
    body['addressLine3'] = addressLine3
    body['zipcode'] = zipcode
    body['county'] = county
    body['city'] = city
    body['state'] = state
    body['country'] = country
    body['addressTypeId'] = addressTypeId
    body['createdBy'] = createdBy
    return invoke(url, "POST", body, cancel)
  },
  addAddressByuserId: async (jsonAddress) => {
    let cancel;
    let url = AoUrl.postaddressbyuserid;
    let body = jsonAddress;
    return invoke(url, "POST", body, cancel)
  },



  getVenueData: async (subtenantId, venueId, venueText, addressId, isActive, remarks, upsertedBy) => {
    let cancel;
    let url = AoUrl.postVenueData;
    let body = {}
    body['subtenantId'] = subtenantId
    body['venueId'] = venueId
    body['venueText'] = venueText
    body['addressId'] = addressId
    body['isActive'] = isActive
    body['remarks'] = remarks
    body['upsertedBy'] = upsertedBy
    return invoke(url, "POST", body, cancel)
  },

  postSeminarPublisherData: async (subtenantId, seminarId, seminarTopic, seminarTypeId,
    seminarDate, seminarTime, noOfSeats, seminarVenue, addressId, webinarLink, mapsLink, videoLink, description,
    isActive, seminarServiceFeeId, remarks, seminarSpeakerId, speakerUserId,
    remark, coordinatorUserId, speakersDetails, isSeminarPaid,
    fareAmount, upsertedBy

  ) => {
    // //console.log("dataaaa",subtenantId, seminarId, seminarTopic, seminarTypeId,
    // seminarDate, seminarTime, noOfSeats, seminarVenue, addressId,webinarLink, mapsLink, videoLink, description,
    // isActive, seminarServiceFeeId, remarks, seminarSpeakerId, speakerUserId,
    // remark, coordinatorUserId,speakersDetails, isSeminarPaid,
    // fareAmount, upsertedBy)
    let cancel
    let url = AoUrl.postSeminarPublisherApi


    let body = {};
    let seminar = {}
    let speakers = {}
    let coordinators = {}
    let seminarFare = {}
    let last = {}
    body['subtenantId'] = subtenantId;
    seminar['seminarId'] = seminarId
    seminar['seminarTopic'] = seminarTopic
    seminar['seminarTypeId'] = seminarTypeId
    seminar['seminarDate'] = seminarDate
    seminar['seminarTime'] = seminarTime
    seminar['noOfSeats'] = noOfSeats
    seminar['seminarVenue'] = seminarVenue
    seminar['addressId'] = addressId
    seminar['webinarLink'] = webinarLink
    seminar['mapsLink'] = mapsLink
    seminar['videoLink'] = videoLink
    seminar['description'] = description
    seminar['isActive'] = isActive
    seminar['seminarServiceFeeId'] = seminarServiceFeeId
    seminar['remarks'] = remarks
    speakers['seminarSpeakerId'] = seminarSpeakerId
    speakers['speakerUserId'] = speakerUserId
    speakers['remark'] = remark
    // coordinators['seminarCoordinatorId'] = seminarCoordinatorId
    coordinators['coordinatorUserId'] = coordinatorUserId
    seminarFare['isSeminarPaid'] = isSeminarPaid

    konsole.log("seminarFareisSeminarPaid", isSeminarPaid)
    konsole.log("seminarFareisSeminarPaid", fareAmount)
    if (isSeminarPaid == 'true') {
      seminarFare['fareAmount'] = fareAmount
    }
    konsole.log("seminarFareisSeminarPaid", seminarFare)
    konsole.log("seminarFareisSeminarPaid", fareAmount)
    seminar['upsertedBy'] = upsertedBy

    body['seminar'] = seminar
    seminar['speakers'] = speakersDetails
    seminar['coordinators'] = coordinatorUserId
    seminar['seminarFare'] = seminarFare
    return invoke(url, "POST", body, cancel)
    // console.log("seminar11",body)


  },
  getSpeakerUserDetails: async (subtenantId, roleId) => {
    let cancel;
    let url = AoUrl.getSpeakerUserDetailsPath;
    let body = {};
    // body['subtenantCategoryId'] = subtenantCategoryId;
    body['subtenantId'] = subtenantId;
    body['roleId'] = roleId;
    // sessionStorage.setItem("subtenant",subtenantName)
    return invoke(url, "POST", body, cancel);

  },

  getCoOrdinatorAddDetails: async (subtenantId, roleId, firstName, lastName, contactTypeId, mobileNo, createdBy, emailId
  ) => {
    let cancel;
    let url = AoUrl.postCoordinatorAdd;
    let body = {}
    let user = {}
    let contacts = {}
    let mobiles = {}
    let emails = {}

    body['subtenantId'] = subtenantId
    user['roleId'] = roleId
    user['firstName'] = firstName
    // user['middleName'] = middleName
    user['lastName'] = lastName
    // user['dob'] = dob
    // user['genderId'] = genderId
    // user['citizenshipId'] = citizenshipId
    // user['createUserCredential'] = createUserCredential
    mobiles['contactTypeId'] = contactTypeId
    mobiles['mobileNo'] = mobileNo
    mobiles['createdBy'] = createdBy
    emails['contactTypeId'] = contactTypeId
    emails['emailId'] = emailId
    emails['createdBy'] = createdBy
    user['createdBy'] = createdBy

    body['user'] = user
    user['contacts'] = contacts
    contacts['mobiles'] = [mobiles]
    contacts['emails'] = [emails]
    return invoke(url, "POST", body, cancel)
  },

  getPhoneCode: async () => {
    let cancel;
    let url = AoUrl.getPhoneCodePath;
    let body = {}

    return invoke(url, "GET", body, cancel);


  },
  getAddressId: async (AddressId) => {
    let cancel;
    let url = AoUrl.getAddressIdPath + `/${AddressId}`
    let body = {}

    // body['AddressId'] = AddressId

    return invoke(url, "GET", body, cancel);


  },
  getContactCatgory: async () => {
    let cancel;
    let url = AoUrl.getContactTypeCat
    let body = {}
    return invoke(url, "GET", body, cancel);


  },

  postSeminarDetailsList: async (isActive, subtenantId) => {
    let cancel;
    let url = AoUrl.postSpeakerList;
    let body = {}

    body['isActive'] = isActive
    body['subtenantId'] = subtenantId

    return invoke(url, "POST", body, cancel);

  },

  postSendUserActivationLink: async (subtenantId, loginUserId, userId, memberName, emailAddress, signUpPlatform, createdBy, clientIPAddress) => {
    let cancel;
    let url = AoUrl.sendUserActivationLink;
    let body = {};

    body['subtenantId'] = subtenantId
    body['loginUserId'] = loginUserId
    body['userId'] = userId
    body['memberName'] = memberName
    body['emailAddress'] = emailAddress
    body['signUpPlatform'] = signUpPlatform
    body['createdBy'] = createdBy
    body['clientIPAddress'] = clientIPAddress


    return invoke(url, "POST", body, cancel);

  },

  getPartnerUserSpeakerDetailsList: async (userId, isActive) => {
    let cancel;
    let url = AoUrl.postSpeakerList;
    let body = {}

    body['userId'] = userId
    body['isActive'] = isActive

    return invoke(url, "POST", body, cancel);

  },
  getSeminarData: async (foradminrole, subtenantid) => {
    let cancel;
    let url = AoUrl.getseminar
    let body = {}
    // body['seminarType']=seminarType
    if (foradminrole == true) {
      body['subtenantId'] = subtenantid
    }

    return invoke(url, "POST", body, cancel)


  },
  getSeminarData1: async (foradminrole, subtenantid) => {
    let cancel;
    let url = AoUrl.getseminar
    let body = {}
    // body['seminarType']=seminarType
    // if(foradminrole==true){
    body['subtenantId'] = subtenantid
    // }

    return invoke(url, "POST", body, cancel)


  },
  upsertSeminarStaus: async (seminarId, isActive, updatedBy) => {
    let cancel;
    let url = AoUrl.upsertSeminar
    let body = {}
    body['seminarId'] = seminarId
    body['isActive'] = isActive
    body['updatedBy'] = "string"

    return invoke(url, "PUT", body, cancel)

  },

  initMapScript: () => {
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApi}?key=${mapKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  },
  getsubtenantdetails: async (createdBy, isActive) => {
    let cancel;
    let url = AoUrl.postSubtenantDetails;
    let body = {};
    // body['createdBy'] = createdBy;
    body["isActive"] = isActive;

    return invoke(url, "POST", body, cancel);
  },
  getUserList: async (subtenantId) => {
    let cancel;
    let url = AoUrl.getUserListpath;
    let body = {};
    // body['createdBy'] = createdBy;
    body["subtenantId"] = subtenantId;

    return invoke(url, "POST", body, cancel);
  },
  getgenders: async () => {
    let cancel;
    let url = AoUrl.getGenders;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },
  getCitizenshipType: async () => {
    let cancel;
    let url = AoUrl.getCitizenshipTypePath;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },
  getcountrycode: async () => {
    let cancel;
    let url = AoUrl.getcountrycodepath
    let body = {}
    return invoke(url, "GET", body, cancel)
  },
  getContactType: async () => {
    let cancel;
    let url = AoUrl.getContactTypePath
    let body = {}
    return invoke(url, "GET", body, cancel)
  },
  // ............................................................................................
  getLoggedInUser: async (userId, appState, loggedInUserId, roleId) => {
    let cancel;
    let url = AoUrl.getAthenticatePath + `${userId}/${appState}/${loggedInUserId}/${roleId}/`;
    let bookingSlot = {};
    return invoke(url, "GET", bookingSlot, cancel);
  },
  getSubtenantDetail: async (subtenantId, isActive) => {
    let cancel;
    let url = AoUrl.getSubtenantDetailPath
    let body = {};
    body['subtenantId'] = subtenantId;
    // body['subtenantLogoUrl'] = subtenantLogoUrl;
    body['isActive'] = isActive;

    return invoke(url, "POST", body, cancel);
  },
  // .......................................................................

  getsubtenantdetails: async (createdBy, isActive) => {
    let cancel;
    let url = AoUrl.postSubtenantDetails;
    let body = {};
    // body['createdBy'] = createdBy;
    body["isActive"] = isActive;

    return invoke(url, "POST", body, cancel);
  },
  getUserList: async (subtenantId) => {
    let cancel;
    let url = AoUrl.getUserListpath;
    let body = {};
    // body['createdBy'] = createdBy;
    body["subtenantId"] = subtenantId;
    // body['isActive']=true

    return invoke(url, "POST", body, cancel);
  },
  getSubtenantPlannerList: async (subtenantId, roleId) => {
    let cancel;
    let url = AoUrl.getSubtenantPlanner;
    let body = {};
    body["subtenantId"] = subtenantId;
    body["roleId"] = roleId;

    return invoke(url, "POST", body, cancel);
  },
  getgenders: async () => {
    let cancel;
    let url = AoUrl.getGenders;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },
  getCitizenshipType: async () => {
    let cancel;
    let url = AoUrl.getCitizenshipTypePath;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },
  getcountrycode: async () => {
    let cancel;
    let url = AoUrl.getcountrycodepath;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  getContactType: async () => {
    let cancel;
    let url = AoUrl.getContactTypePath;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },

  getUserListByRoleId: async (subtenantId, isActive, roleId) => {
    let cancel;
    let url = AoUrl.getUserListByRoleId;
    let body = {};

    body['subtenantId'] = subtenantId;
    // body['userId']=userId;
    body['isActive'] = isActive;
    body['roleId'] = roleId;
    konsole.log("getUserListByRoleId", JSON.stringify(body))
    return invoke(url, "POST", body, cancel)


  },

  getAllUserRoleIdDetails: async () => {
    let cancel;
    let url = AoUrl.getAllUserRoleIdAPi;
    let body = {}
    return invoke(url, "GET", body, cancel);
  },

  postAddUser: async (jsonObjData) => {
    let cancel;
    let url = AoUrl.postAddUserPath;
    let body = jsonObjData;

    return invoke(url, "POST", body, cancel);
  },
  postPlannerCalendardata: async (slotObj) => {
    let cancel;
    let url = AoUrl.postPlannerCalendar;
    let body = slotObj;

    return invoke(url, "POST", body, cancel);
  },
  postAcademyUserdata: async (useData) => {
    let cancel;
    let url = AoUrl.postAcademyUser;
    let body = useData;

    return invoke(url, "POST", body, cancel);
  },


  subtenantCreateUser: async (jsonObjData) => {
    let cancel;
    let url = AoUrl.postsubtenantcreateUser
    let body = jsonObjData;
    return invoke(url, "POST", body, cancel)
  },
  subtenantmemberbyuserid: async (jsondata) => {

    let cancel;
    let url = AoUrl.postsubtenantusermemberupdate;
    let body = jsondata;
    return invoke(url, "PUT", body, cancel)
  },
  postuploadUserDocumantapi: async (jsondata) => {

    let cancel;
    let url = AoUrl.postuploadUserDocumant;
    let body = jsondata;
    return invoke(url, "PUT", body, cancel)
  },
  postAnsycRoleApi: async (userId, jsondata) => {
    let cancel;
    let url = AoUrl.postAnsycRole + userId;
    let body = jsondata;
    return invoke(url, "POST", body, cancel)
  },

  postRoleIdData: async (userId,loginUserId,subtenantId,roleId,isActive,createdBy) => {
    let cancel;
    let url = AoUrl.postRoleIdAPI + userId;
    let body = {}

    body['loginUserId'] = loginUserId;
    body['subtenantId'] = subtenantId;
    body['roleId'] = roleId;
    body['isActive'] = isActive;
    body['createdBy'] = createdBy;
    return invoke(url, "POST", body, cancel)
  },


  getUserListByRoleId: async (subtenantId, isActive, roleId) => {
    let cancel;
    let url = AoUrl.getUserListByRoleId;
    let body = {};

    body['subtenantId'] = subtenantId;
    // body['userId']=userId;
    body['isActive'] = isActive;
    body['roleId'] = roleId;
    konsole.log("getUserListByRoleId", JSON.stringify(body))
    return invoke(url, "POST", body, cancel)


  },
  getUserListByRoleIdAcadmy: async (subtenantId, isActive,userType) => {
    let cancel;
    let url = AoUrl.getUserListByRoleId;
    let body = {};

    body['subtenantId'] = subtenantId;
    body['userType'] = userType;
    body['isActive'] = isActive;
    // body['roleId'] = roleId;
    konsole.log("getUserListByRoleId", JSON.stringify(body))
    return invoke(url, "POST", body, cancel)


  },
  getExpirydatetime: async (loginUserId) => {
    let cancel;
    let url =AoUrl.getExpirydatetime +`/${loginUserId}/18`;
    let body = {};
   return invoke(url, "GET", body, cancel);
  },
  putUpgradeValidity: async (userValidityId,loginUserid,userId,userRoleId,roleId,expriedDate,createdBy,isCash) => {
    let cancel;
    let url = AoUrl.upGradeValidity
    let body = {};
    body['userValidityId'] = userValidityId;
    body['loginUserid'] = loginUserid;
    body['userId'] = userId;
    body['userRoleId'] = userRoleId;
    body['roleId'] = roleId;
    body['expriedDate'] = expriedDate;
    body['createdBy'] = createdBy;
    body['isCash'] = isCash;
    return invoke(url, "PUT", body, cancel);
},  
  apiCallScripePaymentConfirmation: async ({ userEmail }) => {
  return new Promise((resolve, reject) => {
      let newformData = new FormData()
      newformData.append('user_email', userEmail);
      newformData.append('user_confirmation', 1);
      axios.post(stripePaymentConfirmationUrl, newformData, {
          headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
          resolve("done");
          konsole.log('Response:', response.data);
      })
          .catch(error => {
              resolve("err");
              konsole.error('Error:', error);
          });
  })

  },
  atTheRateGetOccurrenceDatalApi: async (occurrenceId, isActive) => {
    let cancel;
    let url = AoUrl.atTheRateGetOccurrenceDataPath;
    let body = {};
    body['occurrenceId'] = occurrenceId;
    body['isActive'] = isActive;

    return invoke(url, "GET", body, cancel);
  },
  getCommonChannelApi: async () => {
    let cancel;
    let url = AoUrl.getCommonChannelPath;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  getUSerRolesAdminApi: async () => {
    let cancel;
    let url = AoUrl.getUSerRolesAdminPath;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  getOccurrenceApi: async () => {
    let cancel;
    let url = AoUrl.getOccurrencePath;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },


  getEmailTemplatesPathApi: async (subtenantID, ID) => {
    let cancel;
    let url = AoUrl.getEmailTemplatesPath;
    let body = {};

    body['TemplateId'] = ID;
    body['subtenantId'] = subtenantID;
    konsole.log("emialtemlateJSON", JSON.stringify(body))

    return invoke(url, "GET", body, cancel);
  },

  getEmailTemplatesUrlPath: async (TemplateId, IsActive, SubtenantId) => {

    let cancel;
    let url = AoUrl.getEmailTemplatesUrl + `?TemplateId=${TemplateId}&IsActive=${IsActive}`

    return invoke(url, "GET", cancel);

  },

  getTextTemplatesPathApi: async (subtenantID, ID) => {
    let cancel;
    let url = AoUrl.getTextTemplatesPath;
    let body = {};
    body['subtenantId'] = subtenantID;
    body['TextTemplateId'] = ID;
    konsole.log("getUserListByRoleId", JSON.stringify(body))
    return invoke(url, "GET", body, cancel);
  },
  postUpsertOccurrenceApi: async (occurrenceId, occurrenceDetails, isActive, upsertBy) => {
    let cancel;
    let url = AoUrl.postUpsertOccurrencePath;
    let body = {};

    body['occurrenceId'] = occurrenceId;
    body['occurrenceDetails'] = occurrenceDetails;
    body['isActive'] = isActive;
    body['upsertBy'] = upsertBy;
    konsole.log("getUserListByRoleId", JSON.stringify(body))
    return invoke(url, "POST", body, cancel)


  },
  putUpdateEmailTemplatesPathApi: async (templateType,
    emailSubject, templateContent, isActive, templateId, updatedBy) => {
    let cancel;
    let url = AoUrl.putUpdateEmailTemplatesPath;
    let body = {};

    // "templateType": "string",
    // "emailSubject": "string",
    // "templateContent": "string",
    // "isActive": true,
    // "templateId": 0,
    // "updatedBy": "string",
    // "remarks": "string"

    body['templateType'] = templateType;
    body['emailSubject'] = emailSubject;
    body['templateContent'] = templateContent;
    body['isActive'] = isActive;
    body['templateId'] = templateId;
    body['updatedBy'] = updatedBy;
    body['remarks'] = ""
    konsole.log("getUserListByRoleId", JSON.stringify(body))
    return invoke(url, "PUT", body, cancel)


  },
  postEmailTemplatesPathApi: async (templateType,
    emailSubject, templateContent, isActive, createdBy) => {
    let cancel;
    let url = AoUrl.postEmailTemplatesPath;
    let body = {};

    body['templateType'] = templateType;
    body['emailSubject'] = emailSubject;
    body['templateContent'] = templateContent;
    body['isActive'] = isActive;
    body['createdBy'] = createdBy;
    konsole.log("getUserListByRoleId", JSON.stringify(body))
    return invoke(url, "POST", body, cancel)


  },
  postTextTemplatesPathApi: async (textTemplateId,
    textTemplateType, textTemplateContent, isActive, upsertedBy) => {
    let cancel;
    let url = AoUrl.postTextTemplatesPath;
    let body = {};

    body['textTemplateId'] = textTemplateId;
    body['textTemplateType'] = textTemplateType;

    body['textTemplateContent'] = textTemplateContent;
    body['isActive'] = isActive;
    body['upsertedBy'] = upsertedBy;

    konsole.log("getUserListByRoleId", JSON.stringify(body))
    return invoke(url, "POST", body, cancel)


  },

  postUpsertCommMediumApi: async (obj) => {
    let cancel;
    let url = AoUrl.postUpsertCommMediumPath;
    let body = obj;
    konsole.log("putapi", obj)
    konsole.log("getUserListByRoleId", JSON.stringify(body))
    return invoke(url, "POST", body, cancel)

  },
  GetCommMediumPathApi: async (subtenantId, OccurrenceID) => {
    let cancel;
    let url = AoUrl.GetCommMediumPath;
    let body = {};
    body['occurrenceId'] = OccurrenceID;
    body['subtenantId'] = subtenantId;

    // body['isActive']=status
    return invoke(url, "POST", body, cancel);
  },
  GetAllFeedbacks: async (status, subtenantid) => {
    let cancel;
    let url = AoUrl.getFeedback;
    let body = {};
    body['isActive'] = status;
    if (subtenantid != 3) {
      body['subtenantId'] = subtenantid
    }
    return invoke(url, "POST", body, cancel);
  },

  sendMailToClientOnclosingFeedbackStatus:async (body) => {
    let cancel;
    let url = AoUrl.sendMailToClientOnclosingFeedbackStatus;
    return invoke(url, "POST", body, cancel);
  },


  getAddressDetails: async (userId) => {
    let cancel;
    let url = AoUrl.getAddressAPI + userId;

    let body = {}

    // body['userId'] = userId

    return invoke(url, 'GET', body, cancel)

  },
  getContactDetails: async (userId) => {
    let cancel;
    let url = AoUrl.getContactAPi + userId;

    let body = {}

    // body['userId'] = userId

    return invoke(url, 'GET', body, cancel)

  },
  getMemberDetails: async (userId) => {
    let cancel;
    let url = AoUrl.getMemberAPI + userId;

    let body = {}

    // body['userId'] = userId

    return invoke(url, 'GET', body, cancel)

  },
  getUserRoleDetails: async (LoginUserId, SubtenantId) => {

    konsole.log("getUserRoleDetails", LoginUserId, SubtenantId)
    let cancel;
    let url = AoUrl.getUserRoleAPI + `?LoginUserId=${LoginUserId}&SubtenantId=${SubtenantId}`;

    let body = {}

    // body['userId'] = userId
    // konsole.log("getUserRoleDetails",url)

    return invoke(url, 'GET', body, cancel)

  },



  putMemberDetails: async (subtenantId, fName, mName, lName, dob, citizenshipId, genderId, userId, updatedBy,fileId, signatureId) => {
    let cancel;
    let url = AoUrl.putAPIForMember;
    let body = {};

    body["subtenantId"] = subtenantId;
    body["fName"] = fName;
    body["mName"] = mName;
    body["lName"] = lName;
    body["fileId"] = fileId;
    body["signatureId"] = signatureId;
    body["dob"] = dob;
    body["citizenshipId"] = citizenshipId;
    body["genderId"] = genderId;
    body["userId"] = userId;
    body["updatedBy"] = updatedBy;

    return invoke(url, 'PUT', body, cancel)

  },


  putContactDetails: async (userId, activityTypeId, contactTypeId, mobileNo, contactId, updatedBy) => {
    let cancel;
    let url = AoUrl.putAPIForContact + userId

    let body = {};
    let contact = {};
    let mobiles = {}

    body["userId"] = userId;
    body["activityTypeId"] = activityTypeId
    mobiles["contactTypeId"] = contactTypeId
    mobiles["mobileNo"] = mobileNo
    mobiles["contactId"] = contactId
    mobiles["updatedBy"] = updatedBy

    contact["mobiles"] = [mobiles]
    body["contact"] = contact

    return invoke(url, 'PUT', body, cancel)
  },

  putAddressDetails: async (addressLine1, zipcode, city, state, country, addressTypeId, addressId, updatedBy, isActive) => {
    let cancel;
    let url = AoUrl.putAPIForAddress;
    let body = {}

    body['addressLine1'] = addressLine1
    body['zipcode'] = zipcode
    // body['county'] = county
    body['city'] = city
    body['state'] = state
    body['country'] = country
    body['addressTypeId'] = addressTypeId
    body['addressId'] = addressId
    body['updatedBy'] = updatedBy
    body['isActive'] = isActive

    return invoke(url, 'PUT', body, cancel)

  },

  putRoleIdDetails: async (loginUserId, subtenantId, roleId, isActive, userRoleId, updatedBy, remarks) => {
    let cancel;
    let url = AoUrl.putRoleIdAPI + updatedBy;
    let body = {}

    body['loginUserId'] = loginUserId;
    body['subtenantId'] = subtenantId;
    body['roleId'] = roleId;
    body['isActive'] = isActive;
    body['userRoleId'] = userRoleId;
    body['updatedBy'] = updatedBy;
    body['remarks'] = remarks;

    // konsole.log("putRoleIdDetailsBody",body)
    // konsole.log("putRoleIdDetailsBody",JSON.stringify(body))

    return invoke(url, 'PUT', body, cancel)
  },

  postSendEmailComm: async (emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy) => {
    let cancel;
    let url = AoUrl.postSendEmailCommUrl
    let body = {}

    body['emailType'] = emailType
    body['emailTo'] = emailTo
    body['emailSubject'] = emailSubject
    body['emailTemplateId'] = emailTemplateId
    body['emailContent'] = emailContent
    body['emailStatusId'] = emailStatusId
    body['emailMappingTable'] = emailMappingTable
    body['emailMappingTablePKId'] = emailMappingTablePKId
    body['createdBy'] = createdBy


    return invoke(url, 'POST', body, cancel)
  },

  deletePartnerUserDetails: async (UserId, LoginUserId, DeletedBy) => {
    let cancel;
    let url = AoUrl.deletePartnerUserAPI + `/${UserId}/${LoginUserId}/${DeletedBy}`;
    let body = {}

    return invoke(url, 'DELETE', body, cancel)
  },
  indServices: async (isActive,subtenantId) => {
    let cancel;
    let url = AoUrl.getIndService;
    let body = {}
    body['isActive'] = isActive
    if(subtenantId){
      body['SubtenantId'] = subtenantId
    }
    return invoke(url, "GET", body, cancel);
  },
  getSubsModel : async () => {
    let cancel;
    let url = AoUrl.getSubsModelPath;
    let body = {}
    return invoke(url, "GET", body, cancel);
  },

  serviceGrp: async (obj) => {
    let cancel;
    let url = AoUrl.postGrpService;

    return invoke(url, "POST", obj, cancel)
  },

  isRateCardNameAvaiFunc: async (rateCardName) => {
    let cancel;
    let url = AoUrl.getAvailableRateCardName + `?rateCardName=${rateCardName}`
    let body = {}

    return invoke(url, "GET", body, cancel)
  },

  groupedService: async (isActive,subtenantId) => {
    let cancel;
    let url = AoUrl.getGrpService;
    let body = {}
    body['isActive'] = isActive
    if(subtenantId){
      body['SubtenantId'] = subtenantId
    }
    return invoke(url, "GET", body, cancel);
  },

  postIndService: async (service) => {
    let cancel;
    let url = AoUrl.postIndServicePath;

    return invoke(url, "POST", service, cancel)
  },
  saleAbleSkuPath: async (subscription) => {
    let cancel;
    let url = AoUrl.postSaleAbleSKUPath;

    return invoke(url, "POST", subscription, cancel)
  },

  getSkuList: async (skuListId, isActive,subtenantId,SkuListName) => {
    let cancel;
    let url = AoUrl.getSaleAbleSKUPath;
    let body = {}
    if(skuListId){
      body['skuListId'] = skuListId
    }
    if(subtenantId){
      body['SubtenantId'] = subtenantId
    }
    if(SkuListName){
      body['SkuListName'] = SkuListName
    }
    body['isActive'] = isActive
    return invoke(url, "GET", body, cancel);
  },

  //Add Rate Card
  postToAddRateCard: async (addRateCardJson) => {
    let cancel;
    let url = AoUrl.postToAddRateCardPath;

    return invoke(url, "POST", addRateCardJson, cancel)
  },
  postAppSubtForRateCard: async (addAppSubtForRateCardJson) => {
    let cancel;
    let url = AoUrl.postAppSubtForRateCardPath;

    return invoke(url, "POST", addAppSubtForRateCardJson, cancel)
  },
  getpostPlannerSlotDetails: async (data) => {
    let cancel;
    let url = AoUrl.getpostPlannerDetails;
    let body = data;
    return invoke(url, "POST", body, cancel)

  },
  upsertPlannerDetails: async (data) => {
    let cancel;
    let url = AoUrl.upsertPlannerDetails;
    let body = data;
    return invoke(url, "POST", body, cancel)
  },

  getRateCard: async (rateCardId, skuListId,subtenantId, isActive) => {
    let cancel;
    let url = AoUrl.getRateCardPath;
    let body = {}
    if(rateCardId){
      body['rateCardId'] = rateCardId
    }
    body['skuListId'] = skuListId
    body['subtenantId'] = subtenantId
    body['isActive'] = isActive
    return invoke(url, "GET", body, cancel);
  },

  // file cabinet 
  getFileCabinet: async () => {
    let cancel;
    let url = AoUrl.getFileCabinetType;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  postFileDocumentCabinet: async (postData) => {
    let cancel;
    let url = AoUrl.postFileDocumentCabinetById;
    let body = {};

    body["userId"] = postData.userId;
    body["fileCabinetId"] = postData.fileCabinetId;

    return invoke(url, "POST", body, cancel);
  },
  filecabinetPreparebyapi: async (userId, fileCabinetId) => {
    let cancel;
    let url = AoUrl.filecabinetPreparebyUrl + userId + " / " + fileCabinetId;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  filecabinetfilestatusapi: async (filestatus) => {
    let cancel;
    let url = AoUrl.filecabinetfilestatus + "?IsActive=" + filestatus;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  filecabinetdocumentapi: async (userId, FileCategoryId, PreparedByIndex, FileStatusId) => {
    let cancel;
    let url = AoUrl.filecabinetdocumenturl + "/" + userId + "?FileCategoryId=" + FileCategoryId + "&PreparedByIndex=" + PreparedByIndex + "&FileStatusId=" + FileStatusId;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },

  //  new file cabinet api --------------------------------------------------------
  getMainCabinetContainer: async ({ cabinetId, createdBy, isActive, subtenantId, roleId, belongsScreen }) => {
    let cancel;
    let url = AoUrl.getMainCabinet;
    let body = {};
    body["isActive"] = isActive;
    // if (roleId != 4 || belongsScreen == 'Firmpartner') {
      // body["cabinetId"] = cabinetId;
      body["subtenantId"] = subtenantId;
    // }
    body["roleId"] = roleId;
    // body["createdBy"] = createdBy;
    return invoke(url, "GET", body, cancel);
  },
  upsertMainCabinet: async (jsonObj) => {
    let cancel
    let url = AoUrl.upsertMainCabinet
    return invoke(url, 'POST', jsonObj, cancel)
  },
  upsertCabinetCategoryType: async (jsonObj) => {
    let cancel;
    let url = AoUrl.upsertCabinetCategoryType
    return invoke(url, 'POST', jsonObj, cancel)
  },
  getCabinetCategoryType: async (jsonObj) => {
    let cancel;
    let url = AoUrl.getFileCategoryTypeList
    return invoke(url, 'POST', jsonObj, cancel)
  },

  upsertCabinetFolder: async (jsonObj) => {
    let cancel;
    let url = AoUrl.upsertCabinetFolder
    return invoke(url, 'POST', jsonObj, cancel)
  },
  deleteFileCabinetFolder: async ({folderId,memberUserId,subtenantId,roleId,deletedBy,folderCategoryId}) => {
    let cancel;
    let body={}
    let url = AoUrl.deleteFileCabinetFolder+`${folderId}/${memberUserId}/${subtenantId}/${roleId}/${deletedBy}/${folderCategoryId}`
    konsole.log('urlurlurlurl',url)
    return invoke(url,"DELETE",body,cancel)
  },
  getAllSubtenantDetails: async (json) => {
    let cancel;
    let url = AoUrl.getSubtenantDetails
    return invoke(url, 'POST', json, cancel)
  },
  getUserListBySubtenantId: async (jsonObj) => {
    let cancel;
    let url = AoUrl.getUserList
    return invoke(url, 'POST', jsonObj, cancel)
  },
  getfileBelongsTo: async (clientId) => {
    let cancel;
    let url = AoUrl.fileBelongsTo + `?ClientUserId=${clientId}`
    let body = {}
    return invoke(url, 'GET', body, cancel)
  },
  getFileTypeByCategory: async (categoryId) => {
    let cancel;
    let url = AoUrl.getFileTypeByCategory + `?fileCategoryId=${categoryId}`
    let body = {}
    return invoke(url, "GET", body, cancel)
  },
  upsertFileTypeByCategory: async (jsonObj) => {
    let cancel;
    let url = AoUrl.upsertFileTypeByCategory
    return invoke(url, 'POST', jsonObj, cancel)
  },
  postUploadUserDocumant: async ({ UserId, FileStatusId, EmergencyDocument, DocumentLocation, FileTypeId, UserFileName, File, FileCategoryId, DateFinalized, Description, UploadedBy }) => {

    let cancel;
    let url = AoUrl.postUploadUserDocument;
    let formData = new FormData()
    formData.append('UserId', UserId)
    formData.append('FileStatusId', FileStatusId)
    formData.append('EmergencyDocument', EmergencyDocument)
    formData.append('DocumentLocation', DocumentLocation)
    formData.append('FileTypeId', FileTypeId)
    formData.append('UserFileName', UserFileName)
    formData.append('File', File)
    formData.append('FileCategoryId', FileCategoryId)
    formData.append('DateFinalized', DateFinalized)
    formData.append('Description', Description)
    formData.append('UploadedBy', UploadedBy)

    return invokefileApi(url, "POST", formData, cancel)

  },
  addUserFileCabinet: async (json) => {
    let cancel;
    let url = AoUrl.addUserFileCabinet
    let body = json
    return invoke(url, 'POST', body, cancel)
  },

  getFileFromCabinet: async (primaryUserId) => {
    let cancel;
    let url = AoUrl.getUserFileCabinetFile + primaryUserId
    let body = {}
    return invoke(url, 'GET', body, cancel)
  },
  getFileForView: async ({ primaryUserId, fileId, fileTypeId, fileCategoryId, requestedBy }) => {
    let cancel;
    let url = AoUrl.getFileForView + `${primaryUserId}/${fileId}/${fileTypeId}/${fileCategoryId}/${requestedBy}`
    let body = {}
    return invoke(url, 'GET', body, cancel)
  },
  deleteFileCabinetByFileId: async (json) => {
    let cancel;
    let url = AoUrl.deleteFileCabinetFileByFileId
    let body = json
    return invoke(url, 'DELETE', body, cancel)
  },
  getUserDetailsByUserEmailId:async({userId})=>{
    let cancel;
    let url=AoUrl.getUserDetailsByUserEmailId +`?UserId=${userId}`
    return invoke(url,'GET','',cancel)
  }


};

const loadAsyncScript = async (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src
    })
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  })
}


export default Services;