import { Row, Col, Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";

const DeleteConfirmModal = (props) => {


  const onCancel = () => {
    props.deleteStatus(false)
   props.hideModal(false)

  };

  const ConfirmDelete = () =>{
    props.deleteStatus(true)

  }

  return (
    <>
      <Modal
        visible={props?.visible}
        centered
        onCancel={onCancel}
        closable={false}
        footer={false}
        maskClosable={false}
      >
        <div
          style={{
            border: "1px solid #720c20 !important",
            borderRadius: "10px 10px 0px 0px",
            width: "auto",
            margin: "-14px",
            backgroundColor: "#720c20",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
          }}
          
        >
          <div className=""></div>
          <div className="fs-5">{props?.titleText}</div>

          <div className="me-2 mt-1" style={{cursor: "pointer"}}  onClick={onCancel}>X</div>
        </div>

        <br />
        <br />

        <p className="text-center fs-4" style={{ color: "#720c20" }}>
          {props?.bodyContent}
        </p>

        <Row className="justify-content-end">
          
          <Col className="me-3">
            <Button
              style={{
                backgroundColor: "white",
                color: "#720C20",
                border: "1px solid #720c20",
              }}
              onClick={onCancel}
            >
              No
            </Button>
          </Col>
          <Col className="">
            <Button
              style={{
                backgroundColor: "#720c20",
                color: "white",
                border: "none",
              }}

              onClick={()=>ConfirmDelete()}
            >
              Yes
            </Button>
          </Col>
        </Row>
      </Modal>


    </>
  );
};

export default DeleteConfirmModal;
