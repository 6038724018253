import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import "./Firmpartner.css";
import ScheduleUsers from "../SubtenantUsers/SubtenantUsers";
import SubtenantTable from "../SubtenantUsers/SubtenantTable";
import SearchByEmail from "../KnowYourUser/KnowYourUser";
import Subtents from "./Subtents";
import SchedulePlanner from "./SchedulePlanner";
import SchedulePlanner2 from "./SchedulePlanner2";
import konsole from "../../network/konsole";
import ParentCabinet from "../newFileCabinet/ParentCabinet";

const Menubtns = (props) => {
  const [change, setChange] = useState(false);
  const [addbeneficiriesshow, setaddbeneficiries] = useState(false);
  const [addbenefiiriespropstype, setaddbeneficiriestype] = useState("");
  const [changeBorder, setChangeBorder] = useState(1);
  const [number, setNumber] = useState(null);
  const [roleid, setroleid] = useState('')

  //   const managebeneficiries = () => {
  //     setaddbeneficiries(true)
  //     konsole.log("1")
  //     setChange(false)
  //   }

  useEffect(() => {
    let userroleId = JSON.parse(sessionStorage.getItem("stateObj"))
    setroleid(userroleId?.roleId)
    if (userroleId?.roleId == "11") {
      setNumber(2)
    } else {
      setNumber(1)
    }
    konsole.log("roleidid", userroleId?.roleId)
  }, [])
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    let userroleId = JSON.parse(sessionStorage.getItem("stateObj"))
    const viewValue = searchParams.get('view')
    // console.log('viewValueviewValueab', viewValue)
    let viewValueNum = (viewValue == null && userroleId?.roleId != 11) ? 1 : (viewValue == null && userroleId?.roleId == 11) ? 2 : viewValue
    setNumber(viewValueNum)
    if(typeof props?.setsubscriptionbtn === 'function'){
      const viewValueOfPage = (viewValue == null && userroleId?.roleId == 11) ? 1 : viewValueNum;
      props?.setsubscriptionbtn(viewValueOfPage)
    }
  }, [])

  //   const AddBeneficiries = (addbeneficiries) => {
  //     setaddbeneficiriestype(addbeneficiries)
  //     setaddbeneficiries(false)
  // konsole.log("2")
  //     setChange(false)

  //   }

  //   const SchedulePlanner = (addbeneficiries)=>{
  //     setaddbeneficiriestype(addbeneficiries)
  //     setaddbeneficiries(true)
  // konsole.log("3")
  //     setChange(false)
  //   }

  const managebeneficiries = (num) => {
    setNumber(num);
    sessionStorage.setItem("partAdminSubtenant", '');
    const newURL = window.location.origin + window.location.pathname + `?view=${num}`
    window.history.pushState({ path: newURL }, '', newURL);


    // switch (number) {
    //   case 1:
    //     break;
    //   case 2:
    //     break;
    //   default:
    // }
  };

  const manageSubscriptionPages = (num) =>{
    props?.setsubscriptionbtn(num);
    const newURL = window.location.origin + window.location.pathname + `?view=${num}`
    window.history.pushState({ path: newURL }, '', newURL);
  }
 
  return (
    <div className="row d-flex justify-content-between subsmainDiv" style={{borderBottom:"2.5px solid #F2F2F2", margin:" 0px 13px"}}>

      {(props?.screenType == 'subscriptionbtns') ? <div className="col-sm-9 subs2Div ">
        <div  onClick={() => manageSubscriptionPages(1)}>
        {(props?.subscriptionbtn == 1) ? <img src= "/images/package2Img.svg"/> : <img src= "/images/packageImg.svg"/>}
        <button
          className={`${(props?.subscriptionbtn == 1) ? "schedule-active" : ""} me-2 schedule`}
         
        >
          Product Management
        </button>
        </div>
        {roleid == 4 && <div className="d-flex">
        <div  onClick={() => manageSubscriptionPages(2)}>
          {(props?.subscriptionbtn == 2) ? <img src= "/images/rateCardimage.svg"/> : <img src= "/images/rateCard2Img.svg"/>}
        <button
          className={`${(props?.subscriptionbtn == 2) ? "schedule-active" : ""} me-2 schedule  fw-bold  border-gray`}
         
        >
          Pricing
        </button>
        </div>
        {/* <div className="ms-4">
        <button
          className={`${(props?.subscriptionbtn == 3) ? "schedule-active" : ""} me-2 schedule  fw-bold  border-gray`}
          onClick={() => props?.setsubscriptionbtn(3)}
        >
          Discount
        </button>
        </div> */}
          </div>}
        {/* <button
            className={`${(props?.subscriptionbtn == 4) ? "schedule-active" : ""} me-2 schedule  fw-bold  border-gray`}
            onClick={()=>props?.setsubscriptionbtn(4)}
          >
            Billing
          </button>
          <button
            className={`${(props?.subscriptionbtn == 5) ? "schedule-active" : ""} me-2 schedule  fw-bold  border-gray`}
            onClick={()=>props?.setsubscriptionbtn(5)}
          >
            Mis Report
          </button> */}
      </div>
        : <>
          <div className="col-12 d-flex " style={{ paddingTop: "8px" ,borderBottom:"2.5px solid rgb(242, 242, 242)"}} >

            {(roleid !== "11") ?

              <button
                className={
                  number == 1
                    ? "schedule-active me-2 schedule  fw-bold  border-gray"
                    : "me-2 schedule  fw-bold  border-gray"
                }
                onClick={() => managebeneficiries(1)}
              >
                {/* Subtenants */}
                {/* Partners */}
                Firms
              </button> : null
            }

            <button
              className={
                number == 2
                  ? "schedule-active me-2 schedule  fw-bold  border-gray"
                  : "me-2 schedule  fw-bold  border-gray"
              }
              onClick={() => managebeneficiries(2)}
            >
             
              {(roleid == 4)? 'Firm Users':'Staff Members'}
            </button>
            {/*<button className=' schedule  fw-bold  border-gray'>Categories</button>*/}
            <button
              className={
                number == 3
                  ? "schedule-active me-2 schedule  fw-bold  border-gray"
                  : "me-2 schedule  fw-bold  border-gray"
              }
              onClick={() => managebeneficiries(3)}
            >
              Schedule Planner
            </button>
            {roleid == 4 && <button
              className={
                number == 6
                  ? "schedule-active me-2 schedule  fw-bold  border-gray"
                  : "me-2 schedule  fw-bold  border-gray"
              }
              onClick={() => managebeneficiries(6)}
            >
              Know Your User
            </button>}
            {roleid == 4 && <button
              className={
                number == 7 ? "schedule-active me-2 schedule  fw-bold  border-gray" : "me-2 schedule  fw-bold  border-gray"}
              onClick={() => managebeneficiries(7)}
            >
              File Cabinet
            </button>}
          </div>
          <div style={{ paddingRight: "0px", }}>
            {number == 1 ? (
              <Subtents />
            ) : number == 2 ? (
              <SubtenantTable />
            ) : number == 6 ? (
              roleid == 4 && <SearchByEmail />
            ) : (number == 7) ? <ParentCabinet belongsScreen='Firmpartner' /> : (
              // <SchedulePlanner setNumber= {setNumber}/>
              <SchedulePlanner2 setNumber={setNumber} />
            )}
          </div>
        </>}
    </div>
  );
};

export default Menubtns;
