import React from 'react'
import { Row, Col, Button } from "react-bootstrap"
import '../../components/FileCabinet/Filecabinet.css'

const AllFoldersAndFiles = () => {
    return (
        <div>
            <Row className='mt-4'>
                <Col lg="12">
                    <Row>
                        <Col lg="6">
                            <div className='d-flex h-100'>
                                <div style={{ width: "40px" }} className='d-flex justify-content-center align-items-center h-100'>
                                    <img src="./Group.png" className='w-100' />
                                </div>
                                <div className='d-flex justify-content-center align-items-center h-100 px-1'>
                                    <h6 className='m-0 p-0'>Folder 1</h6>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" className="d-flex justify-content-end align-items-center">
                            <div className='d-flex'>
                                <div className="d-flex justify-content-center align-items-center">
                                    <h6 className='mx-2 m-0 p-0' >2  File</h6>  |
                                </div>
                                <div className=''>
                                    <Button className='add-file-button'>Add New File</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="bg-light" style={{ height: "34px" }}>
                        <Col lg="3" className=''>
                            <div className="d-flex justify-content-start align-items-center h-100">
                                <h6 className="m-0 p-0  filetool-text-tag-h6">File Name</h6>
                            </div>
                        </Col>
                        <Col lg="2" className=''>
                            <div className='d-flex justify-content-start align-items-center h-100'>
                                <h6 className="m-0 p-0 filetool-text-tag-h6">Updated</h6>
                            </div>
                        </Col>
                        <Col lg="7" className=''>
                            <div className="d-flex justify-content-Start align-items-center h-100">
                                <h6 className='m-0 p-0 filetool-text-tag-h6'>Description</h6>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            
                            <Row className='mt-1'>
                                <Col lg="3">
                                    <div className='d-flex h-100'>
                                        <div style={{ width: "30px" }} className='d-flex justify-content-center align-items-center h-100'>
                                            <img src="./textfile.png" className='w-100' />
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center h-100 px-1'>
                                            <h6 className='m-0 p-0'>File 1</h6>
                                        </div>
                                    </div>

                                </Col>
                                <Col lg="2">
                                    <div className="d-flex justify-content-start align-items-center h-100">
                                        <h6 className='m-0 p-0 folder-text-class'>6/30/2023</h6>
                                    </div>
                                </Col>
                                <Col lg="7">
                                    <div className="d-flex justify-content-start align-items-center h-100">
                                        <h6 className='m-0 p-0 folder-text-class'>File Description</h6>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col lg="3">
                                    <div className='d-flex h-100'>
                                        <div style={{ width: "30px" }} className='d-flex justify-content-center align-items-center h-100'>
                                            <img src="./textfile.png" className='w-100' />
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center h-100 px-1'>
                                            <h6 className='m-0 p-0'>File 1</h6>
                                        </div>
                                    </div>

                                </Col>
                                <Col lg="2">
                                    <div className="d-flex justify-content-start align-items-center h-100">
                                        <h6 className='m-0 p-0 folder-text-class'>6/30/2023</h6>
                                    </div>
                                </Col>
                                <Col lg="7">
                                    <div className="d-flex justify-content-start align-items-center h-100">
                                        <h6 className='m-0 p-0 folder-text-class'>File Description</h6>
                                    </div>
                                </Col>
                            </Row>
                           
                            <hr />
                        </Col>
                    </Row>
                </Col>
            </Row>
           
        </div>
    )
}

export default AllFoldersAndFiles
