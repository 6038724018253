import { Drawer, Layout } from 'antd'
import React, { useState } from 'react'
import Navbar from './Navbar';
import Sidemenu from './Sidemenu';
import "../Styles/Container.css"
import commonLib from '../control/commonLib';


const { Sider, Content } = Layout;

function Container({ children }) {
    const stateObj = JSON.parse(commonLib.getSessionStoarge("stateObj"))
    const roleId = stateObj?.roleId
    console.log("vv",roleId)

    return (
        <Layout>
            <Navbar className="">
                 
            <Sidemenu />
                 
            </Navbar>
            <Layout>
           {(roleId !== "6")?
            <Sider breakpoint='lg'
                    className='border-0 border-right'
                    theme='light'
                    width={300}
                    collapsedWidth={0}
                    trigger={null}
                ><Sidemenu />
                </Sider>:""}
           
                <Content className='rigth-container bg-white' >
                    <div className="site-layout-background" style={{ paddingRight:"0px", paddingLeft:"5px", minHeight: 600, }}>
                        {children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default Container