import React,{useState,useEffect,useContext} from "react";
import commonLib from "../../control/commonLib";
import Container from "../Container";
import config from '../../config.json'
import Services from "../../network/Services";
import { UserContext } from '../../App';
import { AOLOGINBASEURL } from "../../network/url";


const PrivateRoute = ({children}) =>{
    const AuthToken = commonLib.getSessionStoarge("AuthToken")
    // const AuthToken = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Im1qemJmSVlEUm1ya2tCc1ZIZmlIZSJ9.eyJpc3MiOiJodHRwczovL2FvZGV2LnVzLmF1dGgwLmNvbS8iLCJzdWIiOiJpeHkxa1NYaVV3bzJlUE1la2laaFZlOW50RWdWbGh2N0BjbGllbnRzIiwiYXVkIjoiaHR0cHM6Ly9hb2Rldi51cy5hdXRoMC5jb20vYXBpL3YyLyIsImlhdCI6MTY1OTY3NzA3MiwiZXhwIjoxNjU5NzYzNDcyLCJhenAiOiJpeHkxa1NYaVV3bzJlUE1la2laaFZlOW50RWdWbGh2NyIsInNjb3BlIjoicmVhZDpjbGllbnRfZ3JhbnRzIHVwZGF0ZTpjbGllbnRfZ3JhbnRzIHJlYWQ6dXNlcnMgdXBkYXRlOnVzZXJzIHJlYWQ6dXNlcnNfYXBwX21ldGFkYXRhIHVwZGF0ZTp1c2Vyc19hcHBfbWV0YWRhdGEgY3JlYXRlOmNsaWVudHMgcmVhZDpjb25uZWN0aW9ucyByZWFkOnJ1bGVzIHJlYWQ6cnVsZXNfY29uZmlncyByZWFkOmhvb2tzIHJlYWQ6YWN0aW9ucyByZWFkOmVtYWlsX3Byb3ZpZGVyIHJlYWQ6c3RhdHMgcmVhZDpsb2dzIHJlYWQ6bG9nc191c2VycyByZWFkOmdyYW50cyByZWFkOnVzZXJfaWRwX3Rva2VucyByZWFkOmN1c3RvbV9kb21haW5zIHJlYWQ6ZW1haWxfdGVtcGxhdGVzIiwiZ3R5IjoiY2xpZW50LWNyZWRlbnRpYWxzIn0.TPYwuSBy6ROqsR8dWwMAKZt736IoY7ePTIXJLIsu_tGyS4a9oPvUBheTjl_ARtG75EAJZeXCfi4TtEqi-raP94vYJ0vYkSc436JZJQMgiehBo_8-6-6ii9EwrMTBqdTprUhIHK49NUk4r4KN8qlfkzHVGQsmO6YrDVMs9O6CZWOnCNH4WVVQFES8jTblU5TKiCyUZCMe8AiSVPTI2f0m8awTW83nGCqev-by0vg54gJdt_YY9SZHJDesd0RVQa7PZ5CffWn4pngZ0m2q47qFTO8xMULZCRpW6xTJ3rkwuZX2I-HwPz6qSOg15h-nlN9Hp1e9J8W_Th2QmXeTE4Lihw"
    const authed = (AuthToken !== '' && AuthToken !== null)? true : false;
    const { setLoader } = useContext(UserContext)
    const [ validUser, setValidUser ] = useState(false);


    // useEffect(() => {
    //     let isMounted = true;
    //     if (isMounted) {
    //         const stateObj = commonLib.getObjFromStorage("stateObj");
    //         const promises = Services.getLoggedInUser(stateObj?.userId, stateObj?.appState, stateObj?.loggenInId, stateObj?.roleId)
    //         // setLoader(true);
    //         promises.then(response => {
    //             if (response) {
    //                 console.log("responsresponse",response.data.data)
    //                 // setLoader(false);
    //                 setValidUser(true);
    //             }
    //         }).catch((error)=> {
    //             console.log("responsresponse",error)
    //             setLoader(false);
    //             commonLib.unAuthurizeAccess();
    //         })
    //         .finally(()=>{
    //             setLoader(false);
    //         })
    //     }
    //     return () => {
    //         isMounted = false;
    //     }
    // }, [])



    return(
        <>
       { authed === true ? 
        <Container>
            {children}
        </Container>
     : 
     window.location.replace(`${AOLOGINBASEURL}/Account/Signin`)
       }
        
        </>
    )
}

export default PrivateRoute;