import React, { useContext, useEffect, useState } from "react";
import Services from "../../network/Services";

import "antd/dist/antd.css";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Select,
  Modal,
  notification,
} from "antd";
import config from "../../config.json";
import { UploadOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import konsole from "../../network/konsole.js";
import { UserContext } from "../../App.js";
import commonLib from "../../control/commonLib.js";
import InputMask from "../PhoneInputMask.js";


const { Option } = Select;
const { TextArea } = Input;

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select style={{ width: 70 }}>
      <Option value="86">+86</Option>
      <Option value="87">+87</Option>
    </Select>
  </Form.Item>
);

const AddEditClsSpeaker = (prop) => {
  const [form] = Form.useForm();

    // const { getApiDataValue} = useContext(GlobalSpeaker)
  const firstNames = prop.firstName;

  const { setLoader } = useContext(UserContext);
  let splitSpeakerName;
  // let getFriNameAll;
  //   useEffect(()=>{
  //    getFriNameAll = form.getFieldValue('firstName')
  //   },[getFriNameAll])
  //   konsole.log("getFriNameAll",getFriNameAll)

  const [speakerUserId, setSpeakerUserId] = useState("");
  konsole.log("datauserId", speakerUserId);
  const [sendMobNo, setSendMobNo] = useState("");

  const [AddSpeakerForm, setAddSpeakerForm] = useState(true);
  const [AddSpeakerFullName, setAddSpeakerFullName] = useState("");
  // speakerFullNameStatus(AddSpeakerFullName)
  konsole.log("AddSpeakerFullName", AddSpeakerFullName);

  const get1StName = (e) => {
    konsole.log("get1StName", e.target.value || splitSpeakerName);
  };


  const ModalPhoneNoValidation = (e) => {
    if (!commonLib.isNumberRegex(e.target.value)) {
      form.setFieldsValue({
        PhoneNumberAddEditSpeaker: "",
      });
    } else {
      setMobileNumber(e.target.value);
    }
  };

  const openNotification = (placement, Status) => {
    if (Status == 500) {
      notification.error({
        message: `Error ${Status} `,
        description: "Server Issue! Please try after sometime.",
        placement,
      });
    } else if (Status == 200) {
      notification.success({
        message: `Speaker added successfully`,

        placement,
      });
    }
  };

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");

  let fullName = firstNames + " " + lastname;

  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState();
  const [password, setPassword] = useState("Password@123");
  const [packageCode, setPackageCode] = useState(null);

  const [file, setFile] = useState();
  const [createdByuserId, setCreatedByuserId] = useState("");
  const [fileTypeId, setFileTypeId] = useState(15);
  const [fileCategoryId, setFileCategoryId] = useState(1);
  const [fileStatusId, setFileStatusId] = useState(2);

  const [speakeruserId, setspeakeruserId] = useState(0);
  const [speakerProfile, setSpeakerProfile] = useState("");
  const [videoLinkPath, setvideoLinkPath] = useState("");
  const [upsertedBy, setupsertedBy] = useState("");
  const [createdBySessionStorage, setCreatedBySessionStorage] = useState("");
  const [getValueApi, setGetValueApi] = useState({
    videoPath: "",
    userIdSpeaker: "",
    profileSpeaker: "",
    profileUrlSpeaker: "",
    fullNameSpeaker: "",
    
  });
  konsole.log("getValueApi", getValueApi);

    // getApiDataValue(getValueApi);

  konsole.log("speakerProfile", speakerProfile);

  // sendProfileData(speakerProfile)

  const [contactCodes, setContactCodes] = useState([]);

  useEffect(() => {
    
    const promise = Services.getPhoneCode();
    promise
      .then((res) => {
        konsole.log("PhoneCode", res.data.data);
        setContactCodes(res.data.data);
      })
      .catch((err) => {
        konsole.log("PhoneCodeError", err);
      });

        let upsertedloggedIn = sessionStorage.getItem('loggedUserId')
        setupsertedBy(upsertedloggedIn);
        console.log("upsertedloggedIn",upsertedloggedIn)

        let createdByLocalSession = commonLib.getObjFromStorage('stateObj')
        setCreatedBySessionStorage(createdByLocalSession.userId)
        konsole.log(createdBySessionStorage,'createdByLocalSession')

    splitSpeakerName = firstNames.split(" ");
        konsole.log("splitSpeakerName",splitSpeakerName)

    form.setFieldsValue({
      firstName: splitSpeakerName[0],
      // lastname: splitSpeakerName[1].charAt(0).toUpperCase() + splitSpeakerName[1].slice(1)
 
    });

    if(splitSpeakerName.length ==2){
      form.setFieldsValue({
        lastname: splitSpeakerName[1].charAt(0).toUpperCase() + splitSpeakerName[1].slice(1)});

    }
  }, [firstNames]);




  konsole.log("CheckMobNo", sendMobNo);
    const handleSubmit = (values) => {

      const InpData = values;
      const Inputs = {
        firstName: InpData.firstName,
        lastname: InpData.lastname,
        Profile: InpData.Profile,
        VideoLink: InpData.VideoLink,
        email: InpData.email,
        phone: InpData.phone
      }

      konsole.log("SpeakerAllInputData", Inputs.firstName+" "+Inputs.lastname)
      let UserFullName = Inputs.firstName+" "+Inputs.lastname
      setAddSpeakerFullName(UserFullName)
      setAddSpeakerForm(Inputs.values)

      let MobNoConcat = codeMobileNo.concat(mobileNumber)
      konsole.log("MobNoConcat", MobNoConcat)
      setSendMobNo(MobNoConcat);

      // const speakerRegisterApi = Services.getCoOrdinatorAddDetails(2, 7, Inputs.firstName, Inputs.lastname, 1, sendMobNo, createdBySessionStorage, email, createdBySessionStorage, createdBySessionStorage)
      // setLoader(true)
      // speakerRegisterApi
      //   .then((res) => {
      //     konsole.log("AddSpeakerRes", res)
      //     konsole.log('responseCorrect1', res.data.data.user.createdBy)
      //     // checkingApi(res.data.data.user.userId);
      //     fileupload(res.data.data.createdBy,res.data.data.user.userId);

      //   })
      //   .catch((error) => {
      //     konsole.error("errorDetails", error)
      //   }).finally(()=>{
      //     setLoader(false)
      //     // getdefaultValue("")
      //   })
      setLoader(true)
      let subtenantId = sessionStorage.getItem("subtenantId");
      
      const jsonObjData = {
        subtenantId: subtenantId,
        signUpPlateform: config.AOADMIN,
        createUserCredentials: true,
        createdBy: createdBySessionStorage,
        user: {
          roleId: 7,
          firstName: Inputs.firstName,
          lastName: Inputs.lastname,
          emailAddress: email,
          userName: email,
          countryCode: codeMobileNo,
          mobileNumber: mobileNumber,
          // password: "stringst",
          packageCode: null,
          activateUser: false,
          autoConfirmEmail: false,
          autoConfirmMobile: false
        }
      }

      // datatospeakercls()
      

  console.log("jsonObjDatajsonObjData",jsonObjData)

      const speakerRegisterApi=Services.subtenantCreateUser(jsonObjData)
      speakerRegisterApi.then((res)=>{
        console.log("speakerresponse",res)
        // fileupload(res.data.data.createdBy,res.data.data.userId);
        if(file !== undefined && file !== null && file !== ""){

          fileupload(res.data.data.createdBy, res.data.data.userId);
        }else{
          postspekaeruser(null, res.data.data.userId);
        }
       
        // sendSpeakerListApi();
        // SepkaerListOptionApi()

        // datatospeakercls()
        
      }).catch((err)=>{
        if(err.response.data.messages[0] == "ALREADY_REGISTERED"){
          message.error(err.response.data.messages[1])
        }
        console.log("errr",err)
      }).finally(()=>{
        setLoader(false)
      })

    }
    const fileupload = (createdByuserId,datauserid) => {
      const fileuploadCallApi = Services.postfileuploadspeaker(file, upsertedBy, createdByuserId, fileTypeId, fileCategoryId, fileStatusId)
      fileuploadCallApi
        .then((res) => {
          konsole.log("responsefileupload", res)
          // setspeakerImageId(res.data.data.fileId)
          konsole.log('response',res.data.data.fileId)
          postspekaeruser(res.data.data.fileId,datauserid)

        })
        .catch((error) => {
          konsole.error("errrr", error)
        }).finally(()=>{
          setLoader(false)
        })

    }

    const postspekaeruser=(speakerImageId,createdByuserId)=>{
    // const checkingApi = (SpeakerDetailsUserId,speakerImageId) => {

      const postspeakerupsertCallApi = Services.postspeakerupsertuser(0, createdByuserId, speakerProfile, videoLinkPath, speakerImageId, "Done", true,upsertedBy)
      postspeakerupsertCallApi
        .then((res) => {
          konsole.log("responsepostspeakerupsertCallApi",res)
          // SepkaerListOptionApi()
          prop.form.setFieldsValue({
            "speaker-Img" : res.data.data.speakers[0].speakerProfileUrl,
            "Name_of_Speaker" : res.data.data.speakers[0].speakerFullName,
            "DescriptionSpeaker" : res.data.data.speakers[0].speakerProfile
          })
          konsole.log("UpsetSpeaker", res.data.data.speakers[0].videoLinkPath)
          konsole.log("UpsetSpeaker", res.data.data.speakers[0].speakerProfile)
          konsole.log("UpsetSpeaker", res.data.data.speakers[0].speakerProfileUrl)
          konsole.log("UpsetSpeaker", res.data.data.speakers[0].speakerUserId)
          konsole.log("UpsetSpeaker", res.data.data.speakers[0].speakerFullName)
          setGetValueApi({
            videoPath:res.data.data.speakers[0].videoLinkPath,
            userIdSpeaker: res.data.data.speakers[0].speakerUserId,
            profileSpeaker: res.data.data.speakers[0].speakerProfile,
            profileUrlSpeaker: res.data.data.speakers[0].speakerProfileUrl,
            fullNameSpeaker: res.data.data.speakers[0].speakerFullName,
          })
          prop.speakerData.push(res)
          // sendSpeakerListApi();
          // alert("speaker add successfully")
          prop.datatospeakercls()
          prop.onCancel()
          
          // datatospeakercls()

          // sendSpeakerListApi();
          prop.SepkaerListOptionApi()
          // form.resetFields();
          setLoader(false)
          message.success("Speaker added successfully")

        })
        .catch((error) => {
          konsole.log("upsertError", error)
          message.error("Speaker is not added")
        }).finally(()=>{
          setLoader(false)
        })
    }

  // ......................................................................

  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";

      if (!isPNG) {
        message.error(`${file.name} is not a png file`);
        return;
      }

      return false;
    },

    onChange(info) {
      konsole.log("fileList", info);
      konsole.log("fileListFile", info.file);
      setFile(info.file);

      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      }
    },
  };

  // ......................................................................

  const [codeMobileNo, setCodeMobileNo] = useState("");
  const GetContactCodes = (a, key) => {
    konsole.log("GetContactCodes", a);
    setCodeMobileNo(a);
    // konsole.log("GetContact",key)
  };

  const onChangePhone = (value) => {
    // console.log("value",value);
    setMobileNumber(value);
  };

  return (
    <div style={{}}>
      <Form
        form={form}
        name="basicform"
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{ remember: true, prefix: "91" }}
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              pattern: new RegExp(/^[a-zA-Z]*$/),
              message: "No Space or Special Characters Allowed",
            },
            { required: true, message: "Please input your first Name!" },
          ]}
        >
          <Input onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)} />
        </Form.Item>

        <Col xs={24} lg={24}>
          <Form.Item
            label="Last Name"
            name="lastname"
            rules={[
              {
                pattern: new RegExp(/^[a-zA-Z]*$/),
                message: "No Space or Special Characters Allowed",
              },
              { required: true, message: "Please input your last name!" },
            ]}
          >
            <Input onChange={(e) => setLastName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))} onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)} />
          </Form.Item>
        </Col>

        <br></br>

        <Form.Item
          label="Profile"
          name="Profile"
          rules={[
            {message: "Please enter your Profile Details" },
          ]}
        >
          <TextArea
            rows={2}
            placeholder="Speaker Profile"
            minLength={6}
            onChange={(e) => setSpeakerProfile(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="VideoLinkAddEditSpeaker"
          label=" Link"
          rules={[
            {
              pattern: new RegExp(
                "https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|http?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,}"
              ),
              message: "Please enter valid url with http!",
            },
            { required: true, message: "Please input link!" },
          ]}
        >
          <Input
            // placeholder="input placeholder"
            onChange={(e) => setvideoLinkPath(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="emailAddEditSpeaker"
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          rules={[
            {
              pattern: new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
              message: "Please enter correct email address",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="phoneAddEditSpeaker" label="Cell Number">
          <Input.Group compact>
            <Form.Item
              name="countryCodeAddEditSpeaker"
              noStyle
            //   rules={[
            //     { required: true, message: "Please Select Your Country Code!" },
            //   ]}
            >
              <Select
                placeholder="Country Code"
                onChange={GetContactCodes}
                style={{ width: "23%",marginRight:"2%" }}
              >
                {contactCodes.map((items) => {
                  return <Option value={items.value}>{items.label}</Option>;
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="PhoneNumberAddEditSpeaker"
              noStyle
              
            >
              {/* 
              <Input style={{ width: '75%' }} maxLength={10}
              //  onChange={(e) => setMobileNumber(e.target.value)}
              onChange={ModalPhoneNoValidation} /> */}
                <div   style={{ width: "75%" }}>
              <InputMask
                placeholder=" Cell Number"
                value={mobileNumber}
                onValueChange={onChangePhone}
              />
              </div>
            </Form.Item>
          </Input.Group>
        </Form.Item>

        {/* <Upload
          onChange={(response) => {
            if (response.file.status !== 'uploading') {
              konsole.log(response.file, response.fileList);
            }
            if (response.file.status === 'done') {
              message.success(`${response.file.name} 
                               file uploaded successfully`);
            } else if (response.file.status === 'error') {
              message.error(`${response.file.name} 
                             file upload failed.`);
            }
          }}
        >
          <FormItem label="Profile Image"
            name="Profile Image">
            <Button>Upload File</Button>
          </FormItem>
        </Upload> */}

        {/* <Upload {...props}>
          <Button icon={<UploadOutlined />}  >Upload png only</Button>
        </Upload> */}
        {/* ............................................................................................................. */}
        <Form.Item
          name="PngImgUpload"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please Upload Image",
          //   },
          // ]}
        >
          <Upload {...props}>
          <Button icon={<UploadOutlined />}  >Upload png only</Button>
        </Upload>
        </Form.Item>
        {/* <input type="file" onChange={PreviewFile}/><br/>
<img src="" height="200" alt="Image preview"/> */}

        {/* ............................................................................................................. */}

        <Form.Item className="mt-4" style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEditClsSpeaker;