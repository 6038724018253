import React, { useEffect, useState } from "react";
import { Drawer, Button, Image,Col,Row,Popconfirm } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "../Styles/NavBar.css";
import commonLib from "../control/commonLib";
import config from "../config.json";
import Services from "../network/Services";
import konsole from "../network/konsole";
import Modal from "antd/lib/modal/Modal";
import { AOLOGINBASEURL, EVENTCOUNTERURL } from "../network/url";
import AccountRoleChange from "./Account/AccountRoleChange";

const Navbar = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [subtenantImage, setsubtenantImage] = useState("");
  const [roleIdValue, setRoleIdValue] = useState()
  const [open, setOpen] = useState(false);
  const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    const subtenantId = commonLib.getObjFromStorage("subtenantId");
    let valueRoleId = commonLib.getObjFromStorage("stateObj")?.roleId
    setRoleIdValue(valueRoleId)
    Services.getSubtenantDetail(subtenantId, true)
      .then((res) => {
        setsubtenantImage(res.data?.data[0]?.subtenantLogoUrl);
      })
      .catch((err) => {
        konsole.log("ErrorData", err);
      });
  }, []);

konsole.log("config.EVENTCOUNTERURL",EVENTCOUNTERURL)
  const backTOCoordinator = () =>{

    const stateObj = commonLib.getObjFromStorage('stateObj');
    konsole.log("stateObj", stateObj)
    const userId = stateObj?.userId
    konsole.log("aab",userId)
    let params = `appState=${stateObj.appState}&userId=${userId}&loggedUserId=${userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}`;
    window.location.replace(`${EVENTCOUNTERURL}?token=` + window.btoa(params));
    konsole.log("params",stateObj.appState,stateObj, params)

  }
  const handelconfrimation = () => {
    setOpen(false);
    const stateObj = commonLib.getObjFromStorage("stateObj");
    let params = `appState=${stateObj.appState}&userId=${
      stateObj.userId
    }&roleId=${stateObj.roleId}&loggenInId=${
      stateObj.loggenInId
      }&success=${true}`;
    sessionStorage.clear()
    window.location.replace( `${AOLOGINBASEURL}/Account/Signout?` + params);
  };

  return (
    <>
   <div className="header d-flex justify-content-between align-items-center w-100 border-1 border-bottom " style={{ position: "sticky", top: "0px", zIndex: "99" }} >
        <div className="d-block d-lg-none">
          <Button className="menu" type="primary" icon={<MenuOutlined />} onClick={() => setVisible(true)} />
        </div>
        <div class="h-50">
          <img src={subtenantImage} style={{ width: "140px" }} />
        </div>
        <div className="d-flex align-items-center ">
          {roleIdValue == 6 &&
            <span onClick={backTOCoordinator} >
              <img style={{ width: "30px", cursor: "pointer" }} className="me-1" src="./images/backButton.png" alt="Error" />
            </span>
          }
          <p className="text d-none d-md-block d-lg-block m-0 cursor-pointer">
            Hi, {userLoggedInDetail?.memberName}
          </p>
          <span className="ms-4">
          <AccountRoleChange />
          </span>
          {/* <Image className='admin' src='/images/spouse1.png' width={50}/> */}
          <div onClick={handleClick} style={{ display: 'flex' }}>
            <p className="ms-4 text   m-0 " style={{ cursor: "pointer" }} >Logout</p>
            <img
              className="admin ms-0 mt-1"
              style={{ cursor: "pointer" }}
              src="/icons/logoutcircle.svg"
              width={18}
            />
          </div>

        </div>

        {/* <div class="container-fluid head">
    <div className='col-md-9'><img src='/images/logoImage.png' className='img-fluid logo' ></img></div>
    <div className='col-md-1'><img src='/images/bell.png' className='img-fluid bell'></img></div>
    <div className='col-md-1'><p className='text d-flex'>Hi, Super Admin</p></div>
    <div className='col-md-1'><img className='admin d-flex'  src='/images/spouse1.png'></img></div>
  </div> */}

        <Drawer
          title="Menu"
          placement="left"
          onClick={() => setVisible(false)}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          {children}
        </Drawer>
      </div>

      {open == true ? (
        <>
          <Popconfirm
            title={<p> Are you sure you want to LOGOUT ?</p>}
                  onConfirm={() => {handelconfrimation();}}
            placement="topRight"
                  onCancel={() => { setOpen(false);}}
            visible={open}
            okText="YES"
            cancelText="NO"
                  okButtonProps={{ style: { backgroundColor: '#720c20', color: 'white',border:"none" } }}
                  cancelButtonProps={{ style: { backgroundColor: 'white', color: '#720c20',border:"1px solid #720c20" } }}



          />
        </>
            ) : ( "")}



    </>

  );
};

export default Navbar;
