import React, { useState, useEffect, createContext, useContext } from "react";
import {
  Form,
  Input,
  Card,
  Select,
  Row,
  Col,
  Button,
  Modal,
  AutoComplete,
  Space,
} from "antd";
import AddEditSpeaker from "../AddEditSpeaker";
import Services from "../../network/Services";
import "./Seminar.css";
import konsole from "../../network/konsole";
import { GlobalData } from "./SeminarFile";
const { Option } = Select;
const { TextArea } = Input;

export const GlobalSpeaker = createContext();

const SeminarInformation = ({
  statuSpeakerId,
  editSpeakerData,
  setTotalSpeakers,

  formSeminar,
  setImgAgain,
  IdSpeaker,
  setMultiSpeakersData,
  multiData,
  backClicked
}) => {
  const {
    getSpeakerName,
    getSpeakerVideo,
    getSpeakerDesCript,
    getSpeakerImg,
    editFuncData,
    stopSpeakerEditFunc,
    setStopSpeakerEditFunc,
  } = useContext(GlobalData);
  const { getSpeakerUserId, stopEditFunc, setStopEditFunc, previewData } = useContext(GlobalData)

  // const [form] = Form.useForm();

  const { Meta } = Card;

  konsole.log("setImgAgain", GlobalData);
  const [sendSpeakerStoredVal, setSendSpeakerStoredVal] = useState("");
  const [speakerDescription, setSpeakerDescription] = useState("");
  const [speakerObjectData, setSpeakerObjectData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userDetails, setuserDetails] = useState([]);
  const [storedVal, setstoredVal] = useState("");
  const [speakerId, setSpeakerId] = useState("");
  const [imgLink, setImgLink] = useState("");
  const [imgLink1, setImgLink1] = useState("/images/ApeakerNoImage1.png");
  const [vedio, setVedio] = useState("");
  const [selectSpeakerName, setSelectSpeakerName] = useState("");
  const [imageAlt, setImageAlt] = useState(" Image of Speaker");
  const [totalSpeakerData, setTotalSpeakerData] = useState([]);
  const [multipleSpeakerData, setMultipleSpeakerData] = useState([]);
  const [addArray, setAddArray] = useState([]);
  const [multipleSpeakerUserId, setMultipleSpeakerUserId] = useState();
  const [renderpage, setRenderpage] = useState(false)
  const [multipleSpeakerDataRemoved, setMultipleSpeakerDataRemoved] = useState();
  const [readMoreProfile, setReadMoreProfile] = useState(false)
  let subtenantID = sessionStorage.getItem('subtenantId')





  getSpeakerName(storedVal);

  const getApiDataValue = (itemGetApiDataValue) => {
    konsole.log("itemGetApiDataValue", itemGetApiDataValue);
    setSpeakerObjectData(itemGetApiDataValue);
    formSeminar.setFieldsValue({
      NameofSpeaker: itemGetApiDataValue.fullNameSpeaker,
      VideoLinkOfSpeaker: itemGetApiDataValue.videoPath,
      DescriptionSpeaker: itemGetApiDataValue.profileSpeaker,
    });
    if (
      itemGetApiDataValue.profileUrlSpeaker !== null &&
      itemGetApiDataValue.profileUrlSpeaker !== undefined &&
      itemGetApiDataValue.profileUrlSpeaker !== ""
    ) {
      setImgLink(itemGetApiDataValue.profileUrlSpeaker);
    } else {
      if (itemGetApiDataValue.imageStatus !== 0) {
        setImgLink("/images/ApeakerNoImage1.png");
      }
    }
    setSpeakerId(itemGetApiDataValue.userIdSpeaker);
    konsole.log("speakerId", speakerId);
  };

  useEffect(() => {
    setImgLink1("/images/ApeakerNoImage1.png");
    let subtenantID = parseInt(sessionStorage.getItem('subtenantId'))
    SepkaerListOptionApi(true,subtenantID);
    

    setstoredVal(speakerObjectData.fullNameSpeaker);
  }, [speakerObjectData.fullNameSpeaker]);

  const SepkaerListOptionApi = (isActive,subtenantId,dataValue) => {
    let objCoordinator = [];
    const Details = Services.postSeminarDetailsList(isActive,subtenantId);
    Details.then((response) => {
      konsole.log("POST_GET", response);



      konsole.log("Getted", response.data.data.speakers[0].speakerUserId);
      let responseData = response.data.data.speakers
      for (let i = 0; i < response.data.data.speakers.length; i++) {
        objCoordinator.push({
          value: response.data.data.speakers[i]?.speakerUserId,
          label: response.data.data.speakers[i]?.speakerFullName,
        });
      }
      konsole.log("responseDataresponseData", responseData)
      setTotalSpeakerData(response.data.data.speakers);
      // setTotalSpeakers(response.data.data.speakers)
      let dbs = response.data.data.speakers;

      let newDaTA = dbs.map((val33) => {
        return val33?.speakerUserId
      })

      setTotalSpeakers(newDaTA)
      setuserDetails(objCoordinator);

      if (editFuncData !== null && editFuncData !== undefined && editFuncData !== "" && stopSpeakerEditFunc !== true) {
        let filterData = response.data.data.speakers.filter((x) => {
          return x.speakerUserId == dataValue;
        });
        editSpeakerDataFun(filterData);
        konsole.log("filterDatafilterData", filterData);
      }

      if (editFuncData !== null && editFuncData !== undefined && editFuncData !== "" && stopEditFunc !== true) {
        let edditSpeakerArray = []
        for (let i = 0; i < editFuncData?.speakers.length; i++) {
          edditSpeakerArray.push(editFuncData?.speakers[i]?.speakerUserId)
        }
        konsole.log("edditCoordinatorArray", edditSpeakerArray)
        // props.form.setFieldsValue({
        //   'coordinatorField': edditCoordinatorArray
        // })
        // getDataId(edditCoordinatorArray)

        getSpeakerUserId(edditSpeakerArray)

      }




    }).catch((error) => {
      konsole.log("ErrorSpeakerList", error);
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getinputdata = (e) => {
    setstoredVal(e);
    setSendSpeakerStoredVal(e);
  };

  useEffect(() => {
    if (editFuncData !== null && editFuncData !== undefined && editFuncData !== "" && stopSpeakerEditFunc !== true
    ) {
      // selectspeakerfilter(editFuncData?.speakers[0]?.speakerUserId)
      // editSpeakerDataFun(editFuncData?.speakers[0]?.speakerUserId)
    
      // SepkaerListOptionApi(editFuncData?.speakers[0]?.speakerUserId);
    }
    // showTickedSpeakerInStart()
  }, [editFuncData]);

  const editSpeakerDataFun = (editSpeakerId) => {
    konsole.log("editSpeakerIdData", editSpeakerId);
    editSpeakerId.map((items) => {
      konsole.log("SpeakerListed", items);
      setVedio(items.videoLinkPath);

      if (
        items?.speakerImageId !== null &&
        items?.speakerImageId !== undefined &&
        items?.speakerImageId !== ""
      ) {
        setImgLink(items.speakerProfileUrl);
      } else {
        setImgLink("/images/ApeakerNoImage1.png");
      }
      ImageAlt(items.speakerProfileUrl);
      setSpeakerId(items.speakerUserId);
      setSelectSpeakerName(items.speakerFullName);

      konsole.log("SpeakerId", items.speakerUserId);
      konsole.log("speakerProfile1111", items.speakerProfile);
      setSpeakerDescription(items.speakerProfile);
      formSeminar.setFieldsValue({
        // 'NameofSpeaker': speakerObjectData.fullNameSpeaker,
        VideoLinkOfSpeaker: items.videoLinkPath,
        DescriptionSpeaker: items.speakerProfile,
      });
    });
  };

  const selectspeakerfilter = (value, key) => {
    // konsole.log("selectspeakerfilter::Value",value,"selectspeakerfilter::Key",key)

    if (key !== undefined && key !== null && key !== "") {
      let selectuserId = key.key;
      konsole.log("findKeyData", userDetails);
      let resultfilter = userDetails.filter(function (items) {
        return items.userId == selectuserId;
      });
      konsole.log("resultfilter", resultfilter);

      resultfilter.map((items) => {
        konsole.log("SpeakerListed", items);
        setVedio(items.videoLinkPath);

        if (
          items?.speakerImageId !== null &&
          items?.speakerImageId !== undefined &&
          items?.speakerImageId !== ""
        ) {
          setImgLink(items.speakerProfileUrl);
        } else {
          setImgLink("/images/ApeakerNoImage1.png");
        }
        ImageAlt(items.speakerProfileUrl);
        setSpeakerId(items.speakerUserId);
        setSelectSpeakerName(items.speakerFullName);

        konsole.log("SpeakerId", items.speakerUserId);
        konsole.log("speakerProfile1111", items.speakerProfile);
        setSpeakerDescription(items.speakerProfile);
        formSeminar.setFieldsValue({
          // 'NameofSpeaker': speakerObjectData.fullNameSpeaker,
          VideoLinkOfSpeaker: items.videoLinkPath,
          DescriptionSpeaker: items.speakerProfile,
        });
      });
    } else {
      // let resultfilter1 = userDetails.filter(function (items) {
      //   return items.speakerUserId == 204
      // })
      // konsole.log("resultfilter1", resultfilter1)
      konsole.log("editSpeakerData", userDetails, value);
    }

    if (
      editFuncData !== null &&
      editFuncData !== undefined &&
      editFuncData !== ""
    ) {
      setStopSpeakerEditFunc(true);
    }
  };

  // statuSpeakerId(speakerId);
  getSpeakerVideo(vedio || speakerObjectData.videoPath);

  getSpeakerDesCript(speakerDescription || speakerObjectData.profileSpeaker);
  getSpeakerImg(imgLink || speakerObjectData.profileUrlSpeaker);

  konsole.log("imgLinkimgLink", imgLink);

  const ImageAlt = (checkImgUrl) => {
    if (checkImgUrl == "") {
      setImageAlt("No Image Found!");
    }
    // else if(checkImgUrl == "https://devintakeformdocs.blob.core.windows.net/paralegal/1.png"){
    //   setImageAlt("No Image Found!")
    // }
  };

  konsole.log("SpeakereditFuncData", editFuncData);

  const [searchSpeakerData, setSearchSpeakerData] = useState("");
  const filterSpeakerList = (e) => {
    konsole.log(
      "filterSpeakerListfilterSpeakerList",
      e.target.value.toLowerCase()
    );
    setSearchSpeakerData(e.target.value);

    // filteredData(e.target.value)
  };

  const filteredData = () => {
    let arrayData = [
      {
        speakerProfileUrl: "/images/ApeakerNoImage1.png",
        speakerProfile: "Speaker doesn't exist please add this speaker",
        speakerFullName: "",
        addSpeakerBtn: true,
      },
    ];
    let filteredData = [];
    if (
      searchSpeakerData == "" ||
      searchSpeakerData == undefined ||
      searchSpeakerData == null
    ) {
      konsole.log("searchSpeakerDatasearchSpeakerDatanull", searchSpeakerData);
      filteredData = totalSpeakerData;
    } else if (
      searchSpeakerData !== "" &&
      searchSpeakerData !== undefined &&
      searchSpeakerData !== null
    ) {
      konsole.log("searchSpeakerDatasearchSpeakerData", searchSpeakerData);
      filteredData = totalSpeakerData.filter((items) =>
        items?.speakerFullName
          .toLowerCase()
          .toLowerCase()
          .includes(searchSpeakerData.toLocaleLowerCase())
      );
    }
    if (filteredData.length !== 0) {
      return filteredData;
    } else {
      return (filteredData = arrayData);
    }
  };

  const multiSpeakerFunc = (data, index) => {
    let speakerData = {
      speakerName: data.speakerFullName,
      speakerUrl: data.speakerProfileUrl,
      speakerProfile: data.speakerProfile,
      speakerUserId: data.speakerUserId,
      clickedSpeaker: (data.speakerUserId !== undefined && data.speakerUserId !== null) ? true : false,
      readMore: false
    };
    // statuSpeakerId(speakerData)

    let arrayofspeker = multiData
    let checkvalueexist = arrayofspeker.some(item => item.speakerUserId == speakerData?.speakerUserId);
    konsole.log("speakerDataspeakerData", speakerData, arrayofspeker, checkvalueexist)
    if (checkvalueexist == !true && (data.speakerUserId !== undefined && data.speakerUserId !== null)) {
      arrayofspeker.push(speakerData)
      let filterSpeaker = totalSpeakerData.filter((item) => item?.speakerUserId != data?.speakerUserId)
      filterSpeaker.unshift(data)
      konsole.log("filterSpeakerfilterSpeaker", filterSpeaker)
      setTotalSpeakerData(filterSpeaker)


    } else {
      arrayofspeker = arrayofspeker.filter(item => item.speakerUserId !== speakerData?.speakerUserId)
    }
    setRenderpage(!renderpage)
    setMultiSpeakersData(arrayofspeker);
    konsole.log("checkvalueexistcheckvalueexist111", arrayofspeker)
    konsole.log("checkvalueexistcheckvalueexist", checkvalueexist)
    getSpeakerName(arrayofspeker)


    let speakersid = arrayofspeker.map((e) => {
      return e.speakerUserId
    })

    getSpeakerUserId(speakersid)
    // setStopEditFunc(true)
    // statuSpeakerId(prev=> [prev,...speakerData?.speakerUserId])
    // statuSpeakerId[...speakerData?.speakerUserId]
    // oldarray=>[...oldarray,a]


    // konsole.log(
    //   "speakerDataspeakerData",
    //   speakerData,
    //   totalSpeakerData,
    //   multipleSpeakerData
    // );
    // let filterDataIndex = totalSpeakerData.findIndex((x) => {
    //   return x?.speakerUserId == data?.speakerUserId;
    // });
    // let filterData = totalSpeakerData.filter((x) => {
    //   return x?.speakerUserId == data?.speakerUserId;
    // });
    // konsole.log("findIndexfindIndex", filterDataIndex, filterData);
    // if (multipleSpeakerData.includes(filterDataIndex)) {
    //   let removeIndex = multipleSpeakerData.splice(
    //     multipleSpeakerData.findIndex((x) => x == filterDataIndex),
    //     1
    //   );

    //   // konsole.log("removeIndex",removeIndex, multipleSpeakerData)
    //   totalSpeakerData[filterDataIndex]["clickedSpeaker"] = false;
    //   setMultipleSpeakerDataRemoved(removeIndex);
    //   // filteredData()
    // } else {
    //   totalSpeakerData[filterDataIndex]["clickedSpeaker"] = true;
    //   setMultipleSpeakerData([...multipleSpeakerData, filterDataIndex]);
    //   statuSpeakerId([...IdSpeaker, filterData[0]?.speakerUserId]);
    //   setMultipleSpeakerUserId([...multipleSpeakerUserId, filterData[0]?.speakerUserId])
    // }
    // if (IdSpeaker.includes(filterData[0]?.speakerUserId)) {
    //   let removeIndex = IdSpeaker.splice(
    //     IdSpeaker.findIndex((x) => x == filterDataIndex),
    //     1
    //   );
    //   setMultipleSpeakerUserId(removeIndex);
    // } else {
    //   statuSpeakerId([...IdSpeaker, filterData[0]?.speakerUserId]);
    // }
  };
  const filterSpeakerDescriptionLegth = (dataSpeakerProfile) => {
    return dataSpeakerProfile.substr(0, 20);
  };

  konsole.log("multiDatamultiDatamultiDatamultiData", multiData)



  const calfuncheckselectid = (id) => {
    multiData.filter((ele) => {
      return getSpeakerName(ele?.speakerName)

    })
    let dataItem = multiData
    for (let item of dataItem) {
      if (id !== null && id !== undefined) {

        if (item.speakerUserId == id) {
          // alert("yes")
          return true
        }
      }
    }
  }

  // konsole.log("calfuncheckselectid",calfuncheckselectid(204))
  return (
    <div>
      <div className="seminarcard bg-white mt-2 pb-0 " style={{ marginRight: "5px" }}>
      <h6 className="fs-4 fw-bold seminartxt">Speaker Information</h6>
        {/* <p className="fs-4 fw-bold seminartxt">Speaker Information</p> */}

        <Modal
          centered
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Add Speaker"
          footer={false}
          maskClosable={false}
        >
          <GlobalSpeaker.Provider
            value={{
              firstNames: sendSpeakerStoredVal,
              getApiDataValue: getApiDataValue,
            }}
          >
            <AddEditSpeaker
              formSpeaker={formSeminar}
              hideModal={setIsModalVisible}
              sendSpeakerListApi={SepkaerListOptionApi}
            />
          </GlobalSpeaker.Provider>
        </Modal>
        <Row className=" ">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {/* <p className=' '>Image of Speaker</p> */}
            {/* <Card
             > */}
            {/* <div className=' d-flex  align-items-center imgDivOfSpeaker ' style={{ height: "190px", width: "147px", border: "1px dashed black" }}>
              <img src={imgLink || setImgAgain} alt={imageAlt} className=' img-fluid' style={{height: "188px"}} />

            </div> */}
            {/* </Card> */}
            <Input
              type="search"
              placeholder="Search for speakers"
              onChange={filterSpeakerList}
            />
            <h4 style={{ paddingTop: "8px" }}>Speaker</h4>
          </Col>
          <div className="multiSpeaker vertical-scroll">
            <Form.Item>
              <div className="Parent_grid">
                <div className="speaker-grid">
                  {totalSpeakerData &&
                    filteredData()?.map((items, index) => {
                      konsole.log("aafilteredData", items, multiData);
                      konsole.log("calfuncheckselectiditemsspeakerUserId", calfuncheckselectid(items.speakerUserId))
                      //   speakerUserId
                      return (
                        <>
                          <div
                            style={{ position: "relative",marginTop:"0px",paddingTop:"0px" }}
                            className={`${(calfuncheckselectid(items.speakerUserId) && multiData.length !== 0) ? "clickWillSmith" : "will-smith"}`}
                            onClick={() => multiSpeakerFunc(items, index)}
                          >
                            {/* {(items?.clickedSpeaker==true)&& (
                            <div>
                              <img
                                src="./images/tick.png"
                                alt="Error"
                                width={20}
                                className="img-fluid"
                              />
                            </div>
                          )}  */}

                            {(calfuncheckselectid(items.speakerUserId)) && <span className="Logo" style={{ position: "absolute" }}>
                              <img
                                src="./images/Group.png"
                                alt="Error"
                                width={20}
                                className="img-fluid"
                              />
                            </span>}

                            <div className="img-div">

                              <img
                                src={
                                  items?.speakerProfileUrl !== null &&
                                    items?.speakerProfileUrl !== undefined &&
                                    items?.speakerProfileUrl !== ""
                                    ? items?.speakerProfileUrl
                                    : "/images/ApeakerNoImage1.png"
                                }
                                alt=""
                                className="img-fluid rounded-circle"
                              />
                            </div>
                            <h3>{items?.speakerFullName}</h3>
                            <p>
                              {(items?.speakerProfile?.length > 50)
                                ? `${filterSpeakerDescriptionLegth(
                                  items?.speakerProfile
                                )}...`
                                : items?.speakerProfile}
                              {items?.speakerProfile?.length > 50 && (
                                <a>Read More</a>
                              )}
                            </p>
                            {items?.addSpeakerBtn == true && (
                              <>
                                <Button onClick={showModal} className='seminarbtn ' style={{ marginBottom: "10px",backgroundColor:"#720c20" }}>
                                  Add Speaker
                                </Button>
                              </>
                            )}
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </Form.Item>
            {/* <Col xs={24} sm={24} md={19} lg={19} xl={19}>
            <Form.Item
              name="NameofSpeaker"
              label="Name of Speaker"
              rules={[
                {
                  required: true,
                  message: "Select Name of Speaker!",
                },
              ]}
              className="RespFeilds"
            >
              <AutoComplete
                onInput={(e) =>
                  (e.target.value =
                    e.target.value.charAt(0).toUpperCase() +
                    e.target.value.slice(1))
                }
                onChange={getinputdata}
                onSelect={selectspeakerfilter}
                notFoundContent={
                  <>
                    <Button
                      type="primary"
                      style={{ float: "right" }}
                      onClick={showModal}
                    >
                      Add Speaker
                    </Button>
                    <p
                      style={{ float: "left" }}
                      className="text-danger fw-bold"
                    >
                      
                      Speaker Not Found
                    </p>
                  </>
                }
                placeholder="Select Speaker Name "
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                {userDetails.map((items, index) => {
                  return (
                    <Option value={items.speakerFullName} key={items.userId}>
                      {" "}
                      {items.speakerFullName}{" "}
                    </Option>
                  );
                })}
              </AutoComplete>
            </Form.Item>

            <Form.Item
              label="Video upload link"
              className="RespFeilds"
              name="VideoLinkOfSpeaker"
              rules={[
                {
                  required: true,
                  message: "Video Link Needs!",
                },
              ]}
            >
              <Input className="fs-5 w-100 border-2 " />
            </Form.Item>
            <Form.Item
              label="Description"
              wrapperCol={{
                span: 24,
              }}
              className="RespFeilds"
              name="DescriptionSpeaker"
              rules={[
                {
                  required: true,
                  message: "Description Required!",
                },
              ]}
            >
              <TextArea rows={6} className="border-2 w-100 " />
            </Form.Item>
          </Col> */}
          </div>
        </Row>
      </div>
    </div>
  );
};

export default SeminarInformation;
