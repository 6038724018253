import { Divider, Menu } from 'antd'
import Card from 'antd/lib/card/Card'
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useLocation } from 'react'
import "../Styles/Sidemenu.css"
import commonLib from '../control/commonLib';
import konsole from '../network/konsole';
import config from "../config.json";


function Sidemenu() {

  const [changeBorder, setChangeBorder] = useState("changeFont")
  const [roleid, setroleid] = useState(false)
  const [subtenantId, setSubtenantId] = useState("")

  useEffect(() => {
    const url = window.location.pathname
    if (url == '/SeminarFile') {
      setChangeBorder(3)
    } else if (url == "/FirmPartner") {
      setChangeBorder(2)
    } else if (url == '/Classroom') {
      setChangeBorder(9)
    } else if (url == '/communication') {
      setChangeBorder(10)
    } else if (url == '/ProductManagement') {
      setChangeBorder(4)
    }
    else if (url == '/Managesubscription') {
      setChangeBorder(11)
    }
    else if (url == '/Filecabinet') {
      setChangeBorder(6)
    } else if (url == '/Feedback') {
      setChangeBorder(5)
    } else if (url == '/newFilecabinet') {
      setChangeBorder(7)
    } else if(url == '/academyUsers'){
      setChangeBorder(12)
    }
    else {
      setChangeBorder(1)
    }

  }, [])

  const changeBorderColor = (index) => {
    setChangeBorder(index)

  }


  useEffect(() => {
    let roleid = commonLib.getObjFromStorage("stateObj").roleId
    let subtenantID = commonLib.getObjFromStorage("subtenantId")

    setroleid(roleid)
    setSubtenantId(subtenantID)
  }, [roleid])



  konsole.log('roleidroleidroleid',roleid,config.demo)
  return (
    <Menu className='sider '>
      <div className='sideBars' style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'auto' }}>
      <ul class="list-group list-group-flush">
        <li className='list-group-item' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(1)}
            className={`${(changeBorder === 1) ? 'borderOnClick' : ''} sidebar`} >
            <Link to='../DashBoard' style={{ textDecoration: "none" }} >
              <Card style={{ borderRadius: "10px" }}>
                <div className=' d-flex justify-content-between align-items-center'>
                  <div className={`${(changeBorder === 1) ? 'buttonColor' : ''} changeFont`}> Dashboard </div>
                  {changeBorder === 1 ? <div >

                    <img src="/images/dashboard-Maroon.svg" className='img-fluid img-thumbnail ' style={{ width: "40px", height: "40px", border: "none" }}></img>
                  </div> : <div >

                    <img src="/images/dashboard.svg" className='img-fluid img-thumbnail ' style={{ width: "40px", height: "40px", border: "none" }}></img>
                  </div>}

                </div>
              </Card>
            </Link>
          </div>
        </li>

        <li className='list-group-item mt-1' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(2)}
            className={`${(changeBorder === 2) ? 'borderOnClick' : ''} sidebar`}>
            <Link to='../FirmPartner' style={{ textDecoration: "none" }} >
              <Card style={{ borderRadius: "10px" }}>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className={`${(changeBorder === 2) ? 'buttonColor' : ''} changeFont`}>
                    {/* {(roleid != 4) ? "Firm Partners" : "Users"} */}
                    {(roleid != 4) ? "Staff" : "Firms"}
                  </div>
                  {changeBorder === 2 ? <div >
                    <img src="/images/dashboard-Firm-Maroon.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }}></img>

                  </div> : <div >
                    <img src="/images/dashboard-Firm.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }}></img>

                  </div>}

                </div>
              </Card>
            </Link>
          </div>
        </li>
        {(subtenantId != 745) ?
          <li className='list-group-item mt-1' style={{ border: "none" }}>
            <div onClick={() => changeBorderColor(3)}
              className={`${(changeBorder === 3) ? 'borderOnClick' : ''} sidebar`}>
              <Link to='../SeminarFile' style={{ textDecoration: "none" }} >
                <Card style={{ borderRadius: "10px" }}>
                  <div className='content d-flex justify-content-between align-items-center'>
                    <div className={`${(changeBorder === 3) ? 'buttonColor' : ''} changeFont`}>
                      Seminars
                    </div>
                    {changeBorder === 3 ? <div>
                      <img src="/images/dashboard-seminar-Maroon.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                    </div> : <div>
                      <img src="/images/dashboard-seminar.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                    </div>}

                  </div>
                </Card>
              </Link>

            </div>
          </li> : ""
        }
        {
        roleid !== "4" &&

          <li className='list-group-item mt-1' style={{ border: "none" }}>
            <div onClick={() => changeBorderColor(10)}
              className={`${(changeBorder === 10) ? 'borderOnClick' : ''} sidebar`}>
              <Link to='../communication' style={{ textDecoration: "none" }} >
                <Card style={{ borderRadius: "10px" }}>
                  <div className='content d-flex justify-content-between align-items-center'>
                    <div className={`${(changeBorder === 10) ? 'buttonColor' : ''} changeFont`}>
                      Communication
                    </div>
                    {changeBorder === 10 ? <div>
                      <img src="/images/Chatting.png" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                    </div> : <div>
                      <img src="/images/Chatting2.png" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                    </div>}

                  </div>
                </Card>
              </Link>
            </div>
          </li>
        }
        {((roleid == 4 || roleid == 11) && (subtenantId == 3 || subtenantId == 749 || subtenantId == 745 )) ?

          <li className='list-group-item mt-1' style={{ border: "none" }}>
            <div onClick={() => changeBorderColor(9)}
              className={`${(changeBorder === 9) ? 'borderOnClick' : ''} sidebar`}>
              <Link to='../Classroom' style={{ textDecoration: "none" }} >
                <Card style={{ borderRadius: "10px" }}>
                  <div className='content d-flex justify-content-between align-items-center'>
                    <div className={`${(changeBorder === 9) ? 'buttonColor' : ''} changeFont`}>
                      {/* Classroom */}
                      Workshop
                    </div>
                    {changeBorder === 9 ? <div>
                      <img src="/images/Classroom.png" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                    </div> : <div>
                      <img src="/images/Classroomblack.png" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                    </div>}

                  </div>
                </Card>
              </Link>

            </div>
          </li>
          : ""}

        {/***********************Subscription*********************************/}
         {(roleid == 4) ? <li className='list-group-item mt-1' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(4)}
            className={`${(changeBorder === 4) ? 'borderOnClick' : ''} sidebar`}>
            <Link to='../ProductManagement' style={{ textDecoration: "none" }} >
              <Card style={{ borderRadius: "10px" }}>
                <div className='content d-flex justify-content-between align-items-center'>
                  <div className={`${(changeBorder === 4) ? 'buttonColor' : ''} changeFont`}>
                  Product Management
                  </div>
                  {changeBorder === 4 ? <div>

                    <img src="/images/subscription red.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div> : <div>
                    <img src="/images/subscription black.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div>}

                </div>
              </Card>
            </Link>

          </div>
        </li> : ""}

        {/***********************Firm Subscription*********************************/}
         {(roleid == 11) ? <li className='list-group-item mt-1' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(11)}
            className={`${(changeBorder === 11) ? 'borderOnClick' : ''} sidebar`}>
            <Link to='../ProductManagement' style={{ textDecoration: "none" }} >
              <Card style={{ borderRadius: "10px" }}>
                <div className='content d-flex justify-content-between align-items-center'>
                  <div className={`${(changeBorder === 11) ? 'buttonColor' : ''} changeFont`}>
                    Product Management
                  </div>
                  {changeBorder === 11 ? <div>

                    <img src="/images/subscription red.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div> : <div>
                    <img src="/images/subscription black.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div>}

                </div>
              </Card>
            </Link>

          </div>
        </li> : ""} 

        {/*File Cabinet  */}

        <li className='list-group-item mt-1' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(7)}
            className={`${(changeBorder === 7) ? 'borderOnClick' : ''} sidebar`}>
            <Link to='../newFilecabinet' style={{ textDecoration: "none" }} >
              <Card style={{ borderRadius: "10px" }}>
                <div className='content d-flex justify-content-between align-items-center'>
                  <div className={`${(changeBorder === 7) ? 'buttonColor' : ''} changeFont`}>
                    File Cabinet
                  </div>
                  {
                    changeBorder === 7 ?
                      <div>
                        <img src="./icons/filedrawer.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                      </div> :
                      <div>
                        <img src="./icons/filedrawer.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                      </div>
                  }

                </div>
              </Card>
            </Link>

          </div>
        </li>


        {/* <li className='list-group-item mt-1' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(7)}
            className={`${(changeBorder === 7) ? 'borderOnClick' : ''} sidebar`}>
            <Link to='../Chat' style={{ textDecoration: "none" }} >
              <Card style={{ borderRadius: "10px" }}>
                <div className='content d-flex justify-content-between align-items-center'>
                  <div className={`${(changeBorder === 7) ? 'buttonColor' : ''} changeFont`}>
                    Chat
                  </div>
                  {
                    changeBorder === 7 ?
                      <div>
                        <img src="./icons/filedrawer.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                      </div> :
                      <div>
                        <img src="./icons/filedrawer.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                      </div>
                  }

                </div>
              </Card>
            </Link>

          </div>
        </li> */}

        {/* {(subtenantId !== 3) ? <li className='list-group-item mt-1' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(6)}
            className={`${(changeBorder === 6) ? 'borderOnClick' : ''} sidebar`}>
            <Link to='../Filecabinet' style={{ textDecoration: "none" }} >
              <Card style={{ borderRadius: "10px" }}>
                <div className='content d-flex justify-content-between align-items-center'>
                  <div className={`${(changeBorder === 6) ? 'buttonColor' : ''} changeFont`}>
                    File Cabinet
                  </div>
                  {
                    changeBorder === 6 ?
                      <div>
                        <img src="/images/dashboard-seminar-Maroon.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                      </div> :
                      <div>
                        <img src="/images/dashboard-seminar.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                      </div>
                  }

                </div>
              </Card>
            </Link>

          </div>
        </li> : ""} */}


        <li className='list-group-item mt-1' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(5)}
            className={`${(changeBorder === 5) ? 'borderOnClick' : ''} sidebar`}>
            <Link to='/Feedback' style={{ textDecoration: "none" }} >
              <Card style={{ borderRadius: "10px" }}>
                <div className='content d-flex justify-content-between align-items-center'>
                  <div className={`${(changeBorder === 5) ? 'buttonColor' : ''} changeFont`}>
                    Feedback
                  </div>
                  {changeBorder === 5 ? <div>
                    <img src="/images/feedback red.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div> : <div>
                    <img src="/images/feedback black.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div>}

                </div>
              </Card>
            </Link>

          </div>
        </li>
        {subtenantId == 3 && 
         <li className='list-group-item mt-1' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(12)}
            className={`${(changeBorder === 12) ? 'borderOnClick' : ''} sidebar`}>
            <Link to='/academyUsers' style={{ textDecoration: "none" }} >
              <Card style={{ borderRadius: "10px" }}>
                <div className='content d-flex justify-content-between align-items-center'>
                  <div className={`${(changeBorder === 12) ? 'buttonColor' : ''} changeFont`}>
                    Academy  Users
                  </div>
                  {changeBorder === 12 ? <div>
                    <img src="/images/aoRed.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div> : <div>
                    <img src="/images/AOoutlined.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div>}
                  </div>
              </Card>
            </Link>

          </div>
        </li>
        }
        
       


        {/*
        <li className='list-group-item mt-2' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(4)}
            className={`${(changeBorder === 4) ? 'borderOnClick' : ''} sidebar`}>
            <Card style={{ borderRadius: "10px" }}>
              <div className='content d-flex justify-content-between align-items-center'>
                <div className={`${(changeBorder === 4) ? 'buttonColor' : ''} changeFont`}>Agent Structure</div>
                <div>
                  {changeBorder === 4 ? <div>
                    <img src="/images/dashboard-agent-Maroon.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div> : <div>
                    <img src="/images/dashboard-agent.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div>}

                </div>
              </div>
            </Card>
          </div>
        </li>
        <li className='list-group-item mt-2' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(5)}
            className={`${(changeBorder === 5) ? 'borderOnClick' : ''} sidebar`}>
            <Card style={{ borderRadius: "10px" }}>
              <div className='content d-flex justify-content-between align-items-center'>
                <div className={`${(changeBorder === 5) ? 'buttonColor' : ''} changeFont`}>AORG Certified professionals</div>
                {changeBorder === 5 ? <div>
                  <img src="/images/dashboard-AORG-Maroon.svg" alt='Error' className='img-fluid img-thumbnail' style={{ width: "50px", height: "50px",border: "none" }} />
                </div> :
                  <div>
                    <img src="/images/dashboard-AORG.svg" alt='Error' className='img-fluid img-thumbnail' style={{ width: "50px", height: "50px",border: "none" }} />
                  </div>}
              </div>
            </Card>
          </div>
        </li> */}

        {/* <li className='list-group-item mt-2' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(6)}
            className={`${(changeBorder === 6) ? 'borderOnClick' : ''} sidebar`}>
            <Card style={{ borderRadius: "10px" }}>
              <div className='content d-flex justify-content-between align-items-center'>
                <div className={`${(changeBorder === 6) ? 'buttonColor' : ''} changeFont`}>Manage Subscription </div>
                {changeBorder === 6 ? <div>
                  <img src="/images/manage-subscription-Maroon.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                </div> :
                  <div>
                    <img src="/images/manage-subscription.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div>}

              </div>
            </Card>
          </div>
        </li> */}

        {/* <li className='list-group-item mt-2' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(7)}
            className={`${(changeBorder === 7) ? 'borderOnClick' : ''} sidebar`}>
            <Card style={{ borderRadius: "10px" }}>
              <div className='content d-flex justify-content-between align-items-center'>
                <div className={`${(changeBorder === 7) ? 'buttonColor' : ''} changeFont`}>Raw Data Uplode</div>
                {changeBorder === 7 ? <div>
                  <img src="/images/Upload-Files-Maroon.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                </div> :
                  <div>
                    <img src="/images/dashboard-Upload-Files.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div>
                }

              </div>
            </Card>
          </div>
        </li>  */}

        {/* <li className='list-group-item mt-2' style={{ border: "none" }}>
          <div onClick={() => changeBorderColor(8)}
            className={`${(changeBorder === 8) ? 'borderOnClick' : ''} sidebar`}>
            <Card style={{ borderRadius: "10px" }}>
              <div className='content d-flex justify-content-between align-items-center'>
                <div className={`${(changeBorder === 8) ? 'buttonColor' : ''} changeFont`}>Meta Data</div>
                {changeBorder === 8 ?
                  <div>
                    <img src="/images/dashboard-report-Maroon.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div> :
                  <div>
                    <img src="/images/dashboard-report.svg" className='img-fluid img-thumbnail' style={{ width: "40px", height: "40px", border: "none" }} />
                  </div>}

              </div>
            </Card>
          </div>
        </li> */}
      </ul>
      </div>
     
    </Menu >
  )
}


export default Sidemenu