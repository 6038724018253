import React, { useState, useContext, useEffect } from 'react';
import { Space, Table, Tag, Input, Button, Row, Col, Modal ,message, Form, Tooltip} from "antd";
import { UserContext } from "../../App";
import Services from '../../network/Services';
import DeleteConfirmModal from "../DeleteConfirmModal";
import konsole from "../../network/konsole";
import EditModal from './EditModal';

const SearchByEmail = () => {
    const [selectedUserDetails, setSelectedUserDetails] = useState({});
    let lastSearchText = "";

    const [userList, setuserList] = useState();
    const [curMail, setCurMail] = useState('');
    const { setLoader } = useContext(UserContext);

    const [showDeleteModal , setShowDeleteModal] = useState(false)
    const [userDataToDelete , setUserDataToDelete] = useState()
    const [deleteModal , setDeleteModal] = useState(false)
    const [loggedInUserId, setloggedInUserId] = useState("");

    const deleteData = (data) =>{
        konsole.log("Clicked", data)
        setUserDataToDelete(data)
        setShowDeleteModal(true)
    }

    const CallDeleteAPi = () =>{
        konsole.log("deleteModaldeleteModal",deleteModal)
    
        setLoader(true)
    
        let detetedBy = sessionStorage.getItem('loggedUserId')
        let promise = Services.deletePartnerUserDetails(userDataToDelete?.userId, userDataToDelete?.id, detetedBy)
        promise.then((response)=>{
          message.success("User Deleted Successfully")
          setShowDeleteModal(false)
          setDeleteModal(false)
          setuserList(userList.filter(data => { return data.userId !== userDataToDelete?.userId}));
          konsole.log("deletePartnerUserDetailsRes",response)
        }).catch((error)=>{
          konsole.log("deletePartnerUserDetailsError",error)
        }).finally(()=>{
          konsole.log("deletePartnerUserDetailsEnd")
          setLoader(false)
        })
    }

    useEffect(()=>{
        if(deleteModal == true){
            CallDeleteAPi()
        }
    },[deleteModal])

    useEffect(()=>{
        const _userId = sessionStorage.getItem('loggedUserId');
        setloggedInUserId(_userId);
    },[])

    const refreshData = ( needToUpdate ) => {
        if(needToUpdate) onSearch(lastSearchText);
        setSelectedUserDetails({});
    }

    const getPrimaryContactId = (data) => {        
        for(let i=0; i<data.length; i++) if(data[i].contactTypeId === 1) return data[i].contactId;
        return null;
    }

    const editData = (data) => {
        setLoader(true);
        // getting contacts
        Services.getContactDetails(data.userId).then((res) => {
            data.primaryContactId = getPrimaryContactId(res.data.data?.contact?.mobiles);
            data.mobileNo = data.mobileNo?.replace(/\s|\-|\(|\)/g, "") // removing phone no. formating
            setSelectedUserDetails(data)
            konsole.log("kyu pcid", data.primaryContactId);
        }).catch((err) => {
            konsole.log("kyu err", err);
        }).finally(() => {
            konsole.log("kyu contactfetched", data);
            setLoader(false);
        })
    }

    const formatResults = (result) => {
        // formatting roles
        let roles = '';
        // let setOfRole = new Set(result.roleDetails.map(item => item.roleName)); // for removing duplicate entries
        result.roleDetails.forEach(role => {
            roles += role.roleName + ", ";
        });
        result.roleKey = roles.substring(0, roles.length - 2);

        // formatting createdON
        result.createdOn = result.createdOn ? formatDate(result.createdOn) : null;

        // formatting loginDate
        result.loginDate = result.loginDate ? formatDate(result.loginDate) : null;

        // formatting mobile no.
        result.mobileNo = result.mobileNo ? formatMobileNo(result.mobileNo) : null;

        return result;
    }

    const formatDate = (date) => {
        // YYYY-MM-DD to MM-DD-YYYY
        let resultDate = date.substring(0, 10).split('-');
        return resultDate[1]+"-"+resultDate[2]+"-"+resultDate[0];
    }

    const formatMobileNo = (mobileNo) => {
        let resultMobileNo = mobileNo;
        if(mobileNo?.length == 10 && mobileNo?.startsWith("+") == false) {
            mobileNo = "+1" + mobileNo;
        }
        if(mobileNo.substring(0,2) === "+1") { // US format +1 (xxx) xxx-xxxx
            resultMobileNo = mobileNo.substring(0, 2) + " (" + mobileNo.substring(2,5) + ") " + mobileNo.substring(5, 8) + "-" + mobileNo.substring(8);
        } else if(mobileNo.substring(0,3) === "+91") { // IN format +91 xxxxx-xxxxx
            resultMobileNo = mobileNo.substring(0,3) + " " + mobileNo.substring(3,8) + "-" + mobileNo.substring(8);
        }
        return resultMobileNo;
    }

    const onSearch = (value) => {
        let searchMail = value.length ? value : curMail;
        lastSearchText = searchMail;
        if(searchMail.length < 1) return;
        setLoader(true);
        Services.getUserByEmail(searchMail).then((res) => {
            let results = res.data.map(result => {return formatResults(result)});
            konsole.log(res, results)
            konsole.log("From Know Your User", results);
            setuserList(results);
        }).catch((err) => {
            setuserList([]);
            konsole.log("err", err);
        }).finally(()=>{
            konsole.log("Finish")
            setLoader(false)
        })
    };

    const handleHandOff = (userId) => {
        // userId = "962d83f2-b973-4f27-9524-xxxxxaxxxxxx"; // for safe in testing
        setLoader(true)
        Services.putClientHandOff(userId).then((res) => {
            konsole.log("ress", res)
            message.success("Hand off revoked successfully!");
        }).catch((err) =>  {
            konsole.log("err", err)
            message.warning("Something went wrong!");
        }).finally(() => {
            onSearch("")
            // setLoader(false)
        })
    }

    const isHandOffRevokePossible = (record) => {
        const isRoleValid = record?.roleDetails?.some(ele => ele.roleId == 1 || ele.roleId == 9 || ele.roleId == 10) == true;
        const isHandOff = record.isHandOff == true;

        return isRoleValid && isHandOff;
    }

    return (
        <>
        <EditModal selectedUserDetails={selectedUserDetails} refreshData={refreshData} />
        <div className="d-flex flex-column">
            <div className="select-container">
                <p className=" fs-4 mb-2" style={{marginTop:"5px"}}>
                Search your User by Email Address
                </p>
            </div>
            <Form onFinish={onSearch} >
                <Row className="">
                    <Col
                    xs={24}
                    lg={24}
                    span={24}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    >
                        <Col span={12} style={{ display: "flex" }}>
                            <div className=" d-flex" style={{border:"2px solid lightgray",borderRadius:"5px", justifyContent:"space-between",height:"39px"}}>
                                <Input
                                className="form-control fs-20"
                                style={{border:"none", width: "300px", display: "flex"}}
                                // type="email"
                                placeholder="Enter User Email Address Or User Name"
                                onChange={(e) => setCurMail(e.target.value)}
                                suffix={
                                    <img
                                    onClick={onSearch}
                                    type="submit"
                                    style={{padding:"2px",height:"20px"}}
                                    src="/search icon.png"
                                    />
                                }
                                />
                            </div>
                        </Col>
                    </Col>
                </Row>
            </Form>
            {
              showDeleteModal ? <DeleteConfirmModal visible={showDeleteModal} hideModal={setShowDeleteModal} titleText="Delete User" bodyContent="Do you want to delete this user?" deleteStatus={setDeleteModal} /> : ""
            }
            {userList ? <Row>
                <Col xs={24} className='select-container'>
                    <Table
                        dataSource={userList}
                        pagination={false}
                        className=" mt-3 flex-grow-1 subtanantTable"
                        style={{
                        maxHeight: 'calc(100vh - 290px)',
                        overflowY: 'auto'
                        }} 
                    
                    >

                        <Row
                            title="Subtenant"
                            className="manage fw-bold p-2 shadow-sm text-center "
                            dataIndex="subtenantName"
                            key="subtenantName"
                        />

                        <Row
                            title="Name"
                            className="manage fw-bold p-3  shadow-sm"
                            dataIndex="userFullName"
                            key="userFullName"
                            render={(_, record) =>(
                                // konsole.log(record)
                                <a
                                // onClick={()=>updatePartnerUsers(record)}
                                >
                                {record.userFullName}
                                </a>
                            )}
                        />

                        <Row
                            title="Email"
                            className="manage fw-bold p-2 shadow-sm text-center "
                            dataIndex="emailId"
                            key="emailId"
                        />

                        <Row
                            title="Phone No."
                            className="manage fw-bold p-2 shadow-sm text-center mw-140px"
                            dataIndex="mobileNo"
                            key="mobileNo"
                        />

                        <Row
                            title="Role"
                            className="manage fw-bold p-2 shadow-sm text-center mw-180px"
                            dataIndex="roleKey"
                            key="roleKey"
                        />

                        <Row
                            title="Registered On"
                            className="manage fw-bold  p-2 border-top-0 border-buttom-0 border-start-0 shadow-sm text-center border-gray mw-100px"
                            dataIndex="createdOn"
                            key="createdOn"
                        />

                        <Row
                            title="Last Login Date"
                            className="manage fw-bold  p-2 border-top-0 border-buttom-0 border-start-0 shadow-sm text-center border-gray mw-100px"
                            dataIndex="loginDate"
                            key="loginDate"
                            render={(_, record) =>(
                            <>
                                {record.loginDate ?
                                record.loginDate
                                :
                                <p style={{color: "orange", cursor: "pointer",marginTop:"18px"}}>Inactive</p>}
                            </>
                            )}
                        />

                        <Row
                            title="Status"
                            className="manage fw-bold  p-2 border-top-0 border-buttom-0 border-start-0 shadow-sm text-center border-gray"
                            dataIndex="isEmailVerified"
                            key="isEmailVerified"
                            render={(_, record) =>(
                            <>
                                {record.isEmailVerified && record.isActive ?
                                <p style={{color: "green",marginTop:"18px"}}>Active</p>
                                :
                                <p style={{color: "orange", cursor: "pointer",marginTop:"18px"}}>Inactive</p>}
                            </>
                            )}
                        />

                        <Row
                            className="manage fw-bold  shadow-sm "
                            gutter={[8,8]}
                            title="Action"
                            key="action"
                            render={(_, record) => {
                                return (
                                <div xs={24} className="col mw-100px justify-content-center py-2" style={{minWidth: "140px"}}>
                                {loggedInUserId != record.userId && (
                                <div className='row'>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col' xs={6} sm={6} xl={6}>
                                                <a className="text-center text-decoration-none text-black"
                                                style={{marginLeft:"10px"}}
                                                onClick={()=>editData(record)}
                                                >
                                                    {" "}
                                                    <img src="/images/vector.png"></img>
                                                    <br />
                                                    Edit 
                                                </a>
                                            </div>
                                            <div className='col' xs={6} sm={6} xl={6}>
                                                <a className="text-center text-decoration-none text-black"
                                                style={{marginRight:"10px"}}
                                                onClick={()=>deleteData(record)}
                                                >
                                                    {" "}
                                                    <img src="/images/vector.png"></img>
                                                    <br />
                                                    Delete
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                                </div>
                                )}
                            }
                            style={{ width: "10px" }}
                        />

                        <Row
                            className="manage fw-bold  shadow-sm "
                            gutter={[8,8]}
                            title="Hand Off"
                            key="handOff"
                            render={(_, record) => {
                                const _isHandOffRevokePossible = isHandOffRevokePossible(record);
                                return (
                                <div xs={24} className="col justify-content-center" style={{width: "100px"}}>
                                {loggedInUserId != record.userId && (
                                <div className={`row ${_isHandOffRevokePossible != true ? "disableDiv" : ""}`} title={_isHandOffRevokePossible != true ? "Admin can't hand off" : undefined}>
                                    {/* <div className='col-8'>Hand off</div> */}
                                    <div className=" col-2 w-75 form-check form-switch form-toogle-switch">
                                        <input className="form-check-input w-100 mx-2 shadow-none" style={{opacity: '1', cursor: "pointer"}} type="checkbox" id="flexSwitchCheckChecked" checked={record.isHandOff == true} disabled={_isHandOffRevokePossible != true} onClick={() => handleHandOff(record.userId)} />
                                    </div>
                                </div>)}
                                </div>
                                )}
                            }
                            style={{ width: "10px" }}
                        />
                    </Table>
                </Col>
            </Row>
            :
            <div style={{display: 'flex', paddingLeft: '10px'}}>
                <img style={{display: 'inline-block', width: '25px', height: '20px'}} src='/icons/arrowIcon.svg' /> 
                <p>Please use search bar to get your user details</p>
            </div>}
        </div>
        </>
    )
}

export default SearchByEmail;