import React, { useEffect,useState,useContext } from 'react';
import {Row, Col} from 'react-bootstrap'
import PackageSubscriptionBoxes from './PackageSubscriptionBoxes';
import Services from '../../network/Services';
import { UserContext } from '../../App';
import konsole from '../../network/konsole';

function PackageManagementStructure(){
    const [productsList, setProductsList] = useState([])
    const [productGrpsList, setProductGrpsList] = useState([])
    const [packageList, setPackageList] = useState([])
    const [noPackageExists, setNoPackageExists] = useState(false)
    const { setLoader } = useContext(UserContext);
    const subtenantID = sessionStorage.getItem("subtenantId")
    const loginUserEmail = JSON.parse(sessionStorage.getItem("userLoggedInDetail"))?.primaryEmailId
    const loginUserID = sessionStorage.getItem("loggedUserId")


    useEffect(()=>{
      getUserDetails()
      // rateCards("",subtenantID)
    },[])

    const getUserDetails = async() =>{
      konsole.log("loginUserEmail22",loginUserEmail)
      let checkRollId;
      await Services.getUserByEmail(loginUserEmail)
      .then((res) => {
        konsole.log("loginUserEmail",res.data)
        if(res.data[0]?.subtenantId == 3){
          checkRollId = res.data[0].roleDetails.find(item => item.roleId == 11)?.roleId
        }
        else{
          rateCards("", subtenantID)   
        } 
      }).catch((err) => {
        konsole.log("err", err);
      })
      .finally(() => {
        rateCards("", subtenantID,checkRollId);
    });
    }
    
    const rateCards = (rateCardID,subtenantID,checkRollId) => {
      setLoader(true);
      const rateCardId = rateCardID ?? ''
      const promise = Services.getRateCard(rateCardId,'',subtenantID,true)
      promise.then((res)=>{
        setLoader(false);
        konsole.log("ressRateCards",res.data.data,subtenantID)
        const responseData = res.data.data;
        // setRateCardsData(responseData)
        services(responseData,checkRollId);
        getGroupedService(responseData,checkRollId)
        SkuList(null,responseData,checkRollId);
      }).catch((err)=>{
        konsole.log("errr",err)
        setLoader(false);
      })
    } 
  
      const services = (rateCardData,checkRollId) => {
        konsole.log("checkRollId",checkRollId)
        setLoader(true);
        const promises = Services.indServices(true,subtenantID);
        promises
          .then((res) => {
            setLoader(false);
            const prodResponse = res.data.data
            const filterAssignedProd = prodResponse.filter(item1 => rateCardData.some(item2 =>item2.skuListName == `INDPKG_${item1.serviceName}`))
            konsole.log("resresres", res.data.data,rateCardData,filterAssignedProd);
            if(checkRollId == 11){
              for(const item of filterAssignedProd){
                if(item.createdBy == loginUserID){
                  item["roleId"] = checkRollId
                }
                else{
                  item["roleId"] = null
                }
              }
            }
            const sortDataToShowSuperAdProdFirst = sortToShowSuperAdFirst(filterAssignedProd)
            setProductsList(sortDataToShowSuperAdProdFirst);
            insertEndSubsAmnt(rateCardData,sortDataToShowSuperAdProdFirst,setProductsList)
          })
          .catch((err) => {
            konsole.log("errrProd", err);
            setLoader(false);
          });
      };
    
      const getGroupedService = (rateCardData,checkRollId) => {
        const promise = Services.groupedService(true,subtenantID);
        promise
          .then((res) => {
            const prodGrpResponse = res.data.data
            const filterAssignedProdGrp = prodGrpResponse.filter(item1 => rateCardData.some(item2 =>item2.skuListName == `GRPPKG_${item1.serviceGrpName}`))
            if(checkRollId == 11){
              for(const item of filterAssignedProdGrp){
                if(item.createdBy == loginUserID){
                  item["roleId"] = checkRollId
                }
                else{
                  item["roleId"] = null
                }
              }
            }
            const sortDataToShowSuperAdProdGrpFirst = sortToShowSuperAdFirst(filterAssignedProdGrp)
            konsole.log("grpRes", res.data.data,rateCardData,filterAssignedProdGrp);
            setProductGrpsList(sortDataToShowSuperAdProdGrpFirst);
            insertEndSubsAmnt(rateCardData,sortDataToShowSuperAdProdGrpFirst,setProductGrpsList)
          })
          .catch((err) => {
            konsole.log("error", err);
            setLoader(false);
          });
      };

      const SkuList = (skuListId,rateCardData,checkRollId) => {
        setLoader(true);
        const promises = Services.getSkuList(skuListId, true,subtenantID);
        promises
          .then((res) => {
            setLoader(false);
            konsole.log("resp", res);
            if(res.data.data?.length > 0){
              const filterOnlyIsVisibleData = res.data.data.filter(item => item?.isVisible == true)
              const filterAssignedPack = filterOnlyIsVisibleData.filter(item1 => rateCardData.some(item2 =>item2.skuListName == item1.skuListName))
              if(checkRollId == 11){
                for(const item of filterAssignedPack){
                  if(item.createdBy == loginUserID){
                    item["roleId"] = checkRollId
                  }
                  else{
                    item["roleId"] = null
                  }
                }
              }
              const sortDataToShowSuperAdPackFirst = sortToShowSuperAdFirst(filterAssignedPack)
              setPackageList(sortDataToShowSuperAdPackFirst);
              insertEndSubsAmnt(rateCardData,sortDataToShowSuperAdPackFirst,setPackageList)
            }
            else{
              setNoPackageExists(true)
            }
          })
          .catch((err) => {
            setLoader(false);
            konsole.log("erroeee1212", err);
          });
      };

      const insertEndSubsAmnt = (rateCardData,productsData, setState) => {
        for (const item of rateCardData) {
            if ((item.skuListName.includes("INDPKG_") || item.skuListName.includes("GRPPKG_")) || item.skuListName) {
                const productName = item.skuListName.split(item.skuListName.includes("INDPKG_") ? "INDPKG_" : "GRPPKG_").pop() ?? item.skuListName;
                for (const det of productsData) {
                    if (det.serviceName === productName || det.serviceGrpName === productName || det.skuListName === productName) {
                        console.log("detssss", det);
                        for (const price of item.applicableSubsModel) {
                            det["endSubscriptionAmt"] = price.endSubscriptionAmt;
                            det["skuSubsModelId"] = price.skuSubsModelId;
                            for(const subtRateCardId of price.subtenantRateCard){
                              det["subtenantRateCardId"] = subtRateCardId?.subtenantRateCardId
                              det["isActive"] = subtRateCardId?.subIsActive
                            }                            
                        }
                    }
                }
            }
        }
        const filterActiveProducts = productsData?.filter(item => item.isActive == true)
        setState(filterActiveProducts);
    };

    const sortToShowSuperAdFirst = (responseData) =>{
      const array = responseData
      if(array?.length > 0){
        return(
          array.sort((a,b)=> a.subtenantId == 3 ? -1 : b.subtenantId == 3 ? 1 : 0)
        )
      }
      else{
        return null;
      }
    }

    return(
        <Row className="d-flex mt-3 w-100">
            <Col lg={4}>
            <PackageSubscriptionBoxes addedList = {productsList} key={productsList} productsList = {productsList} heading = "Product" getUserDetails = {getUserDetails}/>
            </Col>
            <Col lg={4}>
            <PackageSubscriptionBoxes addedList = {productGrpsList} productsList = {productsList} key={productGrpsList} productGrpsList = {productGrpsList} heading = "Product Group" getUserDetails = {getUserDetails}/>
            </Col>
            <Col lg={4}>
            <PackageSubscriptionBoxes addedList = {packageList} noPackageExists = {noPackageExists} productsList = {productsList} productGrpsList = {productGrpsList} key={packageList} heading = "Package" getUserDetails = {getUserDetails}/>
            </Col>
        </Row>
    )
}
export default PackageManagementStructure;