import React, { useEffect } from "react";
import { Row, Col, Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import konsole from "../network/konsole";
import { ModalBody } from 'react-bootstrap';

const SendEmailModal = (props) =>{

  useEffect(()=>{
    console.log("previewTemplateData",props?.previewTemplateData)
    document.getElementById("templateData").innerHTML=props?.previewTemplateData
  },[])

    const onCancel = () => {
      props.sendEmailStauts(false)
   props.cancelMOdal(false)
    };

    const submitData = () => {
   props.sendEmailStauts(true)
    };




    return(
        <>

<Modal visible={true} onCancel={onCancel} borderRadius={20} closable={false} footer={false} maskClosable={false}>
        <ModalBody >
         <div  style={{ maxHeight: 'calc(100vh - 226px)', overflowY: 'auto',overflowX:'hidden' }}>
        <div
          style={{
            // border: "1px solid #720c20",
            width: "auto",
             margin: "1px",
            // backgroundColor: "#720c20",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            
          }}
          className="rounded-top"
        >
          <div className=""></div>
          {/* <div className="fs-5">{props?.titleText}</div> */}

          <div className="me-2 mt-1" style={{cursor: "pointer",color:'#720C20',fontWeight:'700'}}  onClick={onCancel}>X</div>
        </div>

        <br />
        <br />

        <div className="text-center w-auto" id="templateData">
          
        </div>
        </div>

        <Row className="justify-content-between">
          <Col className="">
            <Button
              style={{ backgroundColor: "white", color: "#720C20", border: "2px solid #720c20", width: "150%", borderRadius: "5px",padding:"4px 10px"}} onClick={onCancel}>
            Cancel</Button>
          </Col>
          <Col className="me-4">
            <Button
              style={{ backgroundColor: "#720c20", color: "white", border: "none", width: "150%", borderRadius: "5px"}}
              onClick={submitData}
            >Send</Button>
          </Col>
        </Row>
        </ModalBody>
      </Modal>
        
        </>
    )
}

export default SendEmailModal;