import React, { useEffect, useState, useContext, createContext } from 'react';
import { Row, Col } from 'react-bootstrap'
import Services from '../../network/Services';
import konsole from '../../network/konsole';
import './FileCabinet.css'
import ManageAllFile from './ManageAllFile';
import commonLib from '../../control/commonLib';
import { message } from 'antd'
import { UserContext } from '../../App';
import { cabinetFileCabinetId } from '../../control/Constant';
//  cratee context for file cabinet--------------
export const FileCabinetContext = createContext({});

const ParentCabinet = ({ belongsScreen }) => {
    konsole.log('belongsScreenbelongsScreen', belongsScreen)

    const { setLoader } = useContext(UserContext)
    const stateObj = commonLib.getObjFromStorage("stateObj");
    const subtenantId = commonLib.getSessionStoarge("subtenantId");
    // state for global context----------------------------------------------------------------------
    const [allMainCabinetContainer, setAllMainCabinetContainer] = useState([])
    const [showScreenType, setShowScreenType] = useState('')
    const [manageShowFileType, setManageShowFileType] = useState('')
    const [cabinetList, setCabinetList] = useState([])
    const [fileInformation, setFileInformation] = useState([])
    const [categoryTypeListCheckMapping, setCategoryTypeListCheckMapping] = useState([])

    const [showAddEditFolder, setShowAddEditFolder] = useState('')

    //  ------------------------update multi cabinet-------------------------------------------------------------------------------
    const [myAllCabinetList, setMyAllCabinetList] = useState([])
    const [selectedCabinet, setSelectedCabinet] = useState([])

    const [mappingSubtenantInfo, setMappingSubtenantInfo] = useState([])
    const [allPartnerAdminDetails, setAllPartnerAdminDetails] = useState([])

    //  ------------------------update multi cabinet-------------------------------------------------------------------------------

    const globalValue = {
        allMainCabinetContainer: allMainCabinetContainer,
        setAllMainCabinetContainer: setAllMainCabinetContainer,
        showScreenType: showScreenType,
        setShowScreenType: setShowScreenType,
        manageShowFileType: manageShowFileType,
        setManageShowFileType: setManageShowFileType,
        cabinetList: cabinetList,
        fileInformation: fileInformation,
        categoryTypeListCheckMapping: categoryTypeListCheckMapping,
        myAllCabinetList: myAllCabinetList,
        setSelectedCabinet: setSelectedCabinet,
        selectedCabinet: selectedCabinet,
        showAddEditFolder: showAddEditFolder,
        setShowAddEditFolder: setShowAddEditFolder,
        belongsScreen: belongsScreen,
        mappingSubtenantInfo: mappingSubtenantInfo,
        allPartnerAdminDetails: allPartnerAdminDetails

    }


    // define state ---------------------------------------------------------------------------------
    konsole.log('myAllCabinetListmyAllCabinetListmyAllCabinetList', myAllCabinetList)
    konsole.log('selectedCabinetselectedCabinet', selectedCabinet)
    // useEffect ------------------------------------------------------------------------------------
    useEffect(() => {
        const loggedUserId = sessionStorage.getItem('loggedUserId')
        if (belongsScreen !== 'Firmpartner') {
            apiCallGetMainCabinetContainer()
        } else {
            callApiGetCabinetCategoryForCheckMapping()
            callApigetAllSubtenantDetails()
        }
        getFileFromCabinet(loggedUserId)
    }, [])

    const callApigetAllSubtenantDetails = () => {
        setLoader(true)
        Services.getAllSubtenantDetails({ isActive: true }).then((res) => {
            setLoader(false)
            konsole.log('res of all subtenants', res)
            setAllPartnerAdminDetails(res?.data?.data)
        }).catch((err) => {
            setLoader(false)
            konsole.log('err in all subtenants fetch', err)
        })
    }

    function callApiGetCabinetCategoryForCheckMapping() {
        let postJson = { "roleId": 11 }
        setLoader(true)
        Services.getCabinetCategoryType(postJson).then((res) => {
            konsole.log('callApiGetCabinetCategoryForCheckMapping', res)
            setLoader(false)
            let responseData = res?.data?.data
            const uniqueValues = new Set();

            // Filter the array and retain only unique values
            const uniqueArray = responseData.filter(item => {
                if (!uniqueValues.has(item.subtenantId)) {
                    uniqueValues.add(item.subtenantId);
                    return true;
                }
                return false;
            });
            setMappingSubtenantInfo(uniqueArray)
            konsole.log('responseDataresponseData', uniqueArray, responseData)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            konsole.log('callApiGetCabinetCategoryForCheckMapping', err)
        })
    }
    //  get all file ------------------------------------------------------------------------------
    const getFileFromCabinet = (primaryMemberUserId) => {
        Services.getFileFromCabinet(primaryMemberUserId).then((res) => {
            konsole.log('tes of getting file from cabinet', res)
            setFileInformation(res.data.data)
        }).catch((err) => {
            konsole.log('err in getting file ', err, err?.response)
        })
    }

    konsole.log('allMainCabinetContainer', allMainCabinetContainer)
    //  api for Main cabinet--------------------------------------------------------------------------------------------------------------------------------------------------
    function apiCallGetMainCabinetContainer(partnerSubtenantId,partnerRoleId) {
        konsole.log('apiCallGetMainCabinetContainer',partnerSubtenantId,partnerRoleId)
        setLoader(true)
        let jsonObj = { isActive: true, subtenantId:partnerSubtenantId || subtenantId, roleId:partnerRoleId || stateObj.roleId, belongsScreen: belongsScreen }
        Services.getMainCabinetContainer({ ...jsonObj }).then((res) => {
            konsole.log('res of main cabinet', res)
            let resData = res?.data?.data?.filter(({cabinetId})=>cabinetId==cabinetFileCabinetId[0])
            // let resData = res?.data?.data
            konsole.log('resDataresDataresData',resData)
            setMyAllCabinetList(resData)
            setLoader(false)

            // let responseData = res?.data?.data?.filter(({ cabinetId }) => cabinetId == cabinetFileCabinetId[0])
            // konsole.log('responseDataresponseData', responseData)
            // if (responseData.length > 0 && responseData[0].fileCategoryTypeMappingList.length > 0) {
                //     let categoryTypeList = responseData[0].fileCategoryTypeMappingList[0].fileCategoryId
                //     konsole.log('categoryTypeListcategoryTypeList', categoryTypeList)
                //     let postJson = { "fileCategoryId": categoryTypeList }
                //     callApiGetCabinetCategory(postJson)
            // }

            // konsole.log('responseDataresponseData', responseData)
            // setCabinetList(responseData)
            // if (responseData.length > 0 && responseData[0].fileCategoryTypeMappingList.length > 0) {
            //     for (let [index, item] of responseData[0].fileCategoryTypeMappingList.entries()) {
            //         responseData[0].fileCategoryTypeMappingList[index].fileCabinetFolderList = createNestedArray(item.fileCabinetFolderList)
            //     }
            // }
            // konsole.log('categoryListcategoryList', categoryList)
            // setAllMainCabinetContainer(responseData)
        }).catch((err) => {
            konsole.log('errerrerr', err)
            setLoader(false)
            konsole.log('err in main cabinet', err)
        })
    }


    function apiCallUpsertMainCabinet(postJson, msg) {
        setLoader(true)
        konsole.log('createCabinetObjJson', postJson)
        Services.upsertMainCabinet(postJson).then((res) => {
            konsole.log('res of add cabinet', res)
            setLoader(false)
            apiCallGetMainCabinetContainer()
            setShowScreenType('')
            message.success(msg)
            // window.location.reload()

            reloadScreen()
        }).catch((err) => {
            setLoader(false)
            konsole.log('err in add update new cabnet', err)
        })
    }

    // api call for  category--------------------------------------------------------------------
    function callApiGetCabinetCategory(categoryTypeList) {
        let postJson = { "fileCategoryId": categoryTypeList }
        if (categoryTypeList == null) return setCategoryTypeListCheckMapping([])
        setLoader(true)
        Services.getCabinetCategoryType(postJson).then((res) => {
            konsole.log('res of get filecabinet category', res)
            setLoader(false)
            let responseData = res?.data?.data.length > 0 ? res?.data?.data?.filter(({ roleId }) => roleId != 4) : []
            setCategoryTypeListCheckMapping(responseData)
        }).catch((err) => {
            setLoader(false)
            konsole.log('err in getting file category type', err)
        })
    }
    function callApiCabinetCategory(postJson, type) {
        konsole.log('postJson', postJson)
        setLoader(true)
        Services.upsertCabinetCategoryType(postJson).then((res) => {
            konsole.log('res of file category', res)
            setLoader(false)
            // let newCategory = res?.data?.data[0]
            // let newAllList = [...allMainCabinetContainer]
            // if (type == 'ADD') {
            //     let findIndex = newAllList.findIndex(({ cabinetId }) => cabinetId == newCategory.categoryCabinetId)
            //     newAllList[findIndex].fileCategoryTypeMappingList = [...newAllList[findIndex].fileCategoryTypeMappingList, newCategory]
            // }
            // setAllMainCabinetContainer(newAllList)
            setManageShowFileType('')
            message.success(`Drawer ${type} successfully`)
            apiCallGetMainCabinetContainer()

            reloadScreen()
            // window.location.reload()
        }).catch((err) => {
            setLoader(false)
            konsole.log('err of category', err)
        })
    }

    // api call for folder--------------------------------------------------------

    function callApiCabinetFolder(postJson, msg) {
        setLoader(true)
        console.log('postJson', postJson)
        Services.upsertCabinetFolder(postJson).then((response) => {
            konsole.log('response of adding updating and deleting folder', response)
            apiCallGetMainCabinetContainer()
            setManageShowFileType('')
            setShowScreenType('')
            message.success(msg)
            setLoader(false)
            reloadScreen()
        }).catch(err => {
            setLoader(false)
            konsole.log('response of adding updating and deleting folder', err)
        })
    }


    const mapingTile = () => {
        setManageShowFileType('')
        setShowScreenType('showManageCabinetMapping')

    }
    const manageFileCabinetFun = () => {
        setShowScreenType('showManageFileCabinetScreen')
        setShowAddEditFolder('')
    }

    const reloadScreen = () => {
        window.location.reload()
    }

    konsole.log('categoryTypeListCheckMapping', categoryTypeListCheckMapping)
    konsole.log('showScreenTypeshowScreenTypeshowScreenType', showScreenType)
    return (
        <FileCabinetContext.Provider value={globalValue}>
            <>
                <Row>
                    <Col span={24} >
                        <Row>
                            { }
                            {stateObj.roleId == 4 && belongsScreen !== 'Firmpartner' && <>
                                <Col lg="2">
                                    <Row className="d-flex justify-content-between w-100">
                                        <Col className='d-flex justify-content-center'><h4>Page Tools</h4></Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <div className='flex-row-sm'>
                                            {/* <div className='file-tool-div' onClick={() => setShowScreenType('showManageFileCabinetScreen')}   > */}
                                            <div className='file-tool-div' onClick={() => manageFileCabinetFun()}   >
                                                <div className='file-image-div'>
                                                    <img src={showScreenType == 'showManageFileCabinetScreen' ? "./maroonfilemanagecabinet.png" : "./manage-file-cabinet1.png"} className='cabinetIconWidth' />
                                                </div>
                                                <div className='file-text-div'>
                                                    <h6 className={showScreenType == 'showManageFileCabinetScreen' ? "filetool-text-tag-h6" : ""}>Manage File Cabinet</h6>
                                                </div>
                                            </div>
                                            <div>
                                            </div>

                                            {/* {stateObj.roleId == 4 && allMainCabinetContainer.length !== 0 && */}
                                            {stateObj.roleId == 4 && myAllCabinetList.length !== 0 &&
                                                <div className='file-tool-div mt-2' onClick={() => mapingTile()}>
                                                    <div className='file-image-div'>
                                                        <img src={showScreenType == 'showManageCabinetMapping' ? "./maroonfilemanagecabinet.png" : "./manage-file-cabinet1.png"} className='cabinetIconWidth' />
                                                    </div>
                                                    <div className='file-text-div'>
                                                        <h6 className={showScreenType == 'showManageCabinetMapping' ? "filetool-text-tag-h6" : ""}>Assign Cabinet</h6>
                                                    </div>
                                                </div>}
                                        </div>
                                    </Row>
                                </Col>
                            </>}

                            <Col lg={`${stateObj.roleId == 4  &&  belongsScreen !== 'Firmpartner'? '10' : '12'}`}>
                                {/* <ManageAlFile callFileCabinetApi={callFileCabinetApi} callFileCabinetFolder={callFileCabinetFolder} callApiMainCabinet={callApiMainCabinet} key={manageFileCabinetMidScreen} /> */}
                                <ManageAllFile
                                    apiCallUpsertMainCabinet={apiCallUpsertMainCabinet}
                                    callApiCabinetCategory={callApiCabinetCategory}
                                    callApiCabinetFolder={callApiCabinetFolder}
                                    callApiGetCabinetCategory={callApiGetCabinetCategory}
                                    key={allMainCabinetContainer}
                                    apiCallGetMainCabinetContainer={apiCallGetMainCabinetContainer}
                                />
                            </Col>

                        </Row>
                    </Col>
                </Row>

            </>
        </FileCabinetContext.Provider>
    )
}


function createNestedArray(folders) {
    konsole.log('folders', folders)
    const nestedArray = [];

    function buildNestedArray(parentId, level) {
        const folder = folders.filter(folder => folder.parentFolderId === parentId);

        if (folder.length === 0) {
            return [];
        }

        const nestedChildren = folder.map(child => {
            return {
                ...child,
                fileCabinetFolderList: buildNestedArray(child.folderId, level + 1)
            };
        });

        return nestedChildren;
    }

    nestedArray.push(...buildNestedArray(0, 1));

    return nestedArray;
}

export default ParentCabinet