import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Button, Input, Select, Modal, message } from "antd";
import konsole from "../../network/konsole";
import Services from "../../network/Services";
import commonLib from "../../control/commonLib";
import config from "../../config.json";
import InputMask from "../PhoneInputMask";
import { UserContext } from "../../App";
const { TextArea } = Input;
const { Option } = Select;

const AddEditCoornatorperson = ({
  firstName,
  onCancel,
  subtenantValueId,
  Cornatorperson,
  coordinatorsetcls,
  valueSelectName,
  setCoordinatorNum,
}) => {
  const [firstNames, setFirstNames] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState();
  const getCoordName = firstName;
  const [form] = Form.useForm();
  const [sendLoggedUserId, setSendLoggedUserId] = useState("");
  const [getcountrycode, setcountrycode] = useState("");
  const [coordinatorNameFromApiRes, setCoordinatorNameFromApiRes] =
    useState("");
  const [getContact, setGetContact] = useState("");
  const [coordinatorId, setCoordinatorId] = useState("");
  const [getEmailCoordinatorApi, setGetEmailCoordinatorApi] = useState("");
  const [getMobileNoCoorApi, setGetMobileNoCoorApi] = useState("");
  const { setLoader } = useContext(UserContext);
  //   const [subtenantValueId , setSubtenantValueId] = useState("")

  const ModalPhoneNoValidation = (value) => {

    form.setFieldsValue({
      PhoneNumber: value,
    });

    setContact(value);
  };

  const onFinishFailed = (errorInfo) => {
    konsole.log("Failed:", errorInfo);
    // hideModal(false);
    onCancel();
  };

  // setGetFName(firstNames)
  // setGetLName(lastName)
  // setGetEmail(email)

  const [contactCode, setContactCode] = useState([]);

  useEffect(() => {
    const promises = Services.getPhoneCode();
    let loggedIdUser = sessionStorage.getItem("loggedUserId");
    setSendLoggedUserId(loggedIdUser);
    promises
      .then((res) => {
        konsole.log("PhoneCode", res.data.data);
        setContactCode(res.data.data);
      })
      .catch((err) => {
        konsole.log("PhoneCodeError", err);
      });

    let splitGetCoordName = getCoordName.split(" ");
    konsole.log("splitGetCoordName", splitGetCoordName);

    konsole.log("MySended", getCoordName);
    form.setFieldsValue({
      firstName: splitGetCoordName[0],
      // lastname: splitGetCoordName[1].charAt(0).toUpperCase() + splitGetCoordName[1].slice(1)
    
    });

    if(splitGetCoordName.length ==2){
      form.setFieldsValue({
        lastname: splitGetCoordName[1].charAt(0).toUpperCase() + splitGetCoordName[1].slice(1)
      });

    }




    let GetFieldFirstName = form.getFieldValue("firstName");
    konsole.log("GetFieldFirstName", GetFieldFirstName);
    setFirstNames(GetFieldFirstName);

    let getFieldLastName = form.getFieldValue("lastname");
    setLastName(getFieldLastName);
    postSubtenantDetails();
  }, [getCoordName]);

  const postSubtenantDetails = (editfuncDataValue) => {
    let postSubtenantDetailApiCall = Services.postSubtenantDetails();
    postSubtenantDetailApiCall
      .then((res) => {
        console.log("responsedetails", res.data);
        // setCoordinatorData(res.data)
        //   setpostSubtenantDetailResp(res.data.data)
        //   if(editFuncData !== undefined && editFuncData !== null && editFuncData !==""){
        let filterSubtenant = res.data.data.filter((x) => {
          return x.subtenantId == editfuncDataValue;
        });
        fixedEditValue(filterSubtenant);
        konsole.log("filterSubtenant", filterSubtenant);
        //   }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fixedEditValue = (editSubtenatId) => {
    konsole.log("editSubtenatIdeditSubtenatId", editSubtenatId);
    // setValuaSelectName(editSubtenatId.subtenantName)
    // setSubtenantValueId(editSubtenatId.subtenantId)
    // seminarOrg(editSubtenatId.subtenantName)
    // sendSubtenantId(editSubtenatId.subtenantId)
  };

  const [codeMobile, setCodeMobile] = useState("");
  const GetContactCode = (a, key) => {
    konsole.log("GetContactCode", a);
    setCodeMobile(a);
    // setcountrycode(a)
    // konsole.log("GetContact",key)
  };

  const getEmail = form.getFieldValue().email;

  let ConCatMobileNo = codeMobile.concat(contact);
  konsole.log("codeMobile", ConCatMobileNo);

  const onFinish = (values) => {
    konsole.log("Success:", values);
    // hideModal(false);
    konsole.log("subtenantValueId", subtenantValueId);
    setCoordinatorNum(ConCatMobileNo);
    SubmitData(firstNames, lastName);
  };

  const SubmitData = (firstCoordinateName, lastCoordinateName) => {
   
      setLoader(true)
    
    const jsonObjData = {
      subtenantId: subtenantValueId,
      signUpPlateform: config.AOADMIN,
      createUserCredentials: true,
      createdBy: sendLoggedUserId,
      user: {
        roleId: 6,
        firstName: firstCoordinateName,
        lastName: lastCoordinateName,
        emailAddress: email,
        userName: email,
        countryCode: codeMobile,
        mobileNumber: contact,
        // password: "stringst",
        packageCode: null,
        activateUser: false,
        autoConfirmEmail: false,
        autoConfirmMobile: false,
      },
    };

    const speakerRegisterApi = Services.subtenantCreateUser(jsonObjData);
    speakerRegisterApi
      .then((res) => {
        console.log("coordinatorresponse", res);
        let contactIs = codeMobile + contact;
        Cornatorperson.push(contactIs);
        Cornatorperson.push(res);

        coordinatorsetcls();
        onCancel();
       
        konsole.log("SpeakerResasdsada", res.data.data);

        konsole.log("SpeakerRes", res.data.data.userId);
        let fixefFullName =
          res.data.data.firstName + " " + res.data.data.lastName;
        setCoordinatorNameFromApiRes(fixefFullName);
        // form.setFieldsValue({
        //   coordinatorField: fixefFullName,
        //   coordinatorContact: getContact,
        //   coordinatorEmail: getEmail,
        // });

        setCoordinatorId(res.data.data.userId);
        setGetEmailCoordinatorApi(getEmail);
        setGetMobileNoCoorApi(getContact);
        // userListApiCall(props.subtenantValueId);
        // openNotification("topRight", res.status);
        // onCancel()
        // setCoordinatorData(res.data.data)
        message.success("Organizer added successfully");
        
      })

      .catch((err) => {
        if (err.response.data.messages[0] == "ALREADY_REGISTERED") {
          message.error(err.response.data.messages[1]);
        }
        konsole.log("errr", err);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  //   const userListApiCall = (RunApiWithSubtenantId) => {
  //     let getCoOrdinatorUserListApiCall = Services.getCoOrdinatorUserList(RunApiWithSubtenantId, null, true, 6)
  //     getCoOrdinatorUserListApiCall
  //       .then((res) => {
  //         konsole.log("responseCoordinator", res.data.data)

  //         if(editFuncData !==undefined && editFuncData !== null && editFuncData !== ""){
  //           let filterCoordinator = res.data.data.filter((x)=>{
  //             return x.userId == editFuncData?.coordinators[0]?.coordinatorUserId
  //           })

  //           konsole.log("filterCoordinator",filterCoordinator[0])
  //           // fixCoordinatorAllFields(filterCoordinator[0]?.userId)
  //           setEditCoordinator(filterCoordinator[0]?.fullName)

  //           setPrimaryMobile(filterCoordinator[0]?.primaryMobile);
  //           setPrimaryEmail(filterCoordinator[0]?.primaryEmail)
  //           setCoordinatorId(filterCoordinator[0]?.userId)
  //           setCoordinatorName(filterCoordinator[0]?.fullName)

  //             props.form.setFieldsValue({
  //               "coordinatorField": filterCoordinator[0]?.fullName,
  //               "coordinatorContact": filterCoordinator[0]?.primaryMobile,
  //               "coordinatorEmail": filterCoordinator[0]?.primaryEmail

  //             })

  //         }

  //         setCoOrdinatorList(res.data.data)
  //         setIsModalVisible(false);
  //         form.resetFields();
  //         setLoader(false)

  //       })
  //       .catch((error) => {
  //         konsole.log("errorcoordinator", error)
  //       }).finally(() => {
  //         setLoader(false)

  //       })
  //   }
  // const check =() =>{
  //   setCornatorperson("working")
  //   coordinatorsetcls()
  // }

  return (
    <div style={{}}>
      {/* <button onClick={()=>{check()}}>check</button> */}
      <Form
        form={form}
        name="basicform"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ remember: true, prefix: "91" }}
      >
        <Row className="w-100" gutter={[8, 8]}>
          <Col xs={24} lg={24} style={{ marginTop: "30px" }}>
            <Form.Item>
              <Select defaultValue={valueSelectName} disabled></Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  pattern: new RegExp(/^[a-zA-Z]*$/),
                  message: "No Space or Special Characters Allowed",
                },
                { required: true, message: "Please input your first Name!" },
              ]}
            >
              <Input  onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}/>
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label="Last Name"
              name="lastname"
              rules={[
                {
                  pattern: new RegExp(/^[a-zA-Z]*$/),
                  message: "No Space or Special Characters Allowed",
                },
                { required: true, message: "Please input your last name!" },
              ]}
            >
              <Input  onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)} />
            </Form.Item>
          </Col>

          <br></br>
          <Col xs={24} lg={24}>
            <Form.Item
              label="Profile"
              name="Profile"
              rules={[
                {
                  // required: true,
                  message: "Please enter your Profile Details",
                },
              ]}
            >
              <TextArea rows={2}/>
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              name="Video Link"
              label=" Link"
              rules={[
                {
                  pattern: new RegExp(
                    "https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|http?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,}"
                  ),
                  message: "Please enter correct url format!",
                },
                // { required: true, message: "Please input link!" },
              ]}
            >
              <Input placeholder="https://" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  pattern: new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
                  message: "Please enter correct email address",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              name="phone"
              label="Cell Number"

              // rules={[{ required: true, message: 'Please input your phone number!' }]}
            >
              <Col xs={24} lg={24} className="d-flex">
                <Input.Group compact className="d-flex">
                  <Form.Item
                    name="countryCode"
                    noStyle
                    rules={[
                      { required: true, message: "Please select country code" },
                    ]}
                  >
                    <Select
                      placeholder="Country Code"
                      onChange={GetContactCode}
                      style={{ width: "28%",marginRight:"2%" }}
                    >
                      {contactCode.map((items) => {
                        return (
                          <Option value={items.value}>{items.label}</Option>
                        );
                      })}

                      {/* <Option value="=1">1</Option>
                                    <Option value="=2">2</Option> */}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="PhoneNumber"
                    noStyle
                    rules={[
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please Enter Number Only!",
                      },
                      {
                        required: true,
                      },
                    ]}
                  >
                    {/* <Input
                    style={{ width: "70%" }}
                    maxLength={10}
                    onChange={(e) => setContact(e.target.value)}
                  /> */}

                    <InputMask
                      value={contact}
                      onValueChange={ModalPhoneNoValidation}
                    />
                  </Form.Item>
                </Input.Group>
              </Col>
            </Form.Item>
            <Form.Item className="mt-4" style={{ width: "100%" }}>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddEditCoornatorperson;
