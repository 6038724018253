import React, { useState, useContext, createContext, useEffect } from 'react'
import "antd/dist/antd.css";
import { Form, Button, Input, Select, Modal, Row, Col, notification } from 'antd';
import { UserContext } from '../../App';
import Address from '../Address';
import konsole from '../../network/konsole';
import Services from '../../network/Services';



const AddEditVenueCls = ({ visible, onCancel, status, statusId, RecallgetvenueDataAPI, getAddressByAddressId, venueSave }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  const { setLoader } = useContext(UserContext)

  const [getVenueName, setGetVenueName] = useState("")
 
  const [ApiVenue, setApiVenue] = useState("")

  const [ApiAddressId, setApiAddressId] = useState("")

  status(getVenueName)


  const [loggedUserId, setLoggedUserId] = useState("")
  useEffect(() => {
    let loggedUserId = sessionStorage.getItem('loggedUserId')
    konsole.log('respaaa', loggedUserId)
    setLoggedUserId(loggedUserId)
  }, [])

  const openNotification = (placement, Status) => {

    if (Status == 500) {

      notification.error({
        message: `Error ${Status} `,
        description:
          'Server Issue! Please try after sometime.',
        placement,
      });
    } else if (Status == 200) {
      notification.success({
        message: `Venue added successfully`,

        placement,
      });
    }

  };

  const EndedData = (postData) => {
    setLoader(true)
    setGetVenueName(postData.venueName)
    konsole.log("data", postData);
    let mainAddress = postData.address;
    let mainCity = postData.city;
    let mainState = postData.state;
    let mainCountry = postData.country;
    let mainZipCode = postData.zipcode
    let mainVenue = postData.venueName

    sendData(mainAddress, mainCity, mainState, mainCountry, mainZipCode, mainVenue)

  }

  const sendData = (mainAddress, mainCity, mainState, mainCountry, mainZipCode, mainVenue) => {
    const AddressData = Services.addressData(null, null, mainAddress, null, null, mainZipCode, 'no', mainCity, mainState, mainCountry, 1, loggedUserId)
    AddressData.then((res) => {
      konsole.log(res.data)
      konsole.log("AddressId:", res.status);
      SendVenue(res.data.data.addressId, mainVenue)
      openNotification('topRight', res.status)
      onCancel()
    })
      .catch((err) => {
        konsole.log(err)
      }).finally(() => {
        setLoader(false)
      })




  }
  const SendVenue = (AddressId, mainVenue) => {
    let subtenantId = sessionStorage.getItem('subtenantId')
    let loginUserId = sessionStorage.getItem('loggedUserId')
    const UpsertVenue = Services.getVenueData(subtenantId, 0, mainVenue, AddressId, true, "",loginUserId)
    UpsertVenue.then((res) => {
      konsole.log("VenuePostRes", res)
      konsole.log("VenuePostResVenueText", res.data.data.venueText)
      konsole.log("VenuePostResVenueId", res.data.data.addressId)
      setApiVenue(res.data.data.venueText)
      setApiAddressId(res.data.data.addressId)
      getAddressByAddressId(res.data.data.addressId);
      RecallgetvenueDataAPI();
      setLoader(false)
      onCancel()
    }).catch((err) => {
      konsole.log("VenuePost", err)
    }).finally(() => {
      setLoader(false)
    })

  }


  const onOk = () => {
    form.submit();

  }

  const handleOk = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      'venueName': venueSave,
    });
  }, [venueSave])


  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}
      <Modal title="Basic Modal" okText="Add Venue" visible={visible} onOk={onOk} onCancel={onCancel} maskClosable={false} >
        <Form form={form} onFinish={EndedData} name="userForm" layout="vertical">
          <Row className='w-100' gutter={[8, 8]}>
            <Col xs={24} lg={24} >
              <h6>*Venue</h6>
              <Form.Item name="venueName"
                rules={[{
                  required: true,
                  message: 'Enter Venue',
                }]}

              >
                <Input placeholder="Name"
                // onChange={(e) => setGetVenueName(e.target.value)} 
                />

              </Form.Item>

            </Col>
            <Address form={form} />
          </Row>

        </Form>
      </Modal>
    </>

  );
}

export default AddEditVenueCls