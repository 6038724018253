import React,{useState} from "react";
import konsole from "../../network/konsole";
import '../Subscription/Manage.css';
import Tooltip from "../Tooltip";

function PackageMappedCards({productTypeName,heading,description,item,index,getEditableItem,rate,subtenantID,roleId}) {
  const [scrollPosition, setScrollPosition] = useState(0);
  konsole.log("subtenantID122",subtenantID,heading,item,productTypeName)

  const cardsInnerBorderLine = (data) => {
    const borderStyle = {
      height: "1.5px",
      width: "97%",
      backgroundColor: "rgba(235, 235, 235, 1)",
      marginBottom: "20px",
    };
    return <div style={borderStyle}></div>;
  };

  const scrollRight = (ind, scrollFor) => {
     if (scrollFor === heading) {
      const container = document.getElementById(`scroll-container${ind}`);
      const newPosition = scrollPosition + container?.clientWidth;
      setScrollPosition(newPosition);
      container.scrollTo({ left: newPosition, behavior: "smooth" });
      konsole.log("ind1212",ind,scrollFor,heading,container,newPosition)
    }
  };
  

  const scrollLeft = (ind,scrollFor) => {
    
    if(scrollFor == heading){
      const container = document.getElementById(`scroll-container${ind}`);
      const newPosition = scrollPosition - container.clientWidth;
      setScrollPosition(newPosition);
      container.scrollTo({ left: newPosition, behavior: "smooth" });
    }
  };

  return (
      <>
          <div className={`${(subtenantID == 3 && roleId == null) ? "AOProduct" : (subtenantID == 3 && roleId == 11) ? "firmProduct" : "firmProduct"} m-2`}>
            <div draggable="true" className="p-2" style={{fontSize: "15px", }} >
            <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center justify-content-center firstDiv">
              <div>{(subtenantID == 3 && roleId == null) ? <img src="./icons/AOLogo.svg" style={{ height: "18px", width: "18px" }} alt="AO Logo" /> : 
               heading == "Product" ? 
              <img src="./images/product.svg" style={{ height: "15px", width: "15px" }} alt="Product Icon" />
              : heading == "Product Group" ?
              <img src="./images/redProductGroup.svg" style={{ height: "18px", width: "18px" }}/>
              : heading == "Package" ?
              <img src="./images/redPackage.svg" style={{ height: "18px", width: "18px" }}/>: ""
           }</div>
              <div className="m-1">{productTypeName}</div>
            </div>
            {(subtenantID != 3 || (subtenantID == 3 && roleId == 11)) && <div className="d-flex align-items-center justify-content-around w-25 secondDiv">
              <div><img src="./images/EditIcon.svg" style={{ height: "13px", width: "15px" }} alt="Edit Icon" onClick={()=>getEditableItem(item,"update")}/></div>
              <div style={{ height: "20px", borderLeft: "1px solid rgba(237, 237, 237, 0.82)" }}></div>
              <div><img src="./images/crossIcon.svg" style={{ height: "13px", width: "15px" }} alt="Cross Icon" onClick={()=>getEditableItem(item,"delete")}/></div>
            </div>}
          </div>
              <div className="d-flex justify-content-center mt-3" style={{ height: "10px" }} >
                {cardsInnerBorderLine()}
              </div>
              {heading != "Product" && <div className="d-flex scroll-container" id={`scroll-container${index}`} style={{ width: "100%" }}>
              {item?.services?.length > 0 && item?.services.map((det,ind)=>(
               det?.isActive == true && 
               <Tooltip text = {det?.skuServiceName || det?.serviceName}>
                <div className={`d-flex justify-content-between align-items-center mx-1 ${(subtenantID == 3 && roleId == null) ? "productsRedCapsule" : (subtenantID == 3 && roleId == 11) ? "productsGreyCapsule" : "productsGreyCapsule"}`}>
                <div className="d-flex justify-content-center align-items-center">
                <div className="ms-1">{(det.subtenantId == 3 && roleId == null)  ? <img src='./icons/AOLogo.svg' style={{ height: "15px", width: "15px" }} alt="Product Icon"/> :
                 det?.isIndService == false ? <img src="./images/greyProductGroup.svg" style={{ height: "15px", width: "15px" }} alt="Product Icon"/> : 
                 <img src="./icons/greyProductIcon.svg" style={{ height: "15px", width: "15px" }} alt="Product Icon"/>}</div>
                  <div className="ms-1 mt-1 packagesServices">{det?.skuServiceName || det?.serviceName}</div>
                  </div>
                {(det.subtenantId != 3 || (det.subtenantId == 3 && roleId == 11))&& <div className="me-1" onClick={()=>getEditableItem(item,"removeServiceOrGrp",det)}>x</div>}
              </div>
               </Tooltip>
                
              ))}
              </div>}
              <div className="d-flex justify-content-between mt-2 ms-1" style={(subtenantID == 3 && roleId == null) ? {color:"rgba(114, 12, 32, 1)"} : (subtenantID == 3 && roleId == 11) ? {color:"rgba(131, 131, 131, 1)"} : {color:"rgba(131, 131, 131, 1)"}}>
                {description ?? "Portal services include Aging Options Academy, Life Plan Organizer, Agent Guidance & Toolbox"}
              </div>
              {heading != "Product" && <div className="w-100 d-flex justify-content-between align-items-center fs-5 mt-3" style={{ height: "10px" }}>
                <div className="fw-bold" onClick={()=>scrollLeft(index,heading)}>&larr;</div>
                <div className="fw-bold" onClick={()=>scrollRight(index,heading)}>&rarr;</div>
              </div>}
              <div className="d-flex justify-content-center mt-3" style={{ height: "10px" }} >
                {cardsInnerBorderLine()}
              </div>
              <div className="d-flex justify-content-between">
              {/* <div>by agingoptions</div> */}
              <div></div>
              <div style={{fontSize:"17px",fontWeight:"bold"}} className="ms-auto">{`$${rate ? rate : ""}`}</div>
              </div>
            </div>
          </div>
      </>
  );
}
export default PackageMappedCards;
