import React, { useState, useEffect, useContext, useRef } from "react";
import { Row, Col, Form, FormCheck } from "react-bootstrap";
import { Input, message } from "antd";
import "./Manage.css";
import Services from "../../network/Services";
import commonLib from "../../control/commonLib";
import { Container, Accordion } from "react-bootstrap";
import konsole from "../../network/konsole";
import { UserContext } from "../../App";
import { capitalizeFirstLetter, completeEventMessage } from "../../control/Constant";
import DeleteConfirmModal from "../DeleteConfirmModal";
import Tooltip from "../Tooltip";

function PackageSunbscription() {
  const [arrayThreeShow, setArrayThreeShow] = useState(false);
  const [combineSubsArr, setcombineSubsArr] = useState([]);
  const [arrayOne, setarrayOne] = useState([]);
  const [productGrps, setProductGrps] = useState([]);
  const [subsGrpName, setSubsGrpName] = useState("");
  const [loginUserId, setloginUserId] = useState("");
  const [serviceMap, setServiceMap] = useState(false);
  const [editService, setEditService] = useState("");
  const [inputServiceSearch, setInputServiceSearch] = useState("");
  const [inputProdGrpSearch, setInputProdGrpSearch] = useState("");
  const [editSkuListId, setEditSkuListId] = useState(null);
  const [inputSubsGrpSearch, setInputSubsGrpSearch] = useState("");
  const [pagerender, setpagerender] = useState(true);
  const [updateServiceNameId, setUpdateServiceNameId] = useState(null);
  const [checkServicesInPackage, setCheckServicesInPackage] = useState(false);
  const [checkServicesInProdGrp, setCheckServicesInProdGrp] = useState(false);
  const [inputServiceSearchWithCheckbox, setInputServiceSearchWithCheckbox] = useState("");
  const [actionOfPackage, setActionOfPackage] = useState("")
  const [addproductForPackage, setAddproductForPackage] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollPositionInPack, setScrollPositionInPack] = useState(0);
  const [scrollPositionInProdGrpPack, setScrollPositionInProdGrpPack] = useState(0);
  const [arrayTwoShow, setarrayTwoShow] = useState(false);
  const [addproductForProdGrp, setAddproductForProdGrp] = useState(false);
  const [prodGrpName, setProdGrpName] = useState("");
  const [editProdGrpId, setEditProdGrpId] = useState(null)
  const [combProdAndProdGrpArr, setCombProdAndProdGrpArr] = useState([])
  const [jsonToDeleteProd, setJsonToDeleteProd] = useState(null)
  const [jsonToDeleteProdGrpOrRemoveProd, setJsonToDeleteProdGrpOrRemoveProd] = useState(null)
  const [jsonToDeletePackOrRemoveProd, setJsonToDeletePackOrRemoveProd] = useState(null)
  const [removeProdFromGrp, setRemoveProdFromGrp] = useState("")
  const [removeProdFromPack, setRemoveProdFromPack] = useState("")
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  const [messageTypeFor, setMessageTypefor] = useState("")
  const [prodDescription, setProdDescription] = useState("")
  const [prodGrpDescription, setProdGrpDescription] = useState("")
  const [packDescription, setPackDescription] = useState("")
  const { Search, TextArea } = Input;
  const { setLoader } = useContext(UserContext);
  const subtenantID = sessionStorage.getItem("subtenantId")

  konsole.log("editService", combProdAndProdGrpArr,arrayOne,productGrps,combineSubsArr, subsGrpName,inputServiceSearchWithCheckbox,editProdGrpId,prodDescription);

  useEffect(() => {
    let loggedUserId = commonLib.getSessionStoarge("loggedUserId");
    setloginUserId(loggedUserId);
    services();
    getGroupedService()
    SkuList();
  },[]);

  const services = () => {
    setLoader(true);
    const promises = Services.indServices(true,subtenantID);
    promises
      .then((res) => {
        setLoader(false);
        konsole.log("resresres", res.data.data);
        setarrayOne(res.data.data);
      })
      .catch((err) => {
        konsole.log("errr", err);
        setLoader(false);
      });
  };

  const getGroupedService = () => {
    const promise = Services.groupedService(true,subtenantID);
    promise
      .then((res) => {
        konsole.log("grpRes", res.data.data);
        setProductGrps(res.data.data);
      })
      .catch((err) => {
        konsole.log("error", err);
      });
  };

  const addService = () => {
    setarrayTwoShow(false)
    setArrayThreeShow(false);
    setServiceMap(true);
    setUpdateServiceNameId(null)
    setEditService("");
    setProdDescription("")
  };

  const addProductGrp = () =>{
    forCheckingCorrectProdOrGrp(combProdAndProdGrpArr)
    setServiceMap(false);
    setArrayThreeShow(false);
    setarrayTwoShow(true)
    setCheckServicesInProdGrp(false)
    setProdGrpName("");
    setProdGrpDescription("")
    setEditProdGrpId(null)
    setActionOfPackage("makeProdGrp")
    setInputProdGrpSearch("");
    setAddproductForProdGrp(false);
    services()
  }

  const addSubscriptions = () => {
    forCheckingCorrectProdOrGrp(arrayOne)
    const newArray = [...productGrps,...arrayOne]
    setCombProdAndProdGrpArr(newArray)
    setServiceMap(false)
    setarrayTwoShow(false)
    setarrayTwoShow(false)
    setSubsGrpName("");
    setPackDescription("")
    setEditSkuListId(null)
    setActionOfPackage("makePackage")
    setArrayThreeShow(true);
    setInputSubsGrpSearch("");
    setCheckServicesInPackage(false);
    setAddproductForPackage(false);
    services()
  };

  const forCheckingCorrectProdOrGrp = (array) =>{
    for (const [index, data] of array.entries()) {
      if(data.checked == true){
            data["checked"] = false;
        }
    }
  }


  const editServiceName = (serviceArr) => {
    konsole.log("serviceArr", serviceArr);
    const serviceIdToUpdateName = serviceArr.serviceId;
    const serviceToUpdateName = serviceArr.serviceName;
    const serviceDescriptionToUpdate = serviceArr.description;

    setArrayThreeShow(false);
    setarrayTwoShow(false)
    setUpdateServiceNameId(serviceIdToUpdateName);
    setServiceMap(true);
    setEditService(serviceToUpdateName);
    setProdDescription(serviceDescriptionToUpdate)
  };

  const createServices = (deleteServiceJson, delService) => {
    setShowDeleteConfirmModal(false)
    const arrayForUniqueName = updateServiceNameId ? arrayOne?.filter((item) =>item.serviceId != updateServiceNameId) : arrayOne;
    const uniqueServiceName = arrayForUniqueName?.some((item) =>item.serviceName?.toLowerCase() == editService.toLowerCase());
    konsole.log("filterServiceName1212",arrayForUniqueName,uniqueServiceName,arrayOne,editService,updateServiceNameId)
    const postService = [
      {
        serviceId: updateServiceNameId !== null ? updateServiceNameId : 0,
        serviceName: editService,
        isActive: true,
        description : prodDescription,
        upsertedBy: loginUserId,
        subtenantId : subtenantID,
      },
    ];
    const service = delService == "deleted" ? deleteServiceJson : postService;
    if (((editService && editService.trim() !== "")  && serviceMap == true && uniqueServiceName == false) || delService == "deleted") {
      setLoader(true);
      const promises = Services.postIndService(service);
      promises.then((res) => {
        setLoader(false);
        konsole.log("resres", res);
        if(res?.data?.data?.length > 0){
          completeEventMessage("success",`Product ${delService == "deleted" ? "deleted" : updateServiceNameId != null ? "updated" : "created"} successfully`)
        }
        services();
        setJsonToDeleteProd(null)
      }).catch((err) => {
          konsole.log("errorrr", err);
        });
      setServiceMap(false);
    } else {
      completeEventMessage("error",`${uniqueServiceName == true ? "Product name already exists" : "Please enter product name"}`)
    }
  };

  const confirmDelete = (deletionIsConfirm) =>{
    konsole.log("JsonDelete",jsonToDeleteProd,jsonToDeleteProdGrpOrRemoveProd,jsonToDeletePackOrRemoveProd)
    if(deletionIsConfirm == true && (jsonToDeleteProd != undefined && jsonToDeleteProd != null && jsonToDeleteProd != "")){
      createServices(jsonToDeleteProd, "deleted");
      getGroupedService();
      SkuList();
    }
    if(deletionIsConfirm == true && (jsonToDeleteProdGrpOrRemoveProd != undefined && jsonToDeleteProdGrpOrRemoveProd != null && jsonToDeleteProdGrpOrRemoveProd != "")){
      prodGrpApi(jsonToDeleteProdGrpOrRemoveProd,"deleted")
      SkuList();
    }
    if(deletionIsConfirm == true && (jsonToDeletePackOrRemoveProd != undefined && jsonToDeletePackOrRemoveProd != null && jsonToDeletePackOrRemoveProd != "")){
      subscriptionApi(jsonToDeletePackOrRemoveProd,"deleted");
    }
  }


  const deleteService = (e) => {
      let serviceIdToDel = arrayOne[e.target.id]?.serviceId;
      let serviceNameToDel = arrayOne[e.target.id]?.serviceName;
      let serviceSubtenantId = arrayOne[e.target.id]?.subtenantId;
      let serviceDescription = arrayOne[e.target.id]?.description;
      const deleteServiceJson = [
        {
          serviceId: serviceIdToDel,
          serviceName: serviceNameToDel,
          isActive: false,
          description : serviceDescription,
          subtenantId : serviceSubtenantId,
          upsertedBy: loginUserId,
        },
      ];
      setJsonToDeleteProd(deleteServiceJson)
      setMessageTypefor("product")
      setShowDeleteConfirmModal(true)
  };

  const serachServices = (e) => {
    let filteredData = [];
    let data1 = [];
    let id;
    if (e == "1") {
      data1 = arrayOne;
      if (inputServiceSearch !== "" && inputServiceSearch !== null && inputServiceSearch !== undefined) {
        filteredData = data1?.filter((item) => { 
          return item.serviceName?.toLowerCase().includes(inputServiceSearch?.toLowerCase());
        });
      } else {
        return data1;
      }
      return filteredData;
    }
    else if(e == "2"){
      data1 = productGrps;
      if (inputProdGrpSearch !== "" && inputProdGrpSearch !== null && inputProdGrpSearch !== undefined) {
        filteredData = data1?.filter((item) => {
          return item.serviceGrpName?.toLowerCase().includes(inputProdGrpSearch?.toLowerCase());});
      } else {
        return data1;
      }
    }
     else if (e == "3") {
      data1 = combineSubsArr;
      if (inputSubsGrpSearch !== "" && inputSubsGrpSearch !== null && inputSubsGrpSearch !== undefined) {
        filteredData = data1?.filter((item) => {
          return item.skuListName?.toLowerCase().includes(inputSubsGrpSearch?.toLowerCase());});
      } else {
        return data1;
      }
    }
    return filteredData;
  };

  const backOnProdGrpList = () => {
    setarrayTwoShow(false);
    setCheckServicesInProdGrp(false);
    setInputProdGrpSearch("");
    services()
  };

  const backOnSubscList = () => {
    setArrayThreeShow(false);
    setCheckServicesInPackage(false);
    setInputSubsGrpSearch("");
    services()
  };

  const searchServiceWithCheckbox = (e) => {
    let filteredData = [];
    let data1 = [];
    let id;
    if (e == "InProdGrp") {
      data1 = arrayOne;
      if (
        inputServiceSearchWithCheckbox !== "" &&
        inputServiceSearchWithCheckbox !== null &&
        inputServiceSearchWithCheckbox !== undefined
      ) {
        filteredData = data1?.filter((item) => {
          return item.serviceName
            ?.toLowerCase()
            .includes(inputServiceSearchWithCheckbox?.toLowerCase());
        });
      } else {
        return data1;
      }
      return filteredData;
    }else if(e == "InPackage"){
      data1 = combProdAndProdGrpArr;
      if (
        inputServiceSearchWithCheckbox !== "" &&
        inputServiceSearchWithCheckbox !== null &&
        inputServiceSearchWithCheckbox !== undefined
      ) {
        filteredData = data1?.filter((item) => 
    (item.serviceName && item.serviceName?.toLowerCase().includes(inputServiceSearchWithCheckbox?.toLowerCase())) ||
    (item.serviceGrpName && item.serviceGrpName?.toLowerCase().includes(inputServiceSearchWithCheckbox?.toLowerCase())));

      } else {
        return data1;
      }
      return filteredData;
    }
  };

  const searchWhileMakeProdGrp = (e) => {
    const eventValue = e.target.value;
    if(arrayTwoShow == true){
        if (eventValue && actionOfPackage == "makeProdGrp") {
            // setActionOfPackage("")
            setInputServiceSearchWithCheckbox(eventValue);
            setCheckServicesInProdGrp(true);
          }
          else if(eventValue && actionOfPackage == "editProdGrp"){
              setActionOfPackage("searchToUpdate")
              setInputServiceSearchWithCheckbox(eventValue);
              setCheckServicesInProdGrp(true);
          }
          else if(actionOfPackage == "searchToUpdate"){
            setActionOfPackage("editProdGrp")
            }
            else if(actionOfPackage == undefined || actionOfPackage == null || actionOfPackage == ""){
                setCheckServicesInProdGrp(false);
                setActionOfPackage("makeProdGrp")
            }
           else  {
            setCheckServicesInProdGrp(false);
          }
          setInputProdGrpSearch(eventValue)
    }
    else{
        setInputProdGrpSearch(eventValue)
    }
  };

  const searchWhileMakePackage = (e) => {
    const eventValue = e.target.value;
    if(arrayThreeShow == true){
        if (eventValue && actionOfPackage == "makePackage") {
            // setActionOfPackage("")
            setInputServiceSearchWithCheckbox(eventValue);
            setCheckServicesInPackage(true);
          }
          else if(eventValue && actionOfPackage == "editPackage"){
              setActionOfPackage("searchToUpdate")
              setInputServiceSearchWithCheckbox(eventValue);
              setCheckServicesInPackage(true);
          }
          else if(actionOfPackage == "searchToUpdate"){
            setActionOfPackage("editPackage")
            }
            else if(actionOfPackage == undefined || actionOfPackage == null || actionOfPackage == ""){
                setCheckServicesInPackage(false);
                setActionOfPackage("makePackage")
            }
           else  {
            setCheckServicesInPackage(false);
          }
    }
    setInputSubsGrpSearch(eventValue)
  };

  const checkedProduct = (checkProduct,e) => {
      const checkValue = e.target.checked
      for (const [index, data] of arrayOne.entries()) {
          if ((data.serviceId == checkProduct?.serviceId) && checkValue) {
              data["checked"] = true;
            }
            else if((data.serviceId == checkProduct?.serviceId) && !checkValue){
                data["checked"] = false;
            }
        }
        setpagerender(!pagerender)
  };

  const checkedProductOrProdGrp = (checkProduct,e) => {
    const checkValue = e.target.checked
    for (const [index, data] of combProdAndProdGrpArr.entries()) {
      if(data.serviceId){
        if ((data.serviceId == checkProduct?.serviceId) && checkValue) {
            data["checked"] = true;
          }
          else if((data.serviceId == checkProduct?.serviceId) && !checkValue){
              data["checked"] = false;
          }
      }
      else if(data.serviceGrpId){
        if ((data.serviceGrpId == checkProduct?.serviceGrpId) && checkValue) {
          data["checked"] = true;
        }
        else if((data.serviceGrpId == checkProduct?.serviceGrpId) && !checkValue){
            data["checked"] = false;
        }
      }
      }
      setpagerender(!pagerender)
};

  const editProdGrpFunc = (e,editPack,action) => {
    setarrayTwoShow(true)
    setServiceMap(false);
    setArrayThreeShow(false);
    setActionOfPackage(action)
    setAddproductForProdGrp(true)
    setProdGrpName(editPack.serviceGrpName)
    setEditProdGrpId(editPack?.serviceGrpId)
    setProdGrpDescription(editPack?.description)
    setInputProdGrpSearch("")
    
    for(const dat of editPack?.services){
        for(const serv of arrayOne){
            if(dat.serviceId == serv.serviceId){
                serv["serviceGrpMapId"] = dat?.serviceGrpMapId
                serv["checked"] = true
              }
          }
      }
    };

  const editPackage = (e,editPack,action) => {
    const newArray = [...productGrps,...arrayOne]
    setarrayTwoShow(false)
    setServiceMap(false);
    setArrayThreeShow(true)
    setAddproductForPackage(true)
    setActionOfPackage(action)
    setSubsGrpName(editPack.skuListName)
    setEditSkuListId(editPack.skuListId)
    setPackDescription(editPack.description)
    setInputSubsGrpSearch("")
    
    for(const dat of editPack?.services){
        for(const serv of newArray){
            if((dat.skuServiceId == serv.serviceId) || dat.skuServiceId == serv.serviceGrpId){
                serv["skuServiceMapId"] = dat?.skuServiceMapId
                serv["checked"] = true
              }
          }
      }
      setCombProdAndProdGrpArr(newArray)
    };


    const deleteProdGrp = (e) =>{
      let deleteProdGrp = [];
      let delProdGrpServices = [];
      let delProdGrpId = productGrps[e.target.id]?.serviceGrpId;   
      let delProdGrpName = productGrps[e.target.id]?.serviceGrpName;
      let serviceGrpSubtenantId = productGrps[e.target.id]?.subtenantId;
      let serviceGrpDescription = productGrps[e.target.id]?.description;
      delProdGrpServices = productGrps[e.target.id]?.services;
  
      deleteProdGrp = [
          {
          serviceGrpId: delProdGrpId,
          serviceGrpName: delProdGrpName,
          isActive: false,
          description: serviceGrpDescription,
          subtenantId : serviceGrpSubtenantId,
          upsertedBy: loginUserId,
          services: delProdGrpServices,
          },
      ];
      setJsonToDeleteProdGrpOrRemoveProd(deleteProdGrp)
      setMessageTypefor("product group")
      setShowDeleteConfirmModal(true)
    }

    const deletPackage = (e) => {
    let deleteSubsGrp = [];
    let delSubServices = [];
    let delSkuListId = combineSubsArr[e.target.id]?.skuListId;
    let delSkuListName = combineSubsArr[e.target.id]?.skuListName;
    let packageSubtenantId = combineSubsArr[e.target.id]?.subtenantId;
    let packageDescription = combineSubsArr[e.target.id]?.description;
    delSubServices = combineSubsArr[e.target.id]?.services;

    deleteSubsGrp = [
        {
        skuListId: delSkuListId,
        skuListName: delSkuListName,
        isActive: false,
        description : packageDescription,
        subtenantId : packageSubtenantId,
        upsertedBy: loginUserId,
        services: delSubServices,
        },
    ];
    setJsonToDeletePackOrRemoveProd(deleteSubsGrp)
    setMessageTypefor("package")
    setShowDeleteConfirmModal(true)
    };


    const deleteAndUpdateProductInProdGrp = () =>{
      let servicesArr = [];
          if (prodGrpName) {
              for(const det of arrayOne){
                  const obj = {}
                  if(det?.checked == true && !det.hasOwnProperty("serviceGrpMapId")){
                      obj["serviceGrpMapId"] = 0;
                      obj["serviceId"] = det.serviceId;
                      obj["isActive"] = true;
                  }
                  else if(det.checked == true && det.hasOwnProperty("serviceGrpMapId")){
                      obj["serviceGrpMapId"] = det.serviceGrpMapId;
                      obj["serviceId"] = det.serviceId;
                      obj["isActive"] = true;
                  }
                  else if(det?.checked == false && det.hasOwnProperty("serviceGrpMapId")){
                    obj["serviceGrpMapId"] = det.serviceGrpMapId;
                    obj["serviceId"] = det.serviceId;
                    obj["isActive"] = false;
                }
                  servicesArr.push(obj)
              }
              const serviceArrObj = servicesArr?.filter(item => item.serviceGrpMapId !== undefined && item.serviceGrpMapId !== null && item.serviceGrpMapId !== "")
              konsole.log("serviceArrObj99",serviceArrObj)
              prodGrpFormation(serviceArrObj,"updated");
          }
          else{
            completeEventMessage("error","Please write product group name to update a product group")
          }

  }

    const deleteAndUpdateProduct = () =>{
          if (subsGrpName) {
              const servicesArr = [];
      
              for (const det of [...arrayOne, ...productGrps]) {
                  const obj = {};
                  if (det?.checked === true && !det.hasOwnProperty("skuServiceMapId")) {
                      obj["isIndService"] = det.hasOwnProperty("serviceId");
                      obj["skuServiceMapId"] = 0;
                      obj["skuServiceId"] = det.serviceId || det.serviceGrpId;
                      obj["isActive"] = true;
                  } else if (det?.checked === true && det.hasOwnProperty("skuServiceMapId")) {
                      obj["isIndService"] = det.hasOwnProperty("serviceId");
                      obj["skuServiceMapId"] = det.skuServiceMapId;
                      obj["skuServiceId"] = det.serviceId || det.serviceGrpId;
                      obj["isActive"] = true;
                  } else if (det.checked === false && det.hasOwnProperty("skuServiceMapId")) {
                      obj["isIndService"] = det.hasOwnProperty("serviceId");
                      obj["skuServiceMapId"] = det.skuServiceMapId;
                      obj["skuServiceId"] = det.serviceId || det.serviceGrpId;
                      obj["isActive"] = false;
                  }
                  servicesArr.push(obj);
              }
              const serviceArrObj = servicesArr?.filter(item => item.skuServiceMapId !== undefined && item.skuServiceMapId !== null && item.skuServiceMapId !== "");
              konsole.log("serviceArrObj", serviceArrObj);
              packageFormation(serviceArrObj,"updated");
          } else {
            completeEventMessage("error","Please write package name to update a package")
          }    

    }

    const createProdGrp = (prod,removeProd) =>{
      const existingServiceLengthInProdGrp = arrayOne?.length && arrayOne?.filter((item) => item.checked == true);
      if(arrayTwoShow && actionOfPackage == "editProdGrp"){
        if(removeProd == "removeProdWhileUpdate"){
          if(existingServiceLengthInProdGrp?.length == 1){
            completeEventMessage("error","You have only one product in this product group")
          }
          else{
            for(const item of arrayOne){
              if(prod?.serviceId == item?.serviceId){
                item.checked = false
              }
            }
            setpagerender(!pagerender)
          }
        }else{
          deleteAndUpdateProductInProdGrp()
        }
      }
      else{
        const serviceObjPost =  arrayOne?.filter((checkService) => checkService.checked)
             .map((checkService) => ({
               serviceGrpMapId: 0,
               serviceId: checkService.serviceId,
               isActive: true
              }));
              prodGrpFormation(serviceObjPost,"created")
      }
    }

    const prodGrpFormation = (serviceObjPost,useFor,productGrp) =>{
      const productGrpName = productGrp?.serviceGrpName ?? prodGrpName;
      const prodGrpId = editProdGrpId ?? productGrp?.serviceGrpId;
      const arrayForUniqueName = prodGrpId ? productGrps?.filter((item) =>item.serviceGrpId != prodGrpId) : productGrps;
      const uniqueProdGrpName = arrayForUniqueName?.some((item) =>item.serviceGrpName?.toLowerCase() == (prodGrpName.toLocaleLowerCase() || productGrpName.toLocaleLowerCase()));

      konsole.log("prodGrpName888",productGrpName,uniqueProdGrpName,prodGrpId)
      if((productGrpName && productGrpName.trim() !== "") && uniqueProdGrpName == false){
      const prodGrpJson = [
        {
          serviceGrpId: (prodGrpId != undefined && prodGrpId != null && prodGrpId != "") ? prodGrpId : 0,
          serviceGrpName: productGrpName,
          isActive: true,
          description: prodGrpDescription,
          subtenantId : subtenantID,
          upsertedBy: loginUserId,
          services: serviceObjPost
        }
      ]
      if(useFor != "removed"){
        prodGrpApi(prodGrpJson,useFor)
      }
      else{
        setJsonToDeleteProdGrpOrRemoveProd(prodGrpJson)
        setRemoveProdFromGrp("updated")
      }
    }
    else{
      completeEventMessage("error",uniqueProdGrpName == true ? "Product group name already exists" : "Please enter product group name")  
    }
    }

    const prodGrpApi = (prodGrpJson,type) =>{
      setShowDeleteConfirmModal(false)
      setLoader(true)
        const promises = Services.serviceGrp(prodGrpJson);
        promises
          .then((res) => {
            konsole.log("posetResponse", res.data.data,editProdGrpId);
            setLoader(false)
            if(res?.data?.data?.length > 0){
              completeEventMessage("success",`Product group ${removeProdFromGrp || type} successfully`)
            }
            getGroupedService();
            setJsonToDeleteProdGrpOrRemoveProd(null)
            setRemoveProdFromGrp("")
          })
          .catch((err) => {
            konsole.log("posetResponse", err);
          });
        setarrayTwoShow(false);
    }
    
    const createPackage = (prod,removeProd) => {  
      const existingServiceLengthInPack = combProdAndProdGrpArr?.length && combProdAndProdGrpArr?.filter((item) => item.checked === true);

      if((arrayThreeShow && actionOfPackage == "editPackage")){
        if(removeProd == "removeProdAndGrpWhileUpdate"){
          if(existingServiceLengthInPack?.length == 1){
            completeEventMessage("error",`You have only one ${existingServiceLengthInPack[0]?.serviceGrpId ?  "product group" : "product" } in this package`)
          }
          else{
            for(const item of combProdAndProdGrpArr){
              if((prod?.serviceGrpId && prod?.serviceGrpId === item?.serviceGrpId) || (prod?.serviceId && prod?.serviceId === item?.serviceId)) {
                item.checked = false
              }
            }
            setpagerender(!pagerender)
          }
        }
        else{
          deleteAndUpdateProduct()
        }
      }else{
        const arrayForUniqueName = editSkuListId ? combineSubsArr?.filter((item) => item.skuListId != editSkuListId) : combineSubsArr;
        const uniquePackageName = subsGrpName && arrayForUniqueName?.some((item) => item.skuListName?.toLowerCase() == subsGrpName.toLowerCase())
        konsole.log("uniquePackageName454",subsGrpName,arrayForUniqueName,uniquePackageName,editSkuListId)
        if ((subsGrpName && subsGrpName.trim() !== "") && uniquePackageName == false) {
            setLoader(true);
               const servicesArr = combProdAndProdGrpArr
               ?.filter((checkService) => checkService.checked)
               .map((checkService) => ({
                 skuServiceMapId: 0,
                 isIndService: checkService.serviceId ? true : false,
                 isActive: true,
                 skuServiceId: checkService.serviceId || checkService.serviceGrpId,
               }));
            packageFormation(servicesArr,"created");
          } else {
            completeEventMessage("error",uniquePackageName == true ? "Package name already exists" : "Please enter package name")
          }
      }
      };

  const packageFormation = (servicesArr,useFor) => {
    const subscriptionGrpJson = [
      {
        skuListId: (editSkuListId != undefined && editSkuListId != null && editSkuListId != "") ? editSkuListId : 0,
        skuListName: subsGrpName,
        isActive: true,
        isVisible: true,
        description: packDescription,
        subtenantId: subtenantID,
        upsertedBy: loginUserId,
        services: servicesArr,
      },
    ];
    konsole.log("subscriptionGrpJson1212", subscriptionGrpJson);
    if(useFor != "removed"){
      subscriptionApi(subscriptionGrpJson,useFor);
    }
    else{
      setJsonToDeletePackOrRemoveProd(subscriptionGrpJson)
      setRemoveProdFromPack("updated")
    }
  };

  const subscriptionApi = (subscriptionGrpJsonObj,type) => {
    setShowDeleteConfirmModal(false)
    setLoader(true);
    const promises = Services.saleAbleSkuPath(subscriptionGrpJsonObj);
    promises
      .then((res) => {
        setLoader(false);
        konsole.log("responseSKu",res.data.data)
        const SkuListId = res.data.data.skuListId;
        if(res?.data?.data?.length > 0){
          completeEventMessage("success",`Package ${removeProdFromPack || type} successfully`)
        }
        SkuList(SkuListId);
        setJsonToDeletePackOrRemoveProd(null)
        setRemoveProdFromPack("")
      })
      .catch((err) => {
        setLoader(false);
        konsole.log("subErr", err);
      });
  };

  const SkuList = (skuListId) => {
    setLoader(true);
    const promises = Services.getSkuList(skuListId, true,subtenantID);
    promises
      .then((res) => {
        setLoader(false);
        konsole.log("resp", res);
        setcombineSubsArr(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        konsole.log("erroeee", err);
      });
    setArrayThreeShow(false);
  };

  const removeServiceFromProdGrp = (removeServInPrpdGrp, removeServiceId) => {
    konsole.log("removeServ1111", removeServInPrpdGrp, removeServiceId);
    const activeProductsInGrp = removeServInPrpdGrp?.services?.filter(item => item?.isActive == true)
    if(activeProductsInGrp?.length > 1){
      let removeServFromProdGrpArr = [];
      
      const matchProdGrpIdObj = productGrps.find((item) => item.serviceGrpId == removeServInPrpdGrp.serviceGrpId);
      const matchObjectsById = matchProdGrpIdObj?.services?.length > 0 && matchProdGrpIdObj.services.find((item) => item.serviceId == removeServiceId);
      const removeServFromProdGrp = {
        serviceGrpMapId: matchObjectsById.serviceGrpMapId,
        serviceId: matchObjectsById.serviceId,
        isActive: false,
      };
      removeServFromProdGrpArr.push(removeServFromProdGrp);
      setProdGrpName(matchProdGrpIdObj.serviceGrpName)
      setEditProdGrpId(matchProdGrpIdObj.serviceGrpId)
      prodGrpFormation(removeServFromProdGrpArr,"removed",matchProdGrpIdObj);
      setMessageTypefor("product from this product group")
      setShowDeleteConfirmModal(true)
    }
    else if(activeProductsInGrp?.length == 1){
      completeEventMessage("error","You have only one product in this product group")
    }
  };

  const removeServiceFromPackage = (removeServInPacka, removeSkuServiceId) => {
    konsole.log("removeServ1111", removeServInPacka, removeSkuServiceId);
    if(removeServInPacka?.services?.length > 1){
      let removeServFromPackageArr = [];
      
      const matchSkuListIdObj = combineSubsArr.find((item) => item.skuListId == removeServInPacka.skuListId);
      const matchObjectsById = matchSkuListIdObj?.services?.length > 0 && matchSkuListIdObj.services.find((item) => item.skuServiceId == removeSkuServiceId);
      const removeServFromPackage = {
        skuServiceMapId: matchObjectsById.skuServiceMapId,
        skuServiceId: matchObjectsById.skuServiceId,
        isIndService: matchObjectsById.isIndService,
        isActive: false,
      };
      removeServFromPackageArr.push(removeServFromPackage);
      setSubsGrpName(matchSkuListIdObj.skuListName)
      setEditSkuListId(matchSkuListIdObj.skuListId)
      packageFormation(removeServFromPackageArr,"removed");
      setMessageTypefor(`${matchObjectsById.isIndService == true ? "product" : "product group"} from this package`)
      setShowDeleteConfirmModal(true)
    }
    else if(removeServInPacka?.services?.length == 1){
      completeEventMessage("error", `You have only one ${removeServInPacka?.services[0]?.isIndService == true ? "product in this package" : "product group in this package"}`) 
    }
  };

  const scrollRight = (ind, scrollFor) => {

    if (scrollFor === "InPackage") {
      const containerPackage = document.getElementById(`scroll-containerInPackage${ind}`);
        const newPosition = scrollPositionInPack + containerPackage.clientWidth;
        setScrollPositionInPack(newPosition);
        containerPackage.scrollTo({ left: newPosition, behavior: "smooth" });
    } else if (scrollFor === "InProductGrp") {
      const container = document.getElementById(`scroll-container${ind}`);
        const newPosition = scrollPosition + container.clientWidth;
        setScrollPosition(newPosition);
        container.scrollTo({ left: newPosition, behavior: "smooth" });
    }
    else if (scrollFor === "InProductGrpWhileMakePack") {
      const container = document.getElementById(`scroll-containerProdGrpInPack${ind}`);
        const newPosition = scrollPositionInProdGrpPack + container.clientWidth;
        setScrollPositionInProdGrpPack(newPosition);
        container.scrollTo({ left: newPosition, behavior: "smooth" });
    }
  };
  

  const scrollLeft = (ind,scrollFor) => {
    
    if (scrollFor == "InPackage") {
      const containerPackage = document.getElementById(`scroll-containerInPackage${ind}`);
      const newPosition = scrollPositionInPack - containerPackage.clientWidth;
      setScrollPositionInPack(newPosition);
      containerPackage.scrollTo({ left: newPosition, behavior: "smooth" });
    }
    else if(scrollFor == "InProductGrp"){
      const container = document.getElementById(`scroll-container${ind}`);
      const newPosition = scrollPosition - container.clientWidth;
      setScrollPosition(newPosition);
      container.scrollTo({ left: newPosition, behavior: "smooth" });
    }
    else if(scrollFor == "InProductGrpWhileMakePack"){
      const container = document.getElementById(`scroll-containerProdGrpInPack${ind}`);
      const newPosition = scrollPositionInProdGrpPack - container.clientWidth;
      setScrollPositionInProdGrpPack(newPosition);
      container.scrollTo({ left: newPosition, behavior: "smooth" });
    }
  };

  const addServiceHeaderBeforeClick = () => {
    return (
      <>
        <div className="d-flex justify-content-start align-items-center" style={{ height: "50px" }}>
          <div className="d-flex justify-content-center align-items-center ms-1">
            <img src="./images/Service.svg" style={{ height: "22px", width: "22px" }}/>
          </div>
          <div className="d-flex justify-content-center align-items-center mx-2 mt-2">
            <h6 className="fs-5" style={{ color: "white" }}>Products</h6>
          </div>
          <div className="totalServicesNumDiv">
            <div className="d-flex justify-content-center align-items-center">
              <p style={{ fontSize: "13px", color: "white" }}>{arrayOne.length}</p>
            </div>
          </div>
        </div>

        {horizontalBorderLine("serviceHeaderBorder")}

        <div className="d-flex searchInputMainDiv" style={{ height: "50px" }}>
          <div className="searchInputInnerDiv d-flex" style={{ width: "80%" }}>
            <Search placeholder="Search product" className="searchInput" id="Services" onChange={(e) => setInputServiceSearch(e.target.value)}/>
          </div>
          <div className="addServiceBtn d-flex justify-content-center align-items-center cursor-pointer" onClick={addService}>
            <h4 className="text-white mt-2">+</h4>
          </div>
        </div>
      </>
    );
  };

  const addServiceHeaderOnClick = () => {
    return (
      <>
        <div className="d-flex justify-content-start align-items-center text-white fs-5" style={{ height: "50px" }}>
          <div className="me-2 ms-1 cursor-pointer" onClick={() => setServiceMap(false)}><img src="./icons/backArrow2.svg" style={{height:"15px"}}/></div>
          <div className="me-auto">
            <input type="text" id="bottomBorderInput" className="serviceNameinput" placeholder="Enter Product Name" value={editService} onChange={(e) => setEditService(capitalizeFirstLetter(e.target.value))}/>
          </div>
          <div onClick={() => setEditService("")} className="cursor-pointer">
            <img src="./images/Pen.svg" style={{ height: "20px", width: "20px" }}/>
          </div>
          {/* <div className="d-flex justify-content-center align-items-center" style={{width:"18%",height:"25px",backgroundColor:"#720c20",borderRadius:"20px"}}></div> */}
        </div>
      </>
    );
  };

  const addProductOnPcakageFunc = () => {
    const checkProductSelect = combProdAndProdGrpArr.some(item => item.checked == true)
     if(actionOfPackage == "searchToUpdate"){
        setActionOfPackage("editPackage")
    }
    else if(checkProductSelect == true){
      setAddproductForPackage(true);
      setCheckServicesInPackage(false);
    }
    else if(checkProductSelect == false){
      completeEventMessage("error","Please select any product")
    }
    setInputSubsGrpSearch("");
  };

  const addProductOnProdGrpFunc = () => {
    const checkProductSelect = arrayOne.some(item => item.checked == true)
     if(actionOfPackage == "searchToUpdate"){
          setActionOfPackage("editProdGrp")
    }
    else if(checkProductSelect == true){
      setAddproductForProdGrp(true);
      setCheckServicesInProdGrp(false);
    }
    else if(checkProductSelect == false){
      completeEventMessage("error","Please select any product")
    }
    setInputProdGrpSearch("");
  };

  const horizontalBorderLine = (data) => {
    const borderStyles = {
      height: data === "lowerCheckboxListBorder" ? "1px" : "1.5px",
      width: data === "lowerCheckboxListBorder" ? "100%" : data == "serviceHeaderBorder" ? "97%" : "90%",
      backgroundColor: data == "serviceHeaderBorder" ? "white" : "rgba(0, 0, 0, 0.1)",
      marginTop: data == "serviceHeaderBorder" ? "" : "8px",
      marginBottom: data == "serviceHeaderBorder" ? "20px" : "",
    };
    return (
      <div className="d-flex justify-content-center mb-0">
        <div style={borderStyles}>{/* Horizontal Line */}</div>
      </div>
    );
  };

  const cardsInnerBorderLine = (data) => {
    const borderStyle = {
      height: data == "createPackageUpperBorder" ? "1px" : "1.5px",
      width: "97%",
      backgroundColor: "rgba(232, 232, 232, 1)",
      marginBottom: data == "createPackageUpperBorder" ? "10px" : "20px",
    };
    return <div style={borderStyle}></div>;
  };

  const addProdGrpHeaderOnClick = () => {
    return (
      <>
        <div className="d-flex justify-content-start align-items-center text-white fs-5" style={{ height: "50px" }}>
          <div className="me-2 ms-1 cursor-pointer" onClick={() => backOnProdGrpList()}><img src="./icons/backArrow2.svg" style={{height:"15px"}}/></div>
          <div className="me-auto">
            <input type="text" id="bottomBorderInput" className="serviceNameinput" placeholder="Enter Product Group Na..." value={prodGrpName} onChange={(e) => setProdGrpName(capitalizeFirstLetter(e.target.value))}/>
          </div>
          <div onClick={() => setProdGrpName("")}>
            <img src="./images/Pen.svg" style={{ height: "20px", width: "20px" }}/>
          </div>
        </div>
      </>
    );
  };

  const addProdGrpHeaderBeforeClick = () => {
    return (
      <>
        <div className="d-flex justify-content-start align-items-center" style={{ height: "50px" }}>
          <div className="d-flex justify-content-center align-items-center ms-1">
            <img src="./images/Package.svg" style={{ height: "19px", width: "19px" }}/>
          </div>
          <div className="d-flex justify-content-center align-items-center mx-2 mt-2">
            <h6 className="fs-5" style={{ color: "white"}}>Product Groups</h6>
          </div>
          <div className="totalServicesNumDiv">
            <div className="d-flex justify-content-center align-items-center">
              <p style={{ fontSize: "13px", color: "white" }}>{productGrps?.length}</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const addSubsHeaderBeforeClick = () => {
    const isVisiblePackages = combineSubsArr?.filter(item => item.isVisible == true && item.isActive == true)
    return (
      <>
        <div className="d-flex justify-content-start align-items-center" style={{ height: "50px" }}>
          <div className="d-flex justify-content-center align-items-center ms-1">
            <img src="./images/Package.svg" style={{ height: "19px", width: "19px" }}/>
          </div>
          <div className="d-flex justify-content-center align-items-center mx-2 mt-2">
            <h6 className="fs-5" style={{ color: "white"}}>
              Packages
            </h6>
          </div>
          <div className="totalServicesNumDiv">
            <div className="d-flex justify-content-center align-items-center">
              <p style={{ fontSize: "13px", color: "white" }}>{isVisiblePackages?.length}</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const addSubsHeaderOnClick = () => {
    return (
      <>
        <div className="d-flex justify-content-start align-items-center text-white fs-5" style={{ height: "50px" }}>
          <div className="me-2 ms-1 cursor-pointer" onClick={() => backOnSubscList()}><img src="./icons/backArrow2.svg" style={{height:"15px"}}/></div>
          <div className="me-auto">
            <input type="text" id="bottomBorderInput" className="serviceNameinput" placeholder="Enter Package Name" value={subsGrpName} onChange={(e) => setSubsGrpName(capitalizeFirstLetter(e.target.value))}/>
          </div>
          <div onClick={() => setSubsGrpName("")}>
            <img src="./images/Pen.svg" style={{ height: "20px", width: "20px" }}/>
          </div>
        </div>
      </>
    );
  };

  const addedProductAfterCheck = () => {
    // const checkedProductLength = arrayOne?.length && arrayOne?.filter((item) => item.checked == true);
    return (
      <div className="servicesWithCheckBoxScroll w-100">
      <div className="d-flex flex-wrap justify-content-center">
        {arrayOne?.length &&
          arrayOne.map((item, ind) =>
            item.checked === true ? (
              <Tooltip text={item.serviceName}>
              <div key={ind} className="addedProducts rounded-pill d-flex justify-content-center align-items-center mx-2 mt-2">
                <div className="d-flex justify-content-center align-items-center">
                <div><img src="./images/product.svg" className="me-1" style={{ height: "15px", width: "15px" }} alt="Product Icon"/></div>
                <div className="packagesServices cursor-pointer">{item.serviceName}</div>
                </div>
                <div className="ms-1 cursor-pointer" style={{fontSize:"14px"}} onClick={()=>createProdGrp(item,"removeProdWhileUpdate")}>x</div>
              </div>
              </Tooltip>
            ) : null
          )}
      </div>
          <div className="serviceProviderSelect w-100">
            <Form.Group className="w-100 p-2" controlId="formBasicEmail">
              <Form.Label style={{fontSize:"15px"}}>Description</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Type something" name = 'description' value={prodGrpDescription} onChange={(e)=>setProdGrpDescription(e.target.value)} />
          </Form.Group>
            </div>
      </div>
    );
  };

  const addedProductAndProdGrpAfterCheck = () => {
    // const checkedProductLength = combProdAndProdGrpArr?.length && combProdAndProdGrpArr?.filter((item) => item.checked == true);
    return (
      <div className="servicesWithCheckBoxScroll w-100">
      <div className="d-flex flex-wrap justify-content-center">
        {combProdAndProdGrpArr?.length &&
          combProdAndProdGrpArr.map((item, ind) =>
            item.checked === true ? (
              <Tooltip text={`${item?.serviceGrpId ? item?.serviceGrpName : item?.serviceName}`}>
              <div key={ind} className="addedProducts rounded-pill d-flex justify-content-center align-items-center mx-2 mt-2">
                 {item?.serviceGrpId ? <img src="./images/redProductGroup.svg" style={{ height: "17px", width: "17px" }} alt="Product Icon" className="me-1"/> : 
                 <img src="./images/product.svg" style={{ height: "15px", width: "15px" }} alt="Product Icon" className="me-1"/>}
                <div className="packagesServices">{`${item?.serviceGrpId ? item?.serviceGrpName : item?.serviceName}`}</div>
                <div className="ms-2 cursor-pointer" style={{fontSize:"14px"}} onClick={()=>createPackage(item,"removeProdAndGrpWhileUpdate")}>x</div>
              </div>
              </Tooltip>
            ) : null
          )}
      </div>
          <div className="serviceProviderSelect d-flex justify-content-center w-100">
            <Form.Group className="w-100 p-2" controlId="formBasicEmail">
              <Form.Label style={{fontSize:"15px"}}>Description</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Type something" name = 'description' value={packDescription} onChange={(e)=>setPackDescription(e.target.value)} />
          </Form.Group>
          </div>
      </div>
    );
  };

  const toSearchServiceInProdGrp = () => {
    return (
      <div className="" style={{height:"19rem"}}>
        <div className="ms-3 mt-3" style={{ fontSize: "17px", color: "rgba(147, 147, 147, 1)" }}>
          Added products:
        </div>
        {addproductForProdGrp ? (
          <div className="d-flex flex-column align-items-center" style={{ height: "16rem" }}>
            {addedProductAfterCheck()}
            <div className="mt-auto d-flex flex-column align-items-center w-100">
              {cardsInnerBorderLine("createPackageUpperBorder")}
              <div className="rounded-pill d-flex justify-content-center align-items-center text-white cursor-pointer createProductBtn" onClick={() => createProdGrp()}>
              <div className="fs-5 mx-1 mb-1">
                <img src="./images/updateTick.svg" style={{ height: "19px", width: "19px" }} alt="Edit Icon"/>
              </div>
                <div>{`${(arrayTwoShow == true && actionOfPackage == "editProdGrp") ? "Update" : "Create"} Product Group`}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-start">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "200px" }}>
              <div className="d-flex justify-content-center">
                <img src="./images/searchProductMsgImage.svg" style={{ width: "100px", height: "66px" }} alt="Search Icon"/>
              </div>
              <div className="d-flex justify-content-center mt-2" style={{fontSize: "12px", color: "rgba(147, 147, 147, 1)", opacity: "0.6",}}>
                Search above to add products
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const toSearchServiceInSubsc = () => {
    return (
      <div className="" style={{ height: "19rem" }}>
        <div className="ms-3 mt-3" style={{ fontSize: "17px", color: "rgba(147, 147, 147, 1)" }}>
          Added Products/Product Groups:
        </div>
        {addproductForPackage ? (
          <div className="d-flex flex-column align-items-center" style={{ height: "16rem" }}>
            {addedProductAndProdGrpAfterCheck()}
            <div className="mt-auto d-flex flex-column align-items-center w-100">
              {cardsInnerBorderLine("createPackageUpperBorder")}
              <div className="rounded-pill d-flex justify-content-center align-items-center text-white cursor-pointer createProductBtn" onClick={() => createPackage()}>
              <div className="fs-5 mx-1 mb-1">
                <img src="./images/updateTick.svg" style={{ height: "19px", width: "19px" }} alt="Edit Icon"/>
              </div>
                <div>{`${(arrayThreeShow == true && actionOfPackage == "editPackage") ? "Update" : "Create"} Package`}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-start">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "200px" }}>
              <div className="d-flex justify-content-center">
                <img src="./images/searchProductMsgImage.svg" style={{ width: "100px", height: "66px" }} alt="Search Icon"/>
              </div>
              <div className="d-flex justify-content-center mt-2" style={{ fontSize: "12px", color: "rgba(147, 147, 147, 1)", opacity: "0.6",}}>
                Search above to add product/product group
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const servicesWithCheckBox = (e) => {
    return (
      <>
        <div style={{}}>
          <div style={{ fontSize: "15px" }} className="ms-3 mt-2">"{`${searchServiceWithCheckbox(e)?.length} results found`}"</div>
          {horizontalBorderLine()}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="servicesWithCheckBoxScroll ms-3" style={{ fontSize: "14px", width: "90%" }}>
            {arrayOne.length &&
              searchServiceWithCheckbox(e).map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <Form.Check className="">
                        <Form.Check.Input type="checkbox" onChange={(e) => checkedProduct(item,e)}/>
                        <Form.Check.Label className="ms-2 mt-1">{item.serviceName}</Form.Check.Label>
                      </Form.Check>
                    </div>
                    {/* <div className="me-2">Logo</div> */}
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const ProdAndProdGrpCombineList = (e) =>{
    return(
      <div className="servicesWithCheckBoxScrollInPack" style={{width:"98%"}}>
      {searchServiceWithCheckbox(e).length > 0 &&
        searchServiceWithCheckbox(e)?.map((item, ind) =>
          // (item.isVisible == true && item.isActive == true) ? (
            <div className="m-2 card" style={{ cursor: "pointer" }}>
              <div draggable="true" className="p-2" style={{boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",fontSize: "15px",}}>
                <div className="d-flex justify-content-between align-items-center" style={{ width: "95%" }}>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <Form.Check className="">
                        <Form.Check.Input type="checkbox" onChange={(e) => checkedProductOrProdGrp(item,e)}/>
                        <Form.Check.Label className="ms-2 mt-1">
                         {item?.serviceGrpId ? <img src="./images/redProductGroup.svg" style={{ height: "17px", width: "17px" }} alt="Product Icon" className="me-1"/> : 
                          <img src="./images/product.svg" style={{ height: "17px", width: "17px" }} alt="Product Icon" className="me-1"/>}
                        {`${item?.serviceGrpId ? item?.serviceGrpName : item?.serviceName}`}
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  </div>
                </div>
               {(item?.serviceGrpId && item?.services?.length > 0) && <div>
                <div className="d-flex justify-content-center pt-2" style={{ height: "10px" }}>{cardsInnerBorderLine()}</div>
                <div className="scroll-container" id={`scroll-containerProdGrpInPack${ind}`} style={{ width: "100%" }}>
                  <div className="d-flex pt-1">
                    {item.services.map((serv, i) => {
                      return (
                        <Tooltip text={serv.serviceName}>
                        <div className="d-flex justify-content-between align-items-center mx-1" style={{width: "40%",height: "25px",backgroundColor: "rgba(114, 12, 32, 0.1)",color: "#720c20",borderRadius: "20px",border: "1px solid #720c20",}}>
                          <div className="d-flex justify-content-center align-items-center">
                          <div className="ms-1"><img src="./images/product.svg" style={{ height: "15px", width: "15px" }} alt="Product Icon"/></div>
                            <div className="ms-1 mt-1 packagesServices">{serv.serviceName}</div>
                            </div>
                        </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
                </div>}
  
                {/* </div> */}
                {(item?.serviceGrpId && item?.services?.length > 0) && 
                <div className="w-100 d-flex justify-content-between align-items-center fs-5 mt-1" style={{ height: "10px" }}>
                    <div className="fw-bold" onClick={()=>scrollLeft(ind,"InProductGrpWhileMakePack")}>&larr;</div>
                    <div className="fw-bold" onClick={()=>scrollRight(ind,"InProductGrpWhileMakePack")}>&rarr;</div>
                </div>}
              </div>
            </div>
          // ) : "" 
          )}
      </div>
    )
  }

  return (
    <>
    {showDeleteConfirmModal == true && <DeleteConfirmModal visible = {showDeleteConfirmModal} titleText = "Confirmation" bodyContent = {`Are you sure, you want to delete this ${messageTypeFor}?`} deleteStatus = {confirmDelete} hideModal = {setShowDeleteConfirmModal}/>}
      <Row
        lg={12}
        xl={12}
        className="d-flex justify-content-center mx-auto p-3"
      >
        {/* ------------------------------------ Add Product/Service ----------------------------- */}
        <Col xl={4} lg={4} md={6} className="mt-2">
          <div className="service-manage ps-2 pe-2">
            {serviceMap == true ? addServiceHeaderOnClick() : addServiceHeaderBeforeClick()}
          </div>
          <div className="packageLowerboxContainer">
          <div className="packageLowerbox" style={serviceMap == true ? { height: "23.5rem" } : { height: "19rem" }}>
            {/* ----------------------------------- To add Services ------------------------------------------- */}
            {serviceMap == true ? (
              <div className="justify-content-center d-flex " style={{ height: "23.5rem" }}>
                <div className="d-flex flex-column align-items-center w-100">
                  <div className="mt-5 serviceProviderSelect w-100">
                  <Form.Group className="w-100 p-2" controlId="formBasicEmail">
                    <Form.Label style={{fontSize:"15px"}}>Description</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Type something" name = 'description' value={prodDescription} onChange={(e)=>setProdDescription(e.target.value)} />
                </Form.Group>
                  </div>
                  <div onClick={() => createServices()} className="rounded-pill mt-auto mb-4 d-flex justify-content-center align-items-center text-white cursor-pointer createProductBtn">
                    <div className="fs-5 mx-1 mb-1">+</div>
                    <div>{`${updateServiceNameId != null ? "Update" : "Create"} Product`}</div>
                  </div>
                </div>
              </div>
            ) : (
              //   --------------------------------------- Added Service ----------------------------------------
              <div className="d-flex justify-content-center align-items-center">
                <div className="subscriptonScroll" style={{ width: "95%"}}>
                  {arrayOne.length > 0 &&
                    serachServices("1")?.map((arr, map) => {
                      return (
                        <div className="m-2 card" style={{ cursor: "pointer" }}>
                          <div key={map} id={map} draggable="true" className="p-2" style={{boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",fontSize: "15px",}}>
                            <div className="ms-1">{arr.serviceName}</div>
                            <span className="close position-absolute top-0 end-0 me-1" id={map} onClick={(e) => deleteService(e)}>x</span>
                            <div className="d-flex justify-content-center pt-3" style={{ height: "10px" }}>{cardsInnerBorderLine()}</div>
                            <div className="mt-2 ms-1" style={{color:"rgb(131, 131, 131)"}}>{arr.description}</div>
                            <div className="d-flex justify-content-between pt-3">
                              <div className="ms-1"></div>
                              <div className="d-flex justify-content-center align-items-center ms-1" style={{width: "20%",height: "25px",backgroundColor: "#720c20",borderRadius: "20px", }}
                                onClick={() => editServiceName(arr)}
                              >
                                <div className="mb-1">
                                  <img src="./images/EditSubscIcon.svg" style={{ height: "13px", width: "15px" }} alt="Edit Icon"/>
                                </div>
                                <div className="text-white ms-1" style={{ fontSize: "11px" }}>Edit</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
          </div>
        </Col>

        {/* --------------------------------- Add Product Group  -------------------------- */}
        <Col xl={4} lg={4} md={6} className="mt-2">
          <div className="service-manage ps-2 pe-2">
            {arrayTwoShow == true ? addProdGrpHeaderOnClick() : addProdGrpHeaderBeforeClick()}
            <div className="d-flex justify-content-center">
              <div style={{height: "1.5px",width: "97%", backgroundColor: "white",marginBottom: "20px",}}>
                {/* <hr style={{border: "2px solid white"}}/> */}
              </div>
            </div>

            <div className="d-flex searchInputMainDiv" style={{ height: "50px" }}>
              <div className="searchInputInnerDiv d-flex" style={{ width: arrayTwoShow ? "100%" : "80%" }}>
                <Search placeholder={`${arrayTwoShow && !checkServicesInProdGrp ? "Search product to add" : "Search product group"}`} className="searchInput" id="Services" 
                value={inputProdGrpSearch}
                onChange={(e) => searchWhileMakeProdGrp(e)}/>
              </div>
              {!arrayTwoShow && (
                <div className="addServiceBtn d-flex justify-content-center align-items-center cursor-pointer" onClick={addProductGrp}>
                  <h4 className="text-white mt-2">+</h4>
                </div>
              )}
            </div>
          </div>

          <div className="packageLowerboxContainer">
          <div className="packageLowerbox" style={{display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            {(arrayTwoShow && (actionOfPackage == "editProdGrp" || !checkServicesInProdGrp)) ? (toSearchServiceInProdGrp()) : arrayTwoShow && checkServicesInProdGrp ? (
              // <div className="border border-danger">
              <>
                {servicesWithCheckBox("InProdGrp")}
                {horizontalBorderLine("lowerCheckbxListBorder")}
                <div className="d-flex justify-content-center align-items-center my-3">
                  <div onClick={() => addProductOnProdGrpFunc()} className="rounded-pill mt-auto d-flex justify-content-center align-items-center text-white cursor-pointer addProductBtn">
                    <div className="mx-1 mb-1" style={{ fontSize: "18px" }}>
                      +
                    </div>
                    <div>Add Product</div>
                  </div>
                </div>
              </>
              // </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div className="subscriptonScroll" style={{ width: "95%" }}>
                  {productGrps.length > 0 &&
                    serachServices("2")?.map((item, ind) =>
                      // (item.isVisible == true && item.isActive == true) ? (
                        <div className="m-2 card" style={{ cursor: "pointer" }}>
                          <div draggable="true" className="p-2" style={{boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",fontSize: "15px",}}>
                            <div className="d-flex justify-content-between align-items-center" style={{ width: "95%" }}>
                              <div className="ms-1 dotShow">{item.serviceGrpName}</div>
                              <div className="d-flex justify-content-center align-items-center" style={{width: "25%",height: "25px",backgroundColor: "#720c20",borderRadius: "20px",}} onClick={(e)=>editProdGrpFunc(e,item,"editProdGrp")}>
                                <div className="mb-1"><img src="./images/EditSubscIcon.svg" style={{ height: "13px", width: "15px" }} alt="Edit Icon"/></div>
                                <div className="text-white ms-1" style={{ fontSize: "11px" }}>Edit</div>
                              </div>
                            </div>
                            <span className="close position-absolute top-0 end-0 me-1" id={ind} onClick={(e) => deleteProdGrp(e)}>x</span>
                            <div className="d-flex justify-content-center pt-2" style={{ height: "10px" }}>{cardsInnerBorderLine()}</div>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div className="scroll-container"
                          //    id="scroll-container" 
                             id={`scroll-container${ind}`}
                             style={{ width: "100%" }}>
                              <div className="d-flex pt-3">
                                {item.services.map((serv, i) =>
                                serv?.isActive == true && (
                                    <Tooltip text={serv.serviceName}>
                                    <div className="d-flex justify-content-between align-items-center mx-1" style={{width: "40%",height: "25px",backgroundColor: "rgba(114, 12, 32, 0.1)",color: "#720c20",borderRadius: "20px",border: "1px solid #720c20",}}>
                                      <div className="d-flex justify-content-center align-items-center">
                                      <div className="ms-1"><img src="./images/product.svg" style={{ height: "15px", width: "15px" }} alt="Product Icon"/></div>
                                        <div className="ms-1 mt-1 packagesServices">{serv.serviceName}</div>
                                        </div>
                                      <div className="m-1" onClick={() =>removeServiceFromProdGrp(item,serv.serviceId)}>x</div>
                                    </div>
                                    </Tooltip>
                                  ))}
                              </div>
                            </div>
                            <div className="mt-2 ms-1" style={{color:"rgb(131, 131, 131)"}}>{item.description}</div>
  
                            {/* </div> */}
                            <div className="w-100 d-flex justify-content-between align-items-center fs-5 mt-3" style={{ height: "10px" }}>
                              <div className="fw-bold" onClick={()=>scrollLeft(ind,"InProductGrp")}>&larr;</div>
                              <div className="fw-bold" onClick={()=>scrollRight(ind,"InProductGrp")}>&rarr;</div>
                            </div>
                          </div>
                        </div>
                      // ) : "" 
                      )}
                </div>
              </div>
            )}
          </div>
          </div>
        </Col>

        {/* --------------------------------- Add Packages/Subscription  -------------------------- */}
        <Col xl={4} lg={4} md={6} className="mt-2">
          <div className="service-manage ps-2 pe-2">
            {arrayThreeShow == true ? addSubsHeaderOnClick() : addSubsHeaderBeforeClick()}
            <div className="d-flex justify-content-center">
              <div style={{height: "1.5px",width: "97%", backgroundColor: "white",marginBottom: "20px",}}>
                {/* <hr style={{border: "2px solid white"}}/> */}
              </div>
            </div>

            <div className="d-flex searchInputMainDiv" style={{ height: "50px" }}>
              <div className="searchInputInnerDiv d-flex" style={{ width: arrayThreeShow ? "100%" : "80%" }}>
                <Search placeholder={`${arrayThreeShow && !checkServicesInPackage ? "Search product to add" : "Search package"}`} className="searchInput" id="Services" value={inputSubsGrpSearch} onChange={(e) => searchWhileMakePackage(e)}/>
              </div>
              {!arrayThreeShow && (
                <div className="addServiceBtn d-flex justify-content-center align-items-center cursor-pointer" onClick={addSubscriptions}>
                  <h4 className="text-white mt-2">+</h4>
                </div>
              )}
            </div>
          </div>

          <div className="packageLowerboxContainer">
          <div className="packageLowerbox">
            {(arrayThreeShow && (actionOfPackage == "editPackage" || !checkServicesInPackage)) ? (toSearchServiceInSubsc()) : arrayThreeShow && checkServicesInPackage ? (
              <div>
                {/* {servicesWithCheckBox("InPackage")} */}
                {ProdAndProdGrpCombineList("InPackage")}
                {horizontalBorderLine("lowerCheckboxListBorder")}
                <div className="d-flex justify-content-center align-items-center my-3">
                  <div onClick={() => addProductOnPcakageFunc()} className="rounded-pill mt-auto d-flex justify-content-center align-items-center text-white cursor-pointer addProductBtn">
                    <div className="mx-1 mb-1" style={{ fontSize: "18px" }}>
                      +
                    </div>
                    <div>Add Product</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div className="subscriptonScroll" style={{ width: "95%" }}>
                  {combineSubsArr.length > 0 &&
                    serachServices("3")?.map((item, ind) =>
                      (item.isVisible == true && item.isActive == true) ? (
                        <div className="m-2 card" style={{ cursor: "pointer" }}>
                          <div draggable="true" className="p-2" style={{boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",fontSize: "15px",}}>
                            <div className="d-flex justify-content-between align-items-center" style={{ width: "95%" }}>
                              <div className="ms-1 dotShow">{item.skuListName}</div>
                              <div className="d-flex justify-content-center align-items-center" style={{width: "25%",height: "25px",backgroundColor: "#720c20",borderRadius: "20px",}} onClick={(e)=>editPackage(e,item,"editPackage")}>
                                <div className="mb-1"><img src="./images/EditSubscIcon.svg" style={{ height: "13px", width: "15px" }} alt="Edit Icon"/></div>
                                <div className="text-white ms-1" style={{ fontSize: "11px" }}>Edit</div>
                              </div>
                            </div>
                            <span className="close position-absolute top-0 end-0 me-1" id={ind} onClick={(e) => deletPackage(e)}>x</span>
                            <div className="d-flex justify-content-center pt-2" style={{ height: "10px" }}>{cardsInnerBorderLine()}</div>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div className="scroll-container"
                             id={`scroll-containerInPackage${ind}`}
                             style={{ width: "100%" }}>
                              <div className="d-flex pt-3">
                                {item.services.map((serv, i) =>
                                serv?.isActive == true && (
                                    <Tooltip text = {serv.skuServiceName}>
                                    <div className="d-flex justify-content-between align-items-center mx-1" style={{width: "40%",height: "25px",backgroundColor: "rgba(114, 12, 32, 0.1)",color: "#720c20",borderRadius: "20px",border: "1px solid #720c20",}}>
                                       <div className="d-flex justify-content-center align-items-center">
                                      <div className="ms-1">{serv?.services?.length > 0 ? <img src="./images/redProductGroup.svg" style={{ height: "15px", width: "15px" }} alt="Product Icon"/> : <img src="./images/product.svg" style={{ height: "15px", width: "15px" }} alt="Product Icon"/>}</div>
                                      <div className="ms-2 packagesServices">{serv.skuServiceName}</div>
                                      </div>
                                      <div className="m-1 mt-0" onClick={() =>removeServiceFromPackage(item,serv.skuServiceId)}>x</div>
                                    </div>
                                    </Tooltip>
                                  ))}
                              </div>
                            </div>
                            <div className="mt-2 ms-1" style={{color:"rgb(131, 131, 131)"}}>{item.description}</div>
  
                            {/* </div> */}
                            <div className="w-100 d-flex justify-content-between align-items-center fs-5 mt-3" style={{ height: "10px" }}>
                              <div className="fw-bold" onClick={()=>scrollLeft(ind,"InPackage")}>&larr;</div>
                              <div className="fw-bold" onClick={()=>scrollRight(ind,"InPackage")}>&rarr;</div>
                            </div>
                          </div>
                        </div>
                      ) : "" 
                      )}
                </div>
              </div>
            )}
          </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default PackageSunbscription;