import React, { useEffect, useState ,useContext} from "react";
import {
  Col,
  Modal,
  Row,
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Upload,
} from "antd";
import { UserContext } from "../../App";
import { Select } from "antd";
import "../../Styles/subtenant.css";
import Services from "../../network/Services";
import konsole from "../../network/konsole";
import Address from "../Address";
import commonLib from "../../control/commonLib";
import PhoneInput from "react-phone-input-2";
import { isValidFuntion,isNullUndefine } from "../../control/Constant";
const { Option } = Select;

export default function AddSubtents(props) {

  let objField = {
    "emails": [
      {
        "subContactTypeId": 0,
        "contactTypeId": 1,
        "emailAddresses": ''
      }
    ],
    "mobileNos": [
      {
        "subContactTypeId": 0,
        "contactTypeId": 1,
        "mobileNo": '',
        'countryCode':''
      }
    ]
  }

  const [category, setCategory] = useState([]);
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({});
  const [userId, setuserId] = useState("");
  const [loggedUserId, setLoggedUserId] = useState("")
  const [fileList, setFileList] = useState([]);
  const { setLoader } = useContext(UserContext)
  const [contactype, setContactype] = useState()
  const [countrycode, setCountrycode] = useState()
  const [addFields,setAddfields] = useState([objField])
  const [isDisables, setIsDisables] = useState([])
  const [isRender, setisRender] = useState(false)
  const subtenantId = props?.userData?.subtenantId ? props?.userData?.subtenantId : 0

  useEffect(()=>{
 

    if(isValidFuntion(props?.userData) && Object.keys(props?.userData).length !== 0){
      
      upDataUser()
      if(props?.userData?.contacts?.emails?.length > 0 || props?.userData?.contacts?.mobileNos?.length > 0){
        let emaildata;
        let mobiledata;
        if (props?.userData?.contacts?.emails?.length > 0) {
          emaildata = props?.userData?.contacts?.emails.map((e,index) => {
            const emailObj = {
              "subContactTypeId": e.subContactTypeId,
              "contactTypeId": e.contactTypeId, // typo here, should be e.contactTypeId
              "emailAddresses": e.emailAddresses,
              'subContactMapId': e.subContactMapId,
              'contactId': e.contactId
            }
            if (props?.userData?.contacts?.mobileNos?.length === 0) {
              mobiledata = [{
                "subContactTypeId": e.subContactTypeId,
                "contactTypeId": 1,
                "mobileNo": '',
                'countryCode': ''
              }];
            }
            form.setFieldsValue({
              [`categoryDataId-${index}`]:String(e.subContactTypeId)});
            return emailObj
          });
        
          // Check if mobileNos array is empty, if yes, assign a default mobiledata
         
         
        }
        else{
         
          emaildata = [
            {
              "subContactTypeId": 0,
              "contactTypeId": 1,
              "emailAddresses": ''
            }
          ]
        }





        if (props?.userData?.contacts?.mobileNos?.length > 0) {
          mobiledata = props?.userData?.contacts?.mobileNos.map((e, index) => {
            // Create the object for mobiledata
            const mobileDataObject = {
              "subContactTypeId": e.subContactTypeId,
              "contactTypeId": e.contactTypeId,
              "mobileNo": e.mobileNo,
              'subContactMapId': e.subContactMapId,
              'contactId': e.contactId
            };
            if(props?.userData?.contacts?.emails?.length == 0){
              emaildata = [
                {
                  "subContactTypeId": e.contactTypeId,
                  "contactTypeId": 1,
                  "emailAddresses": ''
                }
              ];

            }
                 
            // Set the field value for each category
            form.setFieldsValue({
              [`categoryDataId-${index}`]:String(e.subContactTypeId)});
        
            return mobileDataObject;
          });

        
        
        

        }else{
          mobiledata = [
            {
              "subContactTypeId": 0,
              "contactTypeId": 1,
              "mobileNo": '',
              'countryCode':''
            }
          ]

        }

       let mapdata = props?.userData?.contacts?.emails.length < props?.userData?.contacts?.mobileNos.length ? props?.userData?.contacts?.mobileNos : props?.userData?.contacts?.emails 
       
        let objFields = mapdata.map((e,index)=> {
        return {
          'emails': [emaildata[index]],
          'mobileNos': [mobiledata[index]]
        }
        })
        setAddfields(objFields)

        let contacttypeids = mapdata
        let addFieldsfilter = contactype?.filter((e)=> {
          return !contacttypeids.some(item=>((e?.value.toString() == item?.subContactTypeId.toString()  && item?.emailAddresses != '')))
        })
      
        setContactype(addFieldsfilter)
        if(!isNullUndefine(addFieldsfilter)){
                setContactype(addFieldsfilter)
        }
       
      }
    }else{
      setAddfields([objField])
      setFileList([])
    }
  },[])



  



  const upDataUser = ()=>{
    let subtenantCategoryId = String(props.userData?.subtenantCategoryId)
     form.setFieldsValue({
        subtenantName:props.userData?.subtenantName,
        subtenantCategoryId:subtenantCategoryId,
        License:props.userData?.licenseKey
      })
      if(props.userData?.subtenantLogoUrl){
        setFileList( [{uid: '-1', name: 'image.png', status: 'done',url: props.userData?.subtenantLogoUrl}])
      }

  }
  
  const handleChange = (value) => {
    konsole.log(`selected ${value}`);
    form.setFieldsValue({
      'subtenantCategoryId':value
    })
  };

  const [selectValue,seSelectValue]=useState('')
 
  const handleChangeContact = (value,index) =>{


    seSelectValue(value)
    setErrors(prevErrors => ({
      ...prevErrors,
      [`subContactTypeId-${index}`]: '' // Replace the value of subContactTypeId for the specific index with an empty string
  }));

    const newAddFields = [...addFields]; 
    newAddFields[index].emails[0].subContactTypeId = value.toString(); 

    newAddFields[index].mobileNos[0].subContactTypeId = value.toString(); 
    setAddfields(newAddFields); 
   
    setisRender(true)
    if(isDisables.length > 0){
      setIsDisables([...isDisables, value.toString()])
    }
   
  }
 
 
  const addNewfield = (index) => {
    const newErrors = {};
    let number = addFields[index].mobileNos[0].mobileNo.substring(addFields[index].mobileNos[0].countryCode?.length)
  
    if (addFields[index].mobileNos[0].subContactTypeId == 0 || addFields[index].emails[0].subContactTypeId == 0) {
      newErrors[`subContactTypeId-${index}`] = 'Please select the category';
    }
  
   
    if(!addFields[index]?.mobileNos[0]?.mobileNo){
      newErrors[`mobile-${index}`] = 'Please enter your number';
    }
    if(!addFields[index]?.emails[0]?.emailAddresses){
      newErrors[`email-${index}`] = 'Please enter your email';
    }
    if (addFields[index].mobileNos[0].mobileNo.length > 0 && number.toString().length < 10) {
    
      newErrors[`mobile-${index}`] = 'Please enter a valid number';
    }
  
    let regexName = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
    if (!isNullUndefine(addFields[index].emails[0].emailAddresses) && !regexName.test(addFields[index].emails[0].emailAddresses)) {
      newErrors[`email-${index}`] = 'Please enter a valid email';
    }
  
    // Set errors and return if there are any
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    // No errors, proceed with adding a new field
    const objField = {
      "emails": [
        {
          "subContactTypeId": 0,
          "contactTypeId": 1,
          "emailAddresses": ''
        }
      ],
      "mobileNos": [
        {
          "subContactTypeId": 0,
          "contactTypeId": 1,
          "mobileNo": '',
          'countryCode': ''
        }
      ]
    };
  
    // Update countryCode if not provided
    if (!isNullUndefine(addFields[index].mobileNos[0].mobileNo && isNullUndefine(addFields[index].mobileNos[0].countryCode))) {
      addFields[index].mobileNos[0].countryCode = "+1";
    }
  
    // Update contactype based on conditions


    if (addFields[index].mobileNos[0].subContactTypeId !== 0 && (number.toString().length >= 10 || !isNullUndefine(addFields[index]?.emails[0]?.emailAddresses)) ) {
     
     // Extract subContactTypeId values from the emails array of each element in addFields
      const contacttypeids = addFields.flatMap((e) => e.emails.map(email => email.subContactTypeId));
      
      // Filter addFields based on specified conditions
      const addFieldsfilter = contactype.filter((e) => {
        return !contacttypeids.some(subContactTypeId => (
          (e?.value.toString() === subContactTypeId.toString() && e?.emailAddresses !== '') ||
          e?.value === addFields[index]?.mobileNos[0].subContactTypeId
        ));
      });
          let id = addFields[index]?.emails[0]?.subContactTypeId.toString()|| addFields[index]?.mobileNos[0]?.subContactTypeId.toString()
          setIsDisables([...isDisables, id.toString()]);
    
      setContactype(addFieldsfilter);
    } else {
      setContactype(contactype);
    }
  
    // Update state
    setAddfields([...addFields, objField]);
  };
  
  
  const onChange = ({ fileList: newFileList }) => {
    if (newFileList?.length > 0) {
      const isJpgOrPng = newFileList[0].type === "image/jpeg" || newFileList[0].type === "image/png" ;
      if (!isJpgOrPng) return;
     }
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
    
      message.warn("You can only upload JPG/PNG file!")
    }

    const isLt2M = file.size / 1024 / 1024 > 1;
  

    if (isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  };


  useEffect(() => {
    let loggedUserId = commonLib.getSessionStoarge('loggedUserId')
    let userId = commonLib.getObjFromStorage('stateObj').userId
    konsole.log('respaaa', loggedUserId, userId)
    setuserId(userId)
    setLoggedUserId(loggedUserId)
    getContactCatgoryTYPE()
    getdata();
    Services.getcountrycode().then((response) => {
      setCountrycode(response.data.data);
    }).catch((err) => {
      konsole.log("Error", err);
    });
  }, [])

  const getContactCatgoryTYPE = () => {
    let promise = Services.getContactCatgory();
    promise
      .then((res) => {
        setContactype(res.data.data);
      })
      .catch((err) => {
        konsole.log("ERR", err);
        message.err("Enter the valid data")
      });
  };

  const getdata = () => {
    let promise = Services.getSubtenantCategory();
    promise
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => {
        konsole.log("ERR", err);
        message.err("Enter the valid data")
      });
  };
  const checkJson =()=>{
    const newErrors = {};
    addFields?.map((ele, index) => {
      if ((ele.mobileNos[0]?.subContactTypeId === 0)) {
        newErrors[`subContactTypeId-${index}`] = 'Please select the category';
      }
     
      if(isNullUndefine(ele?.emails[0]?.emailAddresses)){
        newErrors[`mobile-${index}`] = 'Please enter your number';
      }
      if(isNullUndefine(ele.mobileNos[0]?.mobileNo)){
        newErrors[`email-${index}`] = 'Please enter your email';
      }

      if (ele.mobileNos[0].mobileNo.length > 0 && ele.mobileNos[0]?.mobileNo.length < 10) {
        newErrors[`mobile-${index}`] = 'Please enter a valid number';
      }
      let regexName = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!isNullUndefine(ele.emails[0]?.emailAddresses) && !regexName.test(ele.emails[0]?.emailAddresses)) {
        newErrors[`email-${index}`] = 'Please enter a valid email';
      }

    })
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
  }
    return newErrors
  }










  const uploadfileapi = () => {
     if (isNullUndefine(fileList)) {
      message.warn("Please upload a file");
    }else if (isNullUndefine(checkJson())) {
        // Handle the case where checkJson() returns null or undefined
    }else if (fileList[0].uid === '-1') {
      EndedData(props?.userData?.subtenantLogoUrlId);
    } 
     else {
      const UserId = loggedUserId;
      const UploadedBy = loggedUserId;
      const FileTypeId = 16;
      const FileCategoryId = 9;
      const fileStatusId = 1;
      
      const File = fileList[0].originFileObj;
      const fileSizeInBytes = File.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;
    
      if (fileSizeInMB <= 1) {
        let response = Services.postfileuploadspeaker(File, UploadedBy, UserId, FileTypeId, FileCategoryId, fileStatusId);
        response.then((res) => {
          EndedData(res.data.data.fileId);
        }).catch((err) => {
          message.error("Failed to upload file. Please try again.");
          EndedData();
        });
      } else {
        message.warn("File size exceeds 1MB limit");
      }
    }
     
  }
    


  const EndedData = (uploadfileid) => {

    let AddressFieldsValue = form.getFieldValue()
    let mainAddress = AddressFieldsValue.address;
    let mainCity = AddressFieldsValue.city;
    let mainState = AddressFieldsValue.state;
    let mainCountry = AddressFieldsValue.country;
    let mainZipCode = AddressFieldsValue.zipcode
    let mainName = AddressFieldsValue.subtenantName

    konsole.log("FieldValues", mainAddress, mainCity, mainState, mainCountry, mainZipCode, mainName)
    sendAddress(mainAddress, mainCity, mainState, mainCountry, mainZipCode, mainName,uploadfileid)

  }

  const sendAddress = (mainAddress, mainCity, mainState, mainCountry, mainZipCode, mainName,uploadfileid) => {
    const AddressData = Services.addressData(null, null, mainAddress, null, null, mainZipCode, 'no', mainCity, mainState, mainCountry, 1, loggedUserId)
    setLoader(true)
    AddressData.then((res) => {
    
      let addressId = res.data.data.addressId;
      const createdBy = userId;
      FormSubmit(addressId, createdBy,uploadfileid);
      
    })
      .catch((err) => {
        konsole.log(err,"sskk")
        message.error("Enter valid details")
        setLoader(false)
      }).finally(() => {
        konsole.log("Finish")
    setLoader(false)
      })
  }

  const FormSubmit = (addressId, createdBy,uploadfileid) => {
    // form.submit();
    const contactData = form.getFieldValue()
    
    let emails = addFields.flatMap(e=>e.emails)
    let contacts = addFields.flatMap(e=>e.mobileNos)
    let subtenantJosn = {
      // 'subtenantId':0,
      "subtenantName": contactData.subtenantName,
      "subtenantCategoryId": contactData.subtenantCategoryId,
      "subtenantLogoUrlId": uploadfileid,
      "addressId": addressId,
      "licenseKey": contactData.License,
      // "createdBy": createdBy,
      "contacts": {
        "emails": emails,
        "mobileNos": contacts
      }
    }

    if(subtenantId != 0){
      subtenantJosn['subtenantId'] = subtenantId
      subtenantJosn['updatedBy'] = createdBy
    }else{
      subtenantJosn['createdBy'] = createdBy
    }
    konsole.log(subtenantJosn,'subtenantJosn')
    if(subtenantId == 0){
    Services.postSubtenantDetail(subtenantJosn).then((res) => {
        props.onCancel();
        props.tableData();
        form.resetFields()

        message.success("Firm created Successfully")

      })
      .catch((err) => {
        konsole.log("err", err);
        message.error("Please enter the valid data")
    
      })
    }else{
      Services.putSubtenantDetail(subtenantJosn).then((res) => {
        props.onCancel();
        props.tableData();
        form.resetFields()
        message.success("Firm updated Successfully")

      })
      .catch((err) => {
        konsole.log("err", err);
        message.error("Please enter the valid data")
    
      })
    }
  };
  
  const onFinish = (e) =>{
  uploadfileapi()
  }
  const onChangePhone = (value, country,index) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [`mobile-${index}`]: '' // Replace the value of subContactTypeId for the specific index with an empty string
  }));
    const newAddFields = [...addFields]; // Create a copy of addFields array
    newAddFields[index].mobileNos[0].countryCode = `+${country?.dialCode}`
    newAddFields[index].mobileNos[0].mobileNo = `+${value}`; // Update the specific field
    setAddfields(newAddFields); // Set the updated array as the new state
  }






  const onChangeEmail=(e,index) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [`email-${index}`]: '' // Replace the value of subContactTypeId for the specific index with an empty string
  }));
    const newAddFields = [...addFields]; // Create a copy of addFields array
    newAddFields[index].emails[0].emailAddresses = e.target.value; // Update the specific field
    setAddfields(newAddFields); // Set the updated array as the new state
  }

  return (
    <Modal
      visible={props.visible}
      onOk={props.onOk}
      onCancel={props.onCancel}
      footer={null}
      okText="Add"
      centered={true}
      maskClosable={false}
    >
      <Row className="modal-border">
        <Col span={24}>
         
          <Form form={form} onFinish={onFinish} >
          <Row>
            <Col span={6}>
            <Form.Item>Logo
          <Upload className="pt-2" listType="picture-card" fileList={fileList}onChange={onChange}onPreview={onPreview} beforeUpload={beforeUpload}>
                {fileList.length < 1 && "+ Upload Profile Image"}
          </Upload>
          </Form.Item>
            </Col>
            <Col span={18}>
            <Form.Item
              name="subtenantCategoryId"
              rules={[
                {
                  required: true,
                  message: "Please enter select Your category",
                },
              ]}
            style={{marginTop:"1.7rem"}}
            >
              <Select
                name="subtenantCategoryId"
                placeholder='Category'
                style={{ width: 200 }}
                onChange={(e)=>{handleChange(e)}}
              >
                {category.map((categoryprops) => (<Option value={categoryprops.label} key={categoryprops.label}>
                    {categoryprops.value}
                  </Option>))}
              </Select>
            </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11} className="me-5">
                <Form.Item
                  name="subtenantName"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value || value.trim() === "") {
                          return Promise.reject("Please enter your Firm name");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input style={{ width: "100%", textTransform: "capitalize" }} placeholder="Name" />
                </Form.Item>


            </Col>
            <Col span={11}className="ms-1">
            <Form.Item
              name="License"
              
              className="input"
              style={{ width: "100%" }}
            >
              <Input style={{ width: "100%" }} placeholder="License Key" />
            </Form.Item>
            </Col>
          </Row>
            <Address form={form} updatePartnerUsersData={props?.userData} subtinentData={true} />
             <Row>
 
             <p className="mt-2">Contact Type:</p>
                <Col span={24} >
                  {addFields?.map((field,index)=>{
                    return <>
                  <Row gutter={[16, 0]}>
                 
                        <Col span={5} className="selectpicker">
                          <Form.Item
                            name={`categoryDataId-${index}`}
                            className="selectpicker"
                          >
                            <Select
                              className="newDropDown"
                              name={`categoryDataId-${index}`}
                              placeholder='Category'
                              style={{ height: "38px !important" }}
                              onChange={(e) => handleChangeContact(e, index)}
                            > 
                              {contactype?.map((i) => (<Option value={i.value}disabled={isDisables?.includes(i.value)} key={i.value}>
                                {i.label}
                              </Option>))}
                            </Select>
                          </Form.Item>



                          {errors[`subContactTypeId-${index}`] && <p className='text-danger mt-2'><div className="error">{errors[`subContactTypeId-${index}`]}</div></p>}


                        </Col>               
                    <Col span={7}>
                      <Form.Item
                         key={`email-${index}`}
                         name={`email-${index}`}
                        style={{ width: '100%',height:"37px"}}
                      >   
                        <Input style={{ width:'100%',height:"37px"}} placeholder="Email"className="p-1"
                          onChange={(e)=>onChangeEmail(e,index)}
                         
                          value={field?.emails[0]?.emailAddresses}
                        />
                  {errors[`email-${index}`] && <p className='text-danger mt-2'><div className="error">{errors[`email-${index}`]}</div></p>}

                      </Form.Item>
                    </Col>
                   
                    <Col span={6} style={{marginRight:"auto"}}>
                    <div className="passwordInputBorder">
                                <PhoneInput
                                    className="react-tel-input1 border-0 setInputFontSizen"
                                    regions={["america","europe","asia","oceania","africa"]}
                                    country="us"
                                    preferredCountries={["us"]}
                                    displayInitialValueAsLocalNumber={true}
                                    value={field.mobileNos[0]?.mobileNo || "+1"}
                                    onChange={(value,country) => onChangePhone(value,country,index)}
                                    specialOptionLabel={"Other"}
                                    placeholder=""
                                    name="mobile"
                                    countryCodeEditable={false}
                                />
                                </div>
                     
                      {errors[`mobile-${index}`] && <p className='text-danger mt-2'><div className="error">{errors[`mobile-${index}`]}</div></p>}

                    </Col>
                    <Col span={2} style={{height:"37px"}}>
                    {(contactype?.length > 1 && index === addFields.length - 1 && contactype?.length !== addFields.length)&& <Button style={{height:"37px"}} onClick={()=>{addNewfield(index)}}>+</Button>}
                    </Col>
                  </Row></>})}
                </Col>
            </Row>
            <Col style={{ display: "flex" }}></Col>
            <Col span={24} style={{ display: "flex" }}>
            <Form.Item   className="mb-0">
              <Button
                htmlType="submit"
                 style={{
                  margin: "2px auto",
                  backgroundColor: "#720C20",
                  color: "#ffffff",
                }}
              >
                {isValidFuntion(props?.userData) === true && Object.keys(props?.userData).length !== 0 ? "Update" : "Add"}
              </Button>
              </Form.Item>
            </Col>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}
