import React, { useState, useEffect, useRef } from "react";
import konsole from "../network/konsole";

const InputMask = (props) => {
  konsole.log("propsprops",props.value)
  const inputCard = useRef();

  const handleChange = () => {
    const cardValue = inputCard.current.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    konsole.log(cardValue, cardValue[2]);
    inputCard.current.value = !cardValue[2]
      ? cardValue[1]
      : `(${cardValue[1]}) ${cardValue[2]}${`${
          cardValue[3] ? `-${cardValue[3]}` : ""
        }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
    const numbers = inputCard.current.value.replace(/(\D)/g, "");
    props.onValueChange(numbers);
  };
  const filledPhoneNo = () => {
    konsole.log("filledPhoneNo",props.value);
    const cardValue = props.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    konsole.log(cardValue, cardValue[2]);
    inputCard.current.value = !cardValue[2]
      ? cardValue[1]
      : `(${cardValue[1]}) ${cardValue[2]}${`${
          cardValue[3] ? `-${cardValue[3]}` : ""
        }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
    // const numbers = inputCard.current.value.replace(/(\D)/g, "");
    {konsole.log("porps",props.value, inputCard.current.value)}
  };
  if (props.value === "0"){
 konsole.log("propsvalue",props.value)
   
  }
  useEffect(() => {
    // handleChange(props.value)

    if(props.value !== undefined && props.value !== "" && props.value !== null){
      filledPhoneNo();
    }else{
      // alert(props.value="1")
      // filledPhoneNo();
      inputCard.current.value=""
    }
    
  }, [props.value]);
  useEffect(() => {
      
  handleChange()
  }, []);

  return (
    <>
      <input
        className="ant-input"

        placeholder={(props?.placeholder !== undefined)?props?.placeholder :"Cell Number"}

        type="text"
        ref={inputCard}
        onChange={handleChange}
        // style={{ width: '75%' }}
        disabled={props.disabled}
        style={{"color":"black", width: '100%', border: "1px solid #d9d9d9"}}


      />
    </>
  );
};

export default InputMask;
