import React, { useContext, useEffect,useState } from "react";
import konsole from "../../network/konsole";
import "./Seminar.css";
import { StpperStepsContext } from "./SeminarFile";
import { Router, useNavigate } from "react-router-dom";
import Stepper from "./Stepper";
import commonLib from "../../control/commonLib";
const Preview = ({
  text,
  sendDataToPrev,
  form,
  callFunction,
  getAllIdBack,
  sendToPreview,
  setStopEditFunc,
  stopEditFunc,
  editFuncData,
  stopSpeakerEditFunc,
  setStopSpeakerEditFunc,
  setStopFareEditFunc,
  stopFareEditFunc,
  CallFunction,
  multiSpeakersData,
}) => {
  let navigate = useNavigate();

  const { setStepperSteps, setsendToPreview, venueAddressIdTextPublish } = useContext(StpperStepsContext);
  const [readMoreProfile, setReadMoreProfile] = useState(null)
  const [showLess, setShowLess] = useState(false)

  konsole.log("sendToPreviewsendToPreview", sendToPreview,multiSpeakersData);

  useEffect(() => {
    konsole.log("sendToPreviewsendToPreview", sendToPreview);
    if (sendToPreview == "1") {
      reverseClick();
    }
  }, [sendToPreview]);

  let getSeminarFilesDetails = JSON.parse(sendDataToPrev);
  console.log("MyPropss", getSeminarFilesDetails);

  const FixedResult = {
    seminarType: getSeminarFilesDetails.seminarType,
    topics: getSeminarFilesDetails.seminar.topics,
    seminarData: getSeminarFilesDetails.seminar.seminarData,
    seminartime: getSeminarFilesDetails.seminar.seminarTime,
    seminarSeatsNo: getSeminarFilesDetails.seminar.noOfSeats,
    seminarVenue: getSeminarFilesDetails.seminar.seminarVenue,
    seminarMapLink: getSeminarFilesDetails.seminar.mapLink,
    seminarVideoLink: getSeminarFilesDetails.seminar.videoLink,
    seminarDescription: getSeminarFilesDetails.seminar.description,
    seminarSpeakerimg: getSeminarFilesDetails.seminar.speaker.speakerImage,
    seminarSpeakerName: getSeminarFilesDetails.seminar.speaker.speakerName,
    seminarVideoLink: getSeminarFilesDetails.seminar.speaker.speakerVideoLink,
    seminarSpeakerDescription:
      getSeminarFilesDetails.seminar.speaker.speakerDescription,
    seminarOrgPartner:
      getSeminarFilesDetails.seminar.organizerInformation
        .seminarOrganizingPartner,
    seminarCoordinatorName:
      getSeminarFilesDetails.seminar.coordinator.coordinatorName,
    seminarCoordinatorContact:
      getSeminarFilesDetails.seminar.coordinator.coordinatorContact,
    seminarcoordinatorEmail:
      getSeminarFilesDetails.seminar.coordinator.coordinatorEmail,
    seminarFare: getSeminarFilesDetails.seminar.seminarFare.isSeminarPaid,
    seminarLocation: getSeminarFilesDetails.seminar.venueLocation,
    seminarVenueAddress: getSeminarFilesDetails.seminar.venueAddressIdApi,
    seminarSpeakerId: getSeminarFilesDetails.seminar.speakerId,
    seminarCoordinatorId: getSeminarFilesDetails.seminar.coordinatorId,
    seminarVenueName: getSeminarFilesDetails.seminar.seminarVenueName,
    seminarSubtenantId: getSeminarFilesDetails.seminar.subtenantId,
    seminarFixedPrice: getSeminarFilesDetails.seminar.seminarFare.fareAmount,
  };

  let ChangeDateData = commonLib.getFormattedDate(FixedResult.seminarData);
let splitTime = FixedResult.seminartime.split("")
let split12HourFormat = splitTime.splice(splitTime.length - 2, 2).join("")

  konsole.log("ChangeDateData", ChangeDateData);
  konsole.log("FixedResult.seminartimeFixedResult.seminartimeF",FixedResult.seminartime,FixedResult );
  konsole.log("FixedResult.seminartimeFixedResult.seminartime",splitTime.splice(splitTime.length - 2, 2).join("") );
  konsole.log("FixedResult.seminartimeFixedResult.seminartime",split12HourFormat );
  const reverseClick = () => {
    text(0);
    setStepperSteps(0);

    getAllIdBack({
      getSpeakerId: FixedResult.seminarSpeakerId,
      getCoordinatorID: FixedResult.seminarCoordinatorId,
      getVenvueName: FixedResult.seminarVenueName,
      getSubtenantID: FixedResult.seminarSubtenantId,
      getFixedPrice: FixedResult.seminarFixedPrice,
      getFreePaidStatus: FixedResult.seminarFare,
      getImgUrl: FixedResult.seminarSpeakerimg,
      backButton : true,
    });
    setsendToPreview("2");

    if (
      editFuncData !== null &&
      editFuncData !== undefined &&
      editFuncData !== ""
    ) {
      if (stopEditFunc == true) {
        setStopEditFunc(true);
      } else {
        setStopEditFunc(false);
      }

      if (stopSpeakerEditFunc == true) {
        setStopSpeakerEditFunc(true);
      } else {
        setStopSpeakerEditFunc(false);
      }

      if (stopFareEditFunc == true) {
        setStopFareEditFunc(true);
      } else {
        setStopFareEditFunc(false);
      }
    }
  };
  const metaPage = () => {
    form.submit(() => callFunction());
    CallFunction(venueAddressIdTextPublish)

    // text(2);
    setStepperSteps(2);
  };

  const filterSpeakerDescriptionLegth = (dataSpeakerProfile) => {
      return dataSpeakerProfile.substr(0, 25);
  };

  const showFullProfile=(items,index)=>{
    setReadMoreProfile(items?.speakerUserId)
    let setAtZero = [];
    let filterData = [];
    filterData = multiSpeakersData.splice(index, 1);
    setAtZero = multiSpeakersData.unshift(items);
    setShowLess(true)
    }

  console.log("editFuncDataPreviouse", FixedResult.seminarSpeakerName);

  return (
    <>
      <div className="container-fluid">
        <div>
          <p className="ms-2 fs-4 fw-bold seminartxt">Preview</p>
          <div className="ms-2">
            <h1 className="fw-bold seminarhead">
              {FixedResult.topics}
              <small className="fs-4 fw-bold text-dark">
                ( {FixedResult.seminarType},{FixedResult.seminarFare}{" "}
                {FixedResult.seminarFare == "Paid" &&
                  `: $${FixedResult.seminarFixedPrice}`}{" "}
                ){" "}
              </small>
            </h1>
            <p className="fs-4 fw-bold text-secondary ">
              By 
             
                  
                  <span className="fs-4 fw-bold seminartxt ms-2">
                   {multiSpeakersData?.map((ele,index)=>{
                    console.log("speakerasasa",ele)
                    return `${ele?.speakerName == undefined || ele?.speakerName == null || ele?.speakerName == "" ? ele?.fName +" "+ ele?.lName : ele?.speakerName}${(multiSpeakersData.length - 1 == index) ? "" : ', ' }`
                    
                   })} 
                  
                 </span>
               
              
              <br />
              {
                split12HourFormat == "pm" || split12HourFormat == "am" ?
                 `On ${ChangeDateData}, at ${FixedResult.seminartime}` :
                
               `On ${ChangeDateData}, at  ${commonLib.getFormattedTime(FixedResult.seminartime)}`
              }
              {FixedResult.seminarType == "Webinar" ? (
                <></>
              ) : (
                <>
                  <br />
                  Venue : {FixedResult.seminarVenue}.
                  <br />
                  Location : {FixedResult.seminarLocation}.
                </>
              )}
            </p>
          </div>
          <div>
          <p>
            <span className="ms-2 fs-4  fw-bold seminartxt">About seminar</span>
            <br />
            {FixedResult.seminarDescription}{" "}
          </p>
          </div>
          <div>
          <p>
            <span className="ms-2 fs-4  fw-bold seminartxt">Speaker - </span>
          </p>
          </div>
          <div className={`${showLess == true ? "readMorewillgrid" : "speaker-grid"}`}>
          {multiSpeakersData.map((items,ind)=>{
            console.log("speakersss",items)
            return(
                      <div
                        className={`${((readMoreProfile == items?.speakerUserId) && showLess == true) ? "readMorewill-smith" : "will-smith"}`}
                      >
                        <div className={`${((readMoreProfile == items?.speakerUserId) && showLess == true) ? "d-block" : ""}`}>                      
                        <div className={`${((readMoreProfile == items?.speakerUserId) && showLess == true) ? "readMorewill-imgDiv" : "img-div"}`}>
                          <img
                            src={
                              items?.speakerUrl !== null &&
                              items?.speakerUrl !== undefined &&
                              items?.speakerUrl !== ""
                                ? items?.speakerUrl
                                : "/images/ApeakerNoImage1.png"
                            }
                            alt=""
                            className="img-fluid rounded-circle"
                          />
                        </div>
                        <h3>{items?.speakerName}</h3>
                        </div>
                        <p className={showLess == true ? "ms-3 mt-3" : ""}>
                          {/* {items?.speakerProfile.length > 100
                            ? `${filterSpeakerDescriptionLegth(
                                items?.speakerProfile
                              )}...`
                            : items?.speakerProfile} */}
                            {/* {
                              showFullProfile(items?.speakerUserId) == true ? items?.speakerProfile : `${filterSpeakerDescriptionLegth(
                                items?.speakerProfile
                              )}...`
                            } */}
                            {((readMoreProfile == items?.speakerUserId) && showLess == true) ? items?.speakerProfile : `${filterSpeakerDescriptionLegth(
                                items?.speakerProfile
                              )}...`}
                          {(items?.speakerProfile.length > 50 && showLess !== true) ? (
                            <a onClick={()=>showFullProfile(items,ind)}>Read More</a>
                          ) : ((readMoreProfile == items?.speakerUserId) && showLess == true) ? 
                          <a onClick={()=>setShowLess(false)} className="fw-bold text-decoration-none text-black"> Show Less</a> : 
                          <a onClick={()=>showFullProfile(items,ind)}>Read More</a>
                          }
                        </p>
                      </div>
                       )
                      })
                    }
                    </div>
        </div>

        <div className="d-flex justify-content-between pb-5">
          <div>
            <button
              className="seminarbtn mt-3 ms-3 text-white"
              onClick={reverseClick}
            >
              Back
            </button>
          </div>
          <div>
            <button
              className="seminarbtn mt-3 me-3 text-white"
              onClick={metaPage}
            >
              {/* Next */}
              Publish
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preview;
