import React, { useEffect, useRef } from "react"
import { useState, useContext } from "react";
import { UserContext } from "../../App.js";
import { Select, Button, Modal,notification } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import { Input} from 'antd';
import "./Feedback.css";
import { Popover } from 'antd';
import konsole from '../../network/konsole.js';
import Services from '../../network/Services';
import commonLib from '../../control/commonLib';
import { CloseOutlined } from '@ant-design/icons';
import jsonData from '../../config.json';
import * as XLSX from 'xlsx';

export default function Feedback() 
{
  const { setLoader } = useContext(UserContext)
  const stateObj = commonLib.getObjFromStorage("stateObj");
  const[feedbackStatus,setFeedbackStatus]=useState([])
  const[feedbackType,setFeedbackType]=useState([]);
  const [feedbackPriorityList, setFeedbackPriorityList] = useState([])
  const[moduleList,setModuleList]=useState([])
  const[duplicateArray,setDuplicateArray]=useState([])
  const[anotherDuplicateArray,setAnotherDuplicateArray]=useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(null);
  const [selectedSort1, setSelectedSort1] = useState(null);
  const [usefeddbackdata, setfeddbackdata] = useState({})
  const [subtinentss, setsubtinentss] = useState([]);
  const[filteredPriority,setfilteredPriority]=useState([])
  const dropdownRef1 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const tableRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [showPriority, setshowPriority] = useState(false);
  const [showModule, setshowModule] = useState(false);
  const [feedbacksArray, setfeedbacksArray] = useState([])
   const selectRef = useRef();
   const[saveStatus,setSaveStatus]=useState(false)
   const[filteredModule,setfilteredModule]=useState([]);
   const[cleanedModule,setcleanedModule]=useState([]);
   const[cleanedPriority,setcleanedPriority]=useState([]);
   const[sortBy,setSortBy]=useState("Sort By");
   const [searchedvalue, setsearchedvalue] = useState("@");
   const [somecond, setsomecond] = useState(false);
   const [selectoptionvaluesubt, setselectoptionvaluesubt] = useState("All");
   const [subtinentName, setSubtinentName] = useState("All");
   const loggedInUser = sessionStorage.getItem("loggedUserId") || "";
   const [textAreaValue, setTextAreaValue] = useState('')
   const ClosingEmail="Dear User,\nWe would like to inform you that the ticket No. @@ticketno related to the issue you raised `@@FEEDBACK_QUERY` has been successfully resolved, and the ticket is now officially closed. \n\n With regards \n Tech Support Team"
   const[openEmailModal,setOpenEmailModal]=useState(false);
   const[selectedItemForEmail,setSelectedItemForEmail]=useState({})
   const [api, contextHolder] = notification.useNotification();
   const checkDemo=jsonData.demo

  function showPriorityDropdown() {
    setshowPriority(!showPriority)
  }

  function showSortBy() {
    setIsOpen2(!isOpen2)
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function showModuleDropdown() {
    setshowModule(!showModule)
  }

  useEffect(() => {
    GettingFeedback(true)
    postSubtenantDetailsinfeedback(true)
    getFeedbackPriority()
    getModules()
    getFeedbackStatus()
    getFeedbackType()

  }, [])


  //----Handle Module and Priority Filter both---------//

  useEffect(() => {
    const cleanedModule = filteredModule.filter(value => value != null);
    const cleanedPriority = filteredPriority.filter(value => value != null);

    setcleanedModule(cleanedModule);
    setcleanedPriority(cleanedPriority);
    // konsole.log("cleanedModulecleanedModule",cleanedModule,cleanedPriority)
  
    if (cleanedModule.length === 0 && cleanedPriority.length === 0) {
      setDuplicateArray(anotherDuplicateArray);
    } else{

      const filteredArray =anotherDuplicateArray.filter((obj) => {
        return (
          (cleanedModule.length === 0 || cleanedModule.includes(obj.moduleTypeName)) &&
          (cleanedPriority.length === 0 || cleanedPriority.includes(obj.feedbackPriority))
        );
      });
      setDuplicateArray(filteredArray);
    }
    // konsole.log("filteredModulefilteredModule", cleanedModule, cleanedPriority);
  }, [filteredModule, filteredPriority]);


//-------------Close dropdowns on Clicking outside-----------//

  useEffect(() => {
    const dropdownRefs = [dropdownRef1, dropdownRef2, dropdownRef3, dropdownRef4];
  
    const handleClickOutside = (event) => {
      let clickedOutside = true;
  
      for (const ref of dropdownRefs) {
        if (ref.current && ref.current.contains(event.target)) {
          clickedOutside = false;
          break;
        }
      }
  
      if (clickedOutside) {
        setIsOpen1(false);
        setIsOpen2(false);
        setshowModule(false);
        setshowPriority(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1, dropdownRef2, dropdownRef3, dropdownRef4, setIsOpen1, setIsOpen2, setshowModule, setshowPriority]);


  async function getFeedbackStatus()
  {
      let getFeedbackStatus= await Services.getFeedbackStatus()
      getFeedbackStatus = getFeedbackStatus?.data?.data;
      // konsole.log("getFeedbackStatusgetFeedbackStatus",getFeedbackStatus)
      setFeedbackStatus(getFeedbackStatus)
  }

  async function getFeedbackType()
  {
      let getFeedbackType= await Services.getFeedbackType()
      getFeedbackType = getFeedbackType?.data?.data;
      // konsole.log("getFeedbackTypegetFeedbackType",getFeedbackType)
      setFeedbackType(getFeedbackType)
  }

  async function getFeedbackPriority() {
    let result = await Services.getFeedbackPriority()
    result = result?.data?.data;
    const newfpList = result.map((vall) => {
      return { ...vall, isChecked: false }
    })
    setFeedbackPriorityList(newfpList)
  }
  
  async function getModules() {
    let result = await Services.getModuleType()
    result = result?.data?.data;
    const newfpList = result.map((vall) => {
      return { ...vall,isChecked: false }
    })
    setModuleList(newfpList)
  }

  const postSubtenantDetailsinfeedback = (data) => {
    let postSubtenantDetailApiCall = Services.postSubtenantDetails();
    postSubtenantDetailApiCall
      .then((res) => {
        // konsole.log("responsedetails", res?.data?.data);
        setsubtinentss(res?.data?.data)
      })
      .catch((error) => {
        // konsole.log("error", error);
      });
  };

   const GettingFeedback = (param,para1) => {
    let subtenantId = sessionStorage.getItem('subtenantId')
    const promises = Services.GetAllFeedbacks(param, subtenantId);
        setLoader(true)
          promises.then((response) => {
          setLoader(false)
          const response1 = response?.data?.data
          // konsole.log('response1response1response1', response1)
          const updatedFeedbacks = (response1?.length > 0) ? response1.map((item) => { return item?.feedbacks })?.flat(1) : []
          // konsole.log("updatedFeedbacks",updatedFeedbacks)
          const updatedFeedbacksInitialChecked = updatedFeedbacks.map((item) => ({ ...item, isChecked: false,isOpenStatus:false,isOpenType:false }));
          // konsole.log('updatedFeedbacks', updatedFeedbacksInitialChecked)
          setfeedbacksArray([...updatedFeedbacksInitialChecked]);
          setDuplicateArray([...updatedFeedbacksInitialChecked]);
          setAnotherDuplicateArray([...updatedFeedbacksInitialChecked]);
           })
          .catch((error) => {
            // konsole.log("error", error);
          })
          .finally(() => {
          });    
  };


      function selectStatus(event, item, index)
       {
        const status = event.target.value;
        const duplicateArrayCopy = [...duplicateArray];

        const updatedDuplicateArray = duplicateArrayCopy.map((value) => {
            if (value.feedbackId == item.feedbackId) {
                  //  konsole.log("dsdsdssdsdsds",value.feedbackId,item.feedbackId)
                   const getprevValueFromFeedback=feedbacksArray.find((data)=>{
                          if(data.feedbackId==item.feedbackId){
                            return data
                          }
                   })
                  //  konsole.log("getprevValueFromFeedback",getprevValueFromFeedback)
                    if(getprevValueFromFeedback.feedbackStatusId==status )
                    {
                      // konsole.log("faddfdsddsds",feedbacksArray[index],item)
                      return { ...value, feedbackStatusId: status,isOpenStatus:false };
                    }
                    else
                    {
                      return { ...value, feedbackStatusId: status,isOpenStatus:true  }
                    }
            } else {
                return value;
            }
        });

        setDuplicateArray(updatedDuplicateArray);
        setAnotherDuplicateArray(updatedDuplicateArray);
        konsole.log("feedbacksArray",
        feedbacksArray,
        // index,
        // item,
        feedbacksArray[index].feedbackStatusId
        )
        }   
      
    
    const updateFeedbackStatus=(item,index)=>{
        // konsole.log("updateFeedbackStatus",item.feedbackId)
        // const getStatusFromDuplicateArray=duplicateArray[index].feedbackStatusId;
        const getStatusFromDuplicateArray=duplicateArray.find((data)=>{
                if(data.feedbackId==item.feedbackId){
                  return data
                }
        }) 
        // const getStstusFromFeedbackArray=feedbacksArray[index].feedbackStatusId;
        // konsole.log("getStstusFromDuplicateArray",getStatusFromDuplicateArray)
        let loginUserId = sessionStorage.getItem('loggedUserId')
        const body={
          "userId":item.userId,
          "feedbackQuery": item.feedbackQuery,
          "feedbackType": item.feedbackType,
          "applicationURL": item.applicationURL,
          "imageURL":item.imageURL,
          "fileId": item.fileId,
          "moduleTypeId": item.moduleTypeId,
          "feedbackPriorityId": item.feedbackPriorityId,
          "feedbackStatusId":getStatusFromDuplicateArray.feedbackStatusId,
          "feedbackTypeId": item.feedbackTypeId,
          "feedbackId": item.feedbackId,
          "updatedBy":loginUserId
        }

        let updateFeedback=Services.updateFeedback(body);
        setLoader(true)
        updateFeedback.then((resp)=>{
          setLoader(false)

          setDuplicateArray((prev) => {
            var previousArray = [...prev];
            return previousArray.map((values) => {
                if (values.feedbackId === item.feedbackId) {
                    return { ...values, isOpenStatus: false };
                } else {
                    return values;
                }           
            })
          });

          setAnotherDuplicateArray((prev) => {
            var previousArray = [...prev];
            return previousArray.map((values) => {
                if (values.feedbackId === item.feedbackId) {
                    return { ...values, isOpenStatus: false };
                } else {
                    return values;
                }           
            })
          });

          setfeedbacksArray((prev) => {
            var previousFeedbackArray = [...prev];
            return previousFeedbackArray.map((values) => {
                if (values.feedbackId == item.feedbackId) {
                    return { ...values,feedbackStatusId: getStatusFromDuplicateArray.feedbackStatusId, isOpenStatus: false };
                } else {
                    return values;
                }           
            })
          });

        })
        handleEmailCancel();
      }

    // konsole.log("feebackStstusChnage",duplicateArray,feedbacksArray)

    function cancelStatusUpdate(item,index)
    {  
      const getStstusFromFeedbackArray=feedbacksArray.find((data)=>{
        if(data.feedbackId==item.feedbackId)
        {
          return data;
        }
        })

      setDuplicateArray((prev) => {
        var previousArray = [...prev];
        return previousArray.map((values) => {
            if (values.feedbackId === item.feedbackId) {
                return { ...values, isOpenStatus: false,feedbackStatusId:getStstusFromFeedbackArray.feedbackStatusId};
            } else {
                return values;
            }           
        })
      });

      setAnotherDuplicateArray((prev) => {
        var previousArray = [...prev];
        return previousArray.map((values) => {
            if (values.feedbackId === item.feedbackId) {
                return { ...values, isOpenStatus: false,feedbackStatusId:getStstusFromFeedbackArray.feedbackStatusId};
            } else {
                return values;
            }           
        })
      });

   }
      //-------------------Select Type----------------//

    function selectType(event, item, index) {
    const typeValue = event.target.value;

    const duplicateArrayCopy = [...duplicateArray];

    const updatedDuplicateArray = duplicateArrayCopy.map((value, i) => {
      if (value.feedbackId == item.feedbackId) {

        const getprevTypeFromFeedback=feedbacksArray.find((data)=>{
              if(data.feedbackId==item.feedbackId){
                return data
              }
              })

                if(getprevTypeFromFeedback.feedbackTypeId==typeValue )
                {
                    return { ...value, feedbackTypeId: typeValue, isOpenType: false };
                } else 
                {
                    return { ...value, feedbackTypeId: typeValue, isOpenType: true };
                }
      } else {
          return value;
      }
    });

    setDuplicateArray(updatedDuplicateArray);
    setAnotherDuplicateArray(updatedDuplicateArray);

    // konsole.log(
    //     "dsdsdsdsdzczczx",
    //     updatedDuplicateArray
    // );
}


  

      const updateFeedbackType=(item,index)=>{
        // konsole.log("updateFeedbackStatus",item.feedbackId)
        // const getTypeFromDuplicateArray=duplicateArray[index].feedbackTypeId;
        
        const getTypeFromDuplicateArray=duplicateArray.find((data)=>{
          if(data.feedbackId==item.feedbackId) {
            return data
          }
        })
        // const getTypeFromFeedbackArray=feedbacksArray[index].feedbackTypeId;
        // konsole.log("getStstusFromDuplicateArray",getTypeFromFeedbackArray,getTypeFromDuplicateArray)
        let loginUserId = sessionStorage.getItem('loggedUserId')

        setDuplicateArray((prev) => {
            const previousArray = [...prev];
            return previousArray.map((values) => {
                if (values.feedbackId == item.feedbackId) {
                    return { ...values, isOpenType: false };
                } else {
                    return values;
                }           
            })
          });

          setAnotherDuplicateArray((prev) => {
            const previousArray = [...prev];
            return previousArray.map((values) => {
                if (values.feedbackId == item.feedbackId) {
                    return { ...values, isOpenType: false };
                } else {
                    return values;
                }           
            })
          });

        const body={
          "userId":item.userId,
          "feedbackQuery": item.feedbackQuery,
          "feedbackType": item.feedbackType,
          "applicationURL": item.applicationURL,
          "imageURL":item.imageURL,
          "fileId": item.fileId,
          "moduleTypeId": item.moduleTypeId,
          "feedbackPriorityId": item.feedbackPriorityId,
          "feedbackStatusId":item.feedbackStatusId,
          "feedbackTypeId": getTypeFromDuplicateArray.feedbackTypeId,
          "feedbackId": item.feedbackId,
          "updatedBy":loginUserId
        }
          let updateFeedback=Services.updateFeedback(body);
          setLoader(true)
          updateFeedback.then((resp)=>{
          setLoader(false);
          setfeedbacksArray((prev) => {
            var previousFeedbackArray = [...prev];
            return previousFeedbackArray.map((values) => {
                if (values.feedbackId == item.feedbackId) {
                    return { ...values,feedbackTypeId: getTypeFromDuplicateArray.feedbackTypeId, isOpenType: false };
                } else {
                    return values;
                }           
            })
          });


        })   
      }

      
    function cancelTypeUpdate(item,index)
    {  
      const getprevTypeFromFeedback=feedbacksArray.find((data)=>{
        if(data.feedbackId==item.feedbackId)
        {
          return data
        }
        })
     
      // const getTypeFromFeedbackArray=feedbacksArray[index].feedbackTypeId;
      // konsole.log("getprevTypeFromFeedbackgetprevTypeFromFeedback",getprevTypeFromFeedback,getTypeFromFeedbackArray)

      setDuplicateArray((prev) => {
        var previousArray = [...prev];
        return previousArray.map((values) => {
            if (values.feedbackId == item.feedbackId) {
              return { ...values,feedbackTypeId: getprevTypeFromFeedback.feedbackTypeId, isOpenType: false };
            } else {
                return values;
            }           
        })
      });

      setAnotherDuplicateArray((prev) => {
        var previousArray = [...prev];
        return previousArray.map((values) => {
            if (values.feedbackId === item.feedbackId) {
              return { ...values,feedbackTypeId: getprevTypeFromFeedback.feedbackTypeId, isOpenType: false };
            } else {
                return values;
            }           
        })
      });

   }


      //--------------------Check Ticket--------------//

      function checkTicket(ticketdata) {
        // konsole.log("ticketdataticketdata", ticketdata)
        const filteredChekdonClick = duplicateArray.map((value) => {
          if (ticketdata.feedbackId == value.feedbackId) {
            return { ...value, isChecked: !value.isChecked }
          }
          return value   
        })
        setDuplicateArray(filteredChekdonClick)   
      }
    
      //--------Select and remove Tickets ---------//

      function selectAndRemoveTickets(values) {
        // konsole.log("valuesvalues", values)
        if (values == false) {
          const removeAllIsCheckedTickets = duplicateArray.map((data) => {
            return { ...data, isChecked: false }
          })
          setDuplicateArray(removeAllIsCheckedTickets)   
        }
        else {
          const selectAllIsCheckedTickets = duplicateArray.map((data) => {
            return { ...data, isChecked: true }
          });
          setDuplicateArray(selectAllIsCheckedTickets)   
        }
      }

      //-----------Priority Drodown--------------//

      

      function selectPriorities(priority) {
        let updatePriorityDropdownChecks = feedbackPriorityList.map((values) => {
          if (priority.label === values.label) {
            return { ...values, isChecked: !values.isChecked };
          }
          return values;
        });
        
        const onlyCheckedPriorities=updatePriorityDropdownChecks.map((val)=>{
          if(val.isChecked==true)
          {
            return val.label
          }
        })    
        // konsole.log("filterUserFeedbackArrayAccordingToPriorityArray",filteredArray, findOnlyTrueToFilterArray)
        setFeedbackPriorityList(updatePriorityDropdownChecks);
        setfilteredPriority(onlyCheckedPriorities)
        // const filteredArray = duplicateArray.filter((obj) => onlyCheckedPriorities.includes(obj.feedbackPriority));
       }


       //--------------Module Dropdown------------//

       function checkOnModule(module) {
        let updatemoduleCheck = moduleList.map((values) => {
          // konsole.log("modulemodule",module.label,values.label)
          if (module.label == values.label) {
            return {...values,isChecked:!values.isChecked };
          }
          return values;
        });

        const findOnlyTrueToFilterArray=updatemoduleCheck.map((val)=>{
          if(val.isChecked==true)
          {
            return val.label
          }
          
        })
        // konsole.log("findOnlyTrueToFilterArrayfindOnlyTrueToFilterArray",findOnlyTrueToFilterArray)
        setModuleList(updatemoduleCheck)
        setfilteredModule(findOnlyTrueToFilterArray)
      }

      //---------Sort By---------------//

      const handleSortBy = (changeSortValue, selectSortValue) => {
        changeSort(changeSortValue);
        // setSelectedSort(selectSortValue);
        setIsOpen2(false)
      }

      
   const changeSort = (sortOption) => {

    // konsole.log(sortOption, "sortOption")
    setSortBy(sortOption)

    if (sortOption === 'Name AtoZ') {
      const sortedData = [...duplicateArray].sort((a, b) => a?.userName?.localeCompare(b?.userName));
      setDuplicateArray(sortedData)
    }

    else if (sortOption === 'Name ZtoA') {
      const sortedData = [...duplicateArray].sort((a, b) => b?.userName?.localeCompare(a?.userName));
      setDuplicateArray(sortedData)
    }

    else if (sortOption === 'Module Asc') {
      const sortedData = [...duplicateArray].sort((a, b) => { return a.moduleTypeName.localeCompare(b.moduleTypeName) })
      setDuplicateArray(sortedData)
      // konsole.log("qfewdSCXZ", [...duplicateArray])
    }

    else if (sortOption === 'Module Desc') {
      const sortedData = [...duplicateArray].sort((a, b) => { return b.moduleTypeName.localeCompare(a.moduleTypeName) });
      setDuplicateArray(sortedData)
    }


    else if (sortOption === 'Date Desc') {
      const sortedData = [...duplicateArray];
      sortedData.sort((a, b) => {
        const dateA = new Date(a?.createdDate);
        const dateB = new Date(b?.createdDate);
        return dateB - dateA;
      });
      setDuplicateArray(sortedData)
    }
    else if (sortOption === 'Date Asc') {
      const sortedData = [...duplicateArray];
      sortedData.sort((a, b) => {
        const dateA = new Date(a?.createdDate);
        const dateB = new Date(b?.createdDate);
        return dateA - dateB;
      });
      setDuplicateArray(sortedData)
    }
    else if (sortOption == 'Priority Asc') {
      const sortedData = [...duplicateArray];
      sortedData.sort((a, b) => {
        const PriorityAscA = a?.feedbackPriorityId;
        const PriorityAscB = b?.feedbackPriorityId;
        return PriorityAscA - PriorityAscB;
      });
      setDuplicateArray(sortedData)
    }
    else if (sortOption == 'Priority Desc') {
      const sortedData = [...duplicateArray];
      sortedData.sort((a, b) => {
        const PriorityDesA = a?.feedbackPriorityId;
        const PriorityDesB = b?.feedbackPriorityId
        return PriorityDesB - PriorityDesA;
      });
      setDuplicateArray(sortedData)
    }
  }



    //-----------Subtinent Sorting ---------------//


    const handleSortOfAll = (selectValOpSub, comecondVal, firstdpdnid, selectSortVal1) => {
      setSubtinentName(firstdpdnid)
      // document.getElementById('firstdpdnid').innerText = firstdpdnid
      setIsOpen1(false)
      setselectoptionvaluesubt(selectValOpSub)
      setsomecond(comecondVal);
      setSelectedSort1(selectSortVal1);

    }


      //-------------Export -------------//

      const exportToExcel = () => {
        const data = [['Ticket No.','User Name','Module' ,' Type', 'Priority', 'Date', 'Status']];
        const arrayToPrint = duplicateArray.filter((values) => {
          return values.isChecked == true
        })
        const tableAllFalse = duplicateArray.every((value) => {
          return value.isChecked == false
        })
        const TableToExcel = (tableAllFalse == true) ? duplicateArray : arrayToPrint;

        // konsole.log("arrayToPrint", TableToExcel,duplicateArray,arrayToPrint,tableAllFalse)


        TableToExcel
          .forEach((feedback3) => {
            // konsole.log("feedback3feedback3",feedback3)
            const ticketNo = feedback3.feedbackId;
            const module = feedback3.moduleTypeName 
            const Type =(feedback3.feedbackTypeId==1)?"Bug":(feedback3.feedbackTypeId==2)?"Enhancement":"";
            const feedbackStatus =(feedback3.feedbackStatusId==1)?"Open":(feedback3.feedbackStatusId==2)?"Work in progress":"Completed";
            const date = (feedback3.createdDate).slice(5, 7) + '-' + (feedback3.createdDate).slice(8, 10) + '-' + (feedback3.createdDate).slice(2, 4);
            const userName = feedback3.userName + ' (' + feedback3.emailId + ')';
            const comment = feedback3.feedbackQuery;
            const priority = feedback3.feedbackPriority;
            data.push([ticketNo,userName, module,Type, priority, date, feedbackStatus]);
          });

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, 'FeedbackData');
        XLSX.writeFile(wb, 'feedback_data.xlsx');
      };

      // konsole.log("functionForAcknowledgementMail",selectedItemForEmail)

      const funcForUpdateEmailTemplate = (item,index) => {
        if(item.feedbackStatusId==3)
        {
          setOpenEmailModal(true)
          const  emailTemp=ClosingEmail
          const newStr = emailTemp.replaceAll('@@ticketno', item?.feedbackId)
          .replaceAll("@@FEEDBACK_QUERY",  item.feedbackQuery)
          setTextAreaValue(newStr)
          setSelectedItemForEmail((prev)=>item)
          return;
      }
      updateFeedbackStatus(item,index)
      }     

    const handleEmailCancel=()=>{
      setOpenEmailModal(false);
      setSelectedItemForEmail([])
    }
    
    const sendEmailForClosingOfTicket=()=>{

        if(selectedItemForEmail)
        {
          // konsole.log("functionForAcknowledgementMail",selectedItemForEmail);
          updateFeedbackStatus(selectedItemForEmail);
          let emailContent = textAreaValue.replace(/\n/g, '<br>');

        const jsonObjForClosingEmail = {
          emailType: "Client_Feedback_Details",
          emailTo: selectedItemForEmail?.emailId,
          emailSubject: `Closed Feedback for Ticket Number ${ selectedItemForEmail?.feedbackId}`,
          emailContent: emailContent,
          emailStatusId:1,
          emailMappingTable: "tblUsers",
          emailMappingTablePKId: loggedInUser,
          createdBy: selectedItemForEmail?.userId,
          emailcc:(checkDemo==true)?"shreyasinha@agingoptions.com":"techsupport@agingoptions.com",
          emailBcc:"deepaks@agingoptions.com"
        }
        // konsole.log("emailContentemailContent",emailContent,textAreaValue)

        const promises = Services.sendMailToClientOnclosingFeedbackStatus(jsonObjForClosingEmail)
        promises.then((response) => {
          openNotificationWithIcon('success')
              konsole.log("sendMailToClientOnclosingFeedbackStatus", response?.data?.data);
        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });


      // }     
      
      
        }
    }

    const openNotificationWithIcon = (type) => {
      api[type]({
        message: 'Saved and Email sent Successfully',
        placement:'topRight',
      });
    };

    const handleTextAreaChange = (e) => {
      const enteredText = e.target.value;
      setTextAreaValue(enteredText)
  }
  
    // konsole.log("functionForAcknowledgementMail",selectedItemForEmail)
  return (

    <>
    {contextHolder}
      <div className="p-2 m-0 " >
        <div className="row p-0 m-0 justify-content-between">
          <div className="col-5 p-0 mt-2 mb-2">
            {/* search */}
            <div className="w-100 d-flex" style={{ border: "0.6px solid rgba(0, 0, 0, 0.4)", borderRadius: "4px", justifyContent: "space-between", height: "39px" }}>
              <input
                className="form-control fs-20"
                style={{ border: "none" }}
                type="text"
                onChange={(e) => setsearchedvalue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setsearchedvalue(e.target.value);
                  }
                }}
                placeholder="Search here...."
              />
              <div style={{ backgroundColor: "#a9a9a940", paddingLeft: "11px" }}>
                <img className="" style={{ padding: "2px", height: "22px", width: "22px", marginTop: "8px", marginRight: "7px" }} src="/search icon.png" alt="search" />
              </div>
            </div>
            {/* search ends*/}
          </div>
          <div className="col-6 ">
            <div className="row justify-content-end">
              {
                (stateObj.roleId == 4) &&
                // (stateObj.roleId != 4) &&
                <div className="col-5 p-0 mt-2 mb-2" style={{ marginRight: "35px" }}>
                  <div className="dropdown w-100" ref={dropdownRef1} style={{zIndex:"1000"}}>
                    <button
                      className={`dropbtn3  d-flex justify-content-between ${isOpen1 == true ? "addBorderRaduis" : ""}`}
                      onClick={() => { setIsOpen1(!isOpen1); }}>
                      <span className="text-truncate" style={{ paddingLeft: "10px", marginTop: "7px" }} id="firstdpdnid"> {subtinentName}</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none" className={(isOpen1 == true) ? "r90deg heImageDown" : "n90deg heImageDown"}>
                        <path d="M9.75 1.75L5.5 6L1.25 1.75" stroke="#720C20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                    {
                      isOpen1 == true ?

                        <div className="dropdown-content1 " id="dcontent2" style={{ width: "100%" }}>
                          <a href="#" 
                          onClick={() => handleSortOfAll("All", false, "All", 'ALL')}
                           className={selectedSort1 === 'ALL' ? 'selected' : ''}>
                            <p className="d-inline" style={{ fontWeight: "600", paddingTop: "1px" }}> All</p>
                          </a>
                          {subtinentss.length > 0 && subtinentss.map((data1) => (
                            <a href="#" key={data1.subtenantId} className={selectedSort1 === data1.subtenantId ? 'selected text-truncate' : 'text-truncate'} 
                            onClick={() => handleSortOfAll(data1?.subtenantId, true, data1?.subtenantName, data1.subtenantId)} 
                             >
                              <p className="d-inline" style={{ fontWeight: "600", paddingTop: "1px" }}>{data1?.subtenantName}</p>
                            </a>
                          ))}
                        </div>
                        :
                        <div></div>
                    }
                  </div>
                </div>
              }

              <div className="col-4 d-flex justify-content-center align-items-center exportbutton" style={{ marginTop: "9px" }}
               onClick={exportToExcel}
               >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none" className="heImageDown2">
                  <path d="M15.6429 8.5242V16.5837C15.6429 16.9399 15.5014 17.2816 15.2495 17.5335C14.9976 17.7854 14.6559 17.9269 14.2996 17.9269H2.21043C1.85418 17.9269 1.51252 17.7854 1.26061 17.5335C1.00871 17.2816 0.867188 16.9399 0.867188 16.5837V8.5242C0.867188 8.16795 1.00871 7.82629 1.26061 7.57439C1.51252 7.32248 1.85418 7.18096 2.21043 7.18096H4.2253C4.40343 7.18096 4.57426 7.25172 4.70021 7.37767C4.82616 7.50363 4.89692 7.67446 4.89692 7.85258C4.89692 8.03071 4.82616 8.20154 4.70021 8.32749C4.57426 8.45344 4.40343 8.5242 4.2253 8.5242H2.21043V16.5837H14.2996V8.5242H12.2848C12.1066 8.5242 11.9358 8.45344 11.8099 8.32749C11.6839 8.20154 11.6132 8.03071 11.6132 7.85258C11.6132 7.67446 11.6839 7.50363 11.8099 7.37767C11.9358 7.25172 12.1066 7.18096 12.2848 7.18096H14.2996C14.6559 7.18096 14.9976 7.32248 15.2495 7.57439C15.5014 7.82629 15.6429 8.16795 15.6429 8.5242ZM5.3721 4.96964L7.58342 2.75748V10.5391C7.58342 10.7172 7.65418 10.888 7.78013 11.014C7.90608 11.1399 8.07691 11.2107 8.25504 11.2107C8.43316 11.2107 8.60399 11.1399 8.72995 11.014C8.8559 10.888 8.92666 10.7172 8.92666 10.5391V2.75748L11.138 4.96964C11.264 5.09566 11.4349 5.16646 11.6132 5.16646C11.7914 5.16646 11.9623 5.09566 12.0883 4.96964C12.2143 4.84362 12.2851 4.67269 12.2851 4.49447C12.2851 4.31624 12.2143 4.14532 12.0883 4.01929L8.73021 0.661181C8.66784 0.598736 8.59376 0.549197 8.51223 0.515399C8.4307 0.4816 8.3433 0.464203 8.25504 0.464203C8.16678 0.464203 8.07938 0.4816 7.99785 0.515399C7.91631 0.549197 7.84224 0.598736 7.77986 0.661181L4.42175 4.01929C4.29573 4.14532 4.22493 4.31624 4.22493 4.49447C4.22493 4.67269 4.29573 4.84362 4.42175 4.96964C4.54778 5.09566 4.7187 5.16646 4.89692 5.16646C5.07515 5.16646 5.24607 5.09566 5.3721 4.96964Z" fill="white" stroke="white" stroke-width="0.411912" />
                </svg>
                <button className="btn text-white"  >
                  Export
                </button>
              </div>
            </div>
          </div>
          <div></div>

          {/* table */}

          <div className="row  justify-content-between p-0">
            {/* left area */}
            <div className="col-8 ">
              <div className="row  justify-content-start align-items-center">
                <div className="col-2  m-0 pl-2">
                  <span className="filtersize">Filter by :</span>
                </div>
                <div className="col-3 p-0 m-2">
                  <div className="dropdown3"  ref={dropdownRef3} style={{zIndex:"1000"}}>
                    <button
                      className={`dropbtn3 d-flex justify-content-between ${showPriority == false ? `` : `addBorderRaduis`}`}
                      onClick={showPriorityDropdown}
                    >
                      <span className=" d-flex h-100 justify-content-center align-items-center " style={{ paddingLeft: "10px", }} >Priority</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none" className={(showPriority == true) ? "r90deg heImageDown" : "n90deg heImageDown"}>
                        <path d="M9.75 1.75L5.5 6L1.25 1.75" stroke="#720C20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                    {
                      showPriority == true ?
                        <div className="dropdown-content" id="dcontent">
                          {
                            feedbackPriorityList.map((item, index) => {
                              return (
                                <a href="#" key={item.value}>
                                  <input type="checkbox"
                                   onChange={() => { selectPriorities(item) }} 
                                   checked={item.isChecked}></input>
                                  <p className="d-inline sortByStyle" >{item.label}</p>
                                </a>
                              )
                            })
                          }</div> : <div></div>
                    }
                  </div>
                </div>
                <div className="col-3 p-0 m-2">
                  <div className="dropdown3"  ref={dropdownRef4} style={{zIndex:"1000"}}>
                    <button
                      className={`dropbtn3 d-flex justify-content-between ${showModule == false ? `` : `addBorderRaduis`}`}
                      onClick={showModuleDropdown}
                    >
                      <span className=" d-flex h-100 justify-content-center align-items-center " style={{ paddingLeft: "10px", }} >Module</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none" className={(showModule == true) ? "r90deg heImageDown" : "n90deg heImageDown"}>
                        <path d="M9.75 1.75L5.5 6L1.25 1.75" stroke="#720C20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                    {
                      showModule == true ?
                        <div className="dropdown-content" id="dcontent">
                          {
                            moduleList.map((item, index) => {
                              return (
                                <a href="#" key={item.value}>
                                  <input type="checkbox"
                                   onChange={() => { checkOnModule(item) }} 
                                   checked={item.isChecked}></input>
                                  <p className="d-inline sortByStyle" >{item.label} </p>
                                </a>
                              )
                            })
                          }</div> : <div></div>
                    }
                  </div>
                </div>
              </div>
            </div>

            {/* right area */}
            <div className="col-4 ">
              <div className="row  justify-content-end p-0">
                <div className="col-3  d-flex justify-content-end align-items-center">
                  <span >Sort by:</span>
                </div>

                <div className="col-6  p-0 mt-2 mb-2 d-flex justify-content-end">
                  <div className="dropdown" ref={dropdownRef2} style={{zIndex:"1000"}}>
                    <button
                      className={`dropbtn d-flex justify-content-between ${isOpen2 == false ? `` : `addBorderRaduis`}`}
                      onClick={() => { showSortBy() }}
                      >
                      <span className="d-flex h-100 justify-content-center align-items-center " style={{ paddingLeft: "10px", }} id="spansortby">{sortBy}</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none" style={{
                        marginTop: "13px", marginRight: "12px",
                        height: "15px",
                        width: "15px"
                      }}
                        alt=""
                        className={(isOpen2 == true) ? "r90deg" : "n90deg"}>
                        <path d="M9.75 1.75L5.5 6L1.25 1.75" stroke="#720C20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>

                    </button>

                    {isOpen2 && (
                      <div className="dropdown-content" id="dcontent">
                        <a href="#" 
                        onClick={() => handleSortBy('Date Desc', 'dateDesc')} 
                        className={selectedSort === 'dateDesc' ? 'selected' : ''}  >
                          <img src="Vector (1).png" className="imgiconhs"></img>
                          <p className="d-inline sortByStyle" >Date Desc</p>
                        </a>
                        <a href="#"
                         onClick={() => handleSortBy('Date Asc', 'dateAsc')}
                          className={selectedSort === 'dateAsc' ? 'selected' : ''}  >
                          <img src="Vector (2).png" className="imgiconhs"></img>
                          <p className="d-inline sortByStyle">Date Asc</p>
                        </a>
                        <a href="#" 
                        onClick={() => handleSortBy('Name AtoZ', 'NameAtoZ')} 
                        className={selectedSort === 'NameAtoZ' ? 'selected' : ''} >
                          <img src="Vector (13).png" className="imgiconhs"></img>
                          <p className="d-inline sortByStyle" >Name AtoZ</p>
                        </a>
                        <a href="#" 
                        onClick={() => handleSortBy('Name ZtoA', 'NameZtoA')} 
                        className={selectedSort === 'NameZtoA' ? 'selected' : ''} >
                          <img src="Vector (232).png" className="imgiconhs"></img>
                          <p className="d-inline sortByStyle">Name ZtoA</p>
                        </a>
                        <a href="#" 
                        onClick={() => handleSortBy('Priority Asc', 'Priority Asc')} 
                        className={selectedSort === 'Priority Asc' ? 'selected' : ''} >
                          <img src="Priority asc.svg" className="imgiconhs"></img>
                          <p className="d-inline sortByStyle">Priority Asc</p>
                        </a>
                        <a href="#"
                         onClick={() => handleSortBy('Priority Desc', 'Priority Desc')} 
                         className={selectedSort === 'Priority Desc' ? 'selected' : ''} >
                          <img src="Priority desc.svg" className="imgiconhs"></img>
                          <p className="d-inline sortByStyle">Priority Desc</p>
                        </a>
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>       
          <div className="col-12 p-0 mt-2" style={{ height: "72vh", overflow: "hidden scroll" }}>
            <table ref={tableRef} className="table feedbacktable table-bordered table-responsive" style={{ width: "100%" }}>
              
              <thead className=" bg-light sticky-top" style={{zIndex: "1"}}>
                <tr>
                  <th scope="col" className="tablehdth2" 
                  style={{ width: "16%" }} 
                  ><input type="checkbox" className="m-2" 
                  onChange={(e) => { selectAndRemoveTickets(e.target.checked) }}
                  ></input><span>Ticket No.</span></th>
                  <th scope="col" className="tablehdth2"
                   style={{ width: "18%" }} 
                   >Username/Email</th>
                  <th scope="col" className="tablehdth2 "
                   style={{ width: "22%" }}
                   >Module</th>
                  <th scope="col" className="tablehdth2 " 
                   style={{ width: "17%" }}
                  >Priority</th>
                  <th scope="col" className="tablehdth2 "
                   style={{ width: "17%" }}
                  >Date Published</th>
                  <th scope="col" className="tablehdth2"
                  style={{ width: "23%" }}
                  >Status</th>
                  <th scope="col" className="tablehdth2" 
                   style={{ width: "23%" }}
                   >Preview</th>
                </tr>
              </thead>

              <tbody>
  
                {                 
                    duplicateArray
                      .filter((item) => {
                        const feedbackId = parseInt(searchedvalue);
                        const isFeedbackIdNumber = !isNaN(feedbackId);
                        const lowercaseSearchedValue = searchedvalue?.toLowerCase();
                        return (
                          item?.emailId?.toLowerCase()?.includes(lowercaseSearchedValue) ||
                          item?.userName?.toLowerCase()?.includes(lowercaseSearchedValue) ||
                          (isFeedbackIdNumber && item.feedbackId === feedbackId)
                        ) && (somecond ? item?.subtenant_Id == selectoptionvaluesubt : true)
                      })
                    .map((item, index) => {
                      return (
                  <tr key={index}>
                        <td style={{ textAlign: "left" }}>
                          <div style={{ width: "100%", overflowWrap: "break-word" }} >
                            <input type="checkbox" className="m-2" 
                             onChange={() => { checkTicket(item) }}
                             checked={item.isChecked}
                            ></input>
                            <span className="d-inline-block">{item.feedbackId}</span>
                          </div>
                        </td>

                        <td style={{ textAlign: "left" }}>
                          <div className="row justify-content-start">
                              <span className="">{item?.userName}<br></br><span className="pt-0">({item?.emailId})</span></span>
                          </div>
                        </td>

                        <td style={{ textAlign: "left" }} >
                          <div style={{ width: "100%", overflowWrap: "break-word" }} >
                            <span className="text-break" > {item?.moduleTypeName}</span>
                          </div>
                          {
                            (stateObj.roleId == 4)?
                                // (stateObj.roleId != 4)?
                                <>
                                  <div style={{ width: "100%", overflowWrap: "break-word" }} className="">
                                  <select 
                                  className="typeDropdownfeedback"
                                  aria-label="Default select example"
                                  id={"feedbackTypeSelect"+index}                
                                  onChange={(event)=>{selectType(event,item,index)}}>  
                                    {item.feedbackTypeId === null && (
                                        <option value="null" selected>
                                          Select
                                        </option>
                                      )}          
                                  {
                                    feedbackType.map((data)=>{
                                          return(
                                            <option 
                                            key={data.value}
                                            value={data.value} 
                                            selected={data.value==item.feedbackTypeId}
                                            >
                                              {data.label}
                                              </option>
                                            )
                                    })    
                                  }
                              </select>
                              </div>
                            {
                            (item.isOpenType)?
                              <>  
                                  <div className="row justify-content-center mt-2">
                                    <div className="col-3">
                                          <img src="./icons/accept.png" className="iconsHW"
                                              onClick={()=>{updateFeedbackType(item,index)}}
                                          ></img>
                                    </div>
                                    <div className="col-3">
                                          <img src="./icons/cross.png" className="iconsHW"
                                          onClick={()=>{cancelTypeUpdate(item,index)}}
                                          
                                          ></img>
                                    </div>
                                  </div>
                              </>
                              :
                              <>
                              </> 
                            }
                            </>:
                             <div style={{ width: "100%", overflowWrap: "break-word" }} className="mt-1">
                               {(item?.feedbackTypeId==1)?
                                 <span className="text-break typeDropdownfeedbackbutton" >
                                       Bug
                                 </span>
                               :
                               (item?.feedbackTypeId==2)?
                               <span className="text-break typeDropdownfeedbackbutton" >
                                   Enhancement
                               </span>
                               :
                               <span className="text-break " >
                         
                               </span>
                         
                               }
                              </div>
                          }                     
                        </td>
            
                        <td style={{ display: " flex", justifyContent: "center", alignItems: "center", height: "86px", paddingBottom: " 0px" }}>
                          <div className="row">
                            <div className="col-12">
                              <span className={`${(item.feedbackPriority=="High")?"fbPriorityRed":(item.feedbackPriority=="Medium")?"fbPriorityYellow":(item.feedbackPriority=="Low")?"fbPriorityGreen":(item.feedbackPriority=="FB Client")?"fbPriorityGrey":"fbPriorityBlue"}`}>{item.feedbackPriority}</span>
                             </div>
                          </div>
                        </td>

                        <td style={{ textAlign: "left" }}>
                          <div style={{ width: "100%", overflowWrap: "break-word" }} >
                            <span className="text-break">
                              {(item?.createdDate)?.slice(5, 7) + '-' + (item?.createdDate)?.slice(8, 10) + '-' + (item?.createdDate)?.slice(2, 4)}
                            </span>
                          </div>
                        </td>
                    {
                    (stateObj.roleId != 4)?
                        // (stateObj.roleId == 4)?
                        <td>
                            <div style={{ width: "100%", overflowWrap: "break-word" }} className="">
                  
                                      {(item?.feedbackStatusId==1)?
                                        <span className="text-break recievedColor" >
                                      Open
                                        </span>
                                      :
                                      (item?.feedbackStatusId==2)?
                                      <span className="text-break recievedProgress" >
                                          Work In Progress
                                      </span>
                                      :
                                      <span className="text-break recievedComplete" >
                                        Completed
                                      </span>
                            
                                      }                                                                                 
                            </div>
                        </td>
                        :
                      <td>
                        <div style={{ width: "100%", overflowWrap: "break-word" }} className="">
                          <select 
                          className=" statusDropdown"
                          aria-label="Default select example"
                          ref={selectRef}
                          id={"select"+index}
                          style={{
                            color: item?.feedbackStatusId == 1 ? "rgba(215, 1, 1, 1)" :item?.feedbackStatusId==2?"rgba(207, 156, 70, 1)":"#0DA06A"
                          }}
                          onChange={(e)=>{selectStatus(e,item,index)}}
                          >            
                            {
                              feedbackStatus.map((data)=>{
                                    return(
                                      <option 
                                      className={(data.value==1 || data.value==null)?"recievedColor":(data.value==2)?"recievedProgress":"recievedComplete"}
                                      key={data.value}
                                      value={data.value} 
                                      selected={data.value==item.feedbackStatusId}
                                      >
                                        {data.label}
                                        </option>
                                      )
                              })    
                            }
                          </select>
                        </div>
                          {
                            (item.isOpenStatus)?
                            <>
                                <div className="row justify-content-center mt-2">
                                  <div className="col-3">
                                        <img src="./icons/accept.png" className="iconsHW"
                                          onClick={()=>{funcForUpdateEmailTemplate(item,index)}}                                         
                                        ></img>
                                  </div>
                                  <div className="col-3">
                                        <img src="./icons/cross.png" className="iconsHW"
                                        onClick={()=>{cancelStatusUpdate(item,index)}}
                                        

                                        ></img>
                                  </div>
                                </div>
                            </>
                            :
                            <>
                            </>
                          }
                      </td>
                    }
                     
                    <td style={{ textAlign: "left" }}>
                        <div className="row " style={{ margin: "6px 0px 0px 2px" }}>
                          <div className="col-6" 
                          onClick={() => { setIsModalOpen(true); setfeddbackdata(item); }}
                          style={{cursor:"pointer"}}>
                          <img src="Group 16031.svg" className="camerapng "></img>
                        </div>
                        </div>
                      {/* </div> */}
                    </td>

                </tr>)
                  })
              }

              </tbody>
            </table>

            <Modal title="Feedback"
             footer={null}
             className="modalforfeedback"
             visible={isModalOpen}
             closeIcon={<CloseOutlined 
             onClick={handleCancel}
             style={{ color: 'white' }} />}>
              <div className="row">
                <div className="col-5">
                <p className="pcolor">User Name: <span className="spancolor">{usefeddbackdata?.emailId}</span></p>
                </div>
                <div className="col-3">
                  <p className="pcolor">Module: <span className="spancolor">{usefeddbackdata?.moduleTypeName}</span></p>
                </div>
                <div className="col-3">
                  <p className="pcolor">Priority: <span className="spancolor">{usefeddbackdata?.feedbackPriority}</span></p>
                </div> 
                <div className="col-5">
                  <p className="pcolor">Ticket No: <span className="spancolor">{usefeddbackdata?.feedbackId}</span></p>
                </div>
                <div className="col-3">
                  <p className="pcolor">Date: <span className="spancolor">
                    {(usefeddbackdata?.createdDate)?.slice(5, 7) + '-' + (usefeddbackdata?.createdDate)?.slice(8, 10) + '-' + (usefeddbackdata?.createdDate)?.slice(2, 4)}
                  </span></p>
                </div>
                <div className="col-3">
                  <p className="pcolor">Type: <span className="spancolor">
                  {(usefeddbackdata?.feedbackTypeId==1)?
                    "Bug":
                    (usefeddbackdata?.feedbackTypeId==2)?
                    "Enhancement":
                    <>
                       <span className="NotGivenColor">Not Given </span> 
                    </>
                }
                  </span></p>
                </div>
                <div className="col-12">
                  <p className="pcolor">Comment: <span className="spancolor">{usefeddbackdata?.feedbackQuery}</span></p>
                </div>
                <div className="col-12">
                  <p className="pcolor">Image: <img
                    src={usefeddbackdata?.imageURL !== undefined && usefeddbackdata?.imageURL !== null && usefeddbackdata?.imageURL !== "" ? usefeddbackdata?.imageURL : "https://icons8.com/icon/P09ma2Fis_RN/no-image"}
                    className="imagestyling "
                    alt=" "
                  />
                  </p>
                </div>
              </div>
            </Modal>
            <Modal title="Send Email" 
             visible={openEmailModal}
             centered={true}
             aria-labelledby="contained-modal-title-vcenter"
             id="Modal_for_ClosingFeedback"
             style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: "10px",color: 'white' }}
             footer={null}
             className="modalforfeedback"
             closeIcon={<CloseOutlined 
             onClick={handleEmailCancel}
             style={{ color: 'white' }} />}
             >
              <div class="form-outline border border-2 rounded fs-4">
                                        <textarea
                                            className="form-control pt-0 ps-3 fs-4  text-dark  shadow-none border-0" rows="10"
                                            value={textAreaValue} 
                                            onChange={(e) => handleTextAreaChange(e)}
                                        >
                                        </textarea>
                                        
              </div>
              <button className="yes_no_btn btn w-100 mt-2" onClick={sendEmailForClosingOfTicket}>Send</button>
            </Modal>
          </div>
        </div>         
      </div>
    </>
  )
}