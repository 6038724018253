import React, { useState, useEffect,useContext } from 'react';
import Table from 'react-bootstrap/Table';
import konsole from '../../network/konsole';
import Services from '../../network/Services';
import commonLib from '../../control/commonLib';
import { UserContext } from "../../App.js";
import { Viewandupdatemodal } from './Viewandupdatemodal';
import "./communication.css"
import { BackTop } from 'antd';


function BasicExample(props) {

  const [GetCommMediumData, setCommMediumData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false)
  const [getCommMediumRoles, setCommMediumRoles] = useState([])
  const [getData, setData] = useState([])
  const [getActiveData, setActiveData] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState([])
  const [selectedOnOFFRowData, setSelectedOnOFFRowData] = useState([])
  const [callPutAllData, setPutAllData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [subtenantID, setSubtenantID] = useState()
  const [ emptyState, setEmptyState] = useState()
  const [ updateTableData, setupdateTableData] = useState(false)
  const [inblidEmailTemplate, setInblidEmailTemplate] = useState([])
  const [sortedData, setsortedData] = useState(null)


  const { setLoader } = useContext(UserContext)

  useEffect(() => {
    let subtenantIDd = commonLib.getSessionStoarge("subtenantId")
    setSubtenantID(subtenantIDd)
    // props.GetCommMediumPathApiFuction()


  }, [])

  useEffect(() => {
    if (updateTableData == true) {

      props.GetCommMediumPathApiFuction()
      setupdateTableData(false)
    }
  }, [updateTableData])

  useEffect(() => {
    if(subtenantID && !updateTableData) getEmailTemplatesPathApiFunction(subtenantID)
  }, [subtenantID, selectedRowData?.applicableEmailTempId, updateTableData])

  const handleClose = () => {
    setEmptyState({})
    props.GetCommMediumPathApiFuction()
    getEmailTemplatesPathApiFunction(subtenantID)
    setShowAddModal(false);
  }
  useEffect(() => {
    let newData = props.GetCommMediumData.sort((a, b) => a.occurrenceDetails.localeCompare(b.occurrenceDetails));

    setsortedData(newData)
  }, [props.GetCommMediumData])



  const addEditModalFun = (rowData) => {
    setShowAddModal(true)
    setSelectedRowData(rowData)

    // konsole.log("RowData",rowData)
  }

  konsole.log("RowData", selectedRowData?.applicableEmailTempId)

  const isActiveHandleChange = () => {
    setActiveData(true);
  }


  //ljljjuuuuuuuuuuuuyyyyyyyyyyyyyy


  const getEmailTemplatesPathApiFunction = (subtenantID) => {
    //  alert(subtenantID,selectedRowData?.applicableEmailTempId)
    konsole.log("subtenanlkjlj", subtenantID)

    const promises = Services.getEmailTemplatesPathApi(subtenantID)
    promises.then((response) => {
      konsole.log("EmailTemplkjlkjlatesData", response?.data?.data);
      setInblidEmailTemplate(response?.data?.data);
      // props.GetCommMediumPathApiFuction()

    })
      .catch((error) => {
        konsole.log("error", error);
      })
      .finally(() => {
        konsole.log("finish");
        // props.GetCommMediumPathApiFuction()
      });
  };


  konsole.log("inblidEeinblidEmailTemplate", inblidEmailTemplate)

  //Api Code start 

  // const GetCommMediumPathApiFuction = (subtenantID) => {
  //   // alert("communication table inside")
  //   const promises = Services.GetCommMediumPathApi(2)
  //   promises.then((response) => {

  //     konsole.log("GetCommMediumPathApiljljllj;", response);
  //     // setLoader(true)

  //     setCommMediumData(response.data.data);
  //     let arr = [];
  //     let i
  //     for (i = 0; i < GetCommMediumData.length - 1; i++) {
  //       // 
  //       arr.push(response.data.data[i]?.commMediumRoles)
  //     }
  //     // konsole.log("arrr", arr)
  //     setCommMediumRoles(arr)

  //   })
  //     .catch((error) => {
  //       konsole.log("error", error);
  //     })
  //     .finally(() => {
  //       konsole.log("finish");
  //       // props.GetCommMediumPathApiFuction()

  //     });
  // };

  // setTimeout(() => {
  //   setLoader(false)
  // }, 3000)
  // }).catch((err) => {
  //   konsole.log("err", err);
  // }).finally(()=>{
  //   konsole.log("Finish")
  //   setLoader(false)

  // })
  // };






  const PutUpsertCommMediumApiFuctioniOnOFF = (e, rowOnOffData) => {

    // konsole.log("state", e.target.checked)


    setSelectedOnOFFRowData(rowOnOffData)
    let arr = [];

    // konsole.log("applicableEmailTempId", rowOnOffData?.applicableEmailTempId);

    let obj = rowOnOffData?.applicableEmailTempId && rowOnOffData?.applicableTextTempId ? {
      "commMediumId": rowOnOffData?.commMediumId,
      "commChannelId": rowOnOffData?.commChannelId,
      "subtenantId": subtenantID,
      "applicableEmailTempId": rowOnOffData?.applicableEmailTempId,
      "applicableTextTempId": rowOnOffData?.applicableTextTempId,
      "occurrenceId": rowOnOffData?.occurrenceId,
      "isActive": e.target.checked === true ? true : false,
      "upsertedBy": rowOnOffData?.createdBy,
      "commMediumRoles": arr
    }
      : rowOnOffData?.applicableTextTempId ? {
        "commMediumId": rowOnOffData?.commMediumId,
        "commChannelId": rowOnOffData?.commChannelId,
        "subtenantId": subtenantID,
        // "applicableEmailTempId": rowOnOffData?.applicableEmailTempId,
        "applicableTextTempId": rowOnOffData?.applicableTextTempId,
        "occurrenceId": rowOnOffData?.occurrenceId,
        "isActive": e.target.checked === true ? true : false,
        "upsertedBy": rowOnOffData?.createdBy,
        "commMediumRoles": arr
      }
        : rowOnOffData?.applicableEmailTempId ? {
          "commMediumId": rowOnOffData?.commMediumId,
          "commChannelId": rowOnOffData?.commChannelId,
          "subtenantId": subtenantID,
          "applicableEmailTempId": rowOnOffData?.applicableEmailTempId,
          // "applicableTextTempId": rowOnOffData?.applicableTextTempId,
          "occurrenceId": rowOnOffData?.occurrenceId,
          "isActive": e.target.checked === true ? true : false,
          "upsertedBy": rowOnOffData?.createdBy,
          "commMediumRoles": arr
        } : {}

    konsole.log("putOnOffobj", obj)

    let strValue = JSON.stringify(obj)
    konsole.log("strValue", strValue)


    const promises = Services.postUpsertCommMediumApi(obj)
    promises.then((response) => {
      setPutAllData(response.data.data)
      handleClose();

    })
      .catch((error) => {
        konsole.log("error", error);
      })
      .finally(() => {
        konsole.log("finish");
      });

  }
  // konsole.log("onOffData",selectedOnOFFRowData)


  // konsole.log("callputAllData", callPutAllData)

  konsole.log("GetCommMediumPathApiGetCommMediumPathApi", GetCommMediumData)

  // konsole.log("getData", getData);

  //Api Code End

  const fromRoleNameFuction = (data) => {
    let arrData = [], newArr = [];
    let arrayNew = []

    arrData.push(data);

    for (let i = 0; i <= arrData[0].length - 1; i++) {
      newArr.push(arrData[0]?.[i]?.fromRoleName)
    }
    // return [...new Set(newArr).join(" ,")]

    arrayNew.push([...new Set(newArr)].join(', '))



    return (arrayNew)

  }

  const ToRoleNameFuction = (data) => {
    let arrData = [], newArr = [];

    let ToArrData = []
    arrData.push(data);

    for (let i = 0; i <= arrData[0].length - 1; i++) {

      newArr.push(arrData[0]?.[i]?.toRoleName)
    }
    ToArrData.push([...new Set(newArr)].join(', '))
    // konsole.log("newnewArrArr",ToArrData)

    return (ToArrData)

  }

  konsole.log("datadatadatadataGetCommMediumData", props.GetCommMediumData)

  return (
    <>
      <div style={{
        maxHeight: 'calc(100vh - 185px)',
        overflowY: 'auto',
        borderTop: "1px solid black",
        // marginBottom:"20px"
      }}
        className="m-0 p-0"
      >

        <Table bordered className='m-0 p-0'>

          <thead className=" bg-light" style={{ position: "sticky", top: "-2px", background: "white", zIndex: "5", height: "40px" }}>

            <tr >
              <th className="text-center " style={{ border: "1px solid black" }}>Status</th>
              <th className="text-center " style={{ border: "1px solid black" }}>Occurrence</th>
              <th className="text-center " style={{ border: "1px solid black" }}>From</th>
              <th className="text-center " style={{ border: "1px solid black" }}>To</th>
              <th className="text-center " style={{ border: "1px solid black" }}>Medium</th>
              <th className="text-center " style={{ border: "1px solid black" }}>Template</th>
            </tr>

          </thead>



          {
            sortedData != null && sortedData?.filter(data => data?.occurrenceDetails?.toLowerCase().includes(props?.search.toLowerCase())).map((data, index) => {


              return (
                <>

                  <tr>
                    <td style={{ border: "1px solid black" }}>
                      <div className=' mt-2 d-flex justify-content-center align-items-center '>
                        <label className="switch" >
                          <input className="switch-input" type="checkbox" defaultChecked={data?.isActive}
                            onChange={isActiveHandleChange}
                            onClick={(e) => PutUpsertCommMediumApiFuctioniOnOFF(e, data)}
                          />
                          <span className="switch-label" data-on="On" data-off="Off"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </td>

                    <td style={{ border: "1px solid gray" }}>
                      <div className='d-flex justify-content-start align-items-center'>

                        {data.occurrenceDetails}
                      </div>
                    </td>
                    <td style={{ border: "1px solid gray" }}>
                      <div className=' d-flex justify-content-center align-items-center'>
                        {
                          fromRoleNameFuction(data?.commMediumRoles)
                        }
                      </div>
                    </td>
                    <td style={{ border: "1px solid gray", }}>
                      <div className=' d-flex justify-content-center align-items-center text-center'>

                        {
                          ToRoleNameFuction(data?.commMediumRoles)
                        }

                      </div>
                    </td>
                    <td style={{ border: "1px solid gray", }} >
                      <div className='d-flex justify-content-start align-items-start text-center flex-column'>
                        <div >
                          {
                            data.applicableEmailTempId ? "Email" : ""
                          }
                        </div>
                        <div> {data.applicableEmailTempId && data.applicableTextTempId ? <>&</> : ""}</div>

                        <div>
                          {
                            data.applicableTextTempId ? "Text" : ""
                          }
                        </div>

                      </div>
                    </td>

                    <td className=' ' style={{ border: "1px solid gray", }}>
                      <div className='px-1 m-0 p-0 d-flex justify-content-start align-items-start text-center'
                        style={{ cursor: "pointer", }}
                        onClick={() => addEditModalFun(data)}
                      >
                        View
                      </div>
                    </td>
                  </tr>
                </>
              )
            }

            )}
          <tbody>


          </tbody>
        </Table>
        {
          showAddModal == true &&
          <Viewandupdatemodal selectedRowData={selectedRowData} inblidEmailTemplate={inblidEmailTemplate} showAddModal={showAddModal} setShowAddModal={setShowAddModal} GetCommMediumPathApiFuction={props.GetCommMediumPathApiFuction} setupdateTableData={setupdateTableData} />
        }

      </div>
    </>
  );
}

export default BasicExample;