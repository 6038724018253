import React, { useContext, useEffect, useState } from 'react';
import konsole from '../../network/konsole';
import { StpperStepsContext } from './SeminarFile';

import './Seminar.css';
import commonLib from '../../control/commonLib';
const Metadata = ({ text, CallFunction, sendDataToMetaData,getAllDataBack,sendToMetaData}) => {

  const { setStepperSteps, venueAddressIdTextPublish, setsendToMetaData} = useContext(StpperStepsContext)

  const [ageData, setAgeData] = useState("")
  konsole.log("ageData", ageData)

  let getSeminarFilesDetails = JSON.parse(sendDataToMetaData)
  
  useEffect(()=>{
    konsole.log("Metadatata", getSeminarFilesDetails)
    if(sendToMetaData == "1"){
        reverseToCreate()
      }
    },[sendToMetaData])

    const FixedResult = {

      seminarType: getSeminarFilesDetails.seminarType,
      topics: getSeminarFilesDetails.seminar.topics,
      seminarData: getSeminarFilesDetails.seminar.seminarData,
      seminartime: getSeminarFilesDetails.seminar.seminarTime,
      seminarSeatsNo: getSeminarFilesDetails.seminar.noOfSeats,
      seminarVenue: getSeminarFilesDetails.seminar.seminarVenue,
      seminarMapLink: getSeminarFilesDetails.seminar.mapLink,
      seminarVideoLink: getSeminarFilesDetails.seminar.videoLink,
      seminarDescription: getSeminarFilesDetails.seminar.description,
      seminarSpeakerimg: getSeminarFilesDetails.seminar.speaker.speakerImage,
      seminarSpeakerName: getSeminarFilesDetails.seminar.speaker.speakerName,
      seminarVideoLink: getSeminarFilesDetails.seminar.speaker.speakerVideoLink,
      seminarSpeakerDescription: getSeminarFilesDetails.seminar.speaker.speakerDescription,
      seminarOrgPartner: getSeminarFilesDetails.seminar.organizerInformation.seminarOrganizingPartner,
      seminarCoordinatorName: getSeminarFilesDetails.seminar.coordinator.coordinatorName,
      seminarCoordinatorContact: getSeminarFilesDetails.seminar.coordinator.coordinatorContact,
      seminarcoordinatorEmail: getSeminarFilesDetails.seminar.coordinator.coordinatorEmail,
      seminarFare: getSeminarFilesDetails.seminar.seminarFare.isSeminarPaid,
      seminarLocation: getSeminarFilesDetails.seminar.venueLocation,
      seminarVenueAddress: getSeminarFilesDetails.seminar.venueAddressIdApi,
      seminarSpeakerId: getSeminarFilesDetails.seminar.speakerId,
      seminarCoordinatorId: getSeminarFilesDetails.seminar.coordinatorId,
      seminarVenueName: getSeminarFilesDetails.seminar.seminarVenueName,
      seminarSubtenantId: getSeminarFilesDetails.seminar.subtenantId,
      seminarFixedPrice: getSeminarFilesDetails.seminar.seminarFare.fareAmount



  }

  const reverseToCreate = () =>{

    text(0)
    setStepperSteps(0)

    getAllDataBack({
      getSpeakerId: FixedResult.seminarSpeakerId,
      getCoordinatorID: FixedResult.seminarCoordinatorId,
      getVenvueName: FixedResult.seminarVenueName,
      getSubtenantID: FixedResult.seminarSubtenantId,
      getFixedPrice: FixedResult.seminarFixedPrice,
      getFreePaidStatus: FixedResult.seminarFare,
      getImgUrl: FixedResult.seminarSpeakerimg
    })
    setsendToMetaData("2")
  }
  

  


  let ageLength;
  const CheckAge = (e) => {
   ageLength = e.target.value;
    konsole.log("lenghtofage", ageLength.length)
    if (commonLib.isNumberRegex(e.target.value)  ) {
      setAgeData(e.target.value)
    } else {
      setAgeData("")
    }
  }

  const AddAllAge = () =>{
   if( ageData.length < 3 ){
    alert("Submit Successfully")
   } else{
    alert("Please correct age")
   }
  }


  const SubmitAge = () => {
  
    Publish();
  }

  const Publish = () => {
    CallFunction(venueAddressIdTextPublish)


  }

  const BackToPreviem = () => {
    text(1)
    setStepperSteps(1)
  }
  return (
    <div className='container-fluid'>
      <p className='ms-3  fs-4 fw-bold seminartxt'>Metadata <small className='fw-lighter'>(if any)</small></p>
      <div className="mb-sm-2 m-3">
        <label for="topic" className="form-label">What is your age?</label>
        <input type="text" maxLength={2} className="  border-2 form-control" onChange={CheckAge} value={ageData} />
      </div>
      <button className='seminarbtn text-white ms-3 fs-5' onClick={AddAllAge} ><span className='fs-4 me-2'>+</span>Add</button>


      <div className='row'>
        <div className='col-12 h-100'>
          <br />

        </div>
      </div>






      <div className='float-start seminarfoot'>
        <button className='seminarbtn mt-2 ms-3 text-white' onClick={BackToPreviem} >Back</button>
      </div>
      <div className='float-end seminarfoot'>
        <button className='seminarbtn mt-2 me-3 text-white' onClick={SubmitAge}> Publish</button>
      </div>

    </div>
  )
}

export default Metadata