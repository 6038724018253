import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import './Seminar.css'
import { Form, Input, Cascader, Select, DatePicker, Button, Radio, Row, Col, Space, TimePicker, AutoComplete, notification } from 'antd';
import moment from 'moment'

import SpeakerInformation from './SpeakerInformation';
import OrganizerInformation from './OrganizerInformation';
import ListSeminar from './ListSeminar';
import Stepper from './Stepper';
import Services from '../../network/Services';
import konsole from '../../network/konsole';
import 'antd/dist/antd.css';
import AddEditVenue from '../AddEditVenue'
import Preview from './Preview'
import commonLib from '../../control/commonLib';
import { UserContext } from '../../App';
import Metadata from './Metadata';
import Congratulations from './Congratulations';
import PublishedSeminar from './PublishedSeminar';
import SchedulePlanner from '../FirmPartner/SchedulePlanner'
import SchedulePlanner2 from '../FirmPartner/SchedulePlanner2';
import { isNullUndefine } from '../../control/Constant';


export const GlobalData = createContext();


export const StpperStepsContext = React.createContext({
  IncStepperSteps: 0,
});
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const onFinishFailed = (errorInfo) => {
  konsole.log('Failed:', errorInfo);
};

const config = {
  rules: [
    {
      type: 'object',
      required: true,
      message: 'Please Select Date!',
    },
  ],
};

const time = {
  rules: [
    {
      type: 'object',
      required: true,
      message: 'Please Select Time!',
    },
  ],
};

const SeminarFile = () => {
  const { setLoader } = useContext(UserContext)
  const [form] = Form.useForm();
  const [sendToPreview, setsendToPreview] = useState("")
  const [sendToMetaData, setsendToMetaData] = useState("")
  const [previewBtn, setPreviewBtn] = useState("");
  const [seminarFareField, setSeminarFareField] = useState("")
  const [totalSpeakers, setTotalSpeakers] = useState([])
  const getCallPreviewFunction = (itemCallPreviewFunction) => {
    // sendToPreview = itemCallPreviewFunction;
    setsendToPreview(itemCallPreviewFunction)
  }

  const getCallMetaDataFunction = (itemgetCallMetaDataFunction) => {
    setsendToMetaData(itemgetCallMetaDataFunction)
  }

 


  const [venueAddressIdTextPublish, setVenueAddressIdTextPublish] = useState("")
  const [sendSeminarDetails, setSendSeminarDetails] = useState({})
  const [subtenantIdToApi, setSubtenantIdToApi] = useState()
  const [venueaddressId, setVenueAddressId] = useState("")
  const [speakerImgLink, setSpeakerImgLink] = useState("")
  const [IncStepperSteps, setStepperSteps] = useState(0)
  const [seminarOrfInf, setSeminarOrfInf] = useState("")
  const [description, setDescription] = useState("")
  const [seminarDate, setSeminarDate] = useState("")
  const [seminarTime, setSeminarTime] = useState("")
  const [radioValueNo, setRadioValueNo] = useState()
  const [fixedPrice, setFixedPrice] = useState("")
  const [radioValue, setRadiovalue] = useState("")
  const [ChangePage, setChangePage] = useState(0)
  const [vedioLink, setVedioLink] = useState("")
  const [venueData, setVenueData] = useState([]);
  const [addressId, setAddressId] = useState("")
  const [IdSpeaker, setIdSpeaker] = useState([])
  const [venueSave, setVenueSave] = useState("")
  const [isValid, setIsValid] = useState(false);
  const [visible, setVisible] = useState(false);
  const [stateForm, setStateForm] = useState();
  const [loggedId, setLoggedId] = useState("")
  const [location, setLocation] = useState("")
  const [venueLocation, setVenuLocation] = useState("")
  const [webinarLink, setWebinarLink] = useState("")
  const [editFuncData, setEditFuncData] = useState()
  const [editFuncData2, setEditFuncData2] = useState()
  const [changeBorderToTopButtons, setChangeBorderToTopButtons] = useState()
  let roleUserId = commonLib.getSessionStoarge("roleUserId")
  const [filteredSubtinentId, setfilteredSubtinentId] = useState()

  const [SemTopic, setSemTopic] = useState("")
  const [noSeats, setNOSeats] = useState("")
  const [mapLink, setMapLink] = useState("")
  const [coordId, setCoordId] = useState("")
  const [dataGet, setDataGet] = useState([])
  const [venue, setVenue] = useState("")
  const [paid, setPaid] = useState("")
  const [preData, setPreData] = useState(false)
  const [stopEditFunc, setStopEditFunc] = useState(false)
  const [stopSpeakerEditFunc, setStopSpeakerEditFunc] = useState(false)
  const [stopFareEditFunc, setStopFareEditFunc] = useState(false)
  const [multiData, setMultiSpeakersData] = useState([])
  const [multiDataName, setMultiSpeakersDataName] = useState([])
  const [previewData, setPreviewData] = useState({
    getSpeakerId: [],
    getCoordinatorID: [],
    getVenvueName: "",
    getSubtenantID: "",
    getFixedPrice: "",
    getFreePaidStatus: "",
    getImgUrl: "",
    backButton: false
  })
  const [mataData, setMetaData] = useState({
    getSpeakerId: "",
    getCoordinatorID: "",
    getVenvueName: "",
    getSubtenantID: "",
    getFixedPrice: "",
    getFreePaidStatus: "",
    getImgUrl: "",
  })
  const [speakerId, setSpeakerid] = useState(IdSpeaker || previewData.getSpeakerId || mataData.getSpeakerId)
 
  const BtnPreview = () => {
    let strValue = JSON.stringify(sendSeminarDetails)
    // if(PassingVenueAddressId == undefined || PassingVenueAddressId == null || PassingVenueAddressId == ""){
    //   openNotification('top',"Please enter the correct address")
    //   setLoader(false)
    // }else{

    setPreviewBtn("1")
    setPreviewData({
      backButton: false
    })
  // }
  }
  



  // const [allSpeakersData, setAllSpeakersData] = useState(second)
  useEffect(() => {

    if (editFuncData !== null && editFuncData !== undefined && editFuncData !== "") {
      setMultiSpeakersData(editFuncData?.speakers)

    }
  }, [editFuncData])

  // [Remove].................................................................................................
  const [coordName, setCoordName] = useState("")
  const [speakerdes, setSpeakerDes] = useState("")
  const [speakerName, setSpeakerName] = useState("")
  const [speakervideo, setSpeakerVideo] = useState("")
  const [coordEmailId, setCoordEmailId] = useState("")
  const [corrdContactNo, setCoordContactNo] = useState("")
  const [coordContactPerson, setCoordContactPerson] = useState("")
  // [Remove]......

  const disabledforfromDate = (current) => {
    let customDate = moment().format("YYYY-MM-DD");
    return current && current < moment(customDate, "YYYY-MM-DD");
  };


  const SeminarTopicValidation = (e) => {
    // if(!commonLib.isAlphabetRegex(e.target.value)){
    //   form.setFieldsValue({
    //     "topic" : ""
    //   })
    // }
    konsole.log("SeminarTopicValidation", e.target.value)
  }

  // Start ------------------------ListSeminar--------------Published & Publish

  const [publishseminar, setPublishSeminar] = useState(true);
  const [pastSeminar, setPastSeminar] = useState('')
  const [searchseminar, setSearchSeminar] = useState('')




  // End ------------------------ListSeminar--------------Published & Publish

  const sendSubtenantId = (itemSendSubtenantId) => {
    konsole.log("sendSubtenantId", itemSendSubtenantId)
    setSubtenantIdToApi(itemSendSubtenantId)
  }

  // .....................................{Notification}................................................


  const openNotification = (placement, errorStatus) => {

    if (errorStatus == 500) {

      notification.info({
        message: `Error ${errorStatus} `,
        description:
          'Server Issue! Please try after sometime.',
        placement,
      });
    } else {
      notification.info({
        message: `Error ${errorStatus} `,
        description:
          'Please fill the form correctly!.',
        placement,
      });
    }
  };

  // .....................................................................................







  const onFinish = (values) => {
   

    let venueAddressIdText = "";
    if (addressId == "") {
      venueAddressIdText = venueaddressId;
      setVenueAddressIdTextPublish(venueaddressId)
    } else if (venueaddressId == "") {
      venueAddressIdText = addressId;
      setVenueAddressIdTextPublish(addressId)
    }


    if (values.radioButton == "Free") {
      setPaid("false")
      setSeminarFareField(1)
    } else if (values.radioButton == "Paid") {
      setPaid("true")
    }




    const rangeValue = values['range-picker'];
    const rangeTimeValue = values['range-time-picker'];
    const setvalue = {
      ...values,
      'date-picker': values['date-picker'].format('MM-DD,YYYY'),
      'time-picker': values['time-picker'].format("h:mm a"),
    };
    konsole.log('Received values of form: ', values);
    konsole.log('Success:', JSON.stringify(values));


    const inputData = values;
    const input = {
      topic: inputData.topic,
      emaiID: inputData.email,
      date: inputData.date,
      time: inputData.time,
      noofSeats: inputData.seats,
      nameofSpeaker: inputData.NameofSpeaker,
      SpeakerDescription: inputData.DescriptionSpeaker,
      contact: inputData.contact,
      venueName: inputData.Venue,
      SpeakerVideoLink: inputData.VideoLinkOfSpeaker,
      coordinatorName: inputData.coordinatorField,
      coordinatorEmail: inputData.coordinatorEmail,
      coordinatorContact: inputData.coordinatorContact,
      fareAmount: inputData.SeminarFarePrices

    }

    konsole.log("oneinputData", inputData)
    konsole.log("oneinputData", JSON.stringify(input))
    setStateForm(input.values);
    setSemTopic(input.topic)
    setVenuLocation(input.venueName)
    setFixedPrice(input.fareAmount)


    if (input.values == true) {
      setIsValid(true)
      konsole.log(isValid);
    }



    let jsonObject = {

      "seminarType": " ",
      "seminar": {

        "topics": " ",
        "seminarData": " ",
        "seminarTime": " ",
        "noOfSeats": " ",
        "seminarVenue": " ",
        "mapLink": " ",
        "videoLink": " ",
        "description": " ",

        "speaker": {

          "speakerImage": " ",
          "speakerName": " ",
          "speakerVideoLink": " ",
          "speakerDescription": " ",

        },

        "organizerInformation": {

          "seminarOrganizingPartner": " ",
        },

        "coordinator": {

          "coordinatorName": " ",
          "coordinatorContact": " ",
          "coordinatorEmail": " ",
        },
        "seminarFare": {
          "isSeminarPaid": "",
          "fareAmount": ""
        },
        "venueLocation": " ",
        "venueAddressIdApi": " ",
        "speakerId": " ",
        "coordinatorId": " ",
        "seminarVenueName": " ",
        "subtenantId": " ",
      }
    }

    jsonObject.seminarType = radioValue;
    jsonObject.seminar.topics = inputData.topic;
    jsonObject.seminar.seminarData = seminarDate;
    jsonObject.seminar.seminarTime = seminarTime || input.time;
    jsonObject.seminar.noOfSeats = noSeats;
    jsonObject.seminar.seminarVenue = input.venueName;
    jsonObject.seminar.mapLink = mapLink;
    jsonObject.seminar.videoLink = vedioLink;
    jsonObject.seminar.description = description;
    jsonObject.seminar.speaker.speakerImage = speakerImgLink || previewData.getImgUrl || mataData.getImgUrl;
    jsonObject.seminar.speaker.speakerName = speakerName;
    jsonObject.seminar.speaker.speakerVideoLink = inputData.VideoLinkOfSpeaker;
    jsonObject.seminar.speaker.speakerDescription = speakerdes;
    jsonObject.seminar.organizerInformation.seminarOrganizingPartner = seminarOrfInf;
    jsonObject.seminar.coordinator.coordinatorName = inputData.coordinatorField;
    jsonObject.seminar.coordinator.coordinatorContact = inputData.coordinatorContact;
    jsonObject.seminar.coordinator.coordinatorEmail = inputData.coordinatorEmail;
    jsonObject.seminar.seminarFare.isSeminarPaid = values.radioButton;
    // jsonObject.seminar.seminarFare.isSeminarPaid = freePaid || previewData.getFreePaidStatus || mataData.getFreePaidStatus ;
    jsonObject.seminar.seminarFare.fareAmount = inputData.SeminarFarePrices;
    // jsonObject.seminar.seminarFare.fareAmount = fixedPrice || previewData.getFixedPrice || mataData.getFixedPrice;
    jsonObject.seminar.venueLocation = location;
    jsonObject.seminar.venueAddressIdApi = venueAddressIdText;
    jsonObject.seminar.speakerId = IdSpeaker || previewData.getSpeakerId || mataData.getSpeakerId;
    jsonObject.seminar.coordinatorId = coordId || previewData.getCoordinatorID || mataData.getCoordinatorID;
    jsonObject.seminar.seminarVenueName = venue || previewData.getVenvueName || mataData.getVenvueName;
    jsonObject.seminar.subtenantId = subtenantIdToApi || previewData.getSubtenantID || mataData.getSubtenantID;



    let strValue = JSON.stringify(jsonObject)
    setSendSeminarDetails(strValue)

    if (previewBtn == "1") {
      let addressId = jsonObject?.seminar?.venueAddressIdApi || venueaddressId;
      let speakerData = jsonObject?.seminar?.speakerId
         if((addressId == undefined || addressId == null || addressId == "" || speakerData?.length == 0 )&& (radioValueNo != "47") ){
            if(speakerData?.length == 0 && addressId == ""){
              openNotification('top', "Please enter the correct Venue")
              
              
              openNotification('top', "Please select minimum one speaker")
            }else if(speakerData?.length == 0){
              openNotification('top', "Please select minimum one speaker")
            }else{
                openNotification('top', "Please enter the correct Venue")
              
            }

           
           setLoader(false)
         }else{
          if(speakerData?.length == 0){
            openNotification('top', "Please select minimum one speaker") 
          }else{
            ChangeComponent();
            setStepperSteps(1)
            setPreviewBtn("0")
          }
         
           
         }
    }


    setPreData(true)
  };


  const onFinishFailed = (errorInfo) => {
    konsole.log('Failed:', errorInfo);
  };
  const calfuncheckselectid = (id) => {

    // konsole.log("idddddd",id,multiData)
    // let dataItem=multiData
    // for(let item of dataItem){
    //   if(id !== null && id !== undefined){

    //   if(item.speakerUserId==id){
    //     // alert("yes")
    //     return true
    //   }
    // }
    // }
  }



  useEffect(() => {
    getvenueData();
    let loggedUser = sessionStorage.getItem('loggedUserId')
    konsole.log('resp', loggedUser)
    setLoggedId(loggedUser)
  }, [])



  const getvenueData = () => {
    let subtenantId = sessionStorage.getItem('subtenantId')
    let callgetvenueApi = Services.getSeminarVenueData(subtenantId);
    callgetvenueApi
      .then((res) => {
        setVenueData(res.data.data);
      })
      .catch((error) => {
        konsole.log("response", error)
      })
  }


  const showUserModal = () => {
    let GetVenueField = form.getFieldValue("Venue")
    setVenueSave(GetVenueField)
    setVisible(true);
  };


  const hideUserModal = () => {
    setVisible(false);
  };


  useEffect(() => {
    const promise = Services.subtenantType();
    setLoader(true)
    promise.then((res) => {
      konsole.log("konsoleDataGet ", res.data.data)
      setDataGet(res.data.data)
      setLoader(false)
    }).catch((err) => {
      konsole.log(`error %%  ${err}`)
    }).finally(() => {
      setLoader(false)
    })
  }, [])


  useEffect(() => {
    form.setFieldsValue({
      "Venue": venue || previewData.getVenvueName || mataData.getVenvueName
    })
    konsole.log("AfterBacktoPage", venue)
  }, [venue])



  let timeZone = commonLib.timeConvertion(seminarTime)

  const StopNevativeNo = (e) => {

    if (e.target.value <= 0 || !commonLib.isNumberRegex(e.target.value)) {
      form.setFieldsValue({
        "Seat": ""
      })
    } else if (e.target.value > 0) {
      setNOSeats(e.target.value)
    }

  }


  const changeData = (date, dateString) => {
    konsole.log("Getting Date", date)
    setSeminarDate(dateString)
  }

  const StopWritingDate = () => {
    form.setFieldsValue({
      'date-picker': "",
    })
  }

  konsole.log("timeGetting", seminarTime)



  const ChangeComponent = () => {
    setChangePage(1)
    

  }



  const getDataId = (itemId) => {
    setCoordId(itemId)
  }

  const getSpeakerUserId = (itemId) => {
    // let uId = []
    // uId.push(itemId)
    // IdSpeaker(itemId)
    setIdSpeaker(itemId)
  }
  const getDataName = (itemName) => {
    setCoordName(itemName)
  }


  const getSpeakerName = (itemName) => {

    setSpeakerName(itemName)
  }

  const getSpeakerVideo = (itemVideo) => {
    setSpeakerVideo(itemVideo)
  }

  const getSpeakerDesCript = (itemDes) => {
    setSpeakerDes(itemDes);
  }

  const seminarOrg = (itmeSeminarOrg) => {
    setSeminarOrfInf(itmeSeminarOrg)
  }

  const getCoordContactPerson = (itemContactperson) => {
    setCoordContactPerson(itemContactperson)
  }

  const getCoordContactNo = (itemCoordContactNo) => {
    setCoordContactNo(itemCoordContactNo)
  }

  const getCoordEmail = (itemCoordEmail) => {
    setCoordEmailId(itemCoordEmail)
  }

  const getSpeakerImg = (itemImg) => {
    setSpeakerImgLink(itemImg)
  }


  let sendRadioName;
  const radioonchage = (a) => {
    form.setFieldsValue({"radioSelect": parseInt(a.value),})
    setRadioValueNo(a.value)
    setRadiovalue(a.label);



  }
  konsole.log("sendRadioName", radioValue)

  useEffect(() => {


  }, [])




  konsole.log("freefixedPricePaid", fixedPrice)
  konsole.log("freefixedPricePaid", paid)
  let coordinatorFullData = []
  let speakersDetails = []
  const CallFormApi = (PassingVenueAddressId) => {
    let data = {
      subtenantID: subtenantIdToApi || previewData.getSubtenantID || mataData.getSubtenantID,
      seminarID: (editFuncData !== undefined && editFuncData !== null && editFuncData !== "") ? editFuncData?.seminarId : 0,
      SemTopic: SemTopic,
      radioValueNo: radioValueNo,
      seminarDate: seminarDate,
      timeZone: timeZone,
      noSeats: noSeats,
      venueLocation: venueLocation,
      PassingVenueAddressId: PassingVenueAddressId,
      webinarLink: webinarLink,
      mapLink: mapLink,
      vedioLink: vedioLink,
      description: description,
      trueField: true,
      fieldValue: 1,
      fieldValueData: "create new Seminar",
      fieldZera: 0,
      IdSpeaker: IdSpeaker || previewData.getSpeakerId || mataData.getSpeakerId,
      fieldValueData1: "create new Seminar",
      fieldZera1: 0,
      coordID: coordId || previewData.getCoordinatorID || mataData.getCoordinatorID,
      paid: paid,
      fixedPrice: fixedPrice,
      loggedId: loggedId

    }

    // ...........................................................................................
    if (editFuncData !== null && editFuncData !== undefined && editFuncData !== "") {

      let mapEditFuncCoordUserId = editFuncData?.coordinators.map((data) => {
        return data?.coordinatorUserId
      })
      let mapEditFuncSpeakerUserId = editFuncData?.speakers.map((data) => {
        return data?.speakerUserId

      })
        let NewUserId = coordId
        .filter(x => !mapEditFuncCoordUserId.includes(x))
        .concat(coordId.filter(x => !coordId.includes(x))); // new userID



      let NewUserSpeakerId = IdSpeaker
        .filter(x => !mapEditFuncSpeakerUserId.includes(x))
        .concat(IdSpeaker.filter(x => !IdSpeaker.includes(x))); // new userID speaker


      let RemovedUserId = mapEditFuncCoordUserId.filter(x => !coordId.includes(x)); // removed userId cord
      let RepeatedUserId = mapEditFuncCoordUserId.filter(x => coordId.includes(x)); // repeated userId cord

      let RemovedSeminarUserId = mapEditFuncSpeakerUserId.filter(x => !IdSpeaker.includes(x)); // removed userId speaker
      let RepeatedSpeakerUserid = mapEditFuncSpeakerUserId.filter(x => IdSpeaker.includes(x)); // removed userId speaker



      konsole.log("mapEditFuncCoordUserIdmapEditFuncCoordUserId1", RemovedSeminarUserId)
      konsole.log("mapEditFuncCoordUserIdmapEditFuncCoordUserId", RemovedUserId)
      konsole.log("mapEditFuncCoordUserIdmapEditFuncCoordUserId", RepeatedUserId)



      // ..........................................................................................
      //  Removed Data


      for (let i = 0; i < RemovedUserId.length; i++) {
        let RemovedUserIdData = editFuncData?.coordinators.filter(x => {
          return x.coordinatorUserId == RemovedUserId[i]
        });
        // RemovedUserIdData[0]['isActive'] = false
        coordinatorFullData.push({
          'seminarCoordinatorId': RemovedUserIdData[0]?.seminarCoordinatorId,
          'coordinatorUserId': RemovedUserIdData[0]?.coordinatorUserId,
          'isActive': false
        })
        konsole.log("RemovedUserIdDataRemovedUserIdData", RemovedUserIdData)
      }
      konsole.log("coordinatorFullData", coordinatorFullData)

      // Repeated Data

      for (let i = 0; i < RepeatedUserId.length; i++) {
        let RepeatedUserIdData = editFuncData?.coordinators.filter(x => {
          return x.coordinatorUserId == RepeatedUserId[i]
        });
        // RepeatedUserIdData[0]['isActive'] = false
        coordinatorFullData.push({
          'seminarCoordinatorId': RepeatedUserIdData[0]?.seminarCoordinatorId,
          'coordinatorUserId': RepeatedUserIdData[0]?.coordinatorUserId,
          'isActive': true
        })
        konsole.log("RepeatedUserIdData", RepeatedUserIdData)
      }
      konsole.log("coordinatorFullData", coordinatorFullData)

      // NewUser 

      for (let i = 0; i < NewUserId.length; i++) {
        // let NewUserIdData = editFuncData?.coordinators.filter(x => {
        //   return x.coordinatorUserId == NewUserId[i] 
        // });
        // NewUserIdData[0]['isActive'] = false
        coordinatorFullData.push({
          'seminarCoordinatorId': 0,
          'coordinatorUserId': NewUserId[i],
          'isActive': true
        })
        // konsole.log("NewUserIdData",NewUserIdData)
      }

      // speaker edit..........................................

      for (let i = 0; i < RemovedSeminarUserId.length; i++) {
        let RemovedSpeakerUserIdData = editFuncData?.speakers.filter(x => {
          return x.speakerUserId == RemovedSeminarUserId[i]

        });

        speakersDetails.push({
          'seminarSpeakerId': RemovedSpeakerUserIdData[0]?.seminarSpeakerId,
          'speakerUserId': RemovedSpeakerUserIdData[0]?.speakerUserId,
          'isActive': false
        })

      }
      for (let i = 0; i < RepeatedSpeakerUserid.length; i++) {
        let RepeatedSpeakerUserIdData = editFuncData?.speakers.filter(x => {
          return x.speakerUserId == RepeatedSpeakerUserid[i]
        });
        // RepeatedUserIdData[0]['isActive'] = false
        speakersDetails.push({
          'seminarSpeakerId': RepeatedSpeakerUserIdData[0]?.seminarSpeakerId,
          'speakerUserId': RepeatedSpeakerUserIdData[0]?.speakerUserId,
          'isActive': true
        })
        konsole.log("RepeatedUserIdData", RepeatedSpeakerUserIdData)
      }


      for (let i = 0; i < NewUserSpeakerId.length; i++) {

        speakersDetails.push({
          'seminarSpeakerId': 0,
          'speakerUserId': NewUserSpeakerId[i],
          'isActive': true
        })

      }



      konsole.log("coordinatorFullData111", speakersDetails)


      // ..........................................................................................
      // for(let i = 0; i < RemovedUserId.length; i++){


      //   let RemovedUserIdData = editFuncData?.coordinator.filter((x)=>{
      //     return x?.coordinatorUserId == RemovedUserId[i] 
      //   })

      //   konsole.log("RemovedUserIdDataRemovedUserIdData",RemovedUserIdData)
      //   coordinatorFullData.push({
      //     "seminarCoordinatorId": 0,
      //     "coordinatorUserId": "Kartik",
      //   })

      // }

      // konsole.log("coordinatorFullData",coordinatorFullData)



    }
    else {
      // let roleId = commonLib.getObjFromStorage('stateObj').roleId


      for (let i = 0; i < coordId.length; i++) {
        coordinatorFullData.push({

          "seminarCoordinatorId": 0,
          "coordinatorUserId": coordId[i],
          "isActive": true


        })
      }
      // if(roleId == 4){

      //   coordinatorFullData.shift()
      for (let i = 0; i < IdSpeaker.length; i++) {
        speakersDetails.push({
          "seminarSpeakerId": 0,
          "speakerUserId": IdSpeaker[i],
          "remarks": null,
          "isActive": true
        })
      }
      // }

    }
    let speakerDataFinal = editFuncData !== null && editFuncData !== undefined && editFuncData !== "" ? multiData : speakersDetails



    // ...........................................................................................
    let seminarID = (editFuncData !== undefined && editFuncData !== null && editFuncData !== "" && isPastDate(editFuncData?.seminarDate,editFuncData?.seminarTime) == false && isNullUndefine(editFuncData?.currentFuture)) ? editFuncData?.seminarId : 0;
    let seminarSpeakerId = (editFuncData !== undefined && editFuncData !== null && editFuncData !== "") ? editFuncData?.speakers[0]?.seminarSpeakerId : 0;
    let seminarCoordinatorId = (editFuncData !== undefined && editFuncData !== null && editFuncData !== "") ? editFuncData?.coordinators[0]?.seminarCoordinatorId : 0;
 
    setLoader(true)

    let postSeminarPublisher = Services.postSeminarPublisherData(subtenantIdToApi || previewData.getSubtenantID || mataData.getSubtenantID, seminarID, SemTopic, radioValueNo, seminarDate, timeZone, noSeats, venueLocation, PassingVenueAddressId, webinarLink, mapLink, vedioLink, description, true, 1, "create new Seminar", seminarSpeakerId, IdSpeaker || previewData.getSpeakerId || mataData.getSpeakerId, "create new Seminar", coordinatorFullData || previewData.getCoordinatorID || mataData.getCoordinatorID, speakersDetails, paid, fixedPrice, loggedId);
    postSeminarPublisher.then((response) => {
      setLoader(false)
      konsole.log("responseSeminar", PassingVenueAddressId)

      setChangePage(3)
      setStepperSteps(8)
      setLoader(false)
    })
      .catch((err) => {
        setLoader(false)
        konsole.log('PreviousDataError', err)
        openNotification('top', err.response.status)
      }).finally(() => {
        setLoader(false);
      })
    

  }


  const GetVEnuueDataa = (a, key) => {
    konsole.log("editFuncDataGetVEnuueDataa", key)
    if (key !== undefined && key !== null && key !== "") {


      let filterdata = venueData.filter((items) => {
        return items.venueId == key.key
      })
      konsole.log("KeyFilterData", filterdata)

      form.setFieldsValue({
        "Venue": filterdata[0].venueText
      })
      konsole.log("FilterData", filterdata[0]?.addressId);
      getAddressByAddressId(filterdata[0]?.addressId);
      setAddressId(filterdata[0]?.addressId)
    } else {

      let filterdata = venueData.filter((items) => {
        return items.venueText == a
      })
      konsole.log("aFilterData", filterdata)
      getAddressByAddressId(filterdata[0]?.addressId);
      setAddressId(filterdata[0]?.addressId)

      // konsole.log("editFuncDataGetVEnuueDataa", a)
    }
  }

  const getAddressByAddressId = (addressId) => {
    const AddressIdToAddress = Services.getAddressId(addressId)
    AddressIdToAddress.then((res) => {
      konsole.log("AddressIdData", res.data.data.addressLine1)
      setLocation(res.data.data.addressLine1);
      setVenueAddressId(res?.data?.data?.addressId)

    }).catch((err) => {
      konsole.log("AddressIdError", err)
    })
  }

  konsole.log("filelddsds", form.getFieldValue('Venue'));
  konsole.log("ApiAddressId", venueaddressId)




  const webinarlinkhttp = (e) => {
    setWebinarLink(e.target.value)

  }

  konsole.log("editFuncDataeditFuncData", editFuncData, form.getFieldsValue(), radioValueNo);
  useEffect(() => {
    if (editFuncData !== undefined && editFuncData !== null && editFuncData !== "") {
      let isSeminarPast = isPastDate(editFuncData?.seminarDate,editFuncData?.seminarTime)
      let currentDate = new Date()
      const formattedDate = currentDate.toLocaleDateString('en-US', {month: '2-digit',day: '2-digit',year: 'numeric'});

      let PaidFreeDAta = editFuncData?.isSeminarPaid ? "Paid" : "Free"
      form.setFieldsValue({
        "radioSelect": parseInt(editFuncData?.seminarTypeId),
        "topic": editFuncData?.seminarTopic,
        "date-picker": moment(isSeminarPast === true || editFuncData?.currentFuture === true ? formattedDate : editFuncData?.seminarDate),
        "time-picker": isSeminarPast === true || editFuncData?.currentFuture === true ? "" :  moment(editFuncData?.seminarTime, 'HH:mm:ss'),
        "Seat": editFuncData?.noOfSeats,
        "Venue": editFuncData?.seminarVenue,
        "MapLink": editFuncData?.mapsLink,
        "VideoLink": editFuncData?.videoLink,
        "VenueDescription": editFuncData?.description,
        "NameofSpeaker": editFuncData?.speakers[0]?.fName + " " + editFuncData?.speakers[0]?.lName,
        //  "VideoLinkOfSpeaker": editFuncData?.speakers[0]?.videoLinkPath,
        //  "DescriptionSpeaker" : editFuncData?.speakers[0]?.speakerProfile,
        //  "seminarOrganizingPartner": editFuncData?.speakers[0]?.  ,
        // "coordinatorField" : editFuncData?.coordinators[0]?.coordinatorUserId,
        // "coordinatorContact" : editFuncData?.coordinators[0]?.   ,
        // "coordinatorEmail" : editFuncData?.coordinators[0]?.  ,
        radioButton: PaidFreeDAta,
        "SeminarFarePrices": editFuncData?.isSeminarPaid ? editFuncData?.seminarFee : "",
        "Webinar": (editFuncData?.seminarTypeId == 47) ? editFuncData?.webinarLink : ""
      })
      setRadioValueNo(editFuncData?.seminarTypeId)
      setRadiovalue(editFuncData?.seminarType)
      setSeminarDate(isSeminarPast === true || editFuncData?.currentFuture === true ? formattedDate : editFuncData?.seminarDate)
      setNOSeats(editFuncData?.noOfSeats)
      setVedioLink(editFuncData?.videoLink)
      setDescription(editFuncData?.description)
      setMapLink(editFuncData?.mapsLink)
      setSubtenantIdToApi(editFuncData?.subtenantId)
      if (editFuncData?.seminarTypeId == 47) {
        setWebinarLink(editFuncData?.webinarLink)

      } else {

        setWebinarLink("")
      }
      let timeZone1 = commonLib.timeConvertion(moment(editFuncData?.seminarTime, 'HH:mm:ss')._i)

      setSeminarTime(timeZone1)

      if (editFuncData?.seminarVenue !== null && editFuncData?.seminarVenue !== undefined && editFuncData?.seminarVenue !== "") {

        GetVEnuueDataa(editFuncData?.seminarVenue)
      }
      konsole.log("moment(editFuncData?.seminarTime, 'HH:mm:ss')", commonLib.timeConvertion(moment(editFuncData?.seminarTime, 'HH:mm:ss')._i), "timeZone", seminarTime)
      konsole.log("editFuncData?.speakers[0]?.VideoLinkOfSpeaker", editFuncData?.speakers[0]?.VideoLinkOfSpeaker)
      konsole.log("editFuncData?.subtenantId", editFuncData?.subtenantId)
      // timeZone = moment(editFuncData?.seminarTime, 'HH:mm:ss')._i
    }

  }, [editFuncData])
  function isPastDate(dateString, timeString) {
    const currentDate = new Date();
    const selectedDate = new Date(dateString);
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    selectedDate.setHours(hours, minutes, seconds);
    return selectedDate < currentDate;
}
  // konsole.log("seminarDateseminarDate", seminarDate)
  // konsole.log("subtenantIdToApisubtenantIdToApi", subtenantIdToApi, seminarTime)
  // konsole.log("webinarLinkwebinarLink", webinarLink)
  // konsole.log("coordIdcoordIdcoordId", coordId)

  //   $('input[type="text"]').blur(function(event) {
  //     event.target.checkValidity();
  // }).bind('invalid', function(event) {

  //   $(this).focus();
  // });

  return (
    <>
      <div className='mb-5' style={{ height: "auto" }}>
        <ListSeminar setPublishSeminar={setPublishSeminar} changeBorderToTopButtons={changeBorderToTopButtons} setChangeBorderToTopButtons={setChangeBorderToTopButtons} publishseminar={publishseminar} setPastSeminar={setPastSeminar} setSearchSeminar={setSearchSeminar}setfilteredSubtinentId={setfilteredSubtinentId} />
        {publishseminar ?
          <StpperStepsContext.Provider value={{ IncStepperSteps: IncStepperSteps, setStepperSteps: setStepperSteps, venueAddressIdTextPublish: venueAddressIdTextPublish, getCallPreviewFunction: getCallPreviewFunction, setsendToPreview: setsendToPreview, setsendToMetaData: setsendToMetaData, getCallMetaDataFunction: getCallMetaDataFunction }}>
            <Stepper steps={IncStepperSteps} form={form} callFunction={onFinish} setPreviewBtn={setPreviewBtn} setChangePage={setChangePage} preData={preData} speakerId={speakerId} />
            <br />
            {/* <br /> */}
            <div className='saminar-list-scroll'>
              {ChangePage == 1 ? (<Preview value="1" editFuncData={editFuncData} stopEditFunc={stopEditFunc} setStopEditFunc={setStopEditFunc} setStopSpeakerEditFunc={setStopSpeakerEditFunc} stopSpeakerEditFunc={stopSpeakerEditFunc} stopFareEditFunc={stopFareEditFunc} setStopFareEditFunc={setStopFareEditFunc} Form={stateForm} form={form} callFunction={onFinish} text={setChangePage} sendDataToPrev={sendSeminarDetails} getAllIdBack={setPreviewData} sendToPreview={sendToPreview} CallFunction={CallFormApi} multiSpeakersData={multiData} />) : ChangePage == 2 ? (<Metadata text={setChangePage} CallFunction={CallFormApi} sendDataToMetaData={sendSeminarDetails} getAllDataBack={setMetaData} sendToMetaData={sendToMetaData} />) : ChangePage == 3 ? (<Congratulations text={setChangePage} />) :

                (
                  <Form.Provider
                    onFormFinish={(name, { values, forms }) => {
                      if (name === 'userForm') {
                        const { basicForm } = forms;

                        setVisible(false);
                      }
                    }}>
                    <Form
                      name="register"
                      form={form}

                      onFinish={onFinish}
                      layout="vertical"
                      // initialValues={{ remember: true, }}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"

                    // scrollToFirstError={true}
                    >

                      <div className=' ps-2 pe-2 rounded' style={{ border: "1px dashed black", marginRight: "5px" }}
                      >
                        <div className='d-flex justify-content-between'>
                          {/* <Col className='col-sm-8 fs-4 fw-bold seminartxt'>Seminar Details</Col> */}
                          <div className=' fs-4 fw-bold seminartxt d-inline-block'>Seminar Details</div>
                          <div className='d-inline-block mt-2'>
                            {/* <Col span={9}  > */}

                            <span className='seminartype-clas '> <span className='text-danger '>* </span>Seminar Type : </span>

                            <Form.Item
                              className='martopradio d-inline-block p-0'
                              name="radioSelect"
                              rules={[
                              {
                                required :  radioValue === "",
                                message: "Please choose seminar type!"
                              }
                            ]} >

                              {
                                dataGet.map((items, value) => {
                                  return (
                                    <>
                                    <span onChange={() => radioonchage(items)} className='RadioType d-inline-flex p-0 m-0'>
                                    <Radio className='gap-1' value={items.value}  checked={(radioValueNo == items.value) ? true : false} key={items.label} style={{ fontSize: "15px" }}  > {items.label}</Radio>

                                    </span>
                                    </>
                                  )
                                })
                              }
                            </Form.Item>



                          </div>
                          {/* </Col> */}
                        </div>


                        <Form.Item
                          name="topic"
                          label="Topic"
                          className='fs-2 m-0 p-0'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Topic!',
                            },
                          ]}
                        >

                          <Input placeholder="Seminar Topic" style={{ color: "rgba(215, 155, 72, 1)" }} className='fw-bold fs-2 border-2' onChange={SeminarTopicValidation} onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)} />
                        </Form.Item>
                        <Row className='mt-2  d-flex justify-content-between'>
                          <Col>
                            <Form.Item
                              name="date-picker"
                              label="Date"
                              required {...config} >
                              <DatePicker className=' SeminarDate border-2 ' format="MM/DD/YYYY" inputReadOnly={true} onChange={changeData}
                                disabledDate={disabledforfromDate}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              name="time-picker"
                              label="Time"
                              // locale="sv-sv"
                              required {...time} >
                              <TimePicker className=' SeminarTime border-2 ' format="h:mm a" inputReadOnly={true} onChange={(time, timeString) => setSeminarTime(timeString)}/>
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              name="Seat"
                              label="No: of Seats"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Enter Your Seat No!',

                                },

                              ]}
                            >
                              <Input className='NumOfSeats fs-5 border-2 ' maxLength={3}
                                // onChange={(e) => setNOSeats(e.target.value)}
                                onChange={StopNevativeNo}
                              />
                            </Form.Item>
                          </Col>
                        </Row>



                        {(radioValueNo == null || radioValueNo == 46) ?
                          <Form.Item
                            name="Venue"
                            label="Venue"

                            rules={
                              [
                                {
                                  required: true,
                                  message: "Venue is required!"
                                }
                              ]
                            }
                          >
                            {<AutoComplete
                              // value={venue}
                              onSelect={GetVEnuueDataa}
                              // onSearch={(value) => {
                              //   konsole.log("value search", value)
                              // }
                              // }

                              className='fs-5 border-2'
                              
                              notFoundContent={(
                                <div style={{display:"flex",justifyContent:"start"}}>
                                  <Button
                                    // type="primary" 
                                    style={{ float: "right", background: "#720c20", color: "white", border: "none",}} onClick={showUserModal} >Add</Button>
                                  {/* <p className='text-danger fw-bold' style={{ float: "left" }}>Speaker Not Found</p> */}
                                </div>
                              )}
                              filterOption={(inputValue, option) =>
                                option.value
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }


                            >

                              {venueData.map((items, index) => {

                                return <Option value={items.venueText} key={items.venueId} style={{ height: "200" }}> {items.venueText} </Option>;


                              })}
                            </AutoComplete>}

                          </Form.Item> : (radioValueNo == 47) ?
                            <Form.Item
                              name="Webinar"

                              label="Webinar Link"
                              rules={[
                                // {pattern: new RegExp('https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|http?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}')
                                // , message: 'Please enter valid url with http!'},
                                { required: true, message: 'Webinar Link is required!!' }

                              ]}
                            >
                              <Input className='fs-5 border-2' defaultValue={(editFuncData?.seminarTypeId == 47) ? editFuncData?.webinarLink : ""} onChange={webinarlinkhttp} placeholder="https://" />
                            </Form.Item> : ''
                        }





                        <Form.Item
                          name="MapLink"
                          label="Maps link (if any)"
                          rules={[
                            {
                              pattern: new RegExp('https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|http?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}')
                              , message: 'Please enter valid url with https!'
                            }

                          ]}
                        >
                          <Input className='fs-5 border-2' onChange={(e) => setMapLink(e.target.value)} placeholder="https://" />
                        </Form.Item>
                        <Form.Item label="Video upload link"
                          name="VideoLink"
                          rules={[

                            {
                              pattern: new RegExp('https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|http?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}')
                              , message: 'Please enter valid url with https!'
                            }
                          ]}
                        >
                          <Input className='fs-5 border-2' onChange={(e) => setVedioLink(e.target.value)} placeholder="https://" />
                        </Form.Item>
                        <Form.Item label="Description"
                          name="VenueDescription"
                          rules={[
                            {
                              required: true,
                              message: "Description Required!"
                            }
                          ]}
                        >
                          <TextArea rows={6} className="border-2" onChange={(e) => setDescription(e.target.value)} onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)} />
                        </Form.Item>
                      </div>

                      <GlobalData.Provider value={{ getDataId: getDataId, getSpeakerUserId: getSpeakerUserId, getDataName: getDataName, VenueSave: venueSave, getSpeakerName: getSpeakerName, getSpeakerVideo: getSpeakerVideo, getSpeakerDesCript: getSpeakerDesCript, seminarOrg: seminarOrg, getCoordContactPerson: getCoordContactPerson, getCoordContactNo: getCoordContactNo, getCoordEmail: getCoordEmail, getSpeakerImg: getSpeakerImg, sendSubtenantId: sendSubtenantId, setSubtenantIdAgain: previewData.getSubtenantID || mataData.getSubtenantID, seminarFareField: seminarFareField, setSeminarFareField: setSeminarFareField, editFuncData: editFuncData, setStopEditFunc: setStopEditFunc, stopEditFunc: stopEditFunc, setStopSpeakerEditFunc: setStopSpeakerEditFunc, stopSpeakerEditFunc: stopSpeakerEditFunc, setStopFareEditFunc: setStopFareEditFunc, stopFareEditFunc: stopFareEditFunc, previewData: previewData }} >

                        <SpeakerInformation setTotalSpeakers={setTotalSpeakers} editSpeakerData={editFuncData} statuSpeakerId={setIdSpeaker} formSeminar={form} IdSpeaker={IdSpeaker} setImgAgain={previewData.getImgUrl || mataData.getImgUrl} setMultiSpeakersData={setMultiSpeakersData} multiData={multiData} backClicked={previewData.backButton} multiDataName={multiDataName} setMultiSpeakersDataName={setMultiSpeakersDataName} />
                        {roleUserId == 6 ? 
                        // <SchedulePlanner seminarDate={seminarDate}/>
                        <SchedulePlanner2 seminarDate={seminarDate}/>
                        

                        : ""}

                        <OrganizerInformation form={form} />

                        <AddEditVenue form={form} visible={visible} getAddressByAddressId={getAddressByAddressId} onCancel={hideUserModal} status={setVenue} statusId={setVenueAddressId} RecallgetvenueDataAPI={getvenueData} />

                      </GlobalData.Provider>

                      <Form.Item
                        wrapperCol={{
                          // offset: 8,
                          span: 24,
                        }}
                      >
                        <div className='d-flex justify-content-center pt-3 pb-2' >
                          <button className='seminarbtn  text-white'
                            htmlType='submit' onClick={BtnPreview} 
                            style={{fontWeight:500}}
                            >
                            Preview
                          </button>
                        </div>
                      </Form.Item>

                    </Form>
                  </Form.Provider>
                )
              }


            </div>
          </StpperStepsContext.Provider>

          :
          <PublishedSeminar pastSeminar={pastSeminar} setEditFuncData={setEditFuncData} setEditFuncData2={setEditFuncData2} setChangeBorderToTopButtons={setChangeBorderToTopButtons} setPublishSeminar={setPublishSeminar} searchseminar={searchseminar}filteredSubtinentId={filteredSubtinentId} />

        }
      </div>



    </>
  );
}

export default SeminarFile;
